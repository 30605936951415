

import {Injectable} from '@angular/core';
import {SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';

@Injectable()

export class MyWarrantiesService {

  @SessionStorage('phx.toolbox.ui.myWarranties.lastPageNumberLoaded')
  lastPageNumberLoaded: number;

  @SessionStorage('phx.toolbox.ui.myWarranties.lastSearchType')
  lastSearchType: number;

  @SessionStorage('phx.toolbox.ui.myWarranties.lastSearchText')
  lastSearchText: string;

  @SessionStorage('phx.toolbox.ui.myWarranties.lastLongTermFlag')
  lastLongTermFlag: boolean;

  @SessionStorage('phx.toolbox.ui.myWarranties.lastTerritorySearchResultsFlag')
  lastTerritorySearchResultsFlag: boolean;


  constructor(private loginService: PhxLoginService) {

    // on login success, clear saved info
    this.loginService.onLoginSuccess.push(Observable.create((subscriber: Subscriber<boolean>) => {
      this.clearSavedInfo();

      subscriber.next(true);
      subscriber.complete();
    }));


    // on login failure, clear saved info
    this.loginService.onLoginFailure.push(Observable.create((subscriber: Subscriber<boolean>) => {
      this.clearSavedInfo();

      subscriber.next(true);
      subscriber.complete();
    }));


    // on logout
    this.loginService.onLogout.push(Observable.create((subscriber: Subscriber<boolean>) => {
      this.clearSavedInfo();

      subscriber.next(true);
      subscriber.complete();
    }));

  }


  clearSavedInfo() {
    this.lastPageNumberLoaded = null;
    this.lastSearchType = null;
    this.lastSearchText = null;
    this.lastLongTermFlag = null;
    this.lastTerritorySearchResultsFlag = null;
  }


}
