

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {Customer, Employer, EmployerLookupParams, EmployerNameTranslation} from '@orhp/phx-customer-ui-module';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PhxSerializer, RoutingUtility, StringUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from '../_services/persistence.service';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {Relationship} from '@orhp/phx-plan-ui-module';
import {OfficeAgentLookupCacheService, OfficeLookupType} from '../_services/lookup-cache.service';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {IOfficeAgentLookupAuthenticationInterface} from '../_services/office-agent-lookup-authentication-interface';
import {Observable} from 'rxjs/Rx';
import {ProductOffice, ProductOfficeGroupLookupParams, ProductOfficeGroupLookupService} from '@orhp/phx-product-ui-module';
import {Subscriber} from 'rxjs/Subscriber';

@Component({
  selector: 'app-office-agent-lookup',
  templateUrl: './office-lookup.component.html',
  styleUrls: [
    '../office-agent-lookup.scss',
    './office-lookup.component.scss'
  ]
})


export class OfficeLookupComponent implements OnInit {

  @ViewChild('searchPhoneTextInput')
  searchPhoneTextInput: ElementRef;

  @ViewChild('searchEmployerNameTextInput')
  searchEmployerNameTextInput: ElementRef;

  @ViewChild('searchEmployerCityTextInput')
  searchEmployerCityTextInput: ElementRef;

  @ViewChild('searchEmployerStateTextInput')
  searchEmployerStateTextInput: ElementRef;



  get selectedOfficeLookupType(): OfficeLookupType {
    return this.officeAgentLookupCacheService.selectedOfficeLookupType;
  }

  set selectedOfficeLookupType(value: OfficeLookupType) {
    this.officeAgentLookupCacheService.selectedOfficeLookupType = value;
  }



  get lastOfficeLookupType(): OfficeLookupType {
    return this.officeAgentLookupCacheService.lastOfficeLookupType;
  }

  set lastOfficeLookupType(value: OfficeLookupType) {
    this.officeAgentLookupCacheService.lastOfficeLookupType = value;
  }



  get searchEmployerPhone(): string {
    return this.officeAgentLookupCacheService.searchEmployerPhone;
  }

  set searchEmployerPhone(value: string) {
    this.officeAgentLookupCacheService.searchEmployerPhone = value;
  }


  get searchEmployerName(): string {
    return this.officeAgentLookupCacheService.searchEmployerName;
  }


  set searchEmployerName(value: string) {
    this.officeAgentLookupCacheService.searchEmployerName = value;
  }


  get searchEmployerCity(): string {
    return this.officeAgentLookupCacheService.searchEmployerCity;
  }


  set searchEmployerCity(value: string) {
    this.officeAgentLookupCacheService.searchEmployerCity = value;
  }


  get searchEmployerStateCode(): string {
    return this.officeAgentLookupCacheService.searchEmployerStateCode;
  }


  set searchEmployerStateCode(value: string) {
    this.officeAgentLookupCacheService.searchEmployerStateCode = value;
  }



  get recentOfficeResults(): Employer[] {
    return this.officeAgentLookupCacheService.recentOfficeResults;
  }


  set recentOfficeResults(value: Employer[]) {
    this.officeAgentLookupCacheService.recentOfficeResults = value;
  }


  get exactOfficeMatchResults(): Employer[] {
    return this.officeAgentLookupCacheService.exactOfficeMatchResults;
  }


  set exactOfficeMatchResults(value: Employer[]) {
    this.officeAgentLookupCacheService.exactOfficeMatchResults = value;
  }


  get fuzzyOfficeMatchResults(): Employer[] {
    return this.officeAgentLookupCacheService.fuzzyOfficeMatchResults;
  }


  set fuzzyOfficeMatchResults(value: Employer[]) {
    this.officeAgentLookupCacheService.fuzzyOfficeMatchResults = value;
  }


  officeLookupRunningFlag = false;
  officeLookupErrorMessage: string = null;


  get isPlanOrder(): boolean {
    return this.officeAgentLookupService.isPlanOrder;
  }

  // lookup relationship type
  get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
    return this.officeAgentLookupService.lookupRelationshipType;
  }


  get relationship(): Relationship {
    const lookupRelationshipType = this.lookupRelationshipType;

    let relationship: Relationship = null;

    if (lookupRelationshipType) {
      this.planLookupValueService.relationships.forEach((indexRelationship: Relationship) => {
        let correctRelationshipFlag = false;

        correctRelationshipFlag = correctRelationshipFlag ||
          (lookupRelationshipType.initiatingAgentFlag && indexRelationship.initiatingAgentFlag);

        correctRelationshipFlag = correctRelationshipFlag ||
          (lookupRelationshipType.cooperatingAgentFlag && indexRelationship.cooperatingAgentFlag);

        correctRelationshipFlag = correctRelationshipFlag ||
          (lookupRelationshipType.escrowOfficerFlag && indexRelationship.closingFlag);

        if (correctRelationshipFlag) {
          relationship = indexRelationship;
        }
      });
    }

    return relationship;
  }


  get authenticationService(): IOfficeAgentLookupAuthenticationInterface {
    return <IOfficeAgentLookupAuthenticationInterface>this.loginService.authenticationService;
  }


  get searchTitle(): string {
    let title = 'Search By Office:';

    const relationship = this.relationship;

    if (relationship) {
      if (relationship.initiatingAgentFlag) {
        title = 'Search for Initiating Real Estate Agent office:';

      } else if (relationship.cooperatingAgentFlag) {
        title = 'Search for Cooperating Real Estate Agent office:';

      } else if (relationship.closingFlag) {
        title = 'Search for Closing Company:';
      }
    }

    return title;
  }



  get skipLinkText(): string {
    let text: string = null;

    const relationship = this.relationship;

    if (relationship) {
      if (relationship.initiatingAgentFlag) {
        text = 'For Sale By Owner - no real estate agent involved';

      } else if (relationship.cooperatingAgentFlag) {
        text = 'No Cooperating Real Estate Agent Involved';
      }
    }

    return text;
  }


  get productOfficeEmail(): string {
    return this.officeAgentLookupService.productOfficeEmail;
  }


  get productOfficeID(): number {
    return this.officeAgentLookupService.productOfficeID;
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private loginService: PhxLoginService,
              private planLookupValueService: PlanLookupValueService,
              private productOfficeGroupLookupService: ProductOfficeGroupLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
              private customerLookupValueService: CustomerLookupValueService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private employerLookupService: EmployerLookupService) {
  }


  ngOnInit() {
    // default the office lookup to phone
    if (!this.selectedOfficeLookupType) {
      this.selectedOfficeLookupType = OfficeLookupType.officePhone;
    }

    // default the state search if the user is logged in
    if (this.searchEmployerStateCode === '') {
      const employer = this.authenticationService.employer;

      if (employer && employer.physicalAddress) {
        this.searchEmployerStateCode = employer.physicalAddress.stateCode;
      }
    }

    // if no previous search was run
    if (!this.lastOfficeLookupType) {
      this.lastOfficeLookupType = OfficeLookupType.none;

      // fetch recent offices
      if (this.lookupRelationshipType.shouldFetchRecentOffices) {
        this.fetchRecentOffices();
      }

      // if we're auto-focusing the search
      if (this.officeAgentLookupService.autoFocusOfficeSearchFlag) {
        // set the focus on the phone number
        if (this.selectedOfficeLookupType === OfficeLookupType.officePhone) {
          setTimeout(() => {
            this.searchPhoneTextInput.nativeElement.focus();
          });

        } else if (this.selectedOfficeLookupType === OfficeLookupType.officeName) {
          setTimeout(() => {
            this.searchEmployerNameTextInput.nativeElement.focus();
          });
        }
      }
    }
  }



  shouldDisplaySkipTopLink(): boolean {
    let displayFlag = false;

    if (this.lookupRelationshipType.initiatingAgentFlag) {
      displayFlag = true;
    }

    return displayFlag;
  }


  shouldDisplaySkipBottomLink(exactMatchFlag?: boolean): boolean {
    let displayFlag = false;

    if (this.lookupRelationshipType.cooperatingAgentFlag) {
      displayFlag = true;
    }

    return displayFlag;
  }



  shouldDisplayRecentOfficesMessage(): boolean {
    let displayFlag = false;

    if (this.lastOfficeLookupType === OfficeLookupType.recentOffices) {
      displayFlag = true;
    }

    return displayFlag;
  }


  shouldDisplaySearchResultsMessage(): boolean {
    let displayFlag = false;

    // restrict by search type
    let userSearchFlag = false;
    userSearchFlag = userSearchFlag || (this.lastOfficeLookupType === OfficeLookupType.officePhone);
    userSearchFlag = userSearchFlag || (this.lastOfficeLookupType === OfficeLookupType.officeName);

    // restrict by offices found
    if (this.lastOfficeLookupType === OfficeLookupType.recentOffices) {
      displayFlag = displayFlag && !!this.recentOfficeResults.length;

    } else if (userSearchFlag) {
      if (this.exactOfficeMatchResults && this.exactOfficeMatchResults.length) {
        displayFlag = true;
      }

      if (this.fuzzyOfficeMatchResults && this.fuzzyOfficeMatchResults.length) {
        displayFlag = true;
      }
    }

    return displayFlag;
  }


  shouldDisplayNoSearchResultsMessage(): boolean {
    let resultsFoundFlag = false;

    // restrict by search type
    if (this.lastOfficeLookupType === OfficeLookupType.officePhone) {
      resultsFoundFlag = resultsFoundFlag || !!this.exactOfficeMatchResults.length;

    } else if (this.lastOfficeLookupType === OfficeLookupType.officeName) {
      resultsFoundFlag = resultsFoundFlag || !!this.exactOfficeMatchResults.length;
      resultsFoundFlag = resultsFoundFlag || !!this.fuzzyOfficeMatchResults.length;

    } else if (this.lastOfficeLookupType === OfficeLookupType.recentOffices) {
      resultsFoundFlag = resultsFoundFlag || !!this.recentOfficeResults.length;
    }

    let displayFlag = true;

    if (this.lastOfficeLookupType === OfficeLookupType.none) {
      displayFlag = false;

    } else if (resultsFoundFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }



  shouldDisplayPhoneLookupFields(): boolean {
    return (this.selectedOfficeLookupType === OfficeLookupType.officePhone);
  }


  shouldDisplayNameLookupFields(): boolean {
    return (this.selectedOfficeLookupType === OfficeLookupType.officeName);
  }


  // fetches recent offices
  fetchRecentOffices() {
    const loginToken = this.loginService.loginTokenGetter();
    const loggedIn = (loginToken ? loginToken.loggedIn() : false);

    const planOrderFlag = this.officeAgentLookupService.isPlanOrder;

    if (loggedIn && planOrderFlag) {
      const params = new EmployerLookupParams();

      params.recentEmployersFlag = true;
      params.recentEmployersRelationship = this.relationship.relationship;

      this.officeLookupRunningFlag = true;

      this.employerLookupService.fetchEmployers(params).subscribe((employers: Employer[]) => {
        this.officeLookupRunningFlag = false;

        if (employers.length) {
          this.recentOfficeResults = employers;

          this.lastOfficeLookupType = OfficeLookupType.recentOffices;

        } else {
          this.lastOfficeLookupType = OfficeLookupType.none;
        }

      });
    }

  }



  didClickPhoneSearchButton() {
    this.selectedOfficeLookupType = OfficeLookupType.officePhone;

    setTimeout(() => {
      this.searchPhoneTextInput.nativeElement.focus();
      this.searchPhoneTextInput.nativeElement.select();
    });
  }


  didClickNameSearchButton() {
    this.selectedOfficeLookupType = OfficeLookupType.officeName;

    setTimeout(() => {
      this.searchEmployerNameTextInput.nativeElement.focus();
      this.searchEmployerNameTextInput.nativeElement.select();
    });
  }








  // user clicked the office search button
  didClickOfficeSearch() {
    this.officeLookupErrorMessage = null;

    // validate the form
    const errorText = this.validateForm();

    // if there are errors
    if (!!errorText) {
      this.officeLookupErrorMessage = errorText;

    // no errors, continue
    } else {
      this.officeLookupErrorMessage = null;

      const params = new EmployerLookupParams();

      // is there a product office specified
      const productOfficeFlag = ((this.productOfficeEmail || '') !== '') || !!this.productOfficeID;

      // if there is no email specified
      if (!productOfficeFlag) {
        params.employerTypeCode = this.lookupRelationshipType.lookupEmployerTypeCode;

      } else {
        params.productOfficeID = this.productOfficeID;
        params.productOfficeEmail = this.productOfficeEmail;
      }

      // phone search
      if (this.selectedOfficeLookupType === OfficeLookupType.officePhone) {
        params.phone = this.searchEmployerPhone;

      // name/city search
      } else if (this.selectedOfficeLookupType === OfficeLookupType.officeName) {
        params.employerName = this.searchEmployerName || '';
        params.city = this.searchEmployerCity || '';
        params.stateCode = this.searchEmployerStateCode;
      }

      // clear the focus from the search fields
      setTimeout(() => {
        if (this.searchPhoneTextInput) {
          this.searchPhoneTextInput.nativeElement.blur();
        }

        if (this.searchEmployerNameTextInput) {
          this.searchEmployerNameTextInput.nativeElement.blur();
        }

        if (this.searchEmployerCityTextInput) {
          this.searchEmployerCityTextInput.nativeElement.blur();
        }

        if (this.searchEmployerStateTextInput) {
          this.searchEmployerStateTextInput.nativeElement.blur();
        }
      });

      // execute the office lookup and store the results
      this.officeLookupRunningFlag = true;

      this.employerLookupService.fetchEmployers(params).subscribe((employers: Employer[]) => {
        this.parseEmployerResults(employers);

        this.officeLookupRunningFlag = false;

        this.lastOfficeLookupType = this.selectedOfficeLookupType;
      });
    }
  }




  validateForm(): string {
    let errorText: string = null;

    if (this.selectedOfficeLookupType === OfficeLookupType.officePhone) {
      const searchPhone = (this.searchEmployerPhone || '');
      let validFlag = true;

      // if the phone is not empty
      if (searchPhone !== '') {
        // validate the phone
        if (!StringUtility.phoneValidate(this.searchEmployerPhone || '')) {
          validFlag = false;
        }

      // if the phone is empty
      } else {
        validFlag = false;
      }

      // if the phone isn't valid
      if (!validFlag) {
        errorText = 'Enter Main Office Phone to search';
      }

    // name/city search
    } else if (this.selectedOfficeLookupType === OfficeLookupType.officeName) {
      const searchName = (this.searchEmployerName || '');
      const searchCity = (this.searchEmployerCity || '');

      let validFlag = true;

      // if the name isn't filled in
      if (searchName === '') {
        validFlag = false;
      }

      // if the city isn't filled in
      if (searchCity === '') {
        validFlag = false;
      }

      if (!validFlag) {
        errorText = 'Enter Office Name/City/State to search';
      }
    }

    return errorText;
  }






  parseEmployerResults(employers: Employer[]) {
    const exactOfficeMatchResults = [];
    const fuzzyOfficeMatchResults = [];

    // phone
    if (this.selectedOfficeLookupType === OfficeLookupType.officePhone) {
      employers.forEach((indexEmployer: Employer) => {
        exactOfficeMatchResults.push(indexEmployer);
      });

    // name
    } else if (this.selectedOfficeLookupType === OfficeLookupType.officeName) {
      employers.forEach((indexEmployer: Employer) => {
        if (this.isExactNameMatch(indexEmployer)) {
          exactOfficeMatchResults.push(indexEmployer);

        } else {
          fuzzyOfficeMatchResults.push(indexEmployer);
        }
      });
    }

    this.exactOfficeMatchResults = exactOfficeMatchResults;
    this.fuzzyOfficeMatchResults = fuzzyOfficeMatchResults;
  }


  isExactNameMatch(employer: Employer): boolean {
    let matchFlag = false;

    if (this.searchEmployerName !== '') {
      const regex = /[^a-z0-9\ ]/;

      const employerNameRaw = employer.employerName.toLowerCase().replace(regex, '');
      const searchNameRaw = this.searchEmployerName.toLowerCase().replace(regex, '');

      const employerNamePartial = employerNameRaw.substr(0, searchNameRaw.length);

      matchFlag = (searchNameRaw === employerNamePartial);

    } else {
      matchFlag = true;
    }

    return matchFlag;
  }



  officeResultsForExactMatchFlag(exactMatchFlag: boolean) {
    let offices: Employer[] = [];

    if (exactMatchFlag) {
      // recent offices
      if (this.lastOfficeLookupType === OfficeLookupType.recentOffices) {
        offices = this.recentOfficeResults;

      // regular offices
      } else {
        offices = this.exactOfficeMatchResults;
      }

    } else {
      offices = this.fuzzyOfficeMatchResults;
    }

    return offices;
  }


  shouldDisplayHeaderForExactMatchFlag(exactMatchFlag: boolean): boolean {
    let displayFlag = false;

    const exactMatchResultsFlag = (this.exactOfficeMatchResults && this.exactOfficeMatchResults.length);
    const fuzzyMatchResultsFlag = (this.fuzzyOfficeMatchResults && this.fuzzyOfficeMatchResults.length);

    // if this is an exact match
    if (exactMatchFlag) {
      displayFlag = true;

    // if this is a fuzzy match
    } else {
      // display if there are both exact and fuzzy matches
      if (exactMatchResultsFlag && fuzzyMatchResultsFlag) {
        displayFlag = true;
      }
    }

    if (!this.shouldDisplaySearchResultsMessage()) {
      displayFlag = false;
    }

    return displayFlag;
  }


  officeDisplayName(employer: Employer): string {
    // what's the name to display?
    const name = this.employerLookupService.employerDisplayName(employer);

    return name;
  }



  shouldDisplayOfficeLookupError(): boolean {
    return ((this.officeLookupErrorMessage || '') !== '');
  }


  // user clicked the skip button
  didClickSkip() {
    this.officeAgentLookupService.skipEventEmitter.emit(true);
  }


  // should the new office entry be displayed
  shouldDisplayNewOfficeEntry(exactMatchFlag: boolean): boolean {
    let displayFlag = true;

    const exactMatchResultsFlag = (this.exactOfficeMatchResults && this.exactOfficeMatchResults.length);
    const fuzzyMatchResultsFlag = (this.fuzzyOfficeMatchResults && this.fuzzyOfficeMatchResults.length);

    // if this is the exact match section
    if (exactMatchFlag) {
      // if there are any fuzzy matches, do not display
      if (fuzzyMatchResultsFlag) {
        displayFlag = false;
      }

      // if there are no matches at all, do not display
      if (!fuzzyMatchResultsFlag && !exactMatchResultsFlag) {
        displayFlag = false;
      }

    // if this is the fuzzy match section
    } else {
      // if there are exact matches and no fuzzy matches, do not display
      if (exactMatchResultsFlag && !fuzzyMatchResultsFlag) {
        displayFlag = false;
      }
    }


    // if there's a product office that doesn't allow new office creation
    if (this.officeAgentLookupService.productOffice) {
      const productOffice = this.officeAgentLookupService.productOffice;
      const productOfficeToolbox = (!!productOffice ? productOffice.toolbox : null);

      if (!!productOfficeToolbox && !productOfficeToolbox.allowNewOfficeCreationFlag) {
        displayFlag = false;
      }
    }




    // if it's not a name or phone lookup, don't allow it
    const nameLookupFlag = (this.lastOfficeLookupType === OfficeLookupType.officeName);
    const phoneLookupFlag = (this.lastOfficeLookupType === OfficeLookupType.officePhone);

    if (!nameLookupFlag && !phoneLookupFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // user clicked to add a new office
  didClickNewOffice() {
    const url = this.router.routerState.snapshot.url;
    const urlTree = this.urlSerializer.parse(url);

    // replace the last url segment
    const urlSegment = new UrlSegment('new-office', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


  // user clicked a specific office
  didClickOffice(employer: Employer) {
    this.officeAgentLookupPersistenceService.employer = employer;

    if (this.officeAgentLookupService.isPlanOrder) {
        const url = this.router.routerState.snapshot.url;
        const urlTree = this.urlSerializer.parse(url);

        // replace the last url segment
        const urlSegment = new UrlSegment(employer.guid, {});
        RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

        this.router.navigateByUrl(urlTree);
    } else {
        this.officeAgentLookupService.shouldDisplayEmployerSearch = false;
    }
  }


  shouldDisplayRole() {
    return this.officeAgentLookupService.isPlanOrder;
  }

}






