
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {CustomerUpdateService, EmployerUpdateService} from '@orhp/phx-customer-ui-module';

import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderApplication} from '../_models/plan-order-application';

import {AgentRole} from '@orhp/phx-plan-ui-module';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';

import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {OfficeAgentLookupService} from '../../office-agent-lookup/_services/lookup.service';
import {init} from 'protractor/built/launcher';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {AddressValidateService} from '@orhp/phx-addressvalidate-ui-module';
import {AddressValidateRequest} from '@orhp/phx-addressvalidate-ui-module';
import {AddressValidateResult} from '@orhp/phx-addressvalidate-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {
  IOfficeAgentLookupValidateResult,
  OfficeAgentLookupValidateService
} from '../../office-agent-lookup/_services/validate.service';
import {OfficeAgentLookupRelationshipType} from '../../office-agent-lookup/_src/lookup-relationship-type';
import {OfficeAgentLookupCacheService} from '../../office-agent-lookup/_services/lookup-cache.service';
import {PlanOrdererRole} from '@orhp/phx-plan-ui-module';
import {Subscription} from 'rxjs/Subscription';
import {Address} from '@orhp/phx-common-ui-module';


@Component({
  selector: 'app-plan-order-initiating-agent',
  templateUrl: './initiating-agent.component.html',
  styleUrls: ['../plan-order.scss']
})


export class PlanOrderInitiatingAgentComponent
  extends PlanOrderBaseComponent
  implements OnInit, OnDestroy {

  // selected office and agent
  selectedEmployer: Employer = this.officeAgentLookupPersistenceService.employer;
  selectedCustomer: Customer = this.officeAgentLookupPersistenceService.customer;
  relationshipSkippedFlag = (this.planOrderApplication.initiatingAgentSkippedFlag || false);

  // selected role
  private _selectedRole: AgentRole = null;

  get selectedRole(): AgentRole {
    if (!this._selectedRole) {
      // if there is only one role
      if (this.roles.length === 1) {
        this._selectedRole = this.roles[0];
      }
    }

    if (!this._selectedRole) {
      const initiatingAgent = this.planOrderApplication.initiatingAgent;
      const cooperatingAgent = this.planOrderApplication.cooperatingAgent;

      let listingAgentFlag = false;
      let sellingAgentFlag = false;
      let doubleEndFlag = false;

      // double-ending
      if (initiatingAgent && cooperatingAgent && initiatingAgent.isEqualToCustomer(cooperatingAgent)) {
        doubleEndFlag = true;

      } else if (this.planOrderApplication.initiatingAgentListingFlag) {
        listingAgentFlag = true;

      } else if (this.planOrderApplication.cooperatingAgentListingFlag) {
        sellingAgentFlag = true;
      }

      // if none were found
      if (initiatingAgent && !listingAgentFlag && !sellingAgentFlag && !doubleEndFlag) {
        // it's the selling agent
        sellingAgentFlag = true;
      }

      this.planLookupValueService.roles.forEach((indexRole: AgentRole) => {
        if (indexRole.doubleEndFlag) {
          if (doubleEndFlag) {
            this._selectedRole = indexRole;
          }

        } else {
          if (listingAgentFlag && indexRole.listingAgentFlag) {
            this._selectedRole = indexRole;

          } else if (sellingAgentFlag && indexRole.sellingAgentFlag) {
            this._selectedRole = indexRole;
          }
        }
      });


    }

    return this._selectedRole;
  }


  set selectedRole(role: AgentRole) {
    this._selectedRole = role;
  }


  // validation error collection
  validationStatus: ValidationStatus = ValidationStatus.none;
  employerValidateResult: EmployerValidateResult = null;
  customerValidateResult: CustomerValidateResult = null;

  // resets search history
  resetLookupEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  // if we're auto-matching to an office
  employerAutoMatch: Employer = null;
  customerAutoMatch: Customer = null;


  employerEventSubscription: Subscription = null;
  customerEventSubscription: Subscription = null;
  skipEventSubscription: Subscription = null;


  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private officeAgentLookupValidateService: OfficeAgentLookupValidateService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
              private planLookupValueService: PlanLookupValueService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  get roles(): AgentRole[] {
    const transactionType = this.planOrderApplication.transactionType;

    const roles = [];

    this.planLookupValueService.roles.forEach((indexRole: AgentRole) => {
      let includeFlag = true;

      // if this is an active listing
      if (transactionType && transactionType.activeListingFlag) {
        // do not include selling agents
        if (indexRole.sellingAgentFlag) {
          includeFlag = false;
        }
      }

      if (includeFlag) {
        roles.push(indexRole);
      }
    });

    return roles;
  }




  // called when loaded
  ngOnInit() {
    super.ngOnInit();

    // sets the current step
    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'initiating-agent');

    // if there is only one role
    let defaultRole: AgentRole = null;

    if (this.roles.length === 1) {
      defaultRole = this.roles[0];
    }


    // subscribe to employer updates
    this.employerEventSubscription = this.officeAgentLookupPersistenceService.employerEventEmitter.subscribe(
      (employer: Employer) => {

        this.selectedEmployer = employer;

        this.selectedRole = defaultRole;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to customer updates
    this.customerEventSubscription = this.officeAgentLookupPersistenceService.customerEventEmitter.subscribe(
      (customer: Customer) => {

        this.selectedCustomer = customer;

        this.selectedRole = defaultRole;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to skip events
    this.skipEventSubscription = this.officeAgentLookupService.skipEventEmitter.subscribe(
      (skipFlag: boolean) => {

        if (skipFlag) {
          this.didClickSkip();
        }
      });

  }


  ngOnDestroy() {
    this.officeAgentLookupCacheService.clearCache();

    this.employerEventSubscription.unsubscribe();
    this.customerEventSubscription.unsubscribe();
    this.skipEventSubscription.unsubscribe();
  }



  shouldDisplayEmployerAutoMatch(): boolean {
    return !!this.employerAutoMatch;
  }


  shouldDisplayCustomerAutoMatch(): boolean {
    return !!this.customerAutoMatch;
  }


  shouldDisplayRoleSelection(): boolean {
    let displayFlag = true;

    // do not display if no customer has been selected
    if (!this.selectedCustomer) {
      displayFlag = false;
    }

    // do not display if there is one or fewer roles available
    if (this.roles.length <= 1) {
      displayFlag = false;
    }

    return displayFlag;
  }



  roleAutoSelectionMessage(): string {
    let message: string = null;

    const roleFlag = (this.roles.length === 1);
    const customerFlag = !!this.selectedCustomer;

    if (roleFlag && customerFlag) {
      const role = this.roles[0];

      message = 'The Initiating Agent role has been updated to ' + role.description;
    }

    return message;
  }



  shouldDisplayRoleSelectionError(): boolean {
    let flag = true;

    if (this.selectedRole) {
      flag = false;
    }

    if (this.validationStatus === ValidationStatus.none) {
      flag = false;
    }

    return flag;
  }


  isRoleSelected(role: AgentRole): boolean {
    return (role === this.selectedRole);
  }


  didClickRole(role: AgentRole) {
    this.selectedRole = role;
  }


  // should the Next button be displayed
  shouldDisplayNextButton(): boolean {
    return (this.selectedCustomer !== null);
  }


  // user clicked the Back button
  didClickBackButton(): void {

  }


  // user clicked the Next button
  didClickNextButton(): void {
    this.performValidation();
  }





  // performs validation
  performValidation(): void {

    const newEmployerFlag = !this.selectedEmployer.employerID;

    const lookupTypeCode = OfficeAgentLookupRelationshipType.typeCodeInitiatingAgent;

    this.officeAgentLookupValidateService.performValidation(
      this.selectedEmployer,
      this.selectedCustomer,
      lookupTypeCode)
      .subscribe((results: IOfficeAgentLookupValidateResult) => {

        this.customerValidateResult = results.customerValidateResult;
        this.employerValidateResult = results.employerValidateResult;


        // are we allowing auto-match?
        let allowAutoMatch = true;

        if (this.shouldDisplayRoleSelectionError()) {
          allowAutoMatch = false;
        }

        if (allowAutoMatch) {
          this.customerAutoMatch = results.customerAutoMatch;
          this.employerAutoMatch = results.employerAutoMatch;
        }


        // set the validation status
        let validationStatus = ValidationStatus.success;

        // if there was a customer auto-match
        if (this.customerAutoMatch) {
          validationStatus = ValidationStatus.customerAutoMatch;

        // if there was an employer auto-match
        } else if (this.employerAutoMatch) {
          validationStatus = ValidationStatus.employerAutoMatch;

        // if there are any validation errors
        } else if (this.validationError()) {
          validationStatus = ValidationStatus.validationFailure;

        // checks specific to new offices
        } else if (newEmployerFlag) {

          // if the physical address was updated with validation
          if (this.employerValidateResult.employerPhysicalAddressUpdated()) {
            validationStatus = ValidationStatus.addressValidationSuccess;

          // if the physical address wasn't validated
          } else if (!this.employerValidateResult.employerPhysicalAddressValidated()) {
            validationStatus = ValidationStatus.addressValidationFailure;
          }

        }

        this.validationStatus = validationStatus;


        // if the validation was successful
        if (validationStatus === ValidationStatus.success) {
          this.gotoNextStep();
        }

      });
  }


  employerPhysicalAddressForValidation(): Address[] {
    const addresses = [];

    if (this.employerValidateResult) {
      const address = this.employerValidateResult.employerPhysicalAddress();

      addresses.push(address);
    }

    return addresses;
  }


  shouldDisplayAddressValidationModal(): boolean {
    let flag = false;

    flag = flag || (this.validationStatus === ValidationStatus.addressValidationSuccess);
    flag = flag || (this.validationStatus === ValidationStatus.addressValidationFailure);

    return flag;
  }


  handleAddressValidationUserResponse(flag: boolean) {
    // if the user wants to continue
    if (flag) {
      const physicalAddress = this.selectedEmployer.physicalAddress;

      // if the address was validated
      if (this.employerValidateResult.employerPhysicalAddressValidated()) {
        const validatedAddress = this.employerValidateResult.employerValidatedPhysicalAddress();

        physicalAddress.address = validatedAddress.address;
        physicalAddress.city = validatedAddress.city;
        physicalAddress.stateCode = validatedAddress.stateCode;
        physicalAddress.zipCode = validatedAddress.zipCode;
      }

      this.gotoNextStep();

    // if the user wants to make changes
    } else {
      this.validationStatus = ValidationStatus.none;
    }
  }





  // should the validation error be displayed
  validationError(): boolean {
    let errorFlag = false;

    if (this.employerValidateResult) {
      errorFlag = errorFlag || this.employerValidateResult.errorFlag;
    }

    if (this.customerValidateResult) {
      errorFlag = errorFlag || this.customerValidateResult.errorFlag;
    }

    if (!this.selectedRole) {
      errorFlag = true;
    }

    return errorFlag;
  }


  shouldDisplayValidationError(): boolean {
    let displayFlag = true;

    if (!this.validationError()) {
      displayFlag = false;
    }

    if (this.validationStatus !== ValidationStatus.validationFailure) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // the user responded to the office auto-match
  didClickOfficeAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the office
    if (responseFlag) {
      this.selectedEmployer = this.employerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();


    // if the user does not want to accept the office
    } else {
      this.employerAutoMatch = null;
    }
  }


  // the user clicked the agent auto match response
  didClickAgentAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the agent
    if (responseFlag) {
      this.selectedCustomer = this.customerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();

    // if the user does not want to accept the agent
    } else {
      this.customerAutoMatch = null;
    }

  }



  // user clicked to Skip
  didClickSkip(): void {
    this.relationshipSkippedFlag = true;
    this.selectedEmployer = null;
    this.selectedCustomer = null;

    this.gotoNextStep();
  }


  // go to the next step
  gotoNextStep() {

    // was this application previously double-ended? (hold onto for later)
    const prevDoubleEndFlag = this.planOrderApplication.doubleEndFlag;


    // save the initiating agent info
    this.planOrderApplication.initiatingAgentSkippedFlag = this.relationshipSkippedFlag;
    this.planOrderApplication.initiatingAgent = this.selectedCustomer;

    // set the listing/selling flag
    let initiatingAgentListingFlag = false;

    if (this.selectedRole) {
      initiatingAgentListingFlag = initiatingAgentListingFlag || this.selectedRole.listingAgentFlag;
      initiatingAgentListingFlag = initiatingAgentListingFlag || this.selectedRole.doubleEndFlag;
    }

    this.planOrderApplication.initiatingAgentListingFlag = initiatingAgentListingFlag;
    this.planOrderApplication.cooperatingAgentListingFlag = !this.planOrderApplication.initiatingAgentListingFlag;

    // if they are being double-ended now
    if (this.selectedRole && this.selectedRole.doubleEndFlag) {
      this.planOrderApplication.cooperatingAgent = this.selectedCustomer;

    // if the application was previously double-ended
    } else if (prevDoubleEndFlag) {
      this.planOrderApplication.cooperatingAgent = null;
    }


    // next step logic
    this.officeAgentLookupPersistenceService.clearPersistedValues();

    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();

    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }

}


enum ValidationStatus {
  none = 10,
  validationFailure = 20,
  addressValidationFailure = 30,
  addressValidationSuccess = 40,
  employerAutoMatch = 50,
  customerAutoMatch = 60,
  success = 70
}

