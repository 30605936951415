


import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';

@Injectable()

export class OfficeAgentLookupOfficeResolver implements Resolve<Employer> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private employerLookupService: EmployerLookupService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Employer> {
    const observable: Observable<Employer> = Observable.create((subscriber: Subscriber<Employer>) => {

      let progress: ModalProgress = null;

      progress = new ModalProgress('Loading Office', 'Please wait...', 100);
      this.modalProgressService.progress = progress;

      const employerGUID = snapshot.params['employerGUID'];

      // look at the saved employer; reload if it's different
      const savedEmployer = this.officeAgentLookupPersistenceService.employer;

      let reloadEmployer = false;

      // reload if a GUID was passed, but no saved employer was found
      reloadEmployer = reloadEmployer || (employerGUID && !savedEmployer);

      // reload if there is a saved employer, but the GUIDs don't match
      reloadEmployer = reloadEmployer || (savedEmployer && (savedEmployer.guid !== employerGUID));

      // are we reloading or not?
      let employerFetchObservable: Observable<Employer>;

      if (reloadEmployer) {
        employerFetchObservable = this.employerLookupService.fetchEmployerByGUID(employerGUID);

      } else {
        employerFetchObservable = Observable.create((persistedSubscriber: Subscriber<Employer>) => {
          persistedSubscriber.next(savedEmployer);
          persistedSubscriber.complete();
        });
      }


      // perform the fetch
      employerFetchObservable.subscribe((employer: Employer) => {
        this.modalProgressService.progress = null;

        this.officeAgentLookupPersistenceService.employer = employer;

        // if the employer was found
        if (employer) {
          subscriber.next(employer);

        // if no employer was found
        } else {
          // redirect the user to the employer lookup
          const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, employerGUID);

          // remove the EmployerGUID from the URL
          RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

          // we're adding a search
          const urlSegment = new UrlSegment('search', {});
          RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

          this.router.navigateByUrl(urlTree);

          subscriber.next(null);
        }

        subscriber.complete();
      });
    });

    return observable;
  }

}
