
import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {PropertyType} from '@orhp/phx-property-ui-module';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PropertyLookupValueService} from '@orhp/phx-property-ui-module';
import {NewConstructionFlag} from '@orhp/phx-plan-ui-module';
import {PlanOrderLookupValueService} from '../_services/plan-order-lookup-value.service';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {Region} from '@orhp/phx-geography-ui-module';
import {Observable} from 'rxjs/Observable';
import {RegionLookupService} from '@orhp/phx-geography-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {CityLookupService} from '@orhp/phx-geography-ui-module';
import {City} from '@orhp/phx-geography-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {SystemMessage} from '@orhp/phx-system-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {AddressValidateRequest, AddressValidateResult} from '@orhp/phx-addressvalidate-ui-module';
import {AddressValidateService} from '@orhp/phx-addressvalidate-ui-module';
import {ZipCodeLookupService} from '@orhp/phx-geography-ui-module';
import {CityZip} from '@orhp/phx-geography-ui-module';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {Subscription} from 'rxjs/Rx';
import {ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';

@Component({
  selector: 'app-plan-order-property-type',
  templateUrl: './property-type.component.html',
  styleUrls: [
    '../plan-order.scss',
    './property-type.component.scss'
  ]
})

export class PlanOrderPropertyTypeComponent extends PlanOrderBaseComponent implements OnInit {

  private _zipCode = '';

  get zipCode(): string {
    return this._zipCode;
  }

  set zipCode(zipCode: string) {
    this._zipCode = String(zipCode);
  }

  selectedPropertyTypeCode: string = null;
  propertyTypes: PropertyType[] = null;

  selectedNewConstructionFlag: NewConstructionFlag = null;
  newConstructionFlags: NewConstructionFlag[] = null;

  userAttemptedSubmit = false;
  shouldNotSubmit = false;

  zipCodeErrorMessage: string = null;
  propertyTypeErrorMessage: string = null;
  newConstructionFlagErrorMessage: string = null;

  displayValidationErrorFlag = false;
  zipCodeValidationInProgress = false;
  region: Region = null;
  city: City = null;

  redlineMessage: string = null;

  zipLookupAddress = '';
  zipLookupCity = '';
  zipLookupStateCode = '';
  zipLookupCityZipResults: CityZip[] = null;

  zipValidatedAddress = '';
  zipValidatedCity = '';
  zipValidatedStateCode = '';
  zipValidatedZipCode = '';

  zipCodeLookupStatus = ZipCodeLookupStatus.none;

  zipCodeLookupSubscription: Subscription = null;

  @ViewChild('zipCodeInput')
  zipCodeInput: ElementRef;


  @ViewChild('zipLookupAddressInput')
  zipLookupAddressInput: ElementRef;



  get urlCode(): string {
    return <string>this.route.snapshot.params['code'];
  }


  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }

  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private systemService: PhxSystemService,
              private systemMessageLookupService: SystemMessageLookupService,
              private addressValidateService: AddressValidateService,
              private regionLookupService: RegionLookupService,
              private propertyLookupValueService: PropertyLookupValueService,
              private cityLookupService: CityLookupService,
              private zipCodeLookupService: ZipCodeLookupService,
              private planLookupValueService: PlanLookupValueService,
              private planOrderService: PlanOrderService,
              private planOrderAuthService: PlanOrderAuthService,
              private planOrderLookupValueService: PlanOrderLookupValueService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }


  ngOnInit() {
    super.ngOnInit();

    this.propertyTypes = this.propertyLookupValueService.propertyTypes;

    this.newConstructionFlags = this.planLookupValueService.newConstructionFlags;

    const property = this.planOrderApplication.property;

    this.zipCode = (property.zipCode || '').substr(0, 5);
    this.selectedPropertyTypeCode = (property.propertyType ? property.propertyType.propertyType : null);
    this.selectedNewConstructionFlag = this.planOrderApplication.newConstructionFlag;

    // if there's no selection, default to resale transaction
    if (!this.selectedNewConstructionFlag) {
      this.selectedNewConstructionFlag = ArrayUtility.arrayFind(
        this.newConstructionFlags,
        (testFlag: NewConstructionFlag): boolean => {
          return !testFlag.newConstructionFlag;
        }
      );
    }

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'property-type');

    if (this.zipCode === '') {
      this.zipCodeInput.nativeElement.focus();
    }
  }


  get appsPhoneNumber(): string {
    return this.planOrderAuthService.appsPhoneNumber;
  }


  shouldDisplayBackButton(): boolean {
    let flag = true;

    // if this is a new plan
    if (!this.planOrderApplication.planID) {
      // if there's only one transaction type available, don't display it
      const transactionTypes = this.planOrderLookupValueService.transactionTypesForDisplay();

      if ((transactionTypes || []).length <= 1) {
        flag = false;
      }
    }

    return flag;
  }




  shouldDisplayResaleTransactionHelpText(): boolean {
    const type = this.planOrderApplication.transactionType;

    let flag = true;

    if (type && type.directToConsumerFlag) {
      flag = false;
    }

    return flag;
  }



  // should the zip code update message be displayed
  shouldDisplayRegionChangeMessage(): boolean {
    return (this.urlCode === 'region-change');
  }





  newConstructionFlagByBoolean(flag: boolean): NewConstructionFlag {
    return ArrayUtility.arrayFind(
      this.newConstructionFlags,
      (testFlag: NewConstructionFlag): boolean => {
        return (testFlag.newConstructionFlag === flag);
      }
    );
  }


  setNewConstruction(flag: boolean) {
    this.selectedNewConstructionFlag = this.newConstructionFlagByBoolean(flag);
  }


  isNewConstructionSelected(): boolean {
    return (this.selectedNewConstructionFlag === this.newConstructionFlagByBoolean(true));
  }



  // user clicked a New Construction answer
  didClickNewConstruction(): void {
    this.setNewConstruction(!this.isNewConstructionSelected());
  }


  shouldDisplayZipCodeLookup(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.addressPrompt);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.lookupInProgress);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.multipleZipsReturned);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.addressNotFound);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.zipSearchValidationFailure);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.lookupError);

    return displayFlag;
  }


  shouldDisplayZipCodeLookupForm(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.addressPrompt);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.addressNotFound);
    displayFlag = displayFlag || (this.zipCodeLookupStatus === ZipCodeLookupStatus.zipSearchValidationFailure);

    return displayFlag;
  }


  shouldDisplayZipCodeLookupInProgress(): boolean {
    return (this.zipCodeLookupStatus === ZipCodeLookupStatus.lookupInProgress);
  }


  shouldDisplayZipCodeLookupAddressNotFound(): boolean {
    return (this.zipCodeLookupStatus === ZipCodeLookupStatus.addressNotFound);
  }


  shouldDisplayMultipleZipsReturned(): boolean {
    return (this.zipCodeLookupStatus === ZipCodeLookupStatus.multipleZipsReturned);
  }


  shouldDisplayMultipleZipsAddressAttempted(): boolean {
    return (this.zipLookupAddress !== '');
  }


  zipCodeLookupCityError(): string {
    let error: string = null;

    const minCityLength = 3;

    if (this.zipLookupCity.length === 0) {
      error = 'City is required';

    } else if (this.zipLookupCity.length < minCityLength) {
      error = 'City must be at least ' + String(minCityLength) + ' characters';
    }

    return error;
  }


  shouldDisplayZipCodeLookupCityError(): boolean {
    let displayFlag = true;

    if (!this.zipCodeLookupCityError()) {
      displayFlag = false;
    }

    if (this.zipCodeLookupStatus !== ZipCodeLookupStatus.zipSearchValidationFailure) {
      displayFlag = false;
    }

    return displayFlag;
  }


  zipCodeLookupStateCodeError(): string {
    let error: string = null;

    if (this.zipLookupStateCode.length !== 2) {
      error = 'State Code is required';
    }

    return error;
  }


  shouldDisplayZipCodeLookupStateCodeError(): boolean {
    let displayFlag = true;

    if (!this.zipCodeLookupStateCodeError()) {
      displayFlag = false;
    }

    if (this.zipCodeLookupStatus !== ZipCodeLookupStatus.zipSearchValidationFailure) {
      displayFlag = false;
    }

    return displayFlag;
  }


  shouldDisplayZipCodeLookupValidationFailure(): boolean {
    return (this.zipCodeLookupStatus === ZipCodeLookupStatus.zipSearchValidationFailure);
  }



  // the user clicked the zip code lookup button
  didClickZipCodeLookup() {
    this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/zip-lookup');

    this.zipLookupAddress = '';
    this.zipLookupCity = '';
    this.zipLookupStateCode = '';

    this.zipCodeLookupStatus = ZipCodeLookupStatus.addressPrompt;

    setTimeout(() => {
      this.zipLookupAddressInput.nativeElement.focus();
    });
  }


  didClickPerformZipCodeLookup() {
    let validFlag = true;

    validFlag = validFlag && !this.zipCodeLookupCityError();
    validFlag = validFlag && !this.zipCodeLookupStateCodeError();

    if (validFlag) {
      this.zipCodeLookupStatus = ZipCodeLookupStatus.lookupInProgress;

      // if the user entered an address
      if (this.zipLookupAddress !== '') {
        this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/zip-lookup/by-address');

        this.performZipCodeLookupByAddress();

        // if not, lookup by city
      } else {
        this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/zip-lookup/by-city');

        this.performZipCodeLookupByCity();
      }

    } else {
      this.zipCodeLookupStatus = ZipCodeLookupStatus.zipSearchValidationFailure;
    }

  }


  performZipCodeLookupByAddress() {
    // perform an address validation
    const request = new AddressValidateRequest();

    request.address = this.zipLookupAddress.trim();
    request.city = this.zipLookupCity.trim();
    request.stateCode = this.zipLookupStateCode.trim();

    if (this.zipCodeLookupSubscription) {
      this.zipCodeLookupSubscription.unsubscribe();
    }

    this.zipCodeLookupSubscription = this.addressValidateService.validateAddress(request)
      .subscribe((result: AddressValidateResult) => {
      // if a clean zip code was found
      const cleanZipCode = (result.cleanZipCode || '').substr(0, 5);

      if (cleanZipCode !== '') {
        this.zipValidatedAddress = result.cleanAddress;
        this.zipValidatedCity = result.cleanCity;
        this.zipValidatedStateCode = result.cleanStateCode;
        this.zipValidatedZipCode = result.cleanZipCode;

        this.zipCode = cleanZipCode;

        this.zipCodeLookupStatus = ZipCodeLookupStatus.none;

      // if no exact match was found, try lookup by city
      } else {
        this.performZipCodeLookupByCity();
      }
    });

  }


  performZipCodeLookupByCity() {
    const city = this.zipLookupCity.trim();
    const stateCode = this.zipLookupStateCode.trim();

    this.zipCodeLookupSubscription = this.zipCodeLookupService.fetchZipCodes(city, stateCode)
      .subscribe((cityZips: CityZip[]) => {

      // if results were returned
      if (cityZips && cityZips.length) {

        // if there was one zip code
        if (cityZips.length === 1) {
          this.zipCode = cityZips[0].zipCode;

          this.zipCodeLookupStatus = ZipCodeLookupStatus.none;

        // if there were multiple zips returned
        } else {
          this.zipLookupCityZipResults = ArrayUtility.arrayDuplicate(cityZips);

          this.zipCodeLookupStatus = ZipCodeLookupStatus.multipleZipsReturned;
        }

      } else {
        this.zipCodeLookupStatus = ZipCodeLookupStatus.addressNotFound;
      }

      }, (error: Response) => {
        this.zipCodeLookupStatus = ZipCodeLookupStatus.addressNotFound;
      });
  }


  didClickZipCodeLookupSearchAgain() {
    this.zipCodeLookupStatus = ZipCodeLookupStatus.addressPrompt;

    setTimeout(() => {
      this.zipLookupAddressInput.nativeElement.focus();
    });
  }


  didClickCancelZipCodeLookup() {
    if (this.zipCodeLookupSubscription) {
      this.zipCodeLookupSubscription.unsubscribe();
    }

    this.zipCodeLookupStatus = ZipCodeLookupStatus.none;
  }


  didClickZipCodeLookupResult(cityZip: CityZip) {
    this.zipValidatedAddress = this.zipLookupAddress.toUpperCase();
    this.zipValidatedCity = cityZip.city.toUpperCase();
    this.zipValidatedStateCode = cityZip.stateCode.toUpperCase();
    this.zipValidatedZipCode = cityZip.zipCode;

    this.zipCode = cityZip.zipCode;

    this.zipCodeLookupStatus = ZipCodeLookupStatus.none;
  }


  /**
   * Filters property types for display purposes
   */
  propertyTypesToDisplay(): PropertyType[] {
    const productOffice = this.planOrderAuthService.productOffice;
    const productOfficePropertyTypes = (productOffice ? productOffice.propertyTypes : null) || [];

    const toolbox = this.planOrderAuthService.productOfficeToolbox;
    const existingProperty = this.planOrderApplication.property;
    const existingPropertyType = (existingProperty ? existingProperty.propertyType : null);

    const propertyTypes = this.propertyTypes.filter((ixPropertyType: PropertyType): boolean => {
      let flag = true;

      // if the product/office requires client-specific products only, and there are property types
      if (toolbox && toolbox.officeSpecificProductsOnlyFlag && productOfficePropertyTypes.length) {
        const found = !!productOfficePropertyTypes.filter((testPropertyType: string): boolean => {
          return (testPropertyType === ixPropertyType.propertyType) ||
            (existingPropertyType && !this.selectedPropertyTypeCode && (testPropertyType === existingPropertyType.propertyType));
        }).length;

        if (!found) {
          flag = false;
        }
      }

      return flag;
    });

    return propertyTypes;
  }








  // validates zip code
  validateZipCode(): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber) => {
      this.zipCodeErrorMessage = null;

      if (!StringUtility.validateZipCode(this.zipCode)) {
        this.zipCodeErrorMessage = 'Zip Code is required';
      }

      // if the zip code is valid
      if (!this.zipCodeErrorMessage) {
        this.zipCodeValidationInProgress = true;

        const cleanZip = this.zipCode.substr(0, 5);

        const observables: Observable<any>[] = [];

        observables.push(this.regionLookupService.fetchRegion(cleanZip));
        observables.push(this.cityLookupService.fetchCities(cleanZip));

        Observable.forkJoin(observables).subscribe((results: any[]) => {
          const region = <Region>results[0];
          const cities = <City[]>results[1];

          const city = (cities.length ? cities[0] : null);

          let redlineFlag = false;

          if (!region || !city) {
            this.zipCodeErrorMessage = 'Unable to locate Zip Code';

          } else {
            this.region = region;
            this.city = city;

            redlineFlag = region.redlineFlag;
          }

          // if this is a redline zip
          if (redlineFlag) {
            this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/redline-zip');

            this.systemMessageLookupService.systemMessageLookupByType('plan-order-redline-message')
              .subscribe((systemMessage: SystemMessage) => {

                this.redlineMessage = systemMessage.message;

                this.zipCodeValidationInProgress = false;

                subscriber.next(false);
                subscriber.complete();
              });

          // if this is not a redline zip
          } else {
            this.zipCodeValidationInProgress = false;

            const validFlag = !this.zipCodeErrorMessage;

            subscriber.next(validFlag);
            subscriber.complete();
          }

        });

      // if the zip code is not valid
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });


    return observable;
  }


  // should the redline error be displayed
  shouldDisplayRedlineError(): boolean {
    let displayFlag = true;

    if (this.region && !this.region.redlineFlag) {
      displayFlag = false;
    }

    if (!this.redlineMessage) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // should the zip code error be displayed
  shouldDisplayZipCodeError(): boolean {
    return (this.userAttemptedSubmit && !!this.zipCodeErrorMessage);
  }


  // should the empty property type be displayed?
  shouldDisplayEmptyPropertyType(): boolean {
    let flag = true;

    if (this.planOrderApplication.property.propertyType) {
      flag = false;
    }

    return flag;
  }



  // validates the property type
  validatePropertyType(): boolean {
    let valid = true;

    const propertyType = this.propertyLookupValueService.propertyTypeByCode(this.selectedPropertyTypeCode);

    if (propertyType === null) {
      valid = false;

      this.propertyTypeErrorMessage = 'Property Type is required';
    }

    if (valid) {
      this.propertyTypeErrorMessage = null;
    }

    return valid;
  }


  // should the property type error be displayed
  shouldDisplayPropertyTypeError(): boolean {
    return (this.userAttemptedSubmit && !!this.propertyTypeErrorMessage);
  }


  // validate new construction flag
  validateNewConstructionFlag(): boolean {
    let valid = true;

    if (!this.selectedNewConstructionFlag) {
      valid = false;

      this.newConstructionFlagErrorMessage = 'New Construction is required';
    }

    if (valid) {
      this.newConstructionFlagErrorMessage = null;
    }

    return valid;
  }



  shouldDisplayNewConstructionQuestion(): boolean {
    let flag = true;

    // if a transaction type is defined
    if (this.planOrderApplication.transactionType) {
      flag = this.planOrderApplication.transactionType.promptForNewConstructionFlag;
    }

    // if a product office group is defined
    if (!!this.productOfficeToolbox && this.productOfficeToolbox.hideNewConstructionSelectionFlag) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayNewConstructionFlagError(): boolean {
    return (this.userAttemptedSubmit && !!this.newConstructionFlagErrorMessage);
  }


  shouldDisplayValidationError(): boolean {
    let displayFlag = this.displayValidationErrorFlag;

    if (this.region) {
      if (this.region.redlineFlag) {
        displayFlag = false;
      }
    }

    return displayFlag;
  }


  // user clicked the Next button
  didClickNextButton(boolValue: boolean): void {
    this.userAttemptedSubmit = true;

    let valid = true;

    if (!this.validateNewConstructionFlag()) {
      valid = false;
    }

    if (!this.validatePropertyType()) {
      valid = false;
    }

    this.planOrderApplication.directProcessFlag = !!this.productOfficeGroup && !!this.productOfficeGroup.homeBuyerEmailRequiredFlag;


    this.validateZipCode().subscribe((zipCodeValidationFlag: boolean) => {
      if (!zipCodeValidationFlag) {
        valid = false;
      }

      if (valid && !this.shouldNotSubmit) {
        this.shouldNotSubmit = true;
        this.gotoNextStep();

      // not valid
      } else if (!valid) {
        this.displayValidationErrorFlag = true;
      }
    });


  }



  gotoNextStep() {
    // update property detail
    const property = this.planOrderApplication.property.duplicate();
    const prevPropertyType = property.propertyType;


    // if the validated zip code matches the user zip code
    const validatedZipCode = (this.zipValidatedZipCode ? this.zipValidatedZipCode.substr(0, 5) : null);

    if (validatedZipCode === this.zipCode) {
      property.address = this.zipValidatedAddress;
      property.city = this.zipValidatedCity;
      property.stateCode = this.zipValidatedStateCode;
      property.zipCode = this.zipValidatedZipCode;

    // if the user didn't use the zip lookup
    } else {
      property.city = this.city.city.toUpperCase();
      property.stateCode = this.city.stateCode.toUpperCase();
      property.zipCode = this.zipCode;
    }

    // set the property type
    property.propertyType = this.propertyLookupValueService.propertyTypeByCode(this.selectedPropertyTypeCode);

    const newPropertyType = property.propertyType;

    // if the new property type is different from the previous property type, clear the product
    if (!newPropertyType.isEqual(prevPropertyType)) {
      this.planOrderApplication.product = null;
    }

    this.planOrderApplication.property = property;

    this.planOrderApplication.newConstructionFlag = this.selectedNewConstructionFlag;

    this.planOrderApplication.propertyRegionID = this.region.regionID;

    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();

    let urlSegment: UrlSegment = null;
    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'property-type');

    urlSegment = new UrlSegment(nextRoute, {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


}


enum ZipCodeLookupStatus {
  none = 100,
  addressPrompt = 200,
  lookupInProgress = 300,
  addressNotFound = 400,
  multipleZipsReturned = 500,
  zipSearchValidationFailure = 600,
  lookupError = 700
}


