
import {Routes} from '@angular/router';
import {OfficeLookupComponent} from './office-lookup/office-lookup.component';
import {AgentLookupComponent} from './agent-lookup/agent-lookup.component';
import {OfficeEntryComponent} from './office-entry/office-entry.component';
import {AgentComponent} from './agent/agent.component';
import {AgentEntryComponent} from './agent-entry/agent-entry.component';
import {OfficeAgentLookupRootResolver} from './_services/root.resolver';
import {OfficeAgentLookupOfficeResolver} from './_services/office.resolver';
import {OfficeAgentLookupAgentResolver} from './_services/agent.resolver';
import {OfficeAgentLookupRootWildcardResolver} from './_services/root-wildcard.resolver';
import {OfficeAgentLookupEmptyOfficeResolver} from './_services/empty-office.resolver';
import {OfficeAgentLookupOfficeWildcardResolver} from './_services/office-wildcard.resolver';
import {OfficeAgentLookupEmptyAgentResolver} from './_services/empty-agent.resolver';
import {OfficeAgentLookupOfficeNewResolver} from './_services/office-new.resolver';
import {OfficeAgentLookupAgentNewResolver} from './_services/agent-new.resolver';
import {OfficeAgentLookupAgentClearResolver} from './_services/agent-clear.resolver';
import {OfficeAgentLookupOfficeClearResolver} from './_services/office-clear.resolver';
import {OfficeAgentLookupAgentUnknownResolver} from './_services/agent-unknown.resolver';

export const OfficeAgentLookupRoutes: Routes = [
  {
    path: '',
    resolve: [OfficeAgentLookupRootResolver],
    children: [
      {
        path: 'office',
        children: [
          {
            path: 'search',
            component: OfficeLookupComponent,
            resolve:
              {
                employer: OfficeAgentLookupOfficeClearResolver,
                customer: OfficeAgentLookupAgentClearResolver
              }
          },
          {
            path: 'new-office',
            component: OfficeEntryComponent,
            resolve:
              {
                employer: OfficeAgentLookupOfficeNewResolver,
                customer: OfficeAgentLookupAgentNewResolver
              }
          },
          {
            path: ':employerGUID',
            resolve:
              {
                employer: OfficeAgentLookupOfficeResolver
              },
            children: [
              {
                path: 'agent',
                children: [
                  {
                    path: '',
                    resolve: [OfficeAgentLookupEmptyAgentResolver],
                    children: []
                  },
                  {
                    path: 'search',
                    component: AgentLookupComponent,
                    resolve:
                      {
                        customer: OfficeAgentLookupAgentClearResolver
                      },
                    children: [
                      {
                        path: ':agentSplitLetter',
                        component: AgentLookupComponent
                      }
                    ]
                  },
                  {
                    path: 'new-agent',
                    component: AgentEntryComponent,
                    resolve:
                      {
                        customer: OfficeAgentLookupAgentNewResolver
                      }
                  },
                  {
                    path: 'unknown-agent',
                    component: AgentEntryComponent,
                    resolve:
                      {
                        customer: OfficeAgentLookupAgentUnknownResolver
                      }
                  },
                  {
                    path: ':customerGUID',
                    component: AgentComponent,
                    resolve: {
                      customer: OfficeAgentLookupAgentResolver
                    }
                  }
                ]
              },
              {
                path: '**',
                children: [],
                resolve: [OfficeAgentLookupOfficeWildcardResolver]
              }
            ]
          },
          {
            path: '',
            children: [],
            resolve: [OfficeAgentLookupEmptyOfficeResolver]
          }
        ]
      },
      {
        path: '**',
        children: [],
        resolve: [OfficeAgentLookupRootWildcardResolver]
      }
    ]
  }
];

