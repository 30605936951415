

import {Component, OnInit} from '@angular/core';
import {PlanOrderService} from '../_services/plan-order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanOrderLookupService} from '../_services/plan-order-lookup.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PlanOrderDomainFactoryService} from '../_services/plan-order-domain-factory.service';

@Component({
  selector: 'app-plan-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['../plan-order.scss']
})

export class PlanOrderConfirmationComponent extends PlanOrderBaseComponent implements OnInit {

  displayConfirmationMessage = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private planOrderService: PlanOrderService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private planOrderLookupService: PlanOrderLookupService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }


  ngOnInit() {
    super.ngOnInit();

    // if we need to load the plan
    if (!this.planOrderApplication.planID) {
      const planID = Number(this.route.snapshot.params['planID']);

      this.planOrderLookupService
        .fetchPlanByID(this.planOrderApplication, planID)
        .subscribe((fetchResultFlag: boolean) => {

        this.displayConfirmationMessage = fetchResultFlag;
      });

    // if the plan is already loaded
    } else {

    }
  }


  shouldDisplayConfirmationMessage(): boolean {
    return this.displayConfirmationMessage;
  }


  didClickEditApplication() {
    const url = '../review';

    this.router.navigate([url], { relativeTo: this.route });
  }


  didClickNewApplication() {
    this.planOrderApplication = this.planOrderDomainFactoryService.newApplication();

    this.persistPlanOrderApplication();

    const url = '../../new';

    this.router.navigate([url], { relativeTo: this.route });
  }
}
