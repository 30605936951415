

import {Component, OnInit} from '@angular/core';
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {CustomerLookupParams} from '@orhp/phx-customer-ui-module';
import {CustomerLookupService} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {IOfficeResolverInterface} from '../_src/office-resolver-interface';
import {OfficeAgentLookupPersistenceService} from '../_services/persistence.service';

@Component({
  selector: 'app-agent-lookup',
  templateUrl: './agent-lookup.component.html',
  styleUrls: [
    '../office-agent-lookup.scss',
    './agent-lookup.component.scss'
  ]
})


export class AgentLookupComponent implements OnInit {

  customers: Customer[] = null;

  unknownEscrowCustomer: Customer = null;

  // array of customers by letter
  customerLetters: string[] = null;
  customersByLetter: Map<string, Customer[]> = null;
  selectedCustomerLetter: string = null;

  agentSearchWasRun = false;
  agentLookupRunningFlag = false;
  agentLookupFailureFlag = false;

  lookupRetryCount = 0;
  lookupMaxRetryCount = 3;

  constructor(private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private customerLookupService: CustomerLookupService,
              private urlSerializer: UrlSerializer,
              private router: Router,
              private route: ActivatedRoute) {
  }


  get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
    return this.officeAgentLookupService.lookupRelationshipType;
  }


  // Employer
  employer: Employer = null;


  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      const officeData = <IOfficeResolverInterface>data;

      this.employer = officeData.employer;

      this.fetchCustomers();
    });
  }


  // should the new agent entry be displayed
  shouldDisplayNewAgentEntry(): boolean {
    return this.agentSearchWasRun;
  }



  // should the unknown agent entry be displayed
  shouldDisplayUnknownAgentEntry(): boolean {
    let displayFlag = true;

    if (!this.agentSearchWasRun) {
      displayFlag = false;
    }

    let relationshipFlag = false;
    relationshipFlag = relationshipFlag || this.lookupRelationshipType.escrowOfficerFlag;

    if (!relationshipFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // should the lookup failure message be displayed
  shouldDisplayAgentLookupFailure(): boolean {
    return this.agentLookupFailureFlag;
  }


  // user clicked to try the agent lookup again
  didClickTryLookupAgain() {
    this.agentLookupFailureFlag = false;

    this.fetchCustomers();
  }



  // user clicked the agent search
  didClickAgentSearch() {
    this.fetchCustomers();
  }


  // fetches agents
  fetchCustomers() {
    const params = new CustomerLookupParams();
    params.employer = this.employer;

    if (this.lookupRelationshipType) {
      params.includePhonesFlag = false;
      params.includeFlatPhonesFlag = true;

      if (this.lookupRelationshipType.initiatingAgentFlag) {
        params.toolboxRealtorFlag = true;
        params.includeEmployerFlag = false;

      } else if (this.lookupRelationshipType.cooperatingAgentFlag) {
        params.toolboxRealtorFlag = true;
        params.includeEmployerFlag = false;

      } else if (this.lookupRelationshipType.escrowOfficerFlag) {
        params.toolboxEscrowFlag = true;
        params.includeEmployerFlag = false;
      }
    }

    this.agentLookupRunningFlag = true;

    // initiate the fetch request
    this.customerLookupService.fetchCustomers(params).subscribe((customers: Customer[]) => {
      // if customers were returned
      if (customers) {
        this.parseCustomerLookupResults(customers);

      // if no customers were returned
      } else {
        this.lookupRetryCount++;

        // if we're under the max retry count, try again
        if (this.lookupRetryCount <= this.lookupMaxRetryCount) {
          this.fetchCustomers();

        // if we've reached our max retry count, display an error
        } else {
          this.agentLookupRunningFlag = false;
          this.agentLookupFailureFlag = true;
        }
      }
    });
  }


  // parses customer lookup results
  parseCustomerLookupResults(customers: Customer[]) {
    this.officeAgentLookupService.cacheCustomers(customers);

    const customerLetters = [];
    const customerLetterMap = new Map<string, boolean>();
    const customersByLetter = new Map<string, Customer[]>();

    // should we split the customers by name?
    let splitCustomersByNameFlag = true;

    splitCustomersByNameFlag = splitCustomersByNameFlag && (customers.length > 10);

    const firstNameSplitFlag = this.lookupRelationshipType.agentLookupFirstNamePagingFlag;
    const lastNameSplitFlag = this.lookupRelationshipType.agentLookupLastNamePagingFlag;

    if (!firstNameSplitFlag && !lastNameSplitFlag) {
      splitCustomersByNameFlag = false;
    }


    // sort the returned customers
    customers = customers.sort((a: any, b: any): number => {
      const customer1 = <Customer>a;
      const customer2 = <Customer>b;

      let customer1Name = (firstNameSplitFlag ? customer1.firstLastName : customer1.lastFirstName);
      let customer2Name = (firstNameSplitFlag ? customer2.firstLastName : customer2.lastFirstName);

      customer1Name = (customer1Name || '').toUpperCase();
      customer2Name = (customer2Name || '').toUpperCase();

      let value = 0;

      if (customer1Name < customer2Name) {
        value = -1;

      } else if (customer1Name > customer2Name) {
        value = 1;
      }

      return value;
    });


    const escrowOfficerFlag = this.lookupRelationshipType.escrowOfficerFlag;

    // loop over returned customers
    customers.forEach((indexCustomer: Customer) => {
      // if the customer doesn't have an employer, add the current employer
      if (!indexCustomer.employer) {
        indexCustomer.employer = this.employer;
      }

      // flag that the customer name should not be validated
      indexCustomer.validateNameFlag = false;

      // should the customer be displayed?
      let displayCustomerFlag = true;

      // if this is the escrow relationship
      if (escrowOfficerFlag) {
        // if this is the unknown customer
        if (indexCustomer.unknownEscrowFlag) {
          this.unknownEscrowCustomer = indexCustomer;

          displayCustomerFlag = false;
        }
      }

      // if we're displaying the customer
      if (displayCustomerFlag) {

        let letter = '#';

        // if we're splitting the customers by name
        if (splitCustomersByNameFlag) {
          let splitName: string = null;

          if (firstNameSplitFlag) {
            splitName = indexCustomer.firstLastName;

          } else if (lastNameSplitFlag) {
            splitName = indexCustomer.lastFirstName;
          }

          if (splitName) {
            letter = splitName.substr(0, 1).toUpperCase();
          }

          // no letter goes into '#'
          if (letter === '') {
            letter = '#';

            // anything other than a letter goes into '#'
          } else if (letter.replace(/[^a-zA-Z]/, '#') === '#') {
            letter = '#';
          }
        }


        // if this is the first time we're using this letter
        if (!customerLetterMap[letter]) {
          customerLetterMap[letter] = true;
          customerLetters.push(letter);
        }

        // append the customer to the appropriate array
        const indexCustomersByLetter = customersByLetter[letter] || [];
        indexCustomersByLetter.push(indexCustomer);

        customersByLetter[letter] = indexCustomersByLetter;
      }
    });

    // sort the customer letters
    const sortedCustomerLetters = customerLetters.sort((a: any, b: any): number => {
      const letter1 = (<string>a || '').toUpperCase();
      const letter2 = (<string>b || '').toUpperCase();

      let value = 0;

      if (letter1 < letter2) {
        value = -1;
      } else if (letter1 > letter2) {
        value = 1;
      }

      return value;
    });

    this.customers = customers;
    this.customerLetters = sortedCustomerLetters;
    this.customersByLetter = customersByLetter;

    // pull the selected letter from the URL
    const agentSplitLetter = this.route.snapshot.params['agentSplitLetter'];

    // if there's a selected letter, and there are customers
    if (agentSplitLetter && this.customersByLetter[agentSplitLetter]) {
      this.selectedCustomerLetter = agentSplitLetter;

      // otherwise use the first letter
    } else if (customerLetters.length > 0) {
      this.selectedCustomerLetter = customerLetters[0];
    }

    this.agentSearchWasRun = true;
    this.agentLookupRunningFlag = false;
  }




  shouldDisplayLastFirst(): boolean {
    return this.lookupRelationshipType.agentLookupLastNamePagingFlag;
  }

  shouldDisplayFirstLast(): boolean {
    return this.lookupRelationshipType.agentLookupFirstNamePagingFlag;
  }



  // what are we splitting customers by
  splitCustomersByText(): string {
    let text = '';

    if (this.lookupRelationshipType.agentLookupFirstNamePagingFlag) {
      text = 'First name';

    } else if (this.lookupRelationshipType.agentLookupLastNamePagingFlag) {
      text = 'Last name';
    }

    return text;
  }


  // the user clicked a filter letter
  didClickFilterLetter(letter: string) {
    this.selectedCustomerLetter = letter;
  }


  // customers by filter letter
  customersByFilterLetter(): Customer[] {
    let customers = [];

    if (this.selectedCustomerLetter) {
      customers = this.customersByLetter[this.selectedCustomerLetter];
    }

    return customers;
  }


  // should the letter filter selector be displayed
  shouldLetterFilterBeDisplayed(): boolean {
    return (this.customerLetters.length > 1);
  }


  // is this the selected letter?
  isSelectedLetter(letter: string): boolean {
    return (this.selectedCustomerLetter === letter);
  }


  // user clicked a new agent
  didClickNewAgent() {
    const url = this.router.routerState.snapshot.url;
    const urlTree = this.urlSerializer.parse(url);

    // replace the last segment with the new agent route
    const urlSegment = new UrlSegment('new-agent', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


  // user clicked the unknown agent
  didClickUnknownAgent() {
    // if there is already an UNKNOWN agent for this office
    if (this.unknownEscrowCustomer) {
      this.didClickAgent(this.unknownEscrowCustomer);

    // if we need to create a new UNKNOWN agent
    } else {
      const url = this.router.routerState.snapshot.url;
      const urlTree = this.urlSerializer.parse(url);

      // replace the last segment with the unknown agent route
      const urlSegment = new UrlSegment('unknown-agent', {});
      RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

      this.router.navigateByUrl(urlTree);
    }
  }


  // user clicked an agent
  didClickAgent(customer: Customer) {
    this.officeAgentLookupPersistenceService.customer = customer;

    const url = this.router.routerState.snapshot.url;
    const urlTree = this.urlSerializer.parse(url);

    // replace the last segment with the new agent route
    const urlSegment = new UrlSegment(customer.guid, {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }




}
