


import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderService} from '../_services/plan-order.service';
import {Injectable} from '@angular/core';
import {RoutingUtility} from '@orhp/phx-common-ui-module';

@Injectable()

export class PlanOrderHomeSellerResolver implements Resolve<boolean> {

  constructor(private planOrderService: PlanOrderService,
              private urlSerializer: UrlSerializer,
              private router: Router) {
  }

  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const application = this.planOrderService.planOrderApplication;

      let redirectTo: string = null;

      // new plans
      if (!application.planID) {
        if (!application.property.address) {
          redirectTo = 'property-address';
        }
      }

      // if we're not allowing
      if (redirectTo) {
        const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, 'home-seller');

        const urlSegment = new UrlSegment(redirectTo, {});
        RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

        this.router.navigateByUrl(urlTree);
      }

      resolve(!redirectTo);
    });

    return promise;
  }

}
