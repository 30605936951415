

import {Component, OnInit} from '@angular/core';
import {PlanOrderUpdateService} from '../_services/plan-order-update.service';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderLookupService} from '../_services/plan-order-lookup.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ActivityResultError} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-plan-save-interstitial',
  templateUrl: './save-interstitial.component.html',
  styleUrls: ['../plan-order.scss']
})

export class PlanOrderSaveInterstitialComponent extends PlanOrderBaseComponent implements OnInit {

  progress: PlanOrderProgress = new PlanOrderProgress(0, 'Getting Ready to Update', true);



  constructor(private planOrderService: PlanOrderService,
              private planOrderUpdateService: PlanOrderUpdateService,
              private planOrderLookupService: PlanOrderLookupService,
              private urlSerializer: UrlSerializer,
              private router: Router,
              private route: ActivatedRoute) {
    super(planOrderService);
  }



  ngOnInit() {
    super.ngOnInit();

    try {
      // is this demo mode?
      if (this.planOrderApplication.demoModeSubmittedFlag) {
        this.demoMode();

      // if this is real mode
      } else {
        const existingPlanFlag = !!this.planOrderApplication.planID;

        // if a credit card payment failed
        const paymentResult = this.planOrderApplication.ccPaymentResult;
        const invoicePaymentResult = (paymentResult ? paymentResult.object : null);
        const ccPaymentResult = (invoicePaymentResult ? invoicePaymentResult.creditCardSalesResult : null);

        let prevCcPaymentFailureFlag = false;

        if (ccPaymentResult && !ccPaymentResult.successFlag) {
          prevCcPaymentFailureFlag = true;
        }

        this.planOrderApplication.confirmationSentFlag = false;
        this.planOrderService.persistPlanOrderApplication();


        // perform the update
        this.planOrderUpdateService
          .submitApplication(this.planOrderApplication)
          .subscribe((progress: PlanOrderProgress) => {

            this.progress = progress;

            let confirmationSentFlag = this.planOrderApplication.confirmationSentFlag;

            // if the progress is failure
            if (!progress.successFlag) {
              this.handleUpdateFailure();

              // if the progress is success
            } else {
              // if we're complete
              if (progress.completeFlag) {

                // what text do we display to the user
                let loadPlanText: string = null;

                if (existingPlanFlag) {
                  loadPlanText = 'Accessing updated application';

                } else {
                  loadPlanText = 'Accessing new application';
                }

                this.progress = new PlanOrderProgress(100, loadPlanText, true);

                this.planOrderLookupService
                  .fetchPlanByID(this.planOrderApplication)
                  .subscribe((fetchResultFlag: boolean) => {

                    // if the result wasn't found
                    if (!fetchResultFlag) {
                      const error = new ActivityResultError();
                      error.identifier = 'phx.planOrder.api.UnableToFetchPlanError';
                      error.message = 'Unable to retrieve the new Application';

                      this.planOrderApplication.saveErrors = [error];

                      this.progress = new PlanOrderProgress(100, 'phx.planOrder.api.GenericPlanFetchError', false);


                      this.handleUpdateFailure();

                      // if the result was found
                    } else {
                      // if this is a new plan, flag that the confirmations have been sent
                      if (!existingPlanFlag) {
                        confirmationSentFlag = true;
                      }

                      if (confirmationSentFlag) {
                        this.planOrderApplication.confirmationSentFlag = true;
                      }

                      this.persistPlanOrderApplication();

                      const url = this.router.routerState.snapshot.url;
                      let urlSegment: UrlSegment = null;
                      const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'save');

                      RoutingUtility.removeLastUrlSegmentFromTree(urlTree);
                      RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

                      const redirectPlanID = this.planOrderApplication.planID;
                      urlSegment = new UrlSegment(String(redirectPlanID), {});
                      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

                      urlSegment = new UrlSegment('review', {});
                      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

                      // if there was a previous payment failure, or it's a new plan
                      if (prevCcPaymentFailureFlag || !existingPlanFlag) {
                        urlSegment = new UrlSegment('confirm-new', {});
                        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

                      // if it's an existing plan
                      } else {
                        urlSegment = new UrlSegment('confirm-update', {});
                        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);
                      }

                      this.router.navigateByUrl(urlTree);
                    }
                  });

              }
            }

          });

      }

    } catch (error) {
      this.planOrderApplication.logError('phx.toolbox.ui.ErrorSaving', error);
      this.persistPlanOrderApplication();

      this.handleUpdateFailure();
    }

  }


  shouldDisplayInProgressBar(): boolean {
    return this.progress.successFlag;
  }


  inProgressBarStyle(): any {
    const style = {
      'width': this.progress.progressPercentText
    };

    return style;
  }


  handleUpdateFailure(): void {
    const urlTree = this.urlSerializer.parse(this.router.routerState.snapshot.url);

    let urlSegment: UrlSegment = null;

    urlSegment = new UrlSegment('review', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    const errorCode = 'update-error';
    urlSegment = new UrlSegment(errorCode, {});
    RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }



  demoMode() {
    const progressMessages: string[] = [];

    progressMessages.push('Saving Initiating Agent');
    progressMessages.push('Saving Cooperating Agent');
    progressMessages.push('Saving Closing Agent');
    progressMessages.push('Saving Home Buyer #1');
    progressMessages.push('Saving Home Buyer #2');
    progressMessages.push('Saving Home Seller #1');
    progressMessages.push('Saving Property Address');
    progressMessages.push('Saving Application');

    const progressPercent = Math.floor(100 / (progressMessages.length + 1));

    const progresses: PlanOrderProgress[] = [];

    let progressIndex = 1;

    progressMessages.forEach((indexMessage: string) => {
      const indexProgressPercent = progressIndex * progressPercent;

      const progress = new PlanOrderProgress(indexProgressPercent, indexMessage, true);

      progresses.push(progress);

      progressIndex++;
    });

    this.scheduleProgressAtIndex(progresses, 0);
  }


  scheduleProgressAtIndex(progresses: PlanOrderProgress[], progressIndex: number) {
    const indexProgress = progresses[progressIndex];

    this.progress = new PlanOrderProgress(
      indexProgress.progressPercent,
      indexProgress.progressMessage,
      indexProgress.successFlag
    );

    setTimeout(() => {
      if (progressIndex < (progresses.length - 1)) {
        this.scheduleProgressAtIndex(progresses, (progressIndex + 1));

      } else {
        const url = this.router.routerState.snapshot.url;
        const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'save');

        let segment: UrlSegment;

        segment = new UrlSegment('review', {});
        RoutingUtility.replaceLastUrlSegmentInTree(urlTree, segment);

        segment = new UrlSegment('confirm-new', {});
        RoutingUtility.appendUrlSegmentToTree(urlTree, segment);

        this.router.navigateByUrl(urlTree);
      }
    }, 300);
  }


}
