
import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';
import {Address} from '@orhp/phx-common-ui-module';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderService} from '../_services/plan-order.service';
import {CustomerUpdateService} from '@orhp/phx-customer-ui-module';
import {ActivityResult, ActivityResultError} from '@orhp/phx-common-ui-module';
import {PhoneType} from '@orhp/phx-common-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {Property} from '@orhp/phx-property-ui-module';
import {LocalStorage} from '@orhp/phx-localstorage-ui-module';
import {ICustomer} from '@orhp/phx-customer-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {ProductOfficeToolbox} from '@orhp/phx-product-ui-module';

@Component({
  selector: 'app-plan-order-generic-customer',
  templateUrl: './generic-customer.component.html',
  styleUrls: [
    '../plan-order.scss',
    './generic-customer.component.scss'
  ]
})


export class PlanOrderGenericCustomerComponent implements OnInit {

  static homeBuyerRelationshipCode = 'home-buyer';
  static homeSellerRelationshipCode = 'home-seller';

  @Input() relationshipCode: string = null;
  @Input() customers: Customer[] = null;
  @Input() promptOnNoCustomersFlag = false;
  @Input() requireCustomerFlag = false;
  @Input() property: Property = null;
  @Input() application: PlanOrderApplication = null;

  @Output() nextButtonEventEmitter: EventEmitter<Customer[]> = new EventEmitter<Customer[]>();

  validationStatus = ValidationStatus.noneAttempted;

  customerValidateResults: CustomerValidateResult[] = null;
  hasConfirmEmailValidationError = false;
  hasMobilePhoneValidationError = false;


  get defaultOverrideAddressValidationFlag(): boolean {
    return (this.property ? this.property.overrideValidationFlag : false);
  }


  get defaultAddress(): string {
    return ((this.property ? this.property.address : null) || '').toUpperCase();
  }

  get defaultCity(): string {
    return ((this.property ? this.property.city : null) || '').toUpperCase();
  }

  get defaultStateCode(): string {
    return ((this.property ? this.property.stateCode : null) || '').toUpperCase();
  }

  get defaultZipCode(): string {
    return ((this.property ? this.property.zipCode : null) || '').toUpperCase();
  }


  @ViewChild('givenNameInput')
  givenNameInput: ElementRef;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private customerLookupValueService: CustomerLookupValueService,
              private customerUpdateService: CustomerUpdateService,
              private planOrderAuthService: PlanOrderAuthService) {
  }



  // on init
  ngOnInit() {
    const customers = [];

    (this.customers || []).forEach((indexCustomer: Customer) => {
      const editableCustomer = indexCustomer.duplicate();

      // if the customer has a different mailing address
      if (!this.customerHasMailingAddress(indexCustomer, false)) {
        editableCustomer.hasMailingAddress = false;
      }

      if (indexCustomer.confirmEmail) {
        editableCustomer.confirmEmail = indexCustomer.confirmEmail;
      }

      if (indexCustomer.mobilePhone) {
        editableCustomer.mobilePhone = indexCustomer.mobilePhone;
      }

      customers.push(editableCustomer);
    });

    // if there are no customers, create a default customer
    if (customers.length === 0) {
      const customer = this.newCustomer();
      customers.push(customer);

      setTimeout(() => {
        if (!!this.givenNameInput) {
          this.givenNameInput.nativeElement.focus();
        }
      });
    }

    this.customers = customers;
  }


  buyerConfirmationMessage(): string {
    const introMode = String(this.route.snapshot.params['intro-mode']) || '';

    // default that we want a message
    let flag = true;

    // if HIGHLIGHT isn't displayed, don't display the message
    if (introMode.toUpperCase() !== 'HIGHLIGHT') {
      flag = false;
    }

    // if some validation has been attempted, don't display the message
    if (this.validationStatus !== ValidationStatus.noneAttempted) {
      flag = false;
    }

    // if we want to display the message
    let message: string = null;

    if (flag) {
      message = this.application.homeBuyerOrOwnerText;

      // if there were home buyers
      const homeBuyers = (this.application ? this.application.homeBuyers : null);

      if (homeBuyers && homeBuyers.length) {
        if (homeBuyers.length > 1) {
          message += 's';
        }

        message += ' Updated';

      // if there are no home buyers
      } else {
        message += ' Skipped';
      }
    }

    return message;
  }



  // creates a new customer
  newCustomer(): Customer {
    const customer = this.customerDomainFactoryService.newCustomer();

    this.populateCustomerWithDefaultAddress(customer);

    return customer;
  }


  // populates a customer with defaults
  populateCustomerWithDefaultAddress(customer: Customer) {
    if (this.defaultAddress !== '') {
      customer.address = this.defaultAddress;
    }

    if (this.defaultCity !== '') {
      customer.city = this.defaultCity;
    }

    if (this.defaultStateCode !== '') {
      customer.stateCode = this.defaultStateCode;
    }

    if (this.defaultZipCode !== '') {
      customer.zipCode = this.defaultZipCode;
    }

    customer.overrideAddressValidationFlag = this.defaultOverrideAddressValidationFlag;
  }



  // customer has mailing address?
  customerHasMailingAddress(customer: Customer, includeMailingAddressFlag = true): boolean {
    let hasAddress = false;

    const address = (customer.address || '').toUpperCase();
    const city = (customer.city || '').toUpperCase();
    const stateCode = (customer.stateCode || '').toUpperCase();
    const zipCode = (customer.zipCode || '').toUpperCase();

    const defaultAddress = (this.defaultAddress || '').toUpperCase();
    const defaultCity = (this.defaultCity || '').toUpperCase();
    const defaultStateCode = (this.defaultStateCode || '').toUpperCase();
    const defaultZipCode = (this.defaultZipCode || '').toUpperCase();

    hasAddress = hasAddress || ((address !== '') && (address !== defaultAddress));
    hasAddress = hasAddress || ((city !== '') && (city !== defaultCity));
    hasAddress = hasAddress || ((stateCode !== '') && (stateCode !== defaultStateCode));
    hasAddress = hasAddress || ((zipCode !== '') && (zipCode !== defaultZipCode));

    if (includeMailingAddressFlag) {
      hasAddress = hasAddress || customer.hasMailingAddress;
    }

    return hasAddress;
  }




  // filled-in customers
  filledInCustomers(): Customer[] {
    const filledInCustomers: Customer[] = [];

    this.customers.forEach((indexCustomer: Customer) => {
      let filledInFlag = false;

      filledInFlag = filledInFlag || ((indexCustomer.givenName || '') !== '');
      filledInFlag = filledInFlag || ((indexCustomer.surName || '') !== '');
      filledInFlag = filledInFlag || ((indexCustomer.confirmEmail || '') !== '');
      filledInFlag = filledInFlag || ((indexCustomer.homePhone.formattedPhoneNumber || '') !== '');
      filledInFlag = filledInFlag || ((indexCustomer.workPhone.formattedPhoneNumber || '') !== '');
      filledInFlag = filledInFlag || ((indexCustomer.mobilePhone.formattedPhoneNumber || '') !== '');

      if (indexCustomer.hasMailingAddress) {
        filledInFlag = filledInFlag || ((indexCustomer.address || '') !== '');
        filledInFlag = filledInFlag || ((indexCustomer.city || '') !== '');
        filledInFlag = filledInFlag || ((indexCustomer.stateCode || '') !== '');
        filledInFlag = filledInFlag || ((indexCustomer.zipCode || '') !== '');
      }

      if (filledInFlag) {
        filledInCustomers.push(indexCustomer);
      }
    });

    return filledInCustomers;
  }



  // what is the proper name to use based on relationship code
  nameForRelationshipCode(): string {
    let relationshipName = '';

    switch (this.relationshipCode) {
      case PlanOrderGenericCustomerComponent.homeBuyerRelationshipCode:
        relationshipName = this.application.homeBuyerOrOwnerText;
        break;

      case PlanOrderGenericCustomerComponent.homeSellerRelationshipCode:
        relationshipName = 'Home Seller';
        break;
    }

    return relationshipName;
  }




  // what is the index of the customer
  indexOfCustomer(customer: Customer): number {
    let selectedCustomerIndex = -1;

    for (let customerIndex = 0; customerIndex < this.customers.length; customerIndex++) {
      const indexCustomer = this.customers[customerIndex];

      if (indexCustomer === customer) {
        selectedCustomerIndex = customerIndex;
      }
    }

    return selectedCustomerIndex;
  }




  // mailing address instructions for customer
  mailingAddressInstructionsForCustomer(customer: Customer): string {
    const relationship = this.nameForRelationshipCode();
    const customerIndex = this.indexOfCustomer(customer);

    let instructionText = 'Check here if the ' + relationship + ' mailing address is different from property address';

    if (customerIndex > 0) {
      instructionText += ' and ' + relationship + ' #1 Address';
    }

    return instructionText;
  }



  // should multiple customers be allowed
  allowMultipleCustomers(): boolean {
    let flag = true;

    if (this.relationshipCode === PlanOrderGenericCustomerComponent.homeSellerRelationshipCode) {
      flag = false;
    }

    return flag;
  }





  // should the "Remove Customer" button be shown for this customer
  showRemoveCustomerButton(customer: Customer): boolean {
    return (this.customers.length > 1);
  }


  // should the "add additional" button be shown?
  showAddCustomerButton(): boolean {
    return this.allowMultipleCustomers();
  }


  // customer entry title
  customerEntryTitle(customer: Customer): string {
    let title = this.nameForRelationshipCode();

    // if multiple customers are allowed
    if (this.allowMultipleCustomers()) {
      title += ' #' + String(this.indexOfCustomer(customer) + 1);
    }

    return title;
  }




  // is this the first customer?
  isFirstCustomer(customer: Customer): boolean {
    return (this.indexOfCustomer(customer) === 0);
  }


  // is this the last customer?
  isLastCustomer(customer: Customer): boolean {
    return (this.indexOfCustomer(customer) === (this.customers.length - 1));
  }



  // the user clicked the "Add Customer" button
  didClickAdditionalCustomerButton(): void {
    const customer = this.newCustomer();

    this.customers.push(customer);
  }



  // the user clicked the "Remove Customer" button
  didClickRemoveCustomer(customer: Customer): void {
    const customers = [];

    this.customers.forEach((indexCustomer: Customer) => {
      if (indexCustomer !== customer) {
        customers.push(indexCustomer);
      }
    });

    this.customers = customers;
  }


  // the user clicked the Alt Mailling address for a customer
  didClickAlternateMailingAddress(customer: Customer): void {
    customer.hasMailingAddress = !customer.hasMailingAddress;

    if (!customer.hasMailingAddress) {
      this.populateCustomerWithDefaultAddress(customer);

    } else {
      customer.address = '';
      customer.city = '';
      customer.stateCode = '';
      customer.zipCode = '';

      customer.overrideAddressValidationFlag = false;
    }
  }



  // returns the customer by tag
  customerByTag(tag: number): Customer {
    let customer: Customer = null;

    this.customers.forEach((indexCustomer: Customer) => {
      if (indexCustomer.tag === tag) {
        customer = indexCustomer;
      }
    });

    return customer;
  }


  // returns the validation object for the Customer
  validationObjectForCustomer(customer: Customer): CustomerValidateResult {
    let validation: CustomerValidateResult = null;

    if (this.customerValidateResults) {
      this.customerValidateResults.forEach((indexValidation: CustomerValidateResult) => {
        if (indexValidation.customer === customer) {
          validation = indexValidation;
        }
      });
    }


    return validation;
  }


  // should the first name error be displayed for the customer
  shouldDisplayFirstNameError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerGivenNameError || '') !== '') : false);
  }



  // should the last name error be displayed for the customer
  shouldDisplayLastNameError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerSurNameError || '') !== '') : false);
  }


  // should the home phone error be displayed for the customer
  shouldDisplayHomePhoneError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerHomePhoneError || '') !== '') : false);
  }


  // should the mobile phone error be displayed for the customer
  shouldDisplayMobilePhoneError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerMobilePhoneError || '') !== '') : false);
  }


  // should the work phone error be displayed for the customer
  shouldDisplayWorkPhoneError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerWorkPhoneError || '') !== '') : false);
  }


  // should the email error be displayed for the customer
  shouldDisplayEmailError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    return (validation ? ((validation.customerEmailError || '') !== '') : false);
  }

  shouldDisplayConfirmEmailError(customer: Customer): boolean {

    if (customer.confirmEmail && customer.email.formattedPhoneNumber && (customer.confirmEmail === customer.email.formattedPhoneNumber)) {
      this.hasConfirmEmailValidationError = false;
      return false;
    }
      this.hasConfirmEmailValidationError = true;

      return true;
  }

  shouldDisplayMobilePhoneRequiredError(mobilePhone, indexCustomer): boolean {

    if (this.isDTCOrOlive() && !mobilePhone && this.isFirstCustomer(indexCustomer)) {
      this.hasMobilePhoneValidationError = true;
      return true;
    }
    this.hasMobilePhoneValidationError = false;

    return false;
  }


  // should the street address error be displayed for the customer
  shouldDisplayStreetAddressError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    let displayFlag = false;

    if (validation) {
      displayFlag = displayFlag || ((validation.customerStreetAddressError || '') !== '');
    }

    return displayFlag;
  }


  // should the city error be displayed for the customer
  shouldDisplayCityError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    let displayFlag = false;

    if (validation) {
      displayFlag = displayFlag || ((validation.customerCityError || '') !== '');
    }

    return displayFlag;
  }


  // should the strate error be displayed for the customer
  shouldDisplayStateCodeError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    let displayFlag = false;

    if (validation) {
      displayFlag = displayFlag || ((validation.customerStateCodeError || '') !== '');
    }

    return displayFlag;
  }


  // should the zip code error be displayed for the customer
  shouldDisplayZipCodeError(customer: Customer): boolean {
    const validation = this.validationObjectForCustomer(customer);

    let displayFlag = false;

    if (validation) {
      displayFlag = displayFlag || ((validation.customerZipCodeError || '') !== '');
    }

    return displayFlag;
  }


  // should display validation in process
  shouldDisplayValidationInProgress(): boolean {
    return (this.validationStatus === ValidationStatus.inProgress);
  }


  // should display validation error
  shouldDisplayValidationError(): boolean {
    return (this.validationStatus === ValidationStatus.failed);
  }


  // should the "are you sure" message display if no customers
  shouldDisplayNoCustomersValidationMessage(): boolean {
    return (this.validationStatus === ValidationStatus.confirmNoCustomers);
  }


  // should the "a customer is required" message be displayed
  shouldDisplayCustomerRequiredMessage(): boolean {
    return (this.validationStatus === ValidationStatus.customerRequired);
  }




  shouldDisplayAddressValidationSuccess(): boolean {
    return (this.validationStatus === ValidationStatus.confirmValidatedAddress);
  }


  shouldDisplayAddressValidationModal(): boolean {
    let flag = false;

    if (this.validationStatus === ValidationStatus.confirmValidatedAddress) {
      flag = true;
    }

    if (this.validationStatus === ValidationStatus.confirmNotValidatedAddress) {
      flag = true;
    }

    return flag;
  }


  shouldDisplayEmailAsRequired(): boolean {
    return !!this.planOrderAuthService.productOffice &&
      this.planOrderAuthService.productOffice.homeBuyerEmailRequiredFlag;
  }


  addressValidationResponseHandler(flag: boolean) {
    // if the user wants to continue
    if (flag) {
      // loop over the customer validation results
      this.customerValidateResults.forEach((indexResult: CustomerValidateResult) => {
        const indexCustomer = indexResult.customer;
        const indexAddress = this.addressFromValidationResult(indexResult);

        indexCustomer.address = (indexAddress.address || '').toUpperCase();
        indexCustomer.city = (indexAddress.city || '').toUpperCase();
        indexCustomer.stateCode = (indexAddress.stateCode || '').toUpperCase();
        indexCustomer.zipCode = (indexAddress.zipCode || '').toUpperCase();

        // does the customer have a mailing address
        const customerHasMailingAddressFlag = this.customerHasMailingAddress(indexCustomer);

        // if the customer doesn't have a mailing address, use the default validation
        if (!customerHasMailingAddressFlag) {
          indexCustomer.overrideAddressValidationFlag = this.defaultOverrideAddressValidationFlag;

        } else {
          indexCustomer.overrideAddressValidationFlag = !indexAddress.validatedFlag;
        }
      });

      this.performValidation();


    // if the user wants to revise the addresses
    } else {
      this.validationStatus = ValidationStatus.noneAttempted;
    }
  }




  addressesForValidation(): Address[] {
    const addresses = [];

    (this.customerValidateResults || []).forEach((indexResult: CustomerValidateResult) => {
      // if the customer has a mailing address
      const customer = indexResult.customer;

      if (customer.hasMailingAddress) {
        const address = this.addressFromValidationResult(indexResult);
        addresses.push(address);
      }
    });

    return addresses;
  }


  addressFromValidationResult(result: CustomerValidateResult): Address {
    return result.customerAddress();
  }


  // the user clicked the next button
  didClickNextButton() {
    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag)  {
      if (this.hasConfirmEmailValidationError || this.hasMobilePhoneValidationError) {
        return;
      }
    }

    this.performValidation();
  }



  // performs validation
  performValidation() {
    const customers = this.filledInCustomers();

    // perform customer validation
    this.validationStatus = ValidationStatus.inProgress;


    let validationObservable: Observable<CustomerValidateResult[]>;

    // if customers were specified
    if (customers.length) {
      customers.forEach(cust => {
          if (this.shouldDisplayEmailAsRequired()) {
              cust.emailRequiredFlag = true;
          }
      });
      validationObservable = this.customerUpdateService.performCustomerValidationMultiple(customers);

    // if no customers were specified
    } else {
      validationObservable = Observable.create((subscriber: Subscriber<CustomerValidateResult[]>) => {
        const results = [];

        subscriber.next(results);
        subscriber.complete();
      });
    }


    // execute the observable
    validationObservable.subscribe((results: CustomerValidateResult[]) => {
      let hasErrors = false;
      let hasAddressValidationUpdate = false;
      let hasAddressValidationError = false;

      const propertyAddress = Address.withAddressValues(
        this.property.address,
        this.property.city,
        this.property.stateCode,
        this.property.zipCode
      );

      // loop over the customer validation results
      results.forEach((indexResult: CustomerValidateResult) => {

        const indexCustomer = indexResult.customer;
        const indexCleanParams = <ICustomer>indexResult.customerResult.object;

        // we need the entered customer address
        const indexUserAddress = Address.withAddressValues(
          indexCustomer.address,
          indexCustomer.city,
          indexCustomer.stateCode,
          indexCustomer.zipCode
        );


        // we need the validated address
        let indexCleanAddress: Address = null;

        if (indexCleanParams.address) {
          indexCleanAddress = Address.withAddressValues(
            indexCleanParams.address,
            indexCleanParams.city,
            indexCleanParams.stateCode,
            indexCleanParams.zipCode
          );
        }


        // if the entered address is different from the validated address
        if (indexCleanAddress) {
          if (!indexCleanAddress.compare(indexUserAddress)) {
            hasAddressValidationUpdate = true;
          }
        }


        if (indexResult.customerEmailRequiredError != null) {
          indexResult.customerEmailError = indexResult.customerEmailRequiredError;
        }

        // if there was an error
        if (indexResult.errorFlag) {
          // if there's only a street address error
          if (indexResult.customerAddressValidationError && (indexResult.errors.length === 1)) {
            // if the street address does not match the property address
            if (!indexUserAddress.compare(propertyAddress)) {
              hasAddressValidationError = true;
            }

          } else {
            hasErrors = true;
          }
        }
      });

      this.customerValidateResults = results;


      // if there were no errors found
      if (!hasErrors && !hasAddressValidationError) {
        let validationStatus = ValidationStatus.success;

        // if no customers were specified
        if (!customers.length) {

          if (this.requireCustomerFlag) {
            validationStatus = ValidationStatus.customerRequired;

          } else if (this.promptOnNoCustomersFlag) {
            validationStatus = ValidationStatus.confirmNoCustomers;
          }
        }


        // if there was an address validation update
        if (hasAddressValidationUpdate) {
          validationStatus = ValidationStatus.confirmValidatedAddress;
        }



        this.validationStatus = validationStatus;


        // if the validation status is successful
        if (validationStatus === ValidationStatus.success) {

          // loop over the customers for final treatment
          customers.forEach((indexCustomer: Customer) => {
            // capitalize the first and last name
            indexCustomer.givenName = (indexCustomer.givenName || '').toUpperCase();
            indexCustomer.surName = (indexCustomer.surName || '').toUpperCase();

            // populate missing addresses with the default
            const address = (indexCustomer.address || '');

            if (address === '') {
              this.populateCustomerWithDefaultAddress(indexCustomer);

            } else {
              const overrideAddressValidationFlag = indexCustomer.overrideAddressValidationFlag;

              indexCustomer.address = indexCustomer.address.toUpperCase();
              indexCustomer.city = indexCustomer.city.toUpperCase();
              indexCustomer.stateCode = indexCustomer.stateCode.toUpperCase();
              indexCustomer.zipCode = indexCustomer.zipCode.toUpperCase();

              indexCustomer.overrideAddressValidationFlag = overrideAddressValidationFlag;
            }
          });

          this.nextButtonEventEmitter.emit(customers);
        }


      // there are errors
      } else if (hasErrors) {
        this.validationStatus = ValidationStatus.failed;

      } else if (hasAddressValidationError) {
        this.validationStatus = ValidationStatus.confirmNotValidatedAddress;
      }
    });


  }


  // returns customers with invalid addresses
  customersWithInvalidAddresses(): Customer[] {
    const customers = [];

    if (this.customerValidateResults) {
      this.customerValidateResults.forEach((indexResult: CustomerValidateResult) => {
        if (indexResult.customerAddressValidationError) {
          customers.push(indexResult.customer);
        }
      });
    }

    return customers;
  }




  // the user clicked the "add after all" button
  didClickAddCustomerAfterAll() {
    this.validationStatus = ValidationStatus.noneAttempted;
  }


  // the user clicked the skip button
  didClickSkip() {
    this.validationStatus = ValidationStatus.noneAttempted;

    this.nextButtonEventEmitter.emit([]);
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }

  isDTCOrOlive() {
    return !!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag;
  }

}





enum ValidationStatus {
  noneAttempted = 10,
  inProgress = 20,
  failed = 30,
  success = 40,
  confirmNoCustomers = 50,
  customerRequired = 60,
  confirmNotValidatedAddress = 70,
  confirmValidatedAddress = 80
}




