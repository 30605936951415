

import {Injectable} from '@angular/core';
import {PlanOrderLookupValueService} from './plan-order-lookup-value.service';
import {
  IPlanOrderFetchedApplication, IPlanOrderFetchedCustomer, IPlanOrderPersistedApplication,
  PlanOrderApplication
} from '../_models/plan-order-application';
import {PropertyDomainFactoryService} from '@orhp/phx-property-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {ActivityResult, DateUtility, MessagingFormatter, PhxSerializer} from '@orhp/phx-common-ui-module';
import {ProductDomainFactoryService} from '@orhp/phx-product-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupParams} from '@orhp/phx-customer-ui-module';
import {CustomerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {EmployerLookupParams} from '@orhp/phx-customer-ui-module';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Product} from '@orhp/phx-product-ui-module';
import {ProductLookupService} from '@orhp/phx-product-ui-module';
import {ProductCoverage} from '@orhp/phx-product-ui-module';
import {PropertyLookupParams} from '@orhp/phx-property-ui-module';
import {PropertyLookupService} from '@orhp/phx-property-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {PlanLookupValueService, TransactionType} from '@orhp/phx-plan-ui-module';
import {
  AccountingDomainFactoryService, AccountingLookupValueService, CreditCard, CreditCardService, ICustomerInvoiceRecipient,
  IPlanInvoicePaymentResult,
  PaymentPlanService,
  PlanInvoicePaymentResult
} from '@orhp/phx-accounting-ui-module';
import {PlanDomainFactoryService} from '@orhp/phx-plan-ui-module';
import {PlanInvoiceRecipient} from '@orhp/phx-plan-ui-module';
import {PlanInvoiceRecipientRelationship} from '@orhp/phx-plan-ui-module';
import {PlanClaimHistoryLookupService} from '@orhp/phx-claims-ui-module';
import {ClaimDomainFactoryService} from '@orhp/phx-claims-ui-module';
import {PlanClaimHistory} from '@orhp/phx-claims-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {PhxDateService} from '@orhp/phx-common-ui-module';
import {ActivityResultError, UsefulMap} from '@orhp/phx-common-ui-module';
import {Address} from '@orhp/phx-common-ui-module';
import {Region} from '@orhp/phx-geography-ui-module';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {PlanOption} from '@orhp/phx-plan-ui-module';
import {PlanOrderAuthService} from './plan-order-auth.service';


@Injectable()


export class PlanOrderDomainFactoryService {


  constructor(private planOrderLookupValueService: PlanOrderLookupValueService,
              private propertyDomainFactoryService: PropertyDomainFactoryService,
              private productDomainFactoryService: ProductDomainFactoryService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private claimDomainFactoryService: ClaimDomainFactoryService,
              private planDomainFactoryService: PlanDomainFactoryService,
              private accountingDomainFactoryService: AccountingDomainFactoryService,
              private accountingLookupValueService: AccountingLookupValueService,
              private paymentPlanService: PaymentPlanService,
              private planClaimHistoryLookupService: PlanClaimHistoryLookupService,
              private planLookupValueService: PlanLookupValueService,
              private customerLookupService: CustomerLookupService,
              private employerLookupService: EmployerLookupService,
              private productLookupService: ProductLookupService,
              private propertyLookupService: PropertyLookupService,
              private planOrderAuthService: PlanOrderAuthService,
              private creditCardService: CreditCardService,
              private loginService: PhxLoginService,
              private dateService: PhxDateService
              ) {
  }


  // creates a new application
  newApplication(): PlanOrderApplication {
    const application = new PlanOrderApplication(
      this.propertyDomainFactoryService,
      this.customerDomainFactoryService,
      this.employerDomainFactoryService,
      this.planDomainFactoryService,
      this.planLookupValueService,
      this.accountingDomainFactoryService,
      this.dateService
    );

    // set the logged-in customer on the application
    application.loggedInCustomer = this.planOrderAuthService.customer;
    application.loggedInJobRole = this.planOrderAuthService.jobRole;



    // if the user attempts login
    this.planOrderAuthService.onLoginCompletion.subscribe((successFlag: boolean) => {
      if (successFlag) {
        application.loggedInCustomer = this.planOrderAuthService.customer;
        application.loggedInJobRole = this.planOrderAuthService.jobRole;

      } else {
        application.loggedInCustomer = null;
        application.loggedInJobRole  = null;
      }
    });

    // when the user logs out
    this.planOrderAuthService.onLogout.subscribe((resultFlag: boolean) => {
      application.loggedInCustomer = null;
      application.loggedInJobRole = null;
    });

    return application;
  }



  // creates a new application from the persisted data
  newPersistedApplication(jsonObject?: any): PlanOrderApplication {
    const application = this.newApplication();

    this.populatePersistedApplication(application, jsonObject);

    return application;
  }


  // creates a new fetched application
  newFetchedApplication(jsonObject?: any): Observable<PlanOrderApplication> {
    const application = this.newApplication();

    const observable: Observable<PlanOrderApplication> =
      Observable.create((subscriber: Subscriber<PlanOrderApplication>) => {

      this.populateFetchedApplication(application, jsonObject).subscribe((resultFlag: boolean) => {
        if (resultFlag) {
          subscriber.next(application);

        } else {
          subscriber.next(null);
        }

        subscriber.complete();
      });
    });

    return observable;
  }



  // populates an application from client-side storage
  populatePersistedApplication(application: PlanOrderApplication, jsonObject: any) {
    if (jsonObject) {
      const jsonValues = <IPlanOrderPersistedApplication>jsonObject;

      application.currentStep = jsonValues.currentStep;

      application.demoModeFlag = PhxSerializer.toBoolean(jsonValues.demoModeFlag) || false;
      application.demoModeSubmittedFlag = PhxSerializer.toBoolean(jsonValues.demoModeSubmittedFlag) || false;

      application.allStepsCompletedFlag = PhxSerializer.toBoolean(jsonValues.allStepsCompletedFlag) || false;

      // last error
      if (jsonValues.saveErrors) {
        const saveErrors = [];

        jsonValues.saveErrors.forEach((indexJson: any) => {
          const indexError = new ActivityResultError(indexJson);
          saveErrors.push(indexError);
        });

        application.saveErrors = saveErrors;

      } else {
        application.saveErrors = null;
      }

      application.planID = PhxSerializer.toInteger(jsonValues.planID);
      application.planGUID = jsonValues.planGUID;

      application.channelCode = jsonValues.channelCode;

      application.planReceivedDate = PhxSerializer.toDate(jsonValues.planReceivedDate);
      application.planCoverageStarts = PhxSerializer.toDate(jsonValues.planCoverageStarts);
      application.planCoverageEnds = PhxSerializer.toDate(jsonValues.planCoverageEnds);

      // plan status
      const planStatusCode = PhxSerializer.toInteger(jsonValues.planStatus);
      application.planStatus = this.planLookupValueService.planStatusByCode(planStatusCode);


      // Transaction Type
      const transactionTypeID = PhxSerializer.toInteger(jsonValues.transactionTypeID);
      application.transactionType = this.planLookupValueService.transactionTypeByID(transactionTypeID);


      // Property Type
      const newConstructionFlagID = PhxSerializer.toInteger(jsonValues.newConstructionFlagID);
      application.newConstructionFlag = this.planLookupValueService.newConstructionFlagByID(newConstructionFlagID);


      // Role
      const roleID = PhxSerializer.toInteger(jsonValues.roleID);
      application.role = this.planLookupValueService.transactionRoleByID(roleID);


      // Initiating Agent
      application.initiatingAgentSkippedFlag = PhxSerializer.toBoolean(jsonValues.initiatingAgentSkippedFlag);

      if (jsonValues.initiatingAgent) {
        application.initiatingAgent = this.customerDomainFactoryService.newCustomer(jsonValues.initiatingAgent);

        application.initiatingAgentListingFlag = PhxSerializer.toBoolean(jsonValues.initiatingAgentListingFlag);

      } else {
        application.initiatingAgent = null;
      }


      // Property Region
      if (jsonValues.propertyRegionID) {
        application.propertyRegionID = PhxSerializer.toInteger(jsonValues.propertyRegionID);

      } else {
        application.propertyRegionID = null;
      }



      // Product
      if (jsonValues.product) {
        application.product = this.productDomainFactoryService.newProduct(jsonValues.product);

      } else {
        application.product = null;
      }

      // Orderer Role
      if (jsonValues.planOrdererRole) {

        application.planOrdererRole = this.planDomainFactoryService.newPlanOrdererRole(jsonValues.planOrdererRole);

      } else {
        application.planOrdererRole = null;
      }


      // Optional Coverage
      if (jsonValues.planOptions) {
        const planOptions = [];

        jsonValues.planOptions.forEach((indexJsonObject: any) => {
          const indexOption = this.planDomainFactoryService.newPlanOption(indexJsonObject);
          planOptions.push(indexOption);
        });

        application.planOptions = planOptions;

      } else {
        application.planOptions = null;
      }


      if (jsonValues.productCoverages) {
        const productCoverages = [];

        jsonValues.productCoverages.forEach((indexJsonObject: any) => {
          const indexCoverage = this.productDomainFactoryService.newProductCoverage(indexJsonObject);
          productCoverages.push(indexCoverage);
        });

        application.productCoverages = productCoverages;

      } else {
        application.productCoverages = null;
      }

      if (jsonValues.selectedDisplayCodes) {
        const selectedDisplayCodes = [];

        jsonValues.selectedDisplayCodes.forEach((indexDisplayCode: any) => {
          selectedDisplayCodes.push(<string>indexDisplayCode);
        });

        application.selectedDisplayCodes = selectedDisplayCodes;
      }



      // Property Address
      if (jsonValues.property) {
        application.property = this.propertyDomainFactoryService.newProperty(jsonValues.property);

      } else {
        application.property = null;
      }


      // Cooperating Agent
      application.cooperatingAgentSkippedFlag = PhxSerializer.toBoolean(jsonValues.cooperatingAgentSkippedFlag);

      if (jsonValues.cooperatingAgent) {
        application.cooperatingAgent = this.customerDomainFactoryService.newCustomer(jsonValues.cooperatingAgent);

        application.cooperatingAgentListingFlag = PhxSerializer.toBoolean(jsonValues.cooperatingAgentListingFlag);

      } else {
        application.cooperatingAgent = null;
      }




      // Telemarketing Agent
      if (jsonValues.telemarketingAgent) {
        application.telemarketingAgent = this.customerDomainFactoryService.newCustomer(jsonValues.telemarketingAgent);

      } else {
        application.telemarketingAgent = null;
      }




      // Escrow
      application.closingAgentSkippedFlag = PhxSerializer.toBoolean(jsonValues.closingAgentSkippedFlag);

      if (jsonValues.closingAgent) {
        application.closingAgent = this.customerDomainFactoryService.newCustomer(jsonValues.closingAgent);

      } else {
        application.closingAgent = null;
      }

      application.closingFileNumber = jsonValues.closingFileNumber;
      application.estimatedClosingDate = PhxSerializer.toDate(jsonValues.estimatedClosingDate);
      application.actualClosingDate = PhxSerializer.toDate(jsonValues.actualClosingDate);


      // Invoice Recipient
      const invoiceRecipientID = PhxSerializer.toInteger(jsonValues.invoiceRecipientID);

      if (invoiceRecipientID) {
        application.invoiceRecipient = this.planLookupValueService.invoiceRecipientByID(invoiceRecipientID);

      } else {
        application.invoiceRecipient = null;
      }

      const invoiceRecipientCustomerID = PhxSerializer.toInteger(jsonValues.invoiceRecipientCustomerID);

      if (invoiceRecipientCustomerID) {
        application.invoiceRecipientCustomerID = invoiceRecipientCustomerID;

      } else {
        application.invoiceRecipientCustomerID = null;
      }


      // Default Homeowner
      const defaultHomeownerCustomerID = PhxSerializer.toInteger(jsonValues.defaultHomeownerCustomerID);

      application.defaultHomeownerCustomerID = defaultHomeownerCustomerID;



      // Home Buyers
      const homeBuyerJsonValues = (jsonValues.homeBuyers || []);
      const homeBuyers = [];

      homeBuyerJsonValues.forEach((indexJsonValues: any) => {
        const indexCustomer = this.customerDomainFactoryService.newCustomer(indexJsonValues);
        homeBuyers.push(indexCustomer);
      });

      application.homeBuyers = homeBuyers;


      // Home Sellers
      const homeSellerJsonValues = (jsonValues.homeSellers || []);
      const homeSellers = [];

      homeSellerJsonValues.forEach((indexJsonValues: any) => {
        const indexCustomer = this.customerDomainFactoryService.newCustomer(indexJsonValues);
        homeSellers.push(indexCustomer);
      });

      application.homeSellers = homeSellers;



      // Payment
      application.payNowFlag = PhxSerializer.toBoolean(jsonValues.payNowFlag);

      if (!!jsonValues.paymentPlan) {
        application.paymentPlan = this.paymentPlanService.newPaymentPlan(jsonValues.paymentPlan);
      }

      application.firstPaymentAmount = PhxSerializer.toDouble(jsonValues.firstPaymentAmount);
      application.creditCardGUID = jsonValues.creditCardGUID;
      application.ccCardHolderName = jsonValues.ccCardHolderName;
      application.ccNumber = jsonValues.ccNumber;
      application.ccExpirationMonth = PhxSerializer.toInteger(jsonValues.ccExpirationMonth);
      application.ccExpirationYear = PhxSerializer.toInteger(jsonValues.ccExpirationYear);

      if (jsonValues.ccPaymentResult) {
        const jsonPaymentResult = new ActivityResult(jsonValues.ccPaymentResult);

        const ccPaymentResult = new ActivityResult<PlanInvoicePaymentResult>();
        ccPaymentResult.errors = jsonPaymentResult.errors;

        if (jsonPaymentResult.object) {
          ccPaymentResult.object = new PlanInvoicePaymentResult(jsonPaymentResult.object);
        }

        application.ccPaymentResult = ccPaymentResult;
      }

      // Premium Invoice Recipients
      if (jsonValues.premiumInvoiceRecipients) {
        const recipients = [];
        const recipientJsons = (jsonValues.premiumInvoiceRecipients as ICustomerInvoiceRecipient[]);

        recipientJsons.forEach((indexJson: ICustomerInvoiceRecipient) => {
          recipients.push(this.accountingDomainFactoryService.newCustomerInvoiceRecipient(indexJson));
        });

        application.premiumInvoiceRecipients = recipients;

      } else {
        application.premiumInvoiceRecipients = null;
      }


      // Premium Invoice
      if (jsonValues.premiumInvoice) {
        application.premiumInvoice = this.accountingDomainFactoryService.newCustomerInvoice(jsonValues.premiumInvoice);

      } else {
        application.premiumInvoice = null;
      }




      // Claim History
      if (jsonValues.planClaimHistory) {
        const claims = [];

        jsonValues.planClaimHistory.forEach((indexClaimJsonObject: any) => {
          const claim = this.claimDomainFactoryService.newPlanClaimHistory(indexClaimJsonObject);
          claims.push(claim);
        });

        application.planClaimHistory = claims;
      }


      // Cancels
      if (jsonValues.planCancels) {
        const cancels = [];

        jsonValues.planCancels.forEach((indexCancelJsonObject: any) => {
          const cancel = this.planDomainFactoryService.newPlanCancel(indexCancelJsonObject);
          cancels.push(cancel);
        });

        application.planCancels = cancels;
      }


      // Mailing Address
      if (jsonValues.mailingAddress) {
        application.mailingAddress = new Address(jsonValues.mailingAddress);
      }


      application.autoRenewFlag = jsonValues.autoRenewFlag;
      application.directProcessFlag = jsonValues.directProcessFlag;


      // Updates
      application.updates = jsonValues.updates || [];
      application.edits = jsonValues.edits || [];

      // was the confirmation sent? make sure this is last, because the updates above will reset it
      application.confirmationSentFlag = PhxSerializer.toBoolean(jsonValues.confirmationSentFlag);
    }
  }



  // populates an application from a fetched application
  populateFetchedApplication(application: PlanOrderApplication, jsonObject: any): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      if (jsonObject) {
        const jsonValues = <IPlanOrderFetchedApplication>jsonObject;

        if (jsonValues.planID) {
          const transactionTypeID = PhxSerializer.toInteger(jsonValues.transactionTypeID);
          application.transactionType = this.planLookupValueService.transactionTypeByID(transactionTypeID);

          application.planID = PhxSerializer.toInteger(jsonValues.planID);
          application.planGUID = (jsonValues.guid || '');

          application.channelCode = jsonValues.channelCode;

          application.planReceivedDate = PhxSerializer.toDate(jsonValues.planReceivedDate);
          application.planCoverageStarts = PhxSerializer.toDate(jsonValues.planCoverageStarts);
          application.planCoverageEnds = PhxSerializer.toDate(jsonValues.planCoverageEnds);

          application.paymentPlan = this.accountingLookupValueService.paymentPlanByCode(jsonValues.paymentPlan);

          if (application.paymentPlan.creditCardFlag) {
            application.payNowFlag = true;
          }

          // Cancels
          if (jsonValues.planCancels) {
            const cancels = [];

            jsonValues.planCancels.forEach((indexCancelJsonObject: any) => {
              const cancel = this.planDomainFactoryService.newPlanCancel(indexCancelJsonObject);

              // if the cancellation was not reinstated
              if (!cancel.reinstateDate) {
                cancels.push(cancel);
              }
            });

            application.planCancels = cancels;
          }

          // mailing address
          if (jsonValues.mailingAddress) {
            const address = new Address(jsonValues.mailingAddress);
            application.mailingAddress = address;
          }


          // status
          const planStatusCode = PhxSerializer.toInteger(jsonValues.status);
          application.planStatus = this.planLookupValueService.planStatusByCode(planStatusCode);


          // Orderer Role
          if (jsonValues.planOrdererRole) {

            application.planOrdererRole = this.planDomainFactoryService.newPlanOrdererRole(jsonValues.planOrdererRole);

          } else {
            application.planOrdererRole = null;
          }



          // collect the customers to retrieve
          const customers = [];


          // initiating agent
          if (jsonValues.initiatingAgentCustomerGUID) {
            const customer = this.customerDomainFactoryService.newCustomer();
            customer.guid = jsonValues.initiatingAgentCustomerGUID;

            application.initiatingAgent = customer;

            customers.push(customer);

          } else {
            application.initiatingAgentSkippedFlag = true;
          }

          application.initiatingAgentListingFlag = PhxSerializer.toBoolean(jsonValues.initiatingAgentListingFlag);


          // cooperating agent
          if (jsonValues.cooperatingAgentCustomerGUID) {
            const customer = this.customerDomainFactoryService.newCustomer();
            customer.guid = jsonValues.cooperatingAgentCustomerGUID;

            application.cooperatingAgent = customer;

            customers.push(customer);
          }

          application.cooperatingAgentListingFlag = PhxSerializer.toBoolean(jsonValues.cooperatingAgentListingFlag);


          // closing agent
          if (jsonValues.closingAgentCustomerGUID) {
            const customer = this.customerDomainFactoryService.newCustomer();
            customer.guid = jsonValues.closingAgentCustomerGUID;

            application.closingAgent = customer;

            customers.push(customer);
          }


          // closing info
          application.closingFileNumber = (jsonValues.closingFileNumber || '');
          application.estimatedClosingDate = PhxSerializer.toDate(jsonValues.estimatedClosingDate);
          application.actualClosingDate = PhxSerializer.toDate(jsonValues.actualClosingDate);




          // telemarketing agent
          if (jsonValues.telemarketingAgentCustomerGUID) {
            const customer = this.customerDomainFactoryService.newCustomer();
            customer.guid = jsonValues.telemarketingAgentCustomerGUID;

            application.telemarketingAgent = customer;

            customers.push(customer);

          } else {
            application.telemarketingAgent = null;
          }


          // default homeowner
          const defaultHomeownerCustomerID = PhxSerializer.toInteger(jsonValues.defaultHomeownerCustomerID);

          application.defaultHomeownerCustomerID = defaultHomeownerCustomerID;


          // home buyers
          const homeBuyers = [];

          if (jsonValues.homeBuyers) {
            jsonValues.homeBuyers.forEach((indexCustomerJsonObject: any) => {
              const indexCustomerJsonValues = <IPlanOrderFetchedCustomer>indexCustomerJsonObject;

              const customer = this.customerDomainFactoryService.newCustomer();
              customer.guid = indexCustomerJsonValues.customerGUID;

              homeBuyers.push(customer);

              customers.push(customer);
            });
          }

          application.homeBuyers = homeBuyers;


          // home sellers
          const homeSellers = [];

          if (jsonValues.homeSellers) {
            jsonValues.homeSellers.forEach((indexCustomerJsonObject: any) => {
              const indexCustomerJsonValues = <IPlanOrderFetchedCustomer>indexCustomerJsonObject;

              const customer = this.customerDomainFactoryService.newCustomer();
              customer.guid = indexCustomerJsonValues.customerGUID;

              homeSellers.push(customer);

              customers.push(customer);
            });
          }

          application.homeSellers = homeSellers;


          // premium invoice
          if (jsonValues.premiumInvoice) {
            application.premiumInvoice = this.accountingDomainFactoryService.newCustomerInvoice(jsonValues.premiumInvoice);

          } else {
            application.premiumInvoice = null;
          }


          // Auto renew flag
          if (!!jsonValues.autoRenewFlag) {
            application.autoRenewFlag = jsonValues.autoRenewFlag;

          } else {
            application.autoRenewFlag = false;
          }


          // we need to do some other lookups
          const customerObservable = this.fetchAndPopulateCustomers(customers);

          // product
          const productID = PhxSerializer.toInteger(jsonValues.productID);

          const productObservable = this.fetchAndPopulateProduct(productID, jsonValues.coverages, application);

          // property
          const propertyID = PhxSerializer.toInteger(jsonValues.propertyID);

          const propertyObservable = this.fetchAndPopulateProperty(propertyID, application);

          // claim history
          const claimHistoryObservable = this.fetchAndPopulateClaimHistory(application.planID, application);

          // credit card
          const creditCardObservable = this.fetchAndPopulateCreditCard(application);

          // collect all observables
          const observables = [
            customerObservable,
            productObservable,
            propertyObservable,
            claimHistoryObservable,
            creditCardObservable
          ];



          // populate missing info
          Observable.forkJoin(observables).subscribe((results: any[]) => {

            // invoice recipient
            if (jsonValues.invoiceRecipientID) {
              const invoiceRecipientID = PhxSerializer.toInteger(jsonValues.invoiceRecipientID);
              application.invoiceRecipient = this.planLookupValueService.invoiceRecipientByID(invoiceRecipientID);

            } else {
              application.invoiceRecipient = null;
            }

            // invoice recipient customer
            if (jsonValues.invoiceRecipientCustomerID) {
              const invoiceRecipientCustomerID = PhxSerializer.toInteger(jsonValues.invoiceRecipientCustomerID);
              application.invoiceRecipientCustomerID = invoiceRecipientCustomerID;

            } else {
              application.invoiceRecipientCustomerID = null;
            }


            // clear all updates
            application.clearUpdates();

            subscriber.next(true);
            subscriber.complete();
          });

          // if the plan wasn't found
        } else {
          subscriber.next(false);
          subscriber.complete();
        }
      }
    });

    return observable;

  }


  // fetches customers
  private fetchAndPopulateCustomers(customers: Customer[]): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      let customerGUIDs: string = null;

      // collect the GUID values to load
      customers.forEach((indexCustomer: Customer) => {
        customerGUIDs = (customerGUIDs ? customerGUIDs + ',' : '') + indexCustomer.guid;
      });

      if (!customerGUIDs) {
        customerGUIDs = '';
      }

      if (customerGUIDs.length > 0) {
        const employers = [];

        // initiate the customer fetch
        const params = new CustomerLookupParams();
        params.guid = customerGUIDs;

        // fetches the supplied customers
        this.customerLookupService.fetchCustomers(params).subscribe((fetchedCustomers: Customer[]) => {

          // loop over the existing customers
          customers.forEach((indexCustomer: Customer) => {

            // loop over the fetched customers
            fetchedCustomers.forEach((indexFetchedCustomer: Customer) => {

              // if the customers match
              if (indexCustomer.isEqualToCustomer(indexFetchedCustomer)) {

                // populate the existing customer with the new fetched data
                this.customerDomainFactoryService.populateCustomer(indexCustomer, indexFetchedCustomer.jsonValues());

                if (indexCustomer.employer) {
                  employers.push(indexCustomer.employer);
                }
              }
            });
          });

          this.fetchAndPopulateEmployers(employers).subscribe((boolValue: boolean) => {
            subscriber.next(true);
            subscriber.complete();
          });
        });

      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });

    return observable;
  }


  // fetches employers
  fetchAndPopulateEmployers(employers: Employer[]): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      let employerGUIDs: string = null;

      // collect the GUID values to load
      employers.forEach((indexEmployer: Employer) => {
        employerGUIDs = (employerGUIDs ? employerGUIDs + ',' : '') + indexEmployer.guid;
      });

      // if employers need to be fetched
      if (employers.length > 0) {
        // initiate the employer fetch
        const params = new EmployerLookupParams();
        params.guid = employerGUIDs;

        // fetch the employers
        this.employerLookupService.fetchEmployers(params).subscribe((fetchedEmployers: Employer[]) => {

          // loop over the existing employers
          employers.forEach((indexEmployer: Employer) => {

            // loop over the fetched employers
            fetchedEmployers.forEach((indexFetchedEmployer: Employer) => {

              // if the existing and fetched employer match
              if (indexEmployer.isEqualToEmployer(indexFetchedEmployer)) {

                // populate the existing employer with the fetched employer
                this.employerDomainFactoryService.populateEmployer(indexEmployer, indexFetchedEmployer.jsonValues());
              }
            });
          });

          subscriber.next(true);
          subscriber.complete();
        });

      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });

    return observable;
  }


  // fetches the product
  fetchAndPopulateProduct(productID: number, coverageJsonObjects: any[], application: PlanOrderApplication): Observable<boolean> {

    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      // perform the product fetch by ID
      this.productLookupService.fetchProductByID(productID).subscribe((product: Product) => {

        // if a market segment was returned, pull the new construction flag
        if (product.marketSegment) {
          application.newConstructionFlag = this.planLookupValueService.newConstructionFlagByValue(
            product.marketSegment.newConstructionFlag
          );
        }

        // product must be set after new construction flag
        application.propertyRegionID = product.regionID;
        application.product = product;

        this.fetchAndPopulateCoverages(coverageJsonObjects, application).subscribe((boolValue: boolean) => {
          subscriber.next(true);
          subscriber.complete();
        });
      });
    });

    return observable;
  }


  // fetches optional coverage
  fetchAndPopulateCoverages(coverageJsonObjects: any[], application: PlanOrderApplication): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      // if there is optional coverage
      if (coverageJsonObjects && coverageJsonObjects.length) {
        const product = application.product;

        // create some arrays
        const planOptions = [];
        const productCoverages = [];

        // query for the coverages for the product
        this.productLookupService.fetchCoverages(product).subscribe((fetchedCoverages: ProductCoverage[]) => {

          // loop over plan options
          coverageJsonObjects.forEach((indexJsonObject: any) => {
            const indexPlanOption = this.planDomainFactoryService.newPlanOption(indexJsonObject);
            planOptions.push(indexPlanOption);
          });

          // loop over fetched coverages
          fetchedCoverages.forEach((indexCoverage: ProductCoverage) => {
            // find the PlanOption for this coverage
            const indexOption = ArrayUtility.arrayFind(planOptions, (testOption: PlanOption): boolean => {
              return (testOption.coverageID === indexCoverage.coverageID);
            });

            if (indexOption) {
              planOptions.push(indexOption);
              productCoverages.push(indexCoverage);
            }
          });

          application.productCoverages = productCoverages;
          application.planOptions = planOptions;

          subscriber.next(true);
          subscriber.complete();
        });

        // no optional coverage
      } else {
        application.productCoverages = [];

        subscriber.next(true);
        subscriber.complete();
      }

    });

    return observable;
  }


  // fetches the property
  fetchAndPopulateProperty(propertyID: number, application: PlanOrderApplication): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      const params = new PropertyLookupParams();
      params.propertyID = propertyID;

      this.propertyLookupService.fetchProperties(params).subscribe((properties: Property[]) => {
        if (properties.length === 1) {
          application.property = properties[0];
        }

        subscriber.next(true);
        subscriber.complete();
      });
    });

    return observable;
  }


  // fetches claim history
  fetchAndPopulateClaimHistory(planID: number, application: PlanOrderApplication): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const params = this.claimDomainFactoryService.newPlanClaimHistoryLookupParams();
      params.planID = planID;

      const userCustomer = this.planOrderAuthService.customer;

      // listing or selling agent?
      let listingAgentFlag = false;
      let sellingAgentFlag = false;

      if (application.initiatingAgent &&
          application.initiatingAgent.isEqualToCustomer(userCustomer)) {
        if (application.initiatingAgentListingFlag) {
          listingAgentFlag = true;
        } else {
          sellingAgentFlag = true;
        }
      }

      if (application.cooperatingAgent &&
          application.cooperatingAgent.isEqualToCustomer(userCustomer)) {
        if (application.cooperatingAgentListingFlag) {
          listingAgentFlag = true;
        } else {
          sellingAgentFlag = true;
        }
      }

      if (listingAgentFlag && !sellingAgentFlag) {
        params.sellerFlag = true;

      } else if (sellingAgentFlag && !listingAgentFlag) {
        params.buyerFlag = true;
      }

      this.planClaimHistoryLookupService.fetchPlanClaimHistory(params)
        .subscribe((planClaimHistories: PlanClaimHistory[]) => {

        application.planClaimHistory = planClaimHistories;

        subscriber.next(true);
        subscriber.complete();
      });

    });

    return observable;
  }


  fetchAndPopulateCreditCard(application: PlanOrderApplication): Observable<boolean> {
    const observable = new Observable((subscriber: Subscriber<boolean>) => {
      const creditCardGUID = (application.premiumInvoice ? application.premiumInvoice.creditCardGUID : null);

      let loadCreditCardFlag = application.payNowFlag;
      loadCreditCardFlag = loadCreditCardFlag && ((creditCardGUID || '') !== '');


      if (loadCreditCardFlag) {
        this.creditCardService.creditCardSearch(creditCardGUID).subscribe((result: ActivityResult<CreditCard>) => {
          if (result && result.object) {
            const creditCard = result.object;

            application.creditCardGUID = creditCard.creditCardGUID;
            application.ccCardHolderName = creditCard.cardholder;
            application.ccNumber = creditCard.creditCardNumberMasked;
            application.ccExpirationMonth = creditCard.expirationMonth + 1;
            application.ccExpirationYear = creditCard.expirationYear;
          }

          subscriber.next(true);
          subscriber.complete();
        });

      } else {
        subscriber.next(true);
        subscriber.complete();
      }

    });

    return observable;
  }




}
