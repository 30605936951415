
import {PropertyType} from '@orhp/phx-property-ui-module';

import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';

import {Product, ProductCoverage} from '@orhp/phx-product-ui-module';
import {ProductLookupValueService, ProductLookupService} from '@orhp/phx-product-ui-module';

import {ActivityResult, MessagingFormatter, PhxSerializer} from '@orhp/phx-common-ui-module';

import {PropertyLookupValueService} from '@orhp/phx-property-ui-module';

import {PlanOrdererRole, TransactionType} from '@orhp/phx-plan-ui-module';
import {NewConstructionFlag} from '@orhp/phx-plan-ui-module';
import {TransactionRole} from '@orhp/phx-plan-ui-module';

import {PlanOrderLookupValueService} from '../_services/plan-order-lookup-value.service';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {PropertyDomainFactoryService} from '@orhp/phx-property-ui-module';
import {PlanInvoiceRecipient} from '@orhp/phx-plan-ui-module';
import {
  AccountingDomainFactoryService,
  CustomerInvoiceRecipient,
  PaymentPlan,
  PlanInvoicePaymentResult
} from '@orhp/phx-accounting-ui-module';
import {CustomerInvoice} from '@orhp/phx-accounting-ui-module';
import {PlanDomainFactoryService} from '@orhp/phx-plan-ui-module';
import {Relationship} from '@orhp/phx-plan-ui-module';
import {EventEmitter} from '@angular/core';
import {TransactionRoleOrdererRelationship} from '@orhp/phx-plan-ui-module';
import {PlanStatus} from '@orhp/phx-plan-ui-module';
import {DatePart, DateUtility} from '@orhp/phx-common-ui-module';
import {PlanClaimHistory} from '@orhp/phx-claims-ui-module';
import {PlanCancel} from '@orhp/phx-plan-ui-module';
import {PhxDateService} from '@orhp/phx-common-ui-module';
import {NumberUtility} from '@orhp/phx-common-ui-module';
import {ActivityResultError, UsefulMap} from '@orhp/phx-common-ui-module';
import {Address} from '@orhp/phx-common-ui-module';
import {Region} from '@orhp/phx-geography-ui-module';
import {PlanInvoiceRecipientRelationship} from '@orhp/phx-plan-ui-module';
import {PlanOption} from '@orhp/phx-plan-ui-module';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {JobRole} from '@orhp/phx-customer-ui-module';






export class PlanOrderApplication {

  updates: PlanOrderApplicationUpdates[] = [];
  edits: PlanOrderApplicationUpdates[] = [];


  // Current Step
  currentStep: string = null;

  // Plan Orderer Role
  planOrdererRole: PlanOrdererRole = null;

  // was the confirmation sent?
  confirmationSentFlag = false;


  // Demo Mode?
  demoModeFlag = false;
  demoModeSubmittedFlag = false;


  // errors from saving
  private _saveErrors: ActivityResultError[] = null;

  get saveErrors(): ActivityResultError[] {
    return ArrayUtility.arrayDuplicate(this._saveErrors);
  }

  set saveErrors(saveErrors: ActivityResultError[]) {
    this._saveErrors = ArrayUtility.arrayDuplicate(saveErrors);
  }


  // Logged in Customer
  private _loggedInCustomer: Customer = null;

  get loggedInCustomer(): Customer {
    return this._loggedInCustomer;
  }

  set loggedInCustomer(customer: Customer) {
    this._loggedInCustomer = customer;

    this.reconcileRoleRelationships();
  }


  // Logged in Role
  private _loggedInJobRole: JobRole = null;

  get loggedInJobRole(): JobRole {
    return this._loggedInJobRole;
  }

  set loggedInJobRole(jobRole: JobRole) {
    this._loggedInJobRole = jobRole;
  }



  // Have all steps been completed?
  private _allStepsCompletedFlag = false;

  get allStepsCompletedFlag(): boolean {
    return this._allStepsCompletedFlag;
  }

  set allStepsCompletedFlag(flag: boolean) {
    this._allStepsCompletedFlag = flag;

    if (flag) {
      this.clearEdits();
    }
  }



  // Plan #
  planID: number = null;
  planGUID: string = null;

  get formattedPlanID(): string {
    let formattedPlanID: string = null;

    if (this.planID) {
      formattedPlanID = StringUtility.formatPlanNumber(String(this.planID));
    }

    return formattedPlanID;
  }


  planReceivedDate: Date = null;

  get formattedPlanReceivedDate(): string {
    let text: string = null;

    if (this.planReceivedDate) {
      text = StringUtility.formatDate(this.planReceivedDate);
    }

    return text;
  }


  channelCode: string = null;


  planCoverageStarts: Date = null;
  planCoverageEnds: Date = null;



  // Transaction Type
  private _transactionType: TransactionType = null;

  get transactionType(): TransactionType {
    return this._transactionType;
  }

  set transactionType(transactionType: TransactionType) {
    let updateFlag = false;

    if (transactionType && !this._transactionType) {
      updateFlag = true;

    } else if (!transactionType && this._transactionType) {
      updateFlag = true;

    } else if (transactionType && this._transactionType && !transactionType.isEqual(this._transactionType)) {
      updateFlag = true;
    }

    if (updateFlag) {
      const prevTransactionType = this._transactionType;

      this._transactionType = transactionType;

      this.flagUpdate(PlanOrderApplicationUpdates.transactionType);

      // if we're changing escrow
      const prevActiveListingFlag = (prevTransactionType ? prevTransactionType.activeListingFlag : false);
      const prevInEscrowFlag = (prevTransactionType ? prevTransactionType.inEscrowFlag : false);
      const newActiveListingFlag = (this._transactionType ? this._transactionType.activeListingFlag : false);
      const newInEscrowFlag = (this._transactionType ? this._transactionType.inEscrowFlag : false);

      // if we're going from Active Listing to In Escrow, we need a Closing Agent update
      if (prevActiveListingFlag && newInEscrowFlag) {
        this.flagUpdate(PlanOrderApplicationUpdates.closingAgent);
      }

      // if we're going from In Escrow to Active Listing, remove the closing agent and flag an update
      if (prevInEscrowFlag && newActiveListingFlag) {
        this.closingAgent = null;

        this.flagUpdate(PlanOrderApplicationUpdates.closingAgent);
      }

      // are we updating the role?
      let updateRoleFlag = true;

      // the application has to be new
      updateRoleFlag = updateRoleFlag && !this.planID;

      // the user has to be logged in, with a job role
      updateRoleFlag = updateRoleFlag && !!this.loggedInJobRole;

      // Do Not update from active to in escrow
      if (prevTransactionType !== null) {
        updateRoleFlag = updateRoleFlag && (!prevTransactionType.activeListingFlag  && !transactionType.inEscrowFlag);
      }

      if (updateRoleFlag) {
        const jobRoleCode = this.loggedInJobRole.jobRole;

        // look for a default plan role selection based on job role
        const defaultRole = this.planLookupValueService.ordererRoleDefaultByJobRole(transactionType, jobRoleCode);

        if (defaultRole && defaultRole.planRole) {
          this.role = defaultRole.planRole;

        } else {
          this.role = null;
        }

      }
    }
  }


  // Property Type
  private _newConstructionFlag: NewConstructionFlag = null;

  get newConstructionFlag(): NewConstructionFlag {
    return this._newConstructionFlag;
  }

  set newConstructionFlag(newConstructionFlag: NewConstructionFlag) {
    let updateFlag = false;

    if (!this._newConstructionFlag || !newConstructionFlag) {
      updateFlag = true;

    } else if (!this._newConstructionFlag.isEqual(newConstructionFlag)) {
      updateFlag = true;
    }

    if (updateFlag) {
      this._newConstructionFlag = newConstructionFlag;

      this.flagUpdate(PlanOrderApplicationUpdates.propertyType);

      // the product needs to be re-selected if this was changed
      this.product = null;
    }
  }


  // Role
  private _role: TransactionRole = null;

  get role(): TransactionRole {
    return this._role;
  }

  set role(role: TransactionRole) {
    let updateFlag = false;

    if (role && !this._role) {
      updateFlag = true;

    } else if (!role && this._role) {
      updateFlag = true;

    } else if (role && this._role && !role.isEqual(this._role)) {
      updateFlag = true;
    }


    if (updateFlag) {
      let initiatingAgentListingFlag = false;
      let cooperatingAgentListingFlag = false;

      if (role) {
        initiatingAgentListingFlag = role.realtorListingFlag || role.realtorListingSellingFlag;
        cooperatingAgentListingFlag = role.realtorSellingFlag;
      }

      this._role = role;

      this.initiatingAgentListingFlag = initiatingAgentListingFlag;
      this.cooperatingAgentListingFlag = cooperatingAgentListingFlag;

      this.reconcileRoleRelationships();

      this.flagUpdate(PlanOrderApplicationUpdates.role);
    }

  }



  get initiatingAgentText(): string {
    let text = 'Initiating';

    if (this.transactionType && this.transactionType.realtorReferralFlag) {
      text = 'Insurance';
    }

    return text;
  }




  // Initiating Agent
  private _initiatingAgent: Customer = null;

  get initiatingAgent(): Customer {
    return this._initiatingAgent;
  }

  set initiatingAgent(customer: Customer) {
    let updateCustomerFlag = false;
    let marketSegmentChangeFlag = false;

    // adding an initiating agent
    if (!this._initiatingAgent && customer) {
      updateCustomerFlag = true;
      marketSegmentChangeFlag = true;

    // removing an initiating agent
    } else if (this._initiatingAgent && !customer) {
      updateCustomerFlag = true;
      marketSegmentChangeFlag = true;

    // changing an existing initiating agent
    } else if (this._initiatingAgent && customer && !this._initiatingAgent.isEqualToCustomer(customer)) {
      updateCustomerFlag = true;
    }

    // if we're updating the customer
    if (updateCustomerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.initiatingAgent);

      this._initiatingAgent = customer;
    }

    // if this is a market segment change
    if (marketSegmentChangeFlag && !this.planID) {
      this.product = null;
    }
  }

  get initiatingAgentOffice(): Employer {
    let employer: Employer = null;

    if (this.initiatingAgent && this.initiatingAgent.employer) {
      employer = this.initiatingAgent.employer;
    }

    return employer;
  }


  private _initiatingAgentSkippedFlag = false;

  get initiatingAgentSkippedFlag(): boolean {
    return this._initiatingAgentSkippedFlag;
  }

  set initiatingAgentSkippedFlag(initiatingAgentSkippedFlag: boolean) {
    const clearProductFlag = (this.initiatingAgentSkippedFlag !== initiatingAgentSkippedFlag);

    this._initiatingAgentSkippedFlag = initiatingAgentSkippedFlag;

    if (clearProductFlag) {
      this.product = null;
    }
  }




  private _initiatingAgentListingFlag = false;

  get initiatingAgentListingFlag(): boolean {
    return this._initiatingAgentListingFlag;
  }

  set initiatingAgentListingFlag(flag: boolean) {
    if (flag !== this._initiatingAgentListingFlag) {
      this._initiatingAgentListingFlag = flag;

      if (this.initiatingAgent) {
        this.flagUpdate(PlanOrderApplicationUpdates.initiatingAgent);
      }
    }
  }


  // is this application double-ended
  get doubleEndFlag(): boolean {
    let flag = false;

    if (this.initiatingAgent &&
        this.cooperatingAgent &&
        this.initiatingAgent.isEqualToCustomer(this.cooperatingAgent)) {
      flag = true;
    }

    return flag;
  }



  // Product
  private _marketSegmentID: number = null;
  private _product: Product = null;

  get product(): Product {
    return this._product;
  }

  set product(product: Product) {
    let updateProductFlag = false;

    if (!this._product || !product) {
      updateProductFlag = true;

    } else if (this._product.productID !== product.productID) {
      updateProductFlag = true;
    }

    if (updateProductFlag) {
      if (product && product.marketSegment) {
        this._marketSegmentID = product.marketSegment.marketSegmentID;
      }

      this.productCoverages = null;

      this.flagUpdate(PlanOrderApplicationUpdates.product);
    }

    this._product = product;
  }

  get marketSegmentID(): number {
    return this._marketSegmentID;
  }


  set marketSegmentID(marketSegmentID: number) {
    // if the market segment ID is different
    if (this._marketSegmentID !== marketSegmentID) {
      this._marketSegmentID = marketSegmentID;

      this.product = null;
    }
  }


  // Optional Coverage
  private _planOptions: PlanOption[] = null;

  get planOptions(): PlanOption[] {
    return this._planOptions;
  }


  set planOptions(planOptions: PlanOption[]) {
    const prevSellersCoverageFlag = this.sellersCoverageFlag();

    this._planOptions = ArrayUtility.arrayDuplicate(planOptions);

    const newSellersCoverageFlag = this.sellersCoverageFlag();
    const hasHomeSellerFlag = !!(this.homeSellers || []).length;

    // if sellers coverage was added, but there is no Home Seller, flag that a Home Seller is needed
    if (!prevSellersCoverageFlag && newSellersCoverageFlag && !hasHomeSellerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.homeSeller);
    }
  }







  private _productCoverages: ProductCoverage[] = null;
  selectedDisplayCodes: string[] = null;

  get productCoverages(): ProductCoverage[] {
    return this._productCoverages;
  }

  set productCoverages(coverages: ProductCoverage[]) {
    const prevSellersCoverageFlag = this.sellersCoverageFlag();

    // compare the two coverage arrays
    const coveragesMatchFlag = ArrayUtility.arrayCompare(
      this._productCoverages,
      coverages,
      (item1: any, item2: any) => {
        const coverage1 = <ProductCoverage>item1;
        const coverage2 = <ProductCoverage>item2;

        return (coverage1.coverageID === coverage2.coverageID);
    });

    let updateCoveragesFlag = false;

    if (!coveragesMatchFlag) {
      updateCoveragesFlag = true;
    }

    if (updateCoveragesFlag) {
      if (coverages) {
        const selectedDisplayCodes = [];

        coverages.forEach((indexCoverage: ProductCoverage) => {
          // only include the coverage if the price is not 0
          if ((indexCoverage.premium || 0) !== 0) {
            selectedDisplayCodes.push(indexCoverage.displayCode);
          }
        });

        this.selectedDisplayCodes = selectedDisplayCodes;
      }

      this.flagUpdate(PlanOrderApplicationUpdates.optionalCoverages);

    }

    this._productCoverages = coverages;

    const newSellersCoverageFlag = this.sellersCoverageFlag();
    const hasHomeSellerFlag = !!(this.homeSellers || []).length;

    if (!prevSellersCoverageFlag && newSellersCoverageFlag && !hasHomeSellerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.homeSeller);
    }
  }



  // Property Address
  private _property: Property = null;

  get property(): Property {
    if (!this._property) {
      this._property = this.propertyDomainFactoryService.newProperty();

      this.flagUpdate(PlanOrderApplicationUpdates.propertyAddress);
      this.flagUpdate(PlanOrderApplicationUpdates.propertyType);
    }

    return this._property;
  }

  set property(property: Property) {
    let propertyUpdatedFlag = false;
    let propertyTypeUpdatedFlag = false;

    if (!this._property || !property) {
      propertyUpdatedFlag = true;

    } else {
      if (this._property.propertyID !== property.propertyID) {
        propertyUpdatedFlag = true;
      }

      if (this._property.tag !== property.tag) {
        propertyUpdatedFlag = true;
      }

      if (this._property.propertyType) {
        propertyTypeUpdatedFlag = !this._property.propertyType.isEqual(property.propertyType);
      }
    }

    if (propertyTypeUpdatedFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.propertyType);
    }

    if (propertyUpdatedFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.propertyAddress);

      // synchronize buyer and seller addresses if this is a new plan
      if (!this.planID) {
        this.synchronizeBuyerAddresses(property);
        this.synchronizeSellerAddresses(property);
      }
    }

    this._property = property;

    if (propertyTypeUpdatedFlag) {
      this.product = null;
    }
  }


  // Region
  private _propertyRegionID: number = null;

  get propertyRegionID(): number {
    return this._propertyRegionID;
  }

  set propertyRegionID(propertyRegionID: number) {
    let updateFlag = false;
    let resetProductFlag = false;

    if (!this._propertyRegionID || !propertyRegionID) {
      updateFlag = true;

    } else if (this._propertyRegionID !== propertyRegionID) {
      updateFlag = true;
    }

    if (updateFlag) {
      resetProductFlag = true;
    }

    if (updateFlag) {
      this._propertyRegionID = propertyRegionID;

      this.flagUpdate(PlanOrderApplicationUpdates.propertyType);
    }

    if (resetProductFlag) {
      this.product = null;
    }

  }





  // Cooperating Agent
  private _cooperatingAgent: Customer = null;

  get cooperatingAgent(): Customer {
    return this._cooperatingAgent;
  }

  set cooperatingAgent(customer: Customer) {
    let updateCustomerFlag = false;

    if (!this._cooperatingAgent || !customer) {
      updateCustomerFlag = true;

    } else if (this._cooperatingAgent.customerID !== customer.customerID) {
      updateCustomerFlag = true;
    }

    if (updateCustomerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.cooperatingAgent);
    }

    this._cooperatingAgent = customer;
  }


  get cooperatingAgentOffice(): Employer {
    let employer: Employer = null;

    if (this.cooperatingAgent && this.cooperatingAgent.employer) {
      employer = this.cooperatingAgent.employer;
    }

    return employer;
  }


  cooperatingAgentSkippedFlag = false;


  private _cooperatingAgentListingFlag = false;

  get cooperatingAgentListingFlag(): boolean {
    return this._cooperatingAgentListingFlag;
  }

  set cooperatingAgentListingFlag(flag: boolean) {
    if (flag !== this._cooperatingAgentListingFlag) {
      this._cooperatingAgentListingFlag = flag;

      if (this.cooperatingAgent) {
        this.flagUpdate(PlanOrderApplicationUpdates.cooperatingAgent);
        this.flagUpdate(PlanOrderApplicationUpdates.cooperatingAgentListingFlag);
      }
    }
  }






  // Telemarketing Agent
  private _telemarketingAgent: Customer = null;

  get telemarketingAgent(): Customer {
    return this._telemarketingAgent;
  }

  set telemarketingAgent(customer: Customer) {
    let updateCustomerFlag = false;

    if (!this._telemarketingAgent || !customer) {
      updateCustomerFlag = true;

    } else if (this._telemarketingAgent.customerID !== customer.customerID) {
      updateCustomerFlag = true;
    }

    if (updateCustomerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.telemarketing);
    }

    this._telemarketingAgent = customer;
  }


  get telemarketingAgentOffice(): Employer {
    let employer: Employer = null;

    if (this.telemarketingAgent && this.telemarketingAgent.employer) {
      employer = this.telemarketingAgent.employer;
    }

    return employer;
  }









  // Escrow
  private _closingAgent: Customer = null;

  get closingAgent(): Customer {
    return this._closingAgent;
  }

  set closingAgent(customer: Customer) {
    let updateCustomerFlag = false;

    if (!this._closingAgent || !customer) {
      updateCustomerFlag = true;

    } else if (this._closingAgent.customerID !== customer.customerID) {
      updateCustomerFlag = true;
    }

    if (updateCustomerFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.closingAgent);
      this.flagUpdate(PlanOrderApplicationUpdates.closingInfo);
    }

    this._closingAgent = customer;
  }


  get closingOffice(): Employer {
    let employer: Employer = null;

    if (this.closingAgent && this.closingAgent.employer) {
      employer = this.closingAgent.employer;
    }

    return employer;
  }


  closingAgentSkippedFlag = false;


  // Closing Info
  private _closingFileNumber: string = null;

  get closingFileNumber(): string {
    return this._closingFileNumber;
  }

  set closingFileNumber(closingFileNumber: string) {
    if (closingFileNumber !== this._closingFileNumber) {
      this.flagUpdate(PlanOrderApplicationUpdates.closingInfo);
    }

    this._closingFileNumber = closingFileNumber;
  }


  private _estimatedClosingDate: Date = null;

  get estimatedClosingDate(): Date {
    return this._estimatedClosingDate;
  }

  set estimatedClosingDate(estimatedClosingDate: Date) {
    if (estimatedClosingDate !== this._estimatedClosingDate) {
      this.flagUpdate(PlanOrderApplicationUpdates.closingInfo);
    }

    this._estimatedClosingDate = estimatedClosingDate;
  }


  get formattedEstimatedClosingDate(): string {
    return StringUtility.formatDate(this.estimatedClosingDate);
  }


  private _actualClosingDate: Date = null;

  get actualClosingDate(): Date {
    return this._actualClosingDate;
  }

  set actualClosingDate(actualClosingDate: Date) {
    if (actualClosingDate !== this._actualClosingDate) {
      this.flagUpdate(PlanOrderApplicationUpdates.closingInfo);
    }

    this._actualClosingDate = actualClosingDate;
  }


  get formattedActualClosingDate(): string {
    return StringUtility.formatDate(this.actualClosingDate);
  }



  private _invoiceRecipient: PlanInvoiceRecipient = null;

  get invoiceRecipient(): PlanInvoiceRecipient {
    return this._invoiceRecipient;
  }

  set invoiceRecipient(invoiceRecipient: PlanInvoiceRecipient) {
    let updateFlag = false;

    if (invoiceRecipient && !this._invoiceRecipient) {
      updateFlag = true;
    } else if (!invoiceRecipient && this._invoiceRecipient) {
      updateFlag = true;
    } else if (invoiceRecipient && this._invoiceRecipient) {
      updateFlag = (invoiceRecipient.invoiceRecipientID !== this._invoiceRecipient.invoiceRecipientID);
    }

    if (updateFlag) {
      this._invoiceRecipient = invoiceRecipient;

      this.flagUpdate(PlanOrderApplicationUpdates.invoiceRecipient);

      // do we need a customer selection?
      if (invoiceRecipient) {
        if (invoiceRecipient.homeBuyerFlag) {
          const hasCustomerFlag = (this.homeBuyers ? !!this.homeBuyers.length : false);

          if (!hasCustomerFlag) {
            this.flagUpdate(PlanOrderApplicationUpdates.homeBuyer);
          }
        }

        if (invoiceRecipient.homeSellerFlag) {
          const hasCustomerFlag = (this.homeSellers ? !!this.homeSellers.length : false);

          if (!hasCustomerFlag) {
            this.flagUpdate(PlanOrderApplicationUpdates.homeSeller);
          }
        }
      }
    }
  }


  // Invoice Recipient Customer
  invoiceRecipientCustomerID: number = null;

  get invoiceRecipientCustomer(): Customer {
    let customer: Customer = null;

    if (this.initiatingAgent && (this.initiatingAgent.customerID === this.invoiceRecipientCustomerID)) {
      customer = this.initiatingAgent;
    }

    if (this.cooperatingAgent && (this.cooperatingAgent.customerID === this.invoiceRecipientCustomerID)) {
      customer = this.cooperatingAgent;
    }

    if (this.closingAgent && (this.closingAgent.customerID === this.invoiceRecipientCustomerID)) {
      customer = this.closingAgent;
    }

    if (this.homeBuyers) {
      this.homeBuyers.forEach((indexCustomer: Customer) => {
        if (indexCustomer.customerID === this.invoiceRecipientCustomerID) {
          customer = indexCustomer;
        }
      });
    }

    if (this.homeSellers) {
      this.homeSellers.forEach((indexCustomer: Customer) => {
        if (indexCustomer.customerID === this.invoiceRecipientCustomerID) {
          customer = indexCustomer;
        }
      });
    }

    return customer;
  }



  // Additional Email Address entry
  additionalEmailAddresses: string[] = null;






  // Default homeowner
  defaultHomeownerCustomerID: number = null;



  get homeBuyerOrOwnerText(): string {
    let buyerFlag = true;

    if (this.transactionType && this.transactionType.directToConsumerFlag) {
      buyerFlag = false;
    }

    if (this.product && this.product.marketSegment && this.product.marketSegment.directFlag) {
      buyerFlag = false;
    }

    return (buyerFlag ? 'Home Buyer' : 'Homeowner');
  }



  // Home Buyer
  private _homeBuyers: Customer[] = null;

  get homeBuyers(): Customer[] {
    return this._homeBuyers;
  }

  set homeBuyers(customers: Customer[]) {
    let updateCustomersFlag = false;

    if (!this._homeBuyers || !customers) {
      updateCustomersFlag = true;

    } else if (this._homeBuyers.length !== customers.length) {
      updateCustomersFlag = true;

    } else {
      customers.forEach((indexNewCustomer: Customer) => {
        let indexCustomerFoundFlag = false;

        this._homeBuyers.forEach((indexExistingCustomer: Customer) => {
          if (indexNewCustomer.isEqualToCustomer(indexExistingCustomer)) {
            indexCustomerFoundFlag = true;
          }
        });

        if (!indexCustomerFoundFlag) {
          updateCustomersFlag = true;
        }

        if (indexNewCustomer.hasUpdateFlag) {
          updateCustomersFlag = true;
        }
      });
    }

    if (updateCustomersFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.homeBuyer);
    }


    // check home buyer updates
    const existingHomeBuyerCount = (this._homeBuyers ? this._homeBuyers.length : 0);
    const newHomeBuyerCount = (customers ? customers.length : 0);

    // if there were existing home buyers but no new ones, add a mailing address
    if (existingHomeBuyerCount && !newHomeBuyerCount) {
      const homeBuyer = this._homeBuyers[0];

      if ((homeBuyer.address || '') === '') {
        const mailingAddress = new Address();

        mailingAddress.address = homeBuyer.address;
        mailingAddress.city = homeBuyer.city;
        mailingAddress.stateCode = homeBuyer.stateCode;
        mailingAddress.zipCode = homeBuyer.zipCode;

        this.mailingAddress = mailingAddress;
      }
    }

    this._homeBuyers = customers;
  }


  // Home Seller
  private _homeSellers: Customer[] = null;

  get homeSellers(): Customer[] {
    return this._homeSellers;
  }

  set homeSellers(customers: Customer[]) {
    let updateCustomersFlag = false;

    if (!this._homeSellers || !customers) {
      updateCustomersFlag = true;

    } else if (this._homeSellers.length !== customers.length) {
      updateCustomersFlag = true;

    } else {
      customers.forEach((indexNewCustomer: Customer) => {
        let indexCustomerFoundFlag = false;

        this._homeSellers.forEach((indexExistingCustomer: Customer) => {
          if (indexNewCustomer.isEqualToCustomer(indexExistingCustomer)) {
            indexCustomerFoundFlag = true;
          }
        });

        if (!indexCustomerFoundFlag) {
          updateCustomersFlag = true;
        }

        if (indexNewCustomer.hasUpdateFlag) {
          updateCustomersFlag = true;
        }
      });
    }

    if (updateCustomersFlag) {
      this.flagUpdate(PlanOrderApplicationUpdates.homeSeller);
    }

    this._homeSellers = customers;
  }



  // mailing address
  private _mailingAddress: Address = null;

  get mailingAddress(): Address {
    let address = this._mailingAddress;

    if (!address) {
      // if there are home buyers
      if (this.homeBuyers && this.homeBuyers.length) {
        const homeBuyer = this.homeBuyers[0];

        const mailingAddress = (homeBuyer.address || '');

        if (mailingAddress !== '') {
          address = new Address();
          address.address = homeBuyer.address;
          address.city = homeBuyer.city;
          address.stateCode = homeBuyer.stateCode;
          address.zipCode = homeBuyer.zipCode;
        }
      }
    }

    if (!address) {
      // if there is a property address
      if (this.property) {
        const propertyAddress = (this.property.address || '');

        if (propertyAddress !== '') {
          address = new Address();
          address.address = this.property.address;
          address.city = this.property.city;
          address.stateCode = this.property.stateCode;
          address.zipCode = this.property.zipCode;
        }
      }
    }

    return address;
  }

  set mailingAddress(address: Address) {
    let updateFlag = false;

    if (address && !this._mailingAddress) {
      updateFlag = true;

    } else if (!address && this._mailingAddress) {
      updateFlag = true;

    } else if (address && this._mailingAddress) {
      updateFlag = updateFlag || (address.address !== this._mailingAddress.address);
      updateFlag = updateFlag || (address.city !== this._mailingAddress.city);
      updateFlag = updateFlag || (address.stateCode !== this._mailingAddress.stateCode);
      updateFlag = updateFlag || (address.zipCode !== this._mailingAddress.zipCode);
    }

    if (updateFlag) {
      this._mailingAddress = address;

      // if there is a primary home buyer
      if (this.homeBuyers && this.homeBuyers.length) {
        const homeBuyer = this.homeBuyers[0];
        const homeBuyerUpdatedFlag = homeBuyer.hasUpdateFlag;

        homeBuyer.address = address.address;
        homeBuyer.city = address.city;
        homeBuyer.stateCode = address.stateCode;
        homeBuyer.zipCode = address.zipCode;

        homeBuyer.overrideAddressValidationFlag = true;

        homeBuyer.hasUpdateFlag = homeBuyerUpdatedFlag;
      }

      this.flagUpdate(PlanOrderApplicationUpdates.mailingAddress);
    }
  }







  // payment detail
  payNowFlag: boolean = null;

  paymentPlan: PaymentPlan = null;
  firstPaymentAmount: number = null;
  creditCardGUID: string = null;
  ccCardHolderName: string = null;
  ccNumber: string = null;
  ccExpirationMonth: number = null;
  ccExpirationYear: number = null;

  ccPaymentResult: ActivityResult<PlanInvoicePaymentResult> = null;



  // premium invoice
  premiumInvoice: CustomerInvoice = null;



  // premium invoice recipients
  private _premiumInvoiceRecipients: CustomerInvoiceRecipient[] = null;

  get premiumInvoiceRecipients(): CustomerInvoiceRecipient[] {
    if (!this._premiumInvoiceRecipients && !!this.premiumInvoice) {
      this._premiumInvoiceRecipients = ArrayUtility.arrayDuplicate(this.premiumInvoice.recipients);
    }

    if (!this._premiumInvoiceRecipients) {
      this._premiumInvoiceRecipients = [];
    }

    return this._premiumInvoiceRecipients;
  }

  set premiumInvoiceRecipients(recipients: CustomerInvoiceRecipient[]) {
    this._premiumInvoiceRecipients = ArrayUtility.arrayDuplicate(recipients);
  }




  // plan status
  planStatus: PlanStatus = null;



  // claim history (read-only)
  planClaimHistory: PlanClaimHistory[] = null;



  // cancellation (read-only)
  planCancels: PlanCancel[] = null;

  get planCancel(): PlanCancel {
    let planCancel: PlanCancel = null;

    if (this.planCancels && this.planCancels.length) {
      planCancel = this.planCancels[0];
    }

    return planCancel;
  }



  // all relationships
  get customers(): Customer[] {
    const customers = [];

    if (this.initiatingAgent) {
      customers.push(this.initiatingAgent);
    }

    if (this.cooperatingAgent) {
      customers.push(this.cooperatingAgent);
    }

    if (this.closingAgent) {
      customers.push(this.closingAgent);
    }

    if (this.homeBuyers) {
      this.homeBuyers.forEach((indexCustomer: Customer) => {
        customers.push(indexCustomer);
      });
    }

    if (this.homeSellers) {
      this.homeSellers.forEach((indexCustomer: Customer) => {
        customers.push(indexCustomer);
      });
    }

    return customers;
  }

  // for Auto-renewal feature
  private _autoRenewFlag: boolean = null;

  get autoRenewFlag(): boolean {
    return this._autoRenewFlag;
  }

  set autoRenewFlag(autoRenewFlag: boolean) {
    this._autoRenewFlag = autoRenewFlag;
  }


  // for Auto-renewal feature
  private _directProcessFlag = false;

  get directProcessFlag(): boolean {
    return this._directProcessFlag;
  }

  set directProcessFlag(directProcessFlag: boolean) {
    this._directProcessFlag = directProcessFlag;
  }


  // should confirmations/invoices be sent immediately?
  sendConfirmationsOnSaveFlag = true;




  // services
  propertyDomainFactoryService: PropertyDomainFactoryService;
  customerDomainFactoryService: CustomerDomainFactoryService;
  employerDomainFactoryService: EmployerDomainFactoryService;
  planDomainFactoryService: PlanDomainFactoryService;
  planLookupValueService: PlanLookupValueService;
  accountingDomainFactoryService: AccountingDomainFactoryService;
  dateService: PhxDateService;




  constructor(propertyDomainFactoryService: PropertyDomainFactoryService,
              customerDomainFactoryService: CustomerDomainFactoryService,
              employerDomainFactoryService: EmployerDomainFactoryService,
              planDomainFactoryService: PlanDomainFactoryService,
              planLookupValueService: PlanLookupValueService,
              accountingDomainFactoryService: AccountingDomainFactoryService,
              dateService: PhxDateService) {

    this.propertyDomainFactoryService = propertyDomainFactoryService;
    this.customerDomainFactoryService = customerDomainFactoryService;
    this.employerDomainFactoryService = employerDomainFactoryService;
    this.planDomainFactoryService = planDomainFactoryService;
    this.planLookupValueService = planLookupValueService;
    this.accountingDomainFactoryService = accountingDomainFactoryService;
    this.dateService = dateService;

    this.planReceivedDate = this.dateService.currentDate();
  }





  closingDate(): Date {
    let closingDate: Date = null;

    if (this.actualClosingDate) {
      closingDate = this.actualClosingDate;

    } else if (this.estimatedClosingDate) {
      closingDate = this.estimatedClosingDate;
    }

    return closingDate;
  }



  // returns sellers coverage days
  sellersCoverageDays(): number {
    let sellersCoverageDays: number = null;

    let sellersCoverageDurationQty = 0;

    const sellersCoverage = <ProductCoverage>ArrayUtility.arrayFind(this.productCoverages,
      (testCoverage: ProductCoverage): boolean => {

      const sellersCoverageFlag = testCoverage.coverageType.sellerCoverageFlag;
      const includedCoverageFlag = testCoverage.includedCoverageFlag;

      return (sellersCoverageFlag && !includedCoverageFlag);
    });

    if (sellersCoverage) {
      sellersCoverageDurationQty = sellersCoverage.durationQty;

      const currentDate = DateUtility.midnightDate(this.dateService.currentDate());
      const closingDate = DateUtility.midnightDate(this.closingDate());

      const planReceivedDate = DateUtility.midnightDate(this.planReceivedDate);
      const planCoverageStarts = (this.planCoverageStarts ? DateUtility.midnightDate(this.planCoverageStarts) : null);

      // does the plan have a start date?
      if (planCoverageStarts) {
        const planActiveDays = DateUtility.dateDiff(DatePart.days, planReceivedDate, planCoverageStarts);

        // is the plan coverage start date prior to the app received date?
        if (planActiveDays < 0) {
          sellersCoverageDays = 0;

        } else {
          sellersCoverageDays = planActiveDays;
        }


      // does the plan have a closing date?
      } else if (closingDate) {
        const closingDays = DateUtility.dateDiff(DatePart.days, planReceivedDate, closingDate);

        // is the estimated COE prior to the app received date
        if (closingDays < 0) {
          sellersCoverageDays = 0;

        } else {
          // is the closing date before today?
          const closingBeforeToday = (DateUtility.dateDiff(DatePart.days, currentDate, closingDate) < 0);

          // if it use, use the date count between app received and closing date
          if (closingBeforeToday) {
            sellersCoverageDays = closingDays;

          // if the closing days are greater than the maximum?
          } else if (closingDays > sellersCoverageDurationQty) {
            sellersCoverageDays = sellersCoverageDurationQty;

          } else {
            sellersCoverageDays = closingDays;
          }
        }

        // plan has neither
      } else {
        sellersCoverageDays = sellersCoverageDurationQty;
      }
    }

    return sellersCoverageDays;
  }



  // sellers coverage flag
  sellersCoverageFlag(): boolean {
    let flag = false;

    (this.productCoverages || []).forEach((indexCoverage: ProductCoverage) => {
      if (indexCoverage.coverageType.sellerCoverageFlag) {
        flag = true;
      }
    });

    return flag;
  }



  // returns sellers coverage cost
  sellersCoveragePremium(): number {
    const sellersCoverageDays = this.sellersCoverageDays();

    let premium: number = null;

    if (this.productCoverages) {
      this.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        // if this is sellers coverage
        if (indexCoverage.coverageType.sellerCoverageFlag) {
          // if the premium is a daily rate
          if (indexCoverage.dailyFlag) {
            let flag = true;
            flag = flag && (sellersCoverageDays > 0);
            flag = flag && (sellersCoverageDays !== indexCoverage.durationQty);

            // if sellers coverage days are positive
            if (flag) {
              premium = NumberUtility.roundDollarWithCents(indexCoverage.premium * sellersCoverageDays);

            // negative/0 sellers coverage days means 0 premium
            } else {
              premium = 0;
            }

          } else {
            premium = indexCoverage.premium;
          }
        }
      });
    }

    return premium;
  }


  // formatted sellers coverage premium
  formattedSellersCoveragePremium(): string {
    return StringUtility.formatDollarWithDecimal(this.sellersCoveragePremium());
  }


  // total premium
  coverageTotalPremium(): number {
    let premium = 0;

    if (this.product) {
      premium = this.product.premium;
    }

    const sellersCoverageDays = this.sellersCoverageDays();

    (this.productCoverages || []).forEach((indexCoverage: ProductCoverage) => {
      const sellersCoverageFlag = indexCoverage.coverageType.sellerCoverageFlag;
      const includedCoverageFlag = (indexCoverage.premium === 0);

      if (!includedCoverageFlag) {
        if (!indexCoverage.dailyFlag) {
          const optionUnits = this.optionUnitsForCoverage(indexCoverage);

          premium += (indexCoverage.premium * optionUnits);

        } else if (sellersCoverageFlag && sellersCoverageDays) {
          const sellerPremium = this.sellersCoveragePremium();

          if (sellerPremium) {
            premium += sellerPremium;
          }
        }

        premium = NumberUtility.roundDollarWithCents(premium);
      }
    });

    return premium;
  }


  // formatted total premium
  formattedCoverageTotalPremium(): string {
    return StringUtility.formatDollarWithDecimal(this.coverageTotalPremium());
  }


  // does the application have an initiating agent?
  hasInitiatingAgent(): boolean {
    let hasAgentFlag = false;

    if (this.initiatingAgent) {
      hasAgentFlag = true;

    } else {
      if (this.role) {
        hasAgentFlag = hasAgentFlag || this.role.realtorListingFlag;
        hasAgentFlag = hasAgentFlag || this.role.realtorSellingFlag;
        hasAgentFlag = hasAgentFlag || this.role.realtorListingSellingFlag;
      }
    }

    return hasAgentFlag;
  }




  // reconciles the role with relationships
  reconcileRoleRelationships() {
    const customer = this.loggedInCustomer || this.customerDomainFactoryService.newCustomer();

    let initiatingAgentFlag = false;
    let cooperatingAgentFlag = false;
    let closingFlag = false;
    let telemarketingFlag = false;

    // are we assigning the user to a role?
    if (this.role && customer) {
      this.role.ordererRelationships.forEach(
        (indexOrdererRelationship: TransactionRoleOrdererRelationship) => {
          const relationship = indexOrdererRelationship.assignToRelationship;

          if (relationship.initiatingAgentFlag) {
            initiatingAgentFlag = true;

          } else if (relationship.cooperatingAgentFlag) {
            cooperatingAgentFlag = true;

          } else if (relationship.closingFlag) {
            closingFlag = true;

          } else if (relationship.telemarketingFlag) {
            telemarketingFlag = true;
          }
        });

      // check previous assignments
      if (!initiatingAgentFlag) {
        if (this.initiatingAgent &&
          this.initiatingAgent.isEqualToCustomer(customer)) {
          this.initiatingAgent = null;
        }

      } else {
        this.initiatingAgent = customer;
      }

      if (!cooperatingAgentFlag) {
        if (this.cooperatingAgent &&
          this.cooperatingAgent.isEqualToCustomer(customer)) {
          this.cooperatingAgent = null;
        }

      } else {
        this.cooperatingAgent = customer;
      }

      if (!closingFlag) {
        if (this.closingAgent &&
          this.closingAgent.isEqualToCustomer(customer)) {
          this.closingAgent = null;
        }

      } else {
        this.closingAgent = customer;
      }

      if (!telemarketingFlag) {
        if (this.telemarketingAgent &&
          this.telemarketingAgent.isEqualToCustomer(customer)) {
          this.telemarketingAgent = null;
        }

      } else {
        this.telemarketingAgent = customer;
      }
    }
  }


  // does this plan have the specified display code
  hasDisplayCode(displayCode: string): boolean {
    let flag = false;

    if (this.productCoverages) {
      this.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        const indexDisplayCode = (indexCoverage.displayCode || 'blah').trim();

        if (indexDisplayCode.toLowerCase() === displayCode.toLowerCase()) {
          flag = true;
        }
      });
    }

    return flag;
  }


  // does the customer have the property address
  customerHasPropertyAddress(customer: Customer): boolean {
    let flag = false;

    if ((customer.address || '') !== '') {
      const property = this.property;

      const customerAddress = (customer.address || '').toLowerCase();
      const customerZip = (customer.zipCode || '').substr(0, 5);

      const propertyAddress = (property.address || '').toLowerCase();
      const propertyZip = (property.zipCode || '').substr(0, 5);

      const addressFlag = (customerAddress === propertyAddress);
      const zipFlag = (customerZip === propertyZip);

      if (addressFlag && zipFlag) {
        flag = true;
      }

    } else {
      flag = true;
    }

    return flag;
  }



  logError(identifier: string, error: Error) {
    const resultError = new ActivityResultError();

    resultError.identifier = identifier;

    if (error.stack) {
      resultError.message = error.stack;

    } else {
      resultError.message = error.toString();
    }

    this.saveErrors = [resultError];

    console.error(resultError.identifier);
    console.error(resultError.message);
  }







  // synchronizes customer addresses
  private synchronizeBuyerAddresses(newProperty: Property): boolean {
    let flag = false;

    if (this.homeBuyers) {
      flag = this.synchronizeCustomerAddresses(this.homeBuyers, newProperty);
    }

    return flag;
  }


  private synchronizeSellerAddresses(newProperty: Property): boolean {
    let flag = false;

    if (this.homeSellers) {
      flag = this.synchronizeCustomerAddresses(this.homeSellers, newProperty);
    }

    return flag;
  }


  private synchronizeCustomerAddresses(customers: Customer[], newProperty: Property): boolean {
    let flag = false;

    // loop over the supplied customers
    customers.forEach((indexCustomer: Customer) => {
      // if the customer has the same address as the property address
      if (this.customerHasPropertyAddress(indexCustomer)) {
        flag = true;

        indexCustomer.address = newProperty.address;
        indexCustomer.city = newProperty.city;
        indexCustomer.stateCode = newProperty.stateCode;
        indexCustomer.zipCode = newProperty.zipCode;
        indexCustomer.overrideAddressValidationFlag = true;
      }
    });

    return flag;
  }




  // returns JSON values
  jsonValues(): Map<string, any> {
    const jsonValues = new Map<string, any>();

    const newPlanFlag = !this.planID;

    jsonValues['planID'] = MessagingFormatter.integerToString(this.planID);
    jsonValues['guid'] = (this.planGUID || '');

    if (newPlanFlag && this.role) {
      jsonValues['planRoleID'] = MessagingFormatter.integerToString(this.role.roleID);
    }

    if (this.property && this.hasUpdate(PlanOrderApplicationUpdates.propertyAddress)) {
      jsonValues['propertyID'] = MessagingFormatter.integerToString(this.property.propertyID);
      jsonValues['propertyGUID'] = (this.property.guid || '');
    }

    const productCoveragesFlag = (this.productCoverages && this.hasUpdate(PlanOrderApplicationUpdates.optionalCoverages));

    let productFlag = false;

    productFlag = productFlag || (this.product && this.hasUpdate(PlanOrderApplicationUpdates.product));
    productFlag = productFlag || productCoveragesFlag;

    if (productFlag) {
      jsonValues['productID'] = MessagingFormatter.integerToString(this.product.productID);
      jsonValues['productGUID'] = (this.product.guid || '');
    }

    if (this.planOrdererRole) {
      jsonValues['planOrdererRole'] = this.planOrdererRole.jsonValues();
    }

    if (this.hasUpdate(PlanOrderApplicationUpdates.closingInfo)) {
      jsonValues['closingInfoUpdateFlag'] = MessagingFormatter.booleanToString(true);
      jsonValues['closingFileNumber'] = (this.closingFileNumber || '');
      jsonValues['estimatedClosingDate'] = MessagingFormatter.dateToString(this.estimatedClosingDate);
      jsonValues['actualClosingDate'] = MessagingFormatter.dateToString(this.actualClosingDate);
    }

    const invoiceRecipientID = (this.invoiceRecipient ? this.invoiceRecipient.invoiceRecipientID : null);

    if (invoiceRecipientID) {
      jsonValues['invoiceRecipientID'] = MessagingFormatter.integerToString(invoiceRecipientID);
      }


    if (this.channelCode) {
      jsonValues['channelCode'] = this.channelCode;
    }


    // if there is an initiating agent update
    if (this.hasUpdate(PlanOrderApplicationUpdates.initiatingAgent)) {
      // if there is an initiating agent
      if (this.initiatingAgent) {
        jsonValues['initiatingAgentCustomerID'] = MessagingFormatter.integerToString(this.initiatingAgent.customerID);
        jsonValues['initiatingAgentCustomerGUID'] = (this.initiatingAgent.guid || '');
        jsonValues['initiatingAgentListingFlag'] = MessagingFormatter.booleanToString(this.initiatingAgentListingFlag);

      // if there is no initiating agent
      } else {
        jsonValues['initiatingAgentCustomerID'] = '';
      }
    }


    // if there is a cooperating agent update
    if (this.hasUpdate(PlanOrderApplicationUpdates.cooperatingAgent)) {
      // if there is a cooperating agent
      if (this.cooperatingAgent) {
        jsonValues['cooperatingAgentCustomerID'] = MessagingFormatter.integerToString(this.cooperatingAgent.customerID);
        jsonValues['cooperatingAgentCustomerGUID'] = (this.cooperatingAgent.guid || '');
        jsonValues['cooperatingAgentListingFlag'] = MessagingFormatter.booleanToString(this.cooperatingAgentListingFlag);

      // if there is no cooperating agent
      } else {
        jsonValues['cooperatingAgentCustomerID'] = '';
      }
    }



    // if there is a telemarketing agent update
    if (this.hasUpdate(PlanOrderApplicationUpdates.telemarketing)) {
      // if there is a telemarketing agent
      if (this.telemarketingAgent) {
        jsonValues['telemarketingAgentCustomerID'] = PhxSerializer.fromInteger(this.telemarketingAgent.customerID);
        jsonValues['telemarketingAgentCustomerGUID'] = (this.telemarketingAgent.guid || '');

      // if there is no telemarketing agent
      } else {
        jsonValues['telemarketingAgentCustomerID'] = '';
      }
    }




    // if there is a closing agent update
    if (this.hasUpdate(PlanOrderApplicationUpdates.closingAgent)) {
      // if there is a closing agent
      if (this.closingAgent) {
        jsonValues['closingAgentCustomerID'] = MessagingFormatter.integerToString(this.closingAgent.customerID);
        jsonValues['closingAgentCustomerGUID'] = (this.closingAgent.guid || '');

      // if there is no closing agent
      } else {
        jsonValues['closingAgentCustomerID'] = '';
      }
    }



    if (this.homeBuyers && this.hasUpdate(PlanOrderApplicationUpdates.homeBuyer)) {
      const customerJsonValues = [];

      this.homeBuyers.forEach((indexCustomer: Customer) => {
        const customerIDText = MessagingFormatter.integerToString(indexCustomer.customerID);
        const customerGUID = (indexCustomer.guid || '');

        const indexCustomerJsonValues = {
          'customerID': customerIDText,
          'customerGUID': customerGUID
        };

        customerJsonValues.push(indexCustomerJsonValues);
      });

      jsonValues['homeBuyers'] = customerJsonValues;
    }

    if (this.homeSellers && this.hasUpdate(PlanOrderApplicationUpdates.homeSeller)) {
      const customerJsonValues = [];

      this.homeSellers.forEach((indexCustomer: Customer) => {
        const customerIDText = MessagingFormatter.integerToString(indexCustomer.customerID);
        const customerGUID = (indexCustomer.guid || '');

        const indexCustomerJsonValues = {
          'customerID': customerIDText,
          'customerGUID': customerGUID
        };

        customerJsonValues.push(indexCustomerJsonValues);
      });

      jsonValues['homeSellers'] = customerJsonValues;
    }

    if (productCoveragesFlag) {
      const coverageJsonValues = [];

      this.planOptions.forEach((indexOption: PlanOption) => {
        coverageJsonValues.push(indexOption.jsonValues());
      });

      jsonValues['coverages'] = coverageJsonValues;
    }

    // Additional Email Addresses
    if (this.additionalEmailAddresses) {
      jsonValues['additionalEmailAddresses'] = this.additionalEmailAddresses;
    }


    // if we're paying now
    if (this.payNowFlag) {
      jsonValues['paymentPlan'] = this.paymentPlan.paymentPlan;
      jsonValues['autoAssignPlanInvoiceFlag'] = PhxSerializer.fromBoolean(true);

    // if we're paying later and a payment plan was specified
    } else if (!this.payNowFlag && !!this.paymentPlan) {
      jsonValues['paymentPlan'] = this.paymentPlan.paymentPlan;
    }



    // credit card
    if ((this.creditCardGUID || '') !== '') {
      jsonValues['creditCardGUID'] = this.creditCardGUID;
    }


    // invoice recipients
    const recipientJsons = [];

    this.premiumInvoiceRecipients.forEach((indexRecipient: CustomerInvoiceRecipient) => {
      recipientJsons.push(indexRecipient.jsonValues());
    });

    jsonValues['premiumInvoiceRecipients'] = recipientJsons;


    // order confirmations
    jsonValues['sendConfirmationsOnSaveFlag'] = PhxSerializer.fromBoolean(this.sendConfirmationsOnSaveFlag);

    jsonValues['autoRenewFlag'] =
      this.autoRenewFlag === null || this.autoRenewFlag === undefined ? '' : PhxSerializer.fromBoolean(this.autoRenewFlag);

    jsonValues['directProcessFlag'] = PhxSerializer.fromBoolean(this.directProcessFlag);

    return jsonValues;
  }






  // serialize the plan
  persistenceJsonValues(): Map<string, any> {
    const jsonValues = new Map<string, any>();

    if (this.currentStep) {
      jsonValues['currentStep'] = this.currentStep;
    }

    jsonValues['confirmationSentFlag'] = MessagingFormatter.booleanToString(this.confirmationSentFlag);

    // Demo Mode
    if (this.demoModeFlag) {
      jsonValues['demoModeFlag'] = MessagingFormatter.booleanToString(this.demoModeFlag);
    }

    if (this.demoModeSubmittedFlag) {
      jsonValues['demoModeSubmittedFlag'] = MessagingFormatter.booleanToString(this.demoModeSubmittedFlag);
    }


    // All steps completed
    jsonValues['allStepsCompletedFlag'] = MessagingFormatter.booleanToString(this.allStepsCompletedFlag);


    // Save Errors
    if (this.saveErrors) {
      const saveErrorJson = [];

      this.saveErrors.forEach((indexError: ActivityResultError) => {
        saveErrorJson.push(indexError.jsonValues());
      });

      jsonValues['saveErrors'] = saveErrorJson;
    }


    // Plan
    if (this.planID) {
      jsonValues['planID'] = MessagingFormatter.integerToString(this.planID);
    }

    if (this.planGUID) {
      jsonValues['planGUID'] = this.planGUID;
    }

    if (this.channelCode) {
      jsonValues['channelCode'] = this.channelCode;
    }

    if (this.planReceivedDate) {
      jsonValues['planReceivedDate'] = MessagingFormatter.dateToString(this.planReceivedDate);
    }


    if (this.planCoverageStarts) {
      jsonValues['planCoverageStarts'] = MessagingFormatter.dateToString(this.planCoverageStarts);
    }

    if (this.planCoverageEnds) {
      jsonValues['planCoverageEnds'] = MessagingFormatter.dateToString(this.planCoverageEnds);
    }

    // Plan Status
    if (this.planStatus) {
      jsonValues['planStatus'] = MessagingFormatter.integerToString(this.planStatus.status);
    }

    // Transaction Type
    if (this.transactionType) {
      jsonValues['transactionTypeID'] = MessagingFormatter.integerToString(this.transactionType.transactionTypeID);
    }


    // Property Type
    if (this.newConstructionFlag) {
      jsonValues['newConstructionFlagID'] = MessagingFormatter.integerToString(this.newConstructionFlag.newConstructionFlagID);
    }

    // Role
    if (this.role) {
      jsonValues['roleID'] = MessagingFormatter.integerToString(this.role.roleID);
    }

    if (this.planOrdererRole) {
      jsonValues['planOrdererRole'] = this.planOrdererRole.jsonValues();
    }

    // Initiating Agent
    jsonValues['initiatingAgentSkippedFlag'] = MessagingFormatter.booleanToString(this.initiatingAgentSkippedFlag);

    if (this.initiatingAgent) {
      jsonValues['initiatingAgent'] = this.initiatingAgent.jsonValues();

      jsonValues['initiatingAgentListingFlag'] = MessagingFormatter.booleanToString(this.initiatingAgentListingFlag);
    }



    // Product
    if (this.product) {
      jsonValues['product'] = this.product.jsonValues();
    }


    // Optional Coverage
    if (this.productCoverages !== null) {
      const coverageJsonValues = [];

      this.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        coverageJsonValues.push(indexCoverage.jsonValues());
      });

      jsonValues['productCoverages'] = coverageJsonValues;
    }

    if (this.selectedDisplayCodes !== null) {
      jsonValues['selectedDisplayCodes'] = this.selectedDisplayCodes;
    }

    if (this.planOptions !== null) {
      const optionJsonValues = [];

      this.planOptions.forEach((indexOption: PlanOption) => {
        optionJsonValues.push(indexOption.jsonValues());
      });

      jsonValues['planOptions'] = optionJsonValues;
    }


    // Property Address
    jsonValues['property'] = this.property.jsonValues();

    if (this.propertyRegionID) {
      jsonValues['propertyRegionID'] = MessagingFormatter.integerToString(this.propertyRegionID);
    }

    // Cooperating Agent
    jsonValues['cooperatingAgentSkippedFlag'] = MessagingFormatter.booleanToString(this.cooperatingAgentSkippedFlag);

    if (this.cooperatingAgent !== null) {
      jsonValues['cooperatingAgent'] = this.cooperatingAgent.jsonValues();
      jsonValues['cooperatingAgentListingFlag'] = MessagingFormatter.booleanToString(this.cooperatingAgentListingFlag);
    }


    // Telemarketing
    if (!!this.telemarketingAgent) {
      jsonValues['telemarketingAgent'] = this.telemarketingAgent.jsonValues();
    }



    // Escrow
    jsonValues['closingAgentSkippedFlag'] = MessagingFormatter.booleanToString(this.closingAgentSkippedFlag);

    if (this.closingAgent !== null) {
      jsonValues['closingAgent'] = this.closingAgent.jsonValues();
    }

    jsonValues['closingFileNumber'] = (this.closingFileNumber || '');
    jsonValues['estimatedClosingDate'] = MessagingFormatter.dateToString(this.estimatedClosingDate);
    jsonValues['actualClosingDate'] = MessagingFormatter.dateToString(this.actualClosingDate);

    if (this.invoiceRecipient) {
      jsonValues['invoiceRecipientID'] = MessagingFormatter.integerToString(this.invoiceRecipient.invoiceRecipientID);
    }

    if (this.invoiceRecipientCustomerID) {
      jsonValues['invoiceRecipientCustomerID'] = MessagingFormatter.integerToString(this.invoiceRecipientCustomerID);
    }


    // Default Homeowner
    if (this.defaultHomeownerCustomerID) {
      jsonValues['defaultHomeownerCustomerID'] = MessagingFormatter.integerToString(this.defaultHomeownerCustomerID);
    }


    // Home Buyers
    if (this.homeBuyers) {
      const customerJsonValues = [];

      this.homeBuyers.forEach((indexCustomer: Customer) => {
        customerJsonValues.push(indexCustomer.jsonValues());
      });

      jsonValues['homeBuyers'] = customerJsonValues;
    }


    // Home Sellers
    if (this.homeSellers !== null) {
      const customerJsonValues = [];

      this.homeSellers.forEach((indexCustomer: Customer) => {
        customerJsonValues.push(indexCustomer.jsonValues());
      });

      jsonValues['homeSellers'] = customerJsonValues;
    }


    // Payment
    jsonValues['payNowFlag'] = PhxSerializer.fromBoolean(this.payNowFlag);

    if (this.paymentPlan) {
      jsonValues['paymentPlan'] = this.paymentPlan.jsonValues();
    }

    if (!this.payNowFlag) {
      const recipientJsons = [];

      this.premiumInvoiceRecipients.forEach((indexRecipient: CustomerInvoiceRecipient) => {
        recipientJsons.push(indexRecipient.jsonValues());
      });

      jsonValues['premiumInvoiceRecipients'] = recipientJsons;

    } else {
      jsonValues['firstPaymentAmount'] = PhxSerializer.fromDouble(this.firstPaymentAmount);
      jsonValues['creditCardGUID'] = this.creditCardGUID;
      jsonValues['ccCardHolderName'] = this.ccCardHolderName;
      jsonValues['ccNumber'] = this.ccNumber;
      jsonValues['ccExpirationMonth'] = PhxSerializer.fromInteger(this.ccExpirationMonth);
      jsonValues['ccExpirationYear'] = PhxSerializer.fromInteger(this.ccExpirationYear);
    }

    if (this.ccPaymentResult) {
      jsonValues['ccPaymentResult'] = this.ccPaymentResult.jsonValues();
    }



    // Premium invoice
    if (this.premiumInvoice) {
      jsonValues['premiumInvoice'] = this.premiumInvoice.jsonValues();
    }


    // Claim JSON Values
    if (this.planClaimHistory) {
      const claimJsonValues = [];

      this.planClaimHistory.forEach((indexClaim: PlanClaimHistory) => {
        claimJsonValues.push(indexClaim.jsonValues());
      });

      jsonValues['planClaimHistory'] = claimJsonValues;
    }


    // Plan Cancels
    if (this.planCancels) {
      const cancelJsonValues = [];

      this.planCancels.forEach((indexCancel: PlanCancel) => {
        cancelJsonValues.push(indexCancel.jsonValues());
      });

      jsonValues['planCancels'] = cancelJsonValues;
    }


    // Mailing Address
    if (this.mailingAddress) {
      jsonValues['mailingAddress'] = this.mailingAddress.jsonValues();
    }


    // Additional Email Addresses
    if (this.additionalEmailAddresses) {
      jsonValues['additionalEmailAddresses'] = this.additionalEmailAddresses;
    }

    jsonValues['autoRenewFlag'] =
      this.autoRenewFlag === null || this.autoRenewFlag === undefined ? '' : MessagingFormatter.booleanToString(this.autoRenewFlag);

    jsonValues['directProcessFlag'] = MessagingFormatter.booleanToString(this.directProcessFlag);


    // Updates
    jsonValues['updates'] = this.updates;
    jsonValues['edits'] = this.edits;

    return jsonValues;
  }


  flagUpdate(tag: PlanOrderApplicationUpdates) {
    if (!this.hasUpdate(tag)) {
      this.updates.push(tag);
    }

    this.confirmationSentFlag = false;

    this.flagEdit(tag);
  }


  hasUpdate(tag: PlanOrderApplicationUpdates): boolean {
    let hasUpdate = false;

    this.updates.forEach((indexUpdate: PlanOrderApplicationUpdates) => {
      if (indexUpdate === tag) {
        hasUpdate = true;
      }
    });

    return hasUpdate;
  }


  clearUpdates() {
    this.updates = [];
  }



  flagEdit(tag: PlanOrderApplicationUpdates) {
    if (!this.hasEdit(tag)) {
      this.edits.push(tag);
    }
  }


  hasEdit(tag: PlanOrderApplicationUpdates): boolean {
    let hasEdit = false;

    this.edits.forEach((indexEdit: PlanOrderApplicationUpdates) => {
      if (indexEdit === tag) {
        hasEdit = true;
      }
    });

    return hasEdit;
  }


  clearEdits() {
    this.edits = [];
  }




  // returns the relationship for the given customer
  relationshipForCustomerID(customerID: number): Relationship {
    let relationship: Relationship = null;

    this.planDomainFactoryService.relationships.forEach((indexRelationship: Relationship) => {

      if (indexRelationship.initiatingAgentFlag && this.initiatingAgent) {
        if (customerID === this.initiatingAgent.customerID) {
          relationship = indexRelationship;
        }

      } else if (indexRelationship.cooperatingAgentFlag && this.cooperatingAgent) {
        if (customerID === this.cooperatingAgent.customerID) {
          relationship = indexRelationship;
        }

      } else if (indexRelationship.closingFlag && this.closingAgent) {
        if (customerID === this.closingAgent.customerID) {
          relationship = indexRelationship;
        }

      } else if (indexRelationship.telemarketingFlag && this.telemarketingAgent) {
        if (customerID === this.telemarketingAgent.customerID) {
          relationship = indexRelationship;
        }

      } else if (indexRelationship.ownerFlag && this.homeBuyers) {
        this.homeBuyers.forEach((indexCustomer: Customer) => {
          if (customerID === indexCustomer.customerID) {
            relationship = indexRelationship;
          }
        });

      } else if (indexRelationship.sellerFlag && this.homeSellers) {
        this.homeSellers.forEach((indexCustomer: Customer) => {
          if (customerID === indexCustomer.customerID) {
            relationship = indexRelationship;
          }
        });
      }

    });

    return relationship;
  }



  // returns the premium for the coverage
  totalPremiumForCoverage(coverage: ProductCoverage): number {
    const units = this.optionUnitsForCoverage(coverage);

    let premium: number = null;

    // if units are specified
    if (units !== null) {
      // look for the corresponding PlanOption
      const planOption = ArrayUtility.arrayFind(this.planOptions,
        (testOption: PlanOption): boolean => {
          return (testOption.coverageID === coverage.coverageID);
        });

      let coveragePremium = (planOption ? planOption.optionAmount : null);

      if (coveragePremium === null) {
        coveragePremium = coverage.premium;
      }

      // calculate premium
      premium = coveragePremium * units;
    }

    return premium;
  }


  formattedTotalPremiumForCoverage(coverage: ProductCoverage): string {
    let text: string = null;

    const premium = this.totalPremiumForCoverage(coverage);

    if (premium !== null) {
      text = StringUtility.formatDollarNoDecimal(premium);
    }

    return text;
  }


  // returns the option units for the specified coverage
  optionUnitsForCoverage(coverage: ProductCoverage): number {
    let units: number = null;

    // look for the planOption value
    (this.planOptions || []).forEach((indexOption: PlanOption) => {
      if (indexOption.coverageID === coverage.coverageID) {
        units = indexOption.optionUnits;
      }
    });

    if (units === null) {
      units = 1;
    }

    return units;
  }



}



export enum PlanOrderApplicationUpdates {
  transactionType = 5,
  propertyAddress = 10,
  propertyZipCode = 15,
  propertyType = 20,
  role = 25,
  product = 30,
  optionalCoverages = 40,
  initiatingAgent = 50,
  cooperatingAgent = 60,
  cooperatingAgentListingFlag = 65,
  closingAgent = 70,
  closingInfo = 80,
  invoiceRecipient = 90,
  homeBuyer = 100,
  homeSeller = 110,
  mailingAddress = 120,
  telemarketing = 130,
  payment = 140
}



interface IPlanOrderApplication {
  planID: string;
  guid: string;
}


export interface IPlanOrderPersistedApplication {

  // Current Step
  currentStep: string;

  // Confirmation sent
  confirmationSentFlag: string;

  // Demo Mode
  demoModeFlag: string;
  demoModeSubmittedFlag: string;

  // save errors
  saveErrors: any[];

  // all steps completed
  allStepsCompletedFlag: string;

  // Plan #
  planID: string;
  planGUID: string;

  // Channel
  channelCode: string;

  // Plan Received Date
  planReceivedDate: string;
  planCoverageStarts: string;
  planCoverageEnds: string;

  // Plan Status
  planStatus: string;

  // Transaction Type
  transactionTypeID: string;

  // Property Type
  newConstructionFlagID: string;

  // Role
  roleID: string;

  // Initiating Agent
  initiatingAgentSkippedFlag: string;
  initiatingAgentOffice: any;
  initiatingAgent: any;
  initiatingAgentListingFlag: string;

  // Product
  product: string;

  // Optional Coverage
  planOptions: any[];
  productCoverages: any[];
  selectedDisplayCodes: any[];

  // Property Address
  property: any;
  propertyRegionID: string;

  // Cooperating Agent
  cooperatingAgentSkippedFlag: string;
  cooperatingAgentOffice: any;
  cooperatingAgent: any;
  cooperatingAgentListingFlag: string;

  // Telemarketing Agent
  telemarketingAgentOffice: any;
  telemarketingAgent: any;

  // Escrow
  closingAgentSkippedFlag: string;
  closingOffice: any;
  closingAgent: any;

  closingFileNumber: string;
  estimatedClosingDate: string;
  actualClosingDate: string;

  invoiceRecipientID: string;
  invoiceRecipientCustomerID: string;

  defaultHomeownerCustomerID: string;

  // Home Buyer
  homeBuyers: any[];

  // Home Seller
  homeSellers: any[];

  // Payment
  payNowFlag: any;

  paymentPlan: any;
  firstPaymentAmount: any;
  creditCardGUID: any;
  ccCardHolderName: any;
  ccNumber: any;
  ccExpirationMonth: any;
  ccExpirationYear: any;

  ccPaymentResult: any;

  // Premium Invoice
  premiumInvoice: any;
  premiumInvoiceRecipients: any;


  // Claim History
  planClaimHistory: any[];

  // Cancellation
  planCancels: any[];

  // Mailing Address
  mailingAddress: any;

  // Additional Email Addresses
  additionalEmailAddresses: string[];

  // Plan Orderer Role
  planOrdererRole: any;

  // Updates
  updates: number[];
  edits: number[];

  autoRenewFlag: boolean;
  directProcessFlag: boolean;
}



export interface IPlanOrderFetchedApplication {
  planID: string;
  guid: string;
  propertyID: string;
  productID: string;
  channelCode: string;
  paymentPlan: string;
  planReceivedDate: string;
  planCoverageStarts: string;
  planCoverageEnds: string;
  status: string;
  transactionTypeID: string;
  closingFileNumber: string;
  estimatedClosingDate: string;
  actualClosingDate: string;
  initiatingAgentCustomerID: string;
  initiatingAgentCustomerGUID: string;
  initiatingAgentListingFlag: string;
  cooperatingAgentCustomerID: string;
  cooperatingAgentCustomerGUID: string;
  cooperatingAgentListingFlag: string;
  closingAgentCustomerID: string;
  closingAgentCustomerGUID: string;
  telemarketingAgentCustomerID: string;
  telemarketingAgentCustomerGUID: string;
  defaultHomeownerCustomerID: string;
  homeBuyers: any[];
  homeSellers: any[];
  coverages: any[];
  premiumInvoice: any;
  invoiceRecipientID: string;
  invoiceRecipientCustomerID: string;
  planCancels: any[];
  mailingAddress: any;
  planOrdererRole: any;
  autoRenewFlag: any;
}


export interface IPlanOrderFetchedCustomer {
  customerID: string;
  customerGUID: string;
}


