import {Injectable} from '@angular/core';


@Injectable()

export class NavbarService {

  loginFormPresented = false;



  shouldDisplayLoginMenuItem(): boolean {
    let displayFlag = true;

    if (this.loginFormPresented) {
      displayFlag = false;
    }

    return displayFlag;
  }

}
