

export class PlanOrderProgressBarStep {
  code: string;
  name: string;
  route: string;
  progressBarFlag = true;
  altStepCode: string = null;
  enabledFlag = true;

  constructor(code: string,
              name: string,
              route: string,
              progressBarFlag?: boolean,
              altStepCode?: string,
              enabledFlag?: boolean) {

    this.code = code;
    this.name = name;
    this.route = route;

    if (progressBarFlag === false) {
      this.progressBarFlag = progressBarFlag;
    }

    if (altStepCode) {
      this.altStepCode = altStepCode;
    }

    if (enabledFlag === false) {
      this.enabledFlag = enabledFlag;
    }
  }
}
