import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment,
  UrlSerializer
} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../login/service';


@Injectable()

export class DebugGuard implements CanActivate {

  constructor(private router: Router,
              private urlSerializer: UrlSerializer,
              private authenticationService: AuthenticationService
  ) { }



  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let allowAccess = false;

    // only ORHP users can access this
    if (this.authenticationService.orhpMisUserFlag) {
      allowAccess = true;
    }

    // if we're not allowing access
    if (!allowAccess) {
      this.router.navigate(['/'], {});
    }

    return allowAccess;
  }


}

