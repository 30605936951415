import {Routes} from '@angular/router';

import {HomeComponent} from './home.component';
import {AuthGuard} from "../login/service/auth.guard";
import {MyWarrantiesGuard} from './my-warranties/my-warranties.guard';
import {NoAutoLoginGuard} from '../_services/no-auto-login-guard';

export const HomeRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home/:pageNumber',
    component: HomeComponent,
    canActivate: [AuthGuard, NoAutoLoginGuard]
  },
  {
    path: 'home',
    canActivate: [MyWarrantiesGuard, NoAutoLoginGuard],
    children: []
  }
];
