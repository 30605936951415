

import {
  ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer,
  UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupService} from './lookup.service';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';

@Injectable()

export class OfficeAgentLookupRootWildcardResolver implements Resolve<boolean> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      let urlSegment: UrlSegment = null;

      // employer
      let employerGUID: string = null;
      const savedEmployer = this.officeAgentLookupPersistenceService.employer;

      if (savedEmployer) {
        employerGUID = savedEmployer.guid;
      }


      // customer
      let customerGUID: string = null;
      const savedCustomer = this.officeAgentLookupPersistenceService.customer;

      if (savedCustomer) {
        customerGUID = savedCustomer.guid;
      }

      // build the URL tree
      let urlTree: UrlTree = null;

      // if there was a matching URL segment here, strip everything after out
      if (snapshot.url.length) {
        const firstUrlSegment = snapshot.url[0];

        urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, firstUrlSegment.path);

        RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

      // no matching URL segment
      } else {
        urlTree = this.urlSerializer.parse(state.url);
      }



      // we're going to an office
      urlSegment = new UrlSegment('office', {});
      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

      // if an employer was specified
      if (employerGUID) {
        urlSegment = new UrlSegment(employerGUID, {});
        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

        urlSegment = new UrlSegment('agent', {});
        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

        // if a customer was specified
        if (customerGUID) {
          urlSegment = new UrlSegment(customerGUID, {});
          RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

        // if no customer was specified
        } else {
          if (savedCustomer) {
            urlSegment = new UrlSegment('new-agent', {});

          } else {
            urlSegment = new UrlSegment('search', {});
          }

          RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);
        }

      // if no employer was specified
      } else {
        if (savedEmployer) {
          urlSegment = new UrlSegment('new-office', {});

        } else {
          urlSegment = new UrlSegment('search', {});
        }

        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);
      }

      this.router.navigateByUrl(urlTree);

      subscriber.next(false);

      subscriber.complete();
    });

    return observable;
  }


}
