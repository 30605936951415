
import {Component, OnInit} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {ProductCoverage} from '@orhp/phx-product-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-plan-order-home-seller',
  templateUrl: './home-seller.component.html',
  styleUrls: ['../plan-order.scss']
})


export class PlanOrderHomeSellerComponent extends PlanOrderBaseComponent implements OnInit {

  relationshipCode = 'home-seller';
  customers: Customer[] = null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  get promptOnNoCustomersFlag(): boolean {
    let prompt = false;

    // if the initiating agent is the listing agent
    if (this.planOrderApplication.initiatingAgent && this.planOrderApplication.initiatingAgentListingFlag) {
      prompt = true;
    }

    // if the cooperating agent is the listing agent
    if (this.planOrderApplication.cooperatingAgent && this.planOrderApplication.cooperatingAgentListingFlag) {
      prompt = true;
    }

    return prompt;
  }



  get requireCustomerFlag(): boolean {
    let require = false;

    // test for invoice recipient
    const invoiceRecipient = this.planOrderApplication.invoiceRecipient;

    if (invoiceRecipient && invoiceRecipient.homeSellerFlag) {
      require = true;
    }

    // test for sellers coverage
    this.planOrderApplication.productCoverages.forEach((indexCoverage: ProductCoverage) => {
      // if this is sellers coverage
      if (indexCoverage.coverageType.sellerCoverageFlag && !indexCoverage.includedCoverageFlag) {
        require = true;
      }
    });

    // test for requirement by product market segment
    const marketSegment = this.planOrderApplication.product.marketSegment;

    if (marketSegment.requireHomeSellerFlag) {
      require = true;
    }

    return require;
  }



  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'home-seller');

    this.customers = this.planOrderApplication.homeSellers || [];
  }



  didClickNextButton(customers: Customer[]): void {
    this.customers = customers;

    this.planOrderApplication.homeSellers = this.customers;

    this.persistPlanOrderApplication();

    // next step logic
    const nextRoute = this.planOrderStepService.getNextRoute();

    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'home-seller');

    const urlSegment = new UrlSegment(nextRoute, {});

    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);

  }

}
