import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from "../../_services/profile.service";
import {AuthenticationService} from "../../../login/service/authentication.service";
import {Employer} from '@orhp/phx-customer-ui-module';

@Component({
    selector: 'phx-correspondence-preference',
    templateUrl: './user-correspondence-preference.component.html',
    styleUrls: ['./user-correspondence-preference.component.scss']
})
export class UserCorrespondencePreferenceComponent implements OnInit {
    _correspondencesPreferences: any = [];

    @Input()
    employer: Employer;

    @Input()
    set correspondencesPreferences(preferences: any) {
        this._correspondencesPreferences = preferences;
        this._correspondencesPreferences.forEach((c: any) => {
            c['showInfo'] = false;
        });
    }


    get correspondencesPreferences() {
        return this._correspondencesPreferences;
    }


    constructor(private authenticationService: AuthenticationService,
                private profileService: ProfileService) {

    }


    ngOnInit() {
    }


    setPreference(i, j) {
        console.log(i + " " + j);
        this.correspondencesPreferences[i].preferences.forEach(function (element, idx) {
                if (idx == j) {
                    element.selected = true;
                } else {
                    element.selected = false;
                }
            }
        );
    }


    shouldDisplayPreferences(): boolean {
      return !!this.employer;
    }


    noEmployerMessageText(): string {
      let text: string = null;

      if (!this.employer) {
        text = 'Office is required to set E-Card Preferences';
      }

      return text;
    }

}
