

import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginStatus} from './login.component';
import {Router} from '@angular/router';
import {NavbarService} from '../navbar/service/navbar.service';
import {AuthenticationService} from './service';

@Component({
  selector: 'app-welcome',
  templateUrl: 'welcome.component.html',
  styleUrls: [
    './welcome.component.scss'
  ]
})

export class WelcomeComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
              private navbarService: NavbarService,
              private authenticationService: AuthenticationService) {
  }


  ngOnInit() {
    this.navbarService.loginFormPresented = true;

    // clear the user session
    this.authenticationService.clearUserSession();
  }


  ngOnDestroy() {
    this.navbarService.loginFormPresented = false;
  }


  didChangeLoginStatus(loginStatus: LoginStatus) {
    // when the login is successful, redirect them to the home screen
    if (loginStatus === LoginStatus.success) {
      const url = '/home';

      this.router.navigate([url]);
    }
  }


}


