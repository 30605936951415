import {Injectable} from '@angular/core';
import {Router, UrlSegment, UrlSerializer} from '@angular/router';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {OfficeAgentLookupService} from './lookup.service';

@Injectable()

export class OfficeAgentNavigationService {


  constructor(private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private router: Router,
              private urlSerializer: UrlSerializer
  ) { }



  gotoOfficeSearch() {
    this.officeAgentLookupPersistenceService.employer = null;

    if (!this.officeAgentLookupService.isPlanOrder) {
      this.officeAgentLookupService.shouldDisplayEmployerSearch = true;
    }

    // pull the URL
    const url = this.router.routerState.snapshot.url;
    const urlTree = this.urlSerializer.parse(url);

    // replace the last URL segment
    const urlSegment = new UrlSegment('search', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


}
