
export class OfficeAgentLookupRelationshipType {

  static typeCodeInitiatingAgent = 'initiating-agent';
  static typeCodeCooperatingAgent = 'cooperating-agent';
  static typeCodePropertyManager = 'property-manager';
  static typeCodeEscrowOfficer = 'escrow-officer';




  lookupRelationshipTypeCode: string;


  constructor(lookupRelationshipTypeCode: string) {
    this.lookupRelationshipTypeCode = lookupRelationshipTypeCode;
  }



  get initiatingAgentFlag(): boolean {
    return (this.lookupRelationshipTypeCode === OfficeAgentLookupRelationshipType.typeCodeInitiatingAgent);
  }

  get cooperatingAgentFlag(): boolean {
    return (this.lookupRelationshipTypeCode === OfficeAgentLookupRelationshipType.typeCodeCooperatingAgent);
  }

  get propertyManagerFlag(): boolean {
    return (this.lookupRelationshipTypeCode === OfficeAgentLookupRelationshipType.typeCodePropertyManager);
  }

  get escrowOfficerFlag(): boolean {
    return (this.lookupRelationshipTypeCode === OfficeAgentLookupRelationshipType.typeCodeEscrowOfficer);
  }


  get relationshipName(): string {
    let relationshipName = '';

    if (this.initiatingAgentFlag) {
      relationshipName = 'Initiating Real Estate Agent';

    } else if (this.cooperatingAgentFlag) {
      relationshipName = 'Cooperating Real Estate Agent';

    } else if (this.propertyManagerFlag) {
      relationshipName = 'Property Manager';

    } else if (this.escrowOfficerFlag) {
      relationshipName = 'Closing Officer';
    }

    return relationshipName;
  }


  get newEmployerTypeCode(): string {
    let employerTypeCode: string = null;

    if (this.initiatingAgentFlag) {
      employerTypeCode = 'R';

    } else if (this.cooperatingAgentFlag) {
      employerTypeCode = 'R';

    } else if (this.propertyManagerFlag) {
      employerTypeCode = 'P';

    } else if (this.escrowOfficerFlag) {
      employerTypeCode = 'E';
    }

    return employerTypeCode;
  }


  get lookupEmployerTypeCode(): string {
    let employerTypeCode: string = null;

    if (this.initiatingAgentFlag) {
      employerTypeCode = 'R';

    } else if (this.cooperatingAgentFlag) {
      employerTypeCode = 'R';

    } else if (this.propertyManagerFlag) {
      employerTypeCode = 'P,R';

    } else if (this.escrowOfficerFlag) {
      employerTypeCode = 'R,E';
    } else {
      employerTypeCode = this.lookupRelationshipTypeCode;
    }

    return employerTypeCode;
  }


  get shouldFetchRecentOffices(): boolean {
    let flag = false;

    if (this.initiatingAgentFlag) {
      flag = true;
    }

    if (this.escrowOfficerFlag) {
      flag = true;
    }

    return flag;
  }


  get agentTerm(): string {
    let agentTerm: string = null;

    if (this.propertyManagerFlag) {
      agentTerm = 'property manager';

    } else if (this.escrowOfficerFlag) {
      agentTerm = 'closing officer';

    } else {
      agentTerm = 'agent';
    }

    return agentTerm;
  }



  get agentLookupFirstNamePagingFlag(): boolean {
    let flag = false;

    if (this.escrowOfficerFlag) {
      flag = true;
    }

    return flag;
  }


  get agentLookupLastNamePagingFlag(): boolean {
    let flag = false;

    if (this.initiatingAgentFlag) {
      flag = true;
    } else if (this.cooperatingAgentFlag) {
      flag = true;
    }

    return flag;
  }


  get solicitMobilePhoneFlag(): boolean {
    let flag = true;

    if (this.escrowOfficerFlag) {
      flag = false;
    }

    return flag;
  }

}




