
import {Injectable} from '@angular/core';
import {Employer} from '@orhp/phx-customer-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';

@Injectable()


export class OfficeAgentLookupCacheService {

  selectedOfficeLookupType: OfficeLookupType = null;
  lastOfficeLookupType: OfficeLookupType = null;

  searchEmployerPhone = '';
  searchEmployerName = '';
  searchEmployerCity = '';
  searchEmployerStateCode = '';

  recentOfficeResults: Employer[] = null;

  exactOfficeMatchResults: Employer[] = null;
  fuzzyOfficeMatchResults: Employer[] = null;


  constructor(private loginService: PhxLoginService) {

    // on login
    const loginObservable = Observable.create((subscriber: Subscriber<boolean>) => {
      this.clearCache();

      subscriber.next(true);
      subscriber.complete();
    });

    loginService.onLoginSuccess.push(loginObservable);


    // on logout
    const logoutObservable = Observable.create((subscriber: Subscriber<boolean>) => {
      this.clearCache();

      subscriber.next(true);
      subscriber.complete();
    });

    loginService.onLogout.push(logoutObservable);

  }


  clearCache() {
    this.selectedOfficeLookupType = OfficeLookupType.officePhone;
    this.lastOfficeLookupType = null;

    this.searchEmployerPhone = '';
    this.searchEmployerName = '';
    this.searchEmployerCity = '';
    this.searchEmployerStateCode = '';

    this.recentOfficeResults = null;

    this.exactOfficeMatchResults = null;
    this.fuzzyOfficeMatchResults = null;
  }



}



export enum OfficeLookupType {
  none = 100,
  recentOffices = 200,
  officePhone = 300,
  officeName = 400
}

