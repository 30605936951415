import {PhxSerializer} from '@orhp/phx-common-ui-module';

export class PlanOrderConfirmationRequest {

  newPlanFlag = false;
  planID: number = null;
  additionalEmailAddresses: string[] = null;
  propertyID: number = null;
  productID: number = null;
  initiatingAgentCustomerID: number = null;

  jsonValues(): IPlanOrderConfirmationRequest {
    const request = ({} as IPlanOrderConfirmationRequest);

    request.newPlanFlag = PhxSerializer.fromBoolean(this.newPlanFlag);
    request.planID = PhxSerializer.fromInteger(this.planID);

    if (!!(this.additionalEmailAddresses || []).length) {
      request.additionalEmailAddresses = this.additionalEmailAddresses;
    }

    request.propertyID = PhxSerializer.fromInteger(this.propertyID);
    request.productID = PhxSerializer.fromInteger(this.productID);
    request.initiatingAgentCustomerID = PhxSerializer.fromInteger(this.initiatingAgentCustomerID);

    return request;
  }

}

export interface IPlanOrderConfirmationRequest {

  newPlanFlag: any;
  planID: any;
  additionalEmailAddresses: any;
  propertyID: any;
  productID: any;
  initiatingAgentCustomerID: any;

}

