import {Component, OnDestroy, OnInit} from '@angular/core';
import {Employer} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {UserService} from '../../login/service/user.service';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupService} from '../../_modules/office-agent-lookup/_services/lookup.service';
import {OfficeAgentLookupPersistenceService} from '../../_modules/office-agent-lookup/_services/persistence.service';
import {ProfileService, ProfileUpdateResult} from '../_services/profile.service';
import {AuthenticationService} from '../../login/service/authentication.service';
import {Router} from '@angular/router';
import {ProfileValidationResult, ProfileValidationService} from '../_services/profile-validation.service';
import {ArrayUtility, PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {JobRole} from '@orhp/phx-customer-ui-module';
import {PhxHttpService, PhxSystemService} from '@orhp/phx-common-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupCacheService} from '../../_modules/office-agent-lookup/_services/lookup-cache.service';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {LegacyLoginResult} from '../../login/model/legacy-login-result';
import {isUndefined} from 'util';
import {Subscription} from 'rxjs/Rx';
import {ProductOffice, ProductOfficeGroupLookupParams, ProductOfficeGroupLookupService} from '@orhp/phx-product-ui-module';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit, OnDestroy {
    currentGuest: {};
    original: {};

    currentCustomer: Customer;
    currentEmployer: Employer;
    correspondencesPreferences: any = [];

    profileValidationResult: ProfileValidationResult = new ProfileValidationResult();

    dataLoaded = false;
    dataUpdated = false;
    updateInProgress = false;
    marketingToolsErrorFlag = false;
    saveAttempted = false;
    showGenericError = false;

    jobRoleSelectedFlag = false;
    employerSelectSubscription: Subscription = null;

    get productOffice(): ProductOffice {
      return this.profileService.productOffice;
    }

    set productOffice(productOffice: ProductOffice) {
      this.profileService.productOffice = productOffice;
    }

    productOfficeLookupFlag = false;

    employerSelectedFlag = false;



    imageURL = '';
    legacyURL = '';

    get employer(): Employer {
        if (this.officeAgentLookupPersistenceService.employer !== null) {
            return this.officeAgentLookupPersistenceService.employer;
        } else {
            return this.currentEmployer;
        }
    }

    get employerValidateResult(): EmployerValidateResult {
        return this.officeAgentLookupService.employerValidateResult;
    }

    get customerValidateResult(): CustomerValidateResult {
        return this.officeAgentLookupService.customerValidateResult;
    }

    get jobRoles(): any {
        const roles = [];

        this.customerLookupValueService.jobRoles.forEach(
            (r: JobRole) => {
                if (r.employerType.employerType === 'R' || r.employerType.employerType === 'E' || r.employerType.employerType === 'I') {
                    roles.push(r);
                }

            });
        return roles;
    }




    get productOfficeJobRole(): JobRole {
      const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);
      const forceJobRoleCode = (!!productOfficeToolbox ? productOfficeToolbox.forceCustomerJobRole : null) || '';

      let jobRole: JobRole = null;

      if (forceJobRoleCode !== '') {
        jobRole = ArrayUtility.arrayFind(
          this.customerLookupValueService.jobRoles,
          (testJobRole: JobRole): boolean => {
            return (testJobRole.jobRole.toLowerCase() === forceJobRoleCode.toLowerCase());
          });
      }

      return jobRole;
    }


    get guestEmail(): string {
      return (this.currentGuest ? this.currentGuest['email'] : null);
    }



    constructor(private userService: UserService,
                private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
                private officeAgentLookupService: OfficeAgentLookupService,
                private profileService: ProfileService,
                private authenticationService: AuthenticationService,
                private router: Router,
                private profileValidationService: ProfileValidationService,
                private modalProgressService: PhxModalProgressService,
                private phxHttpService: PhxHttpService,
                private systemService: PhxSystemService,
                private customerLookupValueService: CustomerLookupValueService,
                private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
                private productOfficeGroupLookupService: ProductOfficeGroupLookupService
    ) { }

    ngOnInit() {
        this.officeAgentLookupService.clearAllData();
        this.officeAgentLookupPersistenceService.clearPersistedValues();
        this.officeAgentLookupCacheService.clearCache();

        this.currentEmployer = this.authenticationService.employer;
        this.currentCustomer = this.authenticationService.customer;
        this.officeAgentLookupService.shouldDisplayEmployerSearch = false;
        this.legacyURL = this.systemService.legacyToolboxUrl;

        this.productOffice = this.authenticationService.productOffice;

        // register for employer updates if there's not a product office set
        if (!this.productOffice) {
          this.employerSelectSubscription = this.officeAgentLookupPersistenceService.employerEventEmitter
            .subscribe((employer: Employer) => {
              this.employerSelectedFlag = !!employer;

              this.fetchEmployerProductOffice();
            });
        }


        this.authenticationService.retrieveCurrentCustomer().subscribe(
            c => {
                const employerType = this.employer.employerType.employerType;
                if (employerType === 'E') {
                    this.officeAgentLookupPersistenceService.lookupTypeCode = 'E,R';
                } else if (employerType === 'I') {
                    this.officeAgentLookupPersistenceService.lookupTypeCode = 'I,E,R';
                } else if (employerType === 'P') {
                    this.officeAgentLookupPersistenceService.lookupTypeCode = 'P,R';
                } else {
                    this.officeAgentLookupPersistenceService.lookupTypeCode = employerType;
                }

            }
        );

        this.userService.loadCurrentGuest().subscribe(
            g => {
                this.currentGuest = g.json();
                this.currentGuest['email'] = this.currentGuest['username'];
                this.currentGuest['email2'] = this.currentGuest['username'];
                this.currentGuest['password'] = '';
                this.currentGuest['password2'] = '';
                this.dataLoaded = true;
                this.imageURL = this.phxHttpService.buildApiUrl('/image/guestProfile/?id=' + this.currentGuest['id']);

                this.original = {};
                this.original['email'] = this.currentGuest['username'];
                this.original['customer'] = this.currentCustomer.jsonValues();
                this.original['employer'] = this.currentEmployer.jsonValues();


            }
        );

        this.profileService.loadCorrespondencePreferences(this.currentCustomer).subscribe(
            (r: any) => {
                this.correspondencesPreferences = r;
                this.modalProgressService.progress = null;
            }
        );

        this.fetchEmployerProductOffice();
    }

    ngOnDestroy() {
        this.profileService.productOffice = null;

        if (this.employerSelectSubscription) {
          this.employerSelectSubscription.unsubscribe();
        }

        this.officeAgentLookupPersistenceService.clearPersistedValues();
        this.officeAgentLookupCacheService.clearCache();
        this.officeAgentLookupService.clearAllData();
        this.officeAgentLookupCacheService.selectedOfficeLookupType = null;

        this.authenticationService.retrieveCurrentCustomer().subscribe((c) => {
            this.authenticationService.customer = c;
        });
    }



    fetchEmployerProductOffice() {
      this.productOfficeLookupFlag = false;

      const employerID = this.employer.employerID;

      if (!!employerID) {
        const params = new ProductOfficeGroupLookupParams();
        params.employerID = this.employer.employerID;
        params.jsonToolboxFlag = true;

        this.productOfficeGroupLookupService.fetchProductOfficeGroups(params)
          .subscribe((productOfficeGroups: ProductOffice[]) => {
            // if a group was returned
            if (!!(productOfficeGroups || []).length) {
              this.productOffice = productOfficeGroups[0];

              // if a forced job role was specified
              if (!!this.productOfficeJobRole) {
                this.currentCustomer.jobRole = this.productOfficeJobRole;
                this.jobRoleSelectedFlag = true;
              }
            }

            this.productOfficeLookupFlag = true;
          });

      } else {
        this.productOfficeLookupFlag = true;
      }
    }




    customOfficeDescription(): string {
      const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);

      return (!!productOfficeToolbox ? productOfficeToolbox.customOfficeDescription : null);
    }


    productOfficeID(): number {
      return (!!this.productOffice ? this.productOffice.productOfficeID : null);
    }




    shouldDisplayMarketingTools(): boolean {
      let flag = true;

      if (this.employer && this.employer.employerType && this.employer.employerType.telemarketingFlag) {
        flag = false;
      }

      return flag;
    }



    shouldDisplayEcardPreferences(): boolean {
      let flag = true;

      const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);

      if (!!productOfficeToolbox && !productOfficeToolbox.allowEcardPreferenceSelectionFlag) {
        flag = false;
      }

      return flag;
    }



    didClickSave() {
        this.updateInProgress = true;
        this.saveAttempted = true;
        this.modalProgressService.progress = new ModalProgress('Saving your profile', 'Please wait...', 100);

        this.profileValidationService.validate(
            this.currentGuest, this.currentCustomer, this.employer
        ).subscribe(
            (v: ProfileValidationResult) => {
                this.profileValidationResult = v;

                if (v.isValid) {
                    if (!this.shouldDisplayJobRoleError()) {
                        this.currentCustomer.employer = this.employer;

                        this.profileService.saveProfile(
                            this.currentGuest,
                            this.currentCustomer,
                            this.employer,
                            this.correspondencesPreferences,
                            this.original
                        ).subscribe(
                            (r: ProfileUpdateResult) => {
                                if (r.successFlag) {
                                    this.officeAgentLookupService.clearAllData();
                                    this.officeAgentLookupPersistenceService.clearPersistedValues();

                                    if (r.requireLogout) {
                                        this.modalProgressService.progress = null;
                                        this.authenticationService.logout();
                                        this.router.navigate(['/account/validate/success']);
                                    } else {
                                        this.authenticationService.retrieveCurrentCustomer().subscribe((c) => {
                                            const router = this.router;

                                            sessionStorage.clear();
                                            this.officeAgentLookupCacheService.clearCache();

                                            this.authenticationService.acctExecs = [];

                                            this.modalProgressService.progress = null;
                                            this.dataUpdated = true;

                                            this.authenticationService.customer = <Customer>c;

                                            setTimeout(function() {
                                                router.navigate(['/home']);
                                            }, 2000);
                                        });
                                    }
                                } else {
                                    this.showGenericError = true;
                                    this.errorOccurred();
                                }

                            }, (error: Response) => {
                                this.showGenericError = true;
                                this.errorOccurred();
                            }
                        );
                    } else {
                        this.errorOccurred();                    }
                } else {
                    this.errorOccurred();
                }
            }
        );
    }

    didClickCancel() {
        this.router.navigate(['/home']);
    }

    didClickMarketingTools() {
        this.marketingToolsErrorFlag = false;

        const progress = new ModalProgress('Loading Marketing Tools', 'Please wait...', 100);

        this.modalProgressService.progress = progress;

        this.authenticationService.legacyLogin().subscribe((loginResult: LegacyLoginResult) => {

            this.modalProgressService.progress = null;

            // if the login was successful
            if (loginResult.loginSuccessFlag) {
                const url = this.systemService.legacyToolboxUrl + 'tools/ct_portal.cfm';

                window.location.href = url;

            } else {
                this.marketingToolsErrorFlag = true;
            }
        });
    }

    didClickCancelMarketingTools() {
        this.marketingToolsErrorFlag = false;
    }

    shouldDisplayEmployerSection() {
        let displayFlag = false;

        if (this.currentCustomer && this.currentCustomer.employer && this.currentCustomer.employer.employerType !== null) {
            displayFlag = !(this.currentCustomer.employer.employerType.orhpFlag);
        }

        return displayFlag;
    }


    shouldDisplayJobRole(): boolean {
        if (this.officeAgentLookupPersistenceService.employer !== null) {
            return (this.authenticationService.employer.employerID !== this.employer.employerID);
        } else {
            return false;
        }

    }



    shouldDisplayJobRoleError(): boolean {
        const result = (
            this.shouldDisplayJobRole() &&
            this.saveAttempted &&
            (
                this.currentCustomer.jobRole == null ||
                isUndefined(this.currentCustomer.jobRole)
            )
        );
        return result;
    }


    shouldDisplayValidationError(): boolean {
        return (
            this.customerValidateResult.errorFlag ||
            this.employerValidateResult.errorFlag ||
            !this.profileValidationResult.isValid

        );
    }


    errorOccurred() {
        this.officeAgentLookupService.clearAllData();
        this.officeAgentLookupPersistenceService.clearPersistedValues();
        this.modalProgressService.progress = null;
        this.updateInProgress = false;
        window.scrollTo(0, 0);
    }

}
