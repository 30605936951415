
import {Component, OnInit} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';

@Component({
  selector: 'app-plan-order-home-buyer',
  templateUrl: './home-buyer.component.html',
  styleUrls: ['../plan-order.scss']
})


export class PlanOrderHomeBuyerComponent extends PlanOrderBaseComponent implements OnInit {

  relationshipCode = 'home-buyer';
  customers: Customer[] = null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService,
              private planOrderAuthService: PlanOrderAuthService) {
    super(planOrderService);
  }


  get promptOnNoCustomersFlag(): boolean {
    let prompt = false;

    // if there's escrow, prompt them
    if (this.planOrderApplication.closingAgent) {
      prompt = true;
    }

    // if the initiating agent is the selling agent
    if (this.planOrderApplication.initiatingAgent && !this.planOrderApplication.initiatingAgentListingFlag) {
      prompt = true;
    }

    // if the cooperating agent is the selling agent
    if (this.planOrderApplication.cooperatingAgent && !this.planOrderApplication.cooperatingAgentListingFlag) {
      prompt = true;
    }

    return prompt;
  }


  get requireCustomerFlag(): boolean {
    let require = false;

    const invoiceRecipient = this.planOrderApplication.invoiceRecipient;

    if (invoiceRecipient && invoiceRecipient.homeBuyerFlag) {
      require = true;
    }

    if (!!this.planOrderAuthService.productOffice && this.planOrderAuthService.productOffice.homeBuyerEmailRequiredFlag) {
       require = true;
    }

    return require;
  }


  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'home-buyer');

    this.customers = this.planOrderApplication.homeBuyers || [];
  }



  didClickNextButton(customers: Customer[]): void {
    this.customers = customers;

    this.planOrderApplication.homeBuyers = this.customers;

    // next step logic
    this.persistPlanOrderApplication();

    // get the next route
    const nextRoute = this.planOrderStepService.getNextRoute();

    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'home-buyer');

    const urlSegment = new UrlSegment(nextRoute, {});

    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    // if the next route is home seller
    if (nextRoute.toUpperCase() === 'HOME-SELLER') {
      const highlightUrlSegment = new UrlSegment('highlight', {});

      RoutingUtility.appendUrlSegmentToTree(urlTree, highlightUrlSegment);
    }

    this.router.navigateByUrl(urlTree);
  }

}
