import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-plan-order-review-action-buttons',
  templateUrl: './review-action-buttons.component.html',
  styleUrls: [
    '../plan-order.scss',
    './review-action-buttons.component.scss'
  ]
})


export class PlanOrderReviewActionButtonsComponent implements OnInit, OnDestroy {


  @Input() displayPrintButtonFlag = true;
  @Input() displayEmailButtonFlag = true;
  @Input() displayNewApplicationButtonFlag = true;
  @Input() mobileFlag = false;
  @Input() desktopFlag = false;
  @Input() titleText: string = null;

  @Output() onPrintButtonClick = new EventEmitter<boolean>();
  @Output() onEmailButtonClick = new EventEmitter<boolean>();
  @Output() onNewPlanButtonClick = new EventEmitter<boolean>();
  @Output() onRouteToORHPClick = new EventEmitter<boolean>();



  ngOnInit() {
  }


  ngOnDestroy() {
  }



  shouldDisplayTitleText(): boolean {
    let flag = false;

    if ((this.titleText || '') !== '') {
      flag = true;
    }

    return flag;
  }


  didClickPrintButton() {
    this.onPrintButtonClick.emit(true);
  }


  didClickSendEmails() {
    this.onEmailButtonClick.emit(true);
  }


  didClickNewPlanButton() {
    this.onNewPlanButtonClick.emit(true);
  }


  didClickRouteToORHP() {
    this.onRouteToORHPClick.emit(true);
  }

}
