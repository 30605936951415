import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {AccountService} from "../_services/account.service";
import {StringUtility} from '@orhp/phx-common-ui-module';

@Component({
    selector: 'app-reset-account',
    templateUrl: './reset-account.component.html'
})
export class ResetAccountComponent implements OnInit, OnDestroy {
    mode: Number = null;

    email: string = "";
    isEmailError: boolean = false;
    emailErrorMessage: string = "";

    password: string = "";
    password2: string = "";
    isPasswordError: boolean = false;
    passwordErrorMessage: string = "";

    currentUrl: string = "";

    id: string = "";

    inProgress: boolean = false;

    private sub: any;

    constructor(@Inject(DOCUMENT) private document: Document,
                private accountService: AccountService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.currentUrl = this.document.location.href;
        this.sub = this.route.params.subscribe(params => {
            if (params['id'] != undefined) {
                this.id = params['id'];
            } else {
                this.mode = 0;
            }

            if (this.id.length > 0) {
                this.accountService.validatePasswordReset(this.id).subscribe(r => {
                    const response = r.json();
                    if (response.messageObject.isValid) {
                        this.mode = 2;
                    } else {
                        this.mode = 3;
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    requestPasswordReset() {
        if (this.email.length && StringUtility.emailValidate(this.email)) {
            this.inProgress = true;
            this.accountService.requestPasswordReset(this.email, this.currentUrl).subscribe(
                r => {
                    this.inProgress = false;
                    let data = r.json();
                    if (data.success) {
                        this.mode = 1;
                    } else {
                        this.isEmailError = true;
                        this.emailErrorMessage = data.errorMessage;
                    }
                },
                err => {
                    this.inProgress = false;
                    this.isEmailError = true;
                    this.emailErrorMessage = "The E-mail address you entered has no Toolbox account associated with it. Please re-enter your e-mail address.";
                }
            );
        } else if (this.email.length == 0) {
            this.inProgress = false;
            this.isEmailError = true;
            this.emailErrorMessage = "E-mail Address is required.";
        } else {
            this.inProgress = false;
            this.isEmailError = true;
            this.emailErrorMessage = "E-mail Address requires a valid email address.";
        }
    }

    resetPassword() {
        if (this.password.length < 5) {
            this.isPasswordError = true;
            this.passwordErrorMessage = "Passwords must be at least 5 characters.";
        } else if (this.password !== this.password2) {
            this.isPasswordError = true;
            this.passwordErrorMessage = "Passwords must match.";
        } else {
            this.inProgress = true;
            this.accountService.resetPassword(this.id, this.password, this.currentUrl).subscribe(
                r => {
                    this.inProgress = false;
                    const response = r.json();
                    this.mode = 4;
                    if (response.messageObject.success) {
                        this.email = response.messageObject.email
                    }
                }
            );
        }

    }

    goToLogin() {
        this.router.navigate(['/login']);

    }

}
