

import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {Customer, CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {MarketSegment, ProductCoverage, ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';
import {ActivatedRoute, Router, RouterState, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {ActivityResult, PremiumDurations, RoutingUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PropertyType} from '@orhp/phx-property-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {NewConstructionFlag, Plan, PlanDomainFactoryService, PlanLookupService} from '@orhp/phx-plan-ui-module';
import {Product} from '@orhp/phx-product-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {TransactionType} from '@orhp/phx-plan-ui-module';
import {TransactionRole} from '@orhp/phx-plan-ui-module';
import {PlanOrderLookupService} from '../_services/plan-order-lookup.service';
import {PlanOrderDomainFactoryService} from '../_services/plan-order-domain-factory.service';
import {CustomerPhone} from '@orhp/phx-customer-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {PlanUtility} from '@orhp/phx-plan-ui-module';
import {PlanStatus} from '@orhp/phx-plan-ui-module';
import {CancelReason} from '@orhp/phx-plan-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {PlanCancelService} from '@orhp/phx-plan-ui-module';
import {PlanCancelParams} from '@orhp/phx-plan-ui-module';
import {PlanCancel} from '@orhp/phx-plan-ui-module';
import {Relationship} from '@orhp/phx-plan-ui-module';
import {ProblemLookupService} from '@orhp/phx-claims-ui-module';
import {ProblemLookupParams} from '@orhp/phx-claims-ui-module';
import {Problem} from '@orhp/phx-claims-ui-module';
import {PlanConfirmationService} from '@orhp/phx-plan-ui-module';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {PlanInvoiceRecipient} from '@orhp/phx-plan-ui-module';
import {PlanInvoiceRecipientRelationship} from '@orhp/phx-plan-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {SystemMessageLookupParams} from '@orhp/phx-system-ui-module';
import {SystemMessage, SystemMessageCriteria} from '@orhp/phx-system-ui-module';
import {PlanService} from '@orhp/phx-plan-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {CustomerInvoiceLookupService, PlanInvoicePaymentResult} from '@orhp/phx-accounting-ui-module';
import {CustomerInvoice} from '@orhp/phx-accounting-ui-module';
import {CorrespondenceService} from '../../../user/_services/correspondence.service';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {ActivityResultError} from '@orhp/phx-common-ui-module';
import {PhxDateService} from '@orhp/phx-common-ui-module';
import {PlanOption} from '@orhp/phx-plan-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {PlanOrderReviewAgentType} from './agent/plan-order-review-agent-type';
import {PlanOrderPaymentComponent} from '../payment/payment.component';
import {CorrespondenceLookupValuesService, InvoiceCommunicationType} from '@orhp/phx-correspondence-ui-module';
import {DurationEnum} from '../_src/price-duration-enum';
import {PaymentPlanPayment} from '@orhp/phx-accounting-ui-module/_models/payment-plan-payment';


@Component({
  selector: 'app-plan-order-review',
  templateUrl: './review.component.html',
  styleUrls: [
    '../plan-order.scss',
    './review.component.scss'
  ]
})


export class PlanOrderReviewComponent extends PlanOrderBaseComponent implements OnInit, OnDestroy {

  submitAttemptedFlag = false;
  disclosureFlag = false;


  private _randomPlanID: number = null;


  selectedCancelReasonCode: string = null;

  disallowEditFlag = false;

  sendEmailPageMode = SendEmailPageMode.none;

  resendDecSheetPageMode = ResendDecSheetPageMode.none;
  resendDecSheetConfirmationMessage: string = null;

  updateInvoiceRecipientMode = UpdateInvoiceRecipientMode.none;

  nextButtonTitle = 'Submit Application';

  footerMessages: string[] = null;

  premiumInvoiceRefreshTimeoutHandle: any = null;
  premiumInvoiceRefreshCounter = 0;
  premiumInvoiceMaxRefreshCount = 5;
  premiumInvoiceRefreshFlag = false;

  updateAdditionalEmailMessage: SystemMessage = null;
  updateAdditionalEmailMode = UpdateAdditionalEmailMode.none;

  @ViewChild('messageContainer')
  messageContainer: ElementRef;

  private _updateAdditionalEmailEditing = '';

  get updateAdditionalEmailEditing(): string {
    return this._updateAdditionalEmailEditing;
  }

  set updateAdditionalEmailEditing(value: string) {
    if (this._updateAdditionalEmailEditing !== value) {
      this._updateAdditionalEmailEditing = value;

      if (this.updateAdditionalEmailMode === UpdateAdditionalEmailMode.promptError) {
        this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.promptUser;
      }
    }
  }


  canResendAnniversaryCard = false;
  canResendCongratulationsCard = false;

  emailTitleLanguage = '';
  emailSuccessLanguage = '';
  emailFailureLanguage = '';
  onEmailSendSuccess: Observable<any>[] = [];

  confirmationSentFlag = this.planOrderApplication.confirmationSentFlag;
  confirmationAutoSendMode = ConfirmationAutoSendMode.none;
  confirmationAutoSendEventEmitter = new EventEmitter<boolean>();

  saveErrors: ActivityResultError[] = null;

  planOrderReviewAgentTypes = new PlanOrderReviewAgentType();

  get planInvoicePaymentResult(): PlanInvoicePaymentResult {
    const ccPaymentResult = this.planOrderApplication.ccPaymentResult;

    return (ccPaymentResult ? ccPaymentResult.object : null);
  }


  get isAccountExecutive(): boolean {
    const role = this.planOrderApplication.planOrdererRole;

    let guestGUID = '';

    if (role !== null) {
      guestGUID = this.planOrderApplication.planOrdererRole.guestGUID;
    }

    const hasPlanID = this.planOrderApplication.planID !== null;
    const differentPlanAndCreatorGUIDS = (this.planOrderAuthService.guestGUID || '').toLowerCase() !== (guestGUID || '').toLowerCase();
    const planAndCreatorGUIDSAreNull = this.planOrderAuthService.guestGUID === null && guestGUID === '';
    const hasTerritories = (this.planOrderAuthService.aeTerritories || []).length > 0;



    if (hasPlanID && hasTerritories && ((this.planOrderAuthService.guestGUID !== null && differentPlanAndCreatorGUIDS )
      || (planAndCreatorGUIDSAreNull))) {
      return true;
    } else {
      return false;
    }


  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private modalProgressService: PhxModalProgressService,
              private systemMessageLookupService: SystemMessageLookupService,
              private problemLookupService: ProblemLookupService,
              private dateService: PhxDateService,
              private planOrderAuthService: PlanOrderAuthService,
              private planService: PlanService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private planConfirmationService: PlanConfirmationService,
              private planLookupValueService: PlanLookupValueService,
              private planCancelService: PlanCancelService,
              private planOrderService: PlanOrderService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private planOrderLookupService: PlanOrderLookupService,
              private planLookupService: PlanLookupService,
              private planDomainFactoryService: PlanDomainFactoryService,
              private planOrderStepService: PlanOrderStepService,
              private customerInvoiceLookupService: CustomerInvoiceLookupService,
              private correspondenceLookupValueService: CorrespondenceLookupValuesService,
              private correspondenceService: CorrespondenceService) {
    super(planOrderService);

  }


  ngOnInit() {
    super.ngOnInit();

    // clear the office/agent lookup
    this.officeAgentLookupPersistenceService.clearPersistedValues();

    // set the current step is the review step
    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'review');

    // if there was an error saving the application
    this.saveErrors = this.planOrderApplication.saveErrors;

    if (this.shouldDisplayErrorMessage()) {
      this.planOrderService.restorePlanOrderApplicationBackup();

      this.planOrderApplication.saveErrors = this.saveErrors;
    }

    // flag that all steps have been completed
    this.planOrderApplication.allStepsCompletedFlag = true;

    this.planOrderService.persistPlanOrderApplication();

    if (this.pageMode === PageMode.confirmNewPlan) {
      this.disallowEditFlag = true;
    }

    if (this.pageMode === PageMode.reviewNewPlan) {
      this.fetchReviewScreenFooterMessages();

      this.planOrderService.persistPlanOrderApplication();
    }

    this.planOrderService.backupPlanOrderApplication();



    // are we fetching the premium invoice?
    let shouldFetchPremiumInvoice = true;

    // if there is no invoice recipient
    if (!this.planOrderApplication.invoiceRecipient) {
      shouldFetchPremiumInvoice = false;
    }

    // if the confirmation has not been sent yet
    if (!this.planOrderApplication.confirmationSentFlag) {
      shouldFetchPremiumInvoice = false;
    }


    // only certain page modes
    if ((this.pageMode !== PageMode.confirmNewPlan) &&
        (this.pageMode !== PageMode.confirmUpdatePlan)) {
      shouldFetchPremiumInvoice = false;
    }


    // if the "Send Invoice To" is the same as "Most Recent Invoice To"
    if (this.invoiceRecipientText() === this.lastInvoiceRecipientText()) {
      shouldFetchPremiumInvoice = false;
    }


    // if the recipient does not have an email address
    const invoiceRecipientCustomerEmailFlag = this.invoiceRecipientHasEmailFlag();

    if (!invoiceRecipientCustomerEmailFlag) {
      shouldFetchPremiumInvoice = false;
    }


    if (shouldFetchPremiumInvoice) {
      this.fetchPremiumInvoice();
    }


    // load the additional email message
    this.fetchAdditionalEmailMessage();


    if (this.planOrderApplication.planID !== null) {
        this.correspondenceService.canResendAnniversaryCard(this.planOrderApplication.planID).subscribe(
            (r: boolean) => {
                this.canResendAnniversaryCard = r;
            }
        );

        this.correspondenceService.canResendCongratulationsCard(this.planOrderApplication.planID).subscribe(
            (r: boolean) => {
                this.canResendCongratulationsCard = r;
            }
        );
    }





  }



  ngOnDestroy() {
    clearTimeout(this.premiumInvoiceRefreshTimeoutHandle);
  }



  get pageMode(): PageMode {
    let pageMode: PageMode = null;

    if (this.planOrderApplication.demoModeSubmittedFlag) {
      pageMode = PageMode.confirmNewPlan;

    } else if (!this.planOrderApplication.planID) {
      pageMode = PageMode.reviewNewPlan;

    } else if (this.confirmMessageCode === 'confirm-new') {
      pageMode = PageMode.confirmNewPlan;

    } else if (this.confirmMessageCode === 'confirm-update') {
      pageMode = PageMode.confirmUpdatePlan;

    } else {
      pageMode = PageMode.reviewExistingPlan;
    }

    return pageMode;

  }



  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }


  get titleText(): string {
    let text: string = null;

    if (this.planOrderApplication.planCancel) {
      text = 'Application Cancelled';

    } else {
      switch (this.pageMode) {
        case PageMode.reviewNewPlan:
          text = 'Review Application';
          break;

        case PageMode.confirmNewPlan:
          text = 'Confirmation';
          break;

        case PageMode.confirmUpdatePlan:
          text = 'Confirmation';
          break;

        case PageMode.reviewExistingPlan:
          text = 'Review / Update';
          break;
      }
    }

    return text;
  }





  get pathToReview(): string {
    return RoutingUtility.relativePathToUrlSegment(this.router, 'review');
  }


  get confirmMessageCode(): string {
    const messageCode = (this.route.snapshot.params['confirm-message'] || '').toLowerCase();

    return messageCode;
  }


  get confirmationMessage(): string {
    const messageCode = this.confirmMessageCode;

    let message: string = null;

    switch (messageCode) {
      case 'confirm-new':
        message = 'Thank you for choosing ORHP! ';

        if (!this.planOrderApplication.payNowFlag) {
          message += 'Confirmation and invoice emails/faxes have been sent to the appropriate parties.';

        } else {
          const firstPaymentAmount = (this.planInvoicePaymentResult ? this.planInvoicePaymentResult.transactionAmount : 0);

          message += 'We\'ve charged your credit card $' +
            StringUtility.formatDollarWithDecimal(firstPaymentAmount) + '.';

          if (!!this.productOfficeGroup && this.productOfficeGroup.homeBuyerEmailRequiredFlag) {
            message = this.addEmailConfirmation(message);
          }

          message += '<br><br>Look for an email containing your Declaration of Coverage - an important document that contains your ' +
            'home warranty coverage details.';
        }

        break;

      case 'confirm-cancel':
        message = 'Your application has been cancelled';
        break;

      case 'confirm-update':
        // if the payment was confirmed successful
        const ccPaymentResult = this.planOrderApplication.ccPaymentResult;
        const planInvoicePaymentResult = (!!ccPaymentResult ? ccPaymentResult.object : null);

        if (!!planInvoicePaymentResult && planInvoicePaymentResult.successFlag) {
          message = 'Thank you for choosing ORHP! ';
          message += 'We\'ve charged your credit card $' +
            StringUtility.formatDollarWithDecimal(planInvoicePaymentResult.transactionAmount) + '.';

          if (!!this.productOfficeGroup && this.productOfficeGroup.homeBuyerEmailRequiredFlag) {
            message = this.addEmailConfirmation(message);
          }

          message += '<br><br>Look for an email containing your Declaration of Coverage - an important document that contains your ' +
            'home warranty coverage details.';

        } else if (this.planOrderApplication.confirmationSentFlag) {
          message = 'Your application has been updated. Confirmation and invoice emails/faxes have been sent to the appropriate parties.';
        }

        break;
    }

    if (!!message && !!this.messageContainer) {
      this.messageContainer.nativeElement.innerHTML = message;
    }

    return message;
  }




  get existingPlanFlag(): boolean {
    return !!this.planOrderApplication.planID;
  }


  // returns the formatted plan number
  get formattedPlanID(): string {
    let formattedPlanID = '';

    // if there is a plan number
    if (this.planOrderApplication.planID) {
      formattedPlanID = this.planOrderApplication.formattedPlanID;

    // if there is no plan number
    } else {
      if (!this._randomPlanID) {
        this._randomPlanID = Math.floor(Math.random() * 10000000);
      }

      formattedPlanID = 'D' + PlanUtility.formatPlanNumber(this._randomPlanID);
    }

    return formattedPlanID;
  }


  // returns the transaction type
  get transactionType(): TransactionType {
    const transactionType = this.planOrderApplication.transactionType;

    return transactionType;
  }



  // returns the role
  get role(): TransactionRole {
    const role = this.planOrderApplication.role;

    return role;
  }


  // returns the property
  get property(): Property {
    const property = this.planOrderApplication.property;

    return property;
  }


  // returns the property type
  get propertyType(): PropertyType {
    let propertyType: PropertyType = null;

    if (this.property && this.property.propertyType) {
      propertyType = this.property.propertyType;
    }

    return propertyType;
  }


  // returns the new construction flag
  get newConstructionFlag(): NewConstructionFlag {
    const newConstructionFlag = this.planOrderApplication.newConstructionFlag;

    return newConstructionFlag;
  }


  // returns the product
  get product(): Product {
    const product = this.planOrderApplication.product;

    return product;
  }


  // returns the market segment for the product
  get marketSegment(): MarketSegment {
    return this.product.marketSegment;
  }



  // returns optional coverage
  get optionalCoverages(): ProductCoverage[] {
    const optionalCoverages = [];

    const appCoverages = (this.planOrderApplication.productCoverages || []);

    appCoverages.forEach((indexCoverage: ProductCoverage) => {
      if (indexCoverage.coverageType.optionalCoverageFlag) {
        optionalCoverages.push(indexCoverage);
      }
    });

    return optionalCoverages;
  }



  // returns home buyers
  get homeBuyers(): Customer[] {
    const customers = this.planOrderApplication.homeBuyers || [];

    return customers;
  }


  // returns home sellers
  get homeSellers(): Customer[] {
    const customers = this.planOrderApplication.homeSellers || [];

    return customers;
  }





  // fetches review screen messages
  fetchReviewScreenFooterMessages() {
    const params = new SystemMessageLookupParams();

    params.type = 'plan-order-review-footer-message';
    params.includeCriteriaFlag = true;

    this.systemMessageLookupService.systemMessageLookup(params).subscribe((systemMessages: SystemMessage[]) => {
      const messages = [];

      if (systemMessages) {
        systemMessages.forEach((indexSystemMessage: SystemMessage) => {
          let includeFlag = true;


          // if the system message has a state restriction
          if (indexSystemMessage.hasStateCodeCriteria()) {
            const stateCode = this.planOrderApplication.property.stateCode;

            if (!indexSystemMessage.hasStateCodeCriteria(stateCode)) {
              includeFlag = false;
            }
          }


          // if the system message has a property type restriction
          if (indexSystemMessage.hasPropertyTypeCriteria()) {
            const propertyType = this.planOrderApplication.property.propertyType;

            if (!indexSystemMessage.hasPropertyTypeCriteria(propertyType.propertyType)) {
              includeFlag = false;
            }
          }


          // if the system message has a display code restriction
          if (indexSystemMessage.hasDisplayCodeCriteria()) {
            let displayCodeFlag = false;

            this.planOrderApplication.productCoverages.forEach((indexCoverage: ProductCoverage) => {
              if (indexSystemMessage.hasDisplayCodeCriteria(indexCoverage.displayCode)) {
                displayCodeFlag = true;
              }
            });

            if (!displayCodeFlag) {
              includeFlag = false;
            }
          }

          // if we're including the system message
          if (includeFlag) {
            messages.push(indexSystemMessage.message);
          }
        });
      }

      this.footerMessages = messages;
    });
  }


  // fetches the customer invoice
  fetchPremiumInvoice() {
    // if the counter hasn't hit the maximum yet
    if (this.premiumInvoiceRefreshCounter <= this.premiumInvoiceMaxRefreshCount) {
      this.premiumInvoiceRefreshFlag = true;

      const planID = this.planOrderApplication.planID;

      const premiumInvoice = this.planOrderApplication.premiumInvoice;
      const lastInvoiceDelivery = (premiumInvoice ? premiumInvoice.lastInvoiceDelivery : null);
      const lastInvoiceDeliveryGUID = (lastInvoiceDelivery ? lastInvoiceDelivery.guid.toLowerCase() : null);

      // fetch the most recent premium invoice
      this.customerInvoiceLookupService.fetchPremiumInvoice(planID)
        .subscribe((invoice: CustomerInvoice) => {

          this.premiumInvoiceRefreshCounter++;

          const newInvoiceDelivery = (invoice ? invoice.lastInvoiceDelivery : null);
          const newInvoiceDeliveryGUID = (newInvoiceDelivery ? newInvoiceDelivery.guid.toLowerCase() : null);

          // is the new invoice delivery different?
          if (lastInvoiceDeliveryGUID !== newInvoiceDeliveryGUID) {
            this.premiumInvoiceRefreshFlag = false;

            this.planOrderApplication.premiumInvoice.lastInvoiceDelivery = newInvoiceDelivery;

            this.persistPlanOrderApplication();

            this.planOrderService.backupPlanOrderApplication();

            // if it is not different, try again after a pause
          } else {
            this.premiumInvoiceRefreshTimeoutHandle = setTimeout(() => {
              this.fetchPremiumInvoice();
            }, 5000);
          }
        });

    // if we've hit the maximum counter
    } else {
      this.premiumInvoiceRefreshFlag = false;
    }
  }


  shouldDisplayPremiumInvoiceLastSentTo(): boolean {
    let displayFlag = true;

    // if the refresh is displayed
    if (this.shouldDisplayPremiumInvoiceRefresh()) {
      displayFlag = false;
    }

    // if this is a new application
    if (!this.planOrderApplication.planID) {
      displayFlag = false;
    }

    return displayFlag;
  }


  shouldDisplayPremiumInvoiceRefresh(): boolean {
    return this.premiumInvoiceRefreshFlag;
  }


  invoiceRecipientHasEmailFlag(): boolean {
    const customer = this.planOrderApplication.invoiceRecipientCustomer;
    let emailFlag = false;

    if (customer && customer.phones) {
      customer.phones.forEach((indexPhone: CustomerPhone) => {
        if (indexPhone.phoneType.emailFlag) {
          emailFlag = true;
        }
      });
    }

    return emailFlag;
  }



  fetchAdditionalEmailMessage() {
    let fetchFlag = false;
    fetchFlag = fetchFlag || (this.pageMode === PageMode.reviewNewPlan);
    fetchFlag = fetchFlag || (this.pageMode === PageMode.confirmNewPlan);

    if (!this.planOrderAuthService.employer) {
      fetchFlag = false;
    }

    if (fetchFlag) {

      const params = new SystemMessageLookupParams();
      params.type = 'plan-order-review-additional-emails';
      params.employerID = String(this.planOrderAuthService.employer.employerID);

      this.systemMessageLookupService.systemMessageLookup(params).subscribe((messages: SystemMessage[]) => {
        if ((messages || []).length) {
          this.updateAdditionalEmailMessage = messages[0];

          if (this.pageMode === PageMode.reviewNewPlan) {
            this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.promptUser;
          }
        }
      });
    }
  }






  // should the footer messages be displayed
  shouldDisplayFooterMessages(): boolean {
    let displayFlag = true;

    if (this.footerMessages) {
      if (!this.footerMessages.length) {
        displayFlag = false;
      }

    } else {
      displayFlag = false;
    }

    return displayFlag;
  }



  shouldDisplaySendDecSheetButton(): boolean {
    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag) {

      return false;

    } else {
      const status = this.planOrderApplication.planStatus;

      const flag = (status ? status.canResendDecSheetFlag : false);

      return flag;
    }
  }


  shouldDisplaySendDecSheetModal(): boolean {
    return (this.resendDecSheetPageMode !== ResendDecSheetPageMode.none);
  }




  decSheetRecipientText(): string {
    let recipient = '';

    let homeBuyerFlag = false;

    if (this.planOrderApplication.homeBuyers.length) {
      const homeBuyer = this.planOrderApplication.homeBuyers[0];

      if (!this.isCustomerAddressSameAsProperty(homeBuyer)) {
        homeBuyerFlag = true;
      }
    }

    if (homeBuyerFlag) {
      recipient = 'buyer\'s mailing address';

    } else {
      recipient = 'covered property address';
    }

    return recipient;
  }



  shouldDisplayDecSheetInProgress(): boolean {
    return (this.resendDecSheetPageMode === ResendDecSheetPageMode.inProgress);
  }


  shouldDisplayDecSheetSuccess(): boolean {
    return (this.resendDecSheetPageMode === ResendDecSheetPageMode.resendSuccess);
  }


  shouldDisplayDecSheetFailure(): boolean {
    return (this.resendDecSheetPageMode === ResendDecSheetPageMode.resendFailure);
  }

  didClickResendDecSheetConfirm(confirmFlag: boolean) {
    this.resendDecSheetPageMode = ResendDecSheetPageMode.none;
  }


  didClickResendDecSheet() {
    const planID = this.planOrderApplication.planID;

    this.resendDecSheetPageMode = ResendDecSheetPageMode.inProgress;

    const successMessageCode = 'resend-dec-sheet-confirmation-message';

    let observable: Observable<any> = null;
    const observables = [];

    // resends the Dec Sheet
    observable = this.planService.resendDecSheet(planID);
    observables.push(observable);

    // returns the success message
    observable = this.systemMessageLookupService.systemMessageLookupByType(successMessageCode);
    observables.push(observable);

    // run the observables
    Observable.forkJoin(observables).subscribe((results: any[]) => {
      const decSheetResultFlag = <boolean>results[0];
      const successMessage = <SystemMessage>results[1];

      // if the result was successful
      if (decSheetResultFlag) {
        this.resendDecSheetConfirmationMessage = successMessage.message;

        this.resendDecSheetPageMode = ResendDecSheetPageMode.resendSuccess;

      // if the result was unsuccessful
      } else {
        this.resendDecSheetPageMode = ResendDecSheetPageMode.resendFailure;
      }
    });

  }




  indexOfCustomer(customers: Customer[], customer: Customer): number {
    let foundIndex = -1;
    let customerIndex = 0;

    (customers || []).forEach((indexCustomer: Customer) => {
      if (indexCustomer === customer) {
        foundIndex = customerIndex;
      }

      customerIndex++;
    });

    return foundIndex;
  }




  doesCustomerHaveAddress(customer: Customer): boolean {
    return ((customer.address || '') !== '');
  }


  displayPhonesForCustomer(customer: Customer): CustomerPhone[] {
    const phones: CustomerPhone[] = [];

    customer.phones.forEach((indexPhone: CustomerPhone) => {
      let includePhoneFlag = false;

      includePhoneFlag = includePhoneFlag || ((indexPhone.formattedPhoneNumber || '') !== '');

      if (includePhoneFlag) {
        phones.push(indexPhone);
      }
    });

    return phones;
  }



  // can the application be edited
  canEditApplication(): boolean {
    let editFlag = false;

    // if this is a new application
    if (!this.planOrderApplication.planID) {
      editFlag = true;

    // if this is an existing application, check plan status
    } else {
      const planStatus = this.planOrderApplication.planStatus;

      if (planStatus && planStatus.editableFlag) {
        editFlag = true;
      }
    }

    if (this.disallowEditFlag) {
      editFlag = false;
    }

    return editFlag;
  }



  planStatusEditable(): boolean {
    const planStatus = this.planOrderApplication.planStatus;

    let flag = false;

    if (planStatus && planStatus.editableFlag) {
      flag = true;
    }

    return flag;
  }


  shouldDisplayNoEditingMessage(): boolean {
    return !!this.noEditingMessage();
  }


  noEditingMessage(): string {
    let message: string = null;

    const planStatus = this.planOrderApplication.planStatus;

    if (planStatus && !planStatus.editableFlag) {
      // if the plan has been cancelled
      if (this.planOrderApplication.planCancel) {
        message = 'This Application has been cancelled and cannot be modified';

      } else {
        message = 'This Application has been paid and cannot be modified';
      }
    }

    if (this.isAccountExecutive && message === null) {
      message = 'This App was not placed by you. You will not be able to edit Realtor information.';
    }

    // if there is already a confirmation message
    if (this.shouldDisplayConfirmationMessage()) {
      message = null;
    }

    return message;
  }



  shouldDisplayCombinedInstructionBox(): boolean {
    let flag = false;

    flag = flag || !this.savedPlanFlag();
    flag = flag || this.shouldDisplayCancelInfo();
    flag = flag || this.shouldDisplaySendDecSheetButton();
    flag = flag || this.shouldDisplayPlanListButton();
    flag = flag || !!this.combinedInstructionBoxText();

    return flag;
  }



  combinedInstructionBoxText(): string {
    let text: string = null;
    let testText: string = null;
    let flag = false;

    // test for product/office/group text
    if (!!this.productOfficeGroup) {
      testText = this.productOfficeGroup.invoiceCoverageTermHtml || '';

      flag = true;
      flag = flag && this.savedPlanFlag();
      flag = flag && (testText !== '');
      flag = flag && !this.product.effectiveDateDelay;
    }

    if (flag) {
      text = testText;
    }

    return text;
  }







  // can the application be cancelled
  canCancelApplication(): boolean {
    let flag = this.canEditApplication();

    if (this.pageMode === PageMode.confirmNewPlan) {
      flag = false;

    } else if (this.pageMode === PageMode.reviewNewPlan) {
      flag = false;
    }

    return flag;
  }




  shouldDisplayCancelInfo(): boolean {
    let displayFlag = true;

    if ((this.pageMode !== PageMode.confirmUpdatePlan) &&
        (this.pageMode !== PageMode.reviewExistingPlan)) {
      displayFlag = false;
    }

    if (!this.planOrderApplication.planCancel) {
      displayFlag = false;
    }

    return displayFlag;
  }


  cancelledByName(): string {
    let name: string = null;

    const cancelCustomerID = this.planOrderApplication.planCancel.customerID;

    this.planOrderApplication.customers.forEach((indexCustomer: Customer) => {
      if (indexCustomer.customerID === cancelCustomerID) {
        name = indexCustomer.fullName;
      }
    });

    if (!name) {
      const defaultHomeownerFlag = (this.planOrderApplication.defaultHomeownerCustomerID === cancelCustomerID);

      if (defaultHomeownerFlag) {
        name = 'HOMEOWNER';
      }
    }

    return name;
  }


  cancelReasonText(): string {
    const cancelReason = this.planOrderApplication.planCancel.cancelReason;

    let text = cancelReason.description;

    if (cancelReason.otherWarrantyCompanyNameFlag) {
      this.planLookupValueService.cancelReasons.forEach((indexCancelReason: CancelReason) => {
        if (indexCancelReason.otherWarrantyCompanyGenericFlag) {
          text = indexCancelReason.description + ' - ' + text;
        }
      });
    }

    return text;
  }



  // is this an existing plan
  savedPlanFlag(): boolean {
    let flag = false;

    // if the plan has been saved
    if (this.planOrderApplication.planID) {
      flag = true;
    }

    // if this is a demo mode submitted plan
    if (this.planOrderApplication.demoModeSubmittedFlag) {
      flag = true;
    }

    return flag;
  }



  shouldDisplayConfirmationResendButton(): boolean {
    let flag = true;

    // if the credit card payment failed
    const paymentResult = this.planOrderApplication.ccPaymentResult;
    const invoicePaymentResult = (paymentResult ? paymentResult.object : null);
    const ccPaymentResult = (!!invoicePaymentResult ? invoicePaymentResult.creditCardSalesResult : null);

    if (ccPaymentResult && !ccPaymentResult.successFlag) {
      flag = false;
    }


    // if this step is confirm/new
    if (this.pageMode === PageMode.confirmNewPlan) {
      flag = false;
    }


    // only if an existing application has been updated
    if (this.planOrderApplication.confirmationSentFlag) {
      flag = false;
    }

    // if this is the review step for a new plan
    if (this.pageMode === PageMode.reviewNewPlan) {
      flag = false;
    }

    // if the plan is cancelled
    if (this.planOrderApplication.planCancel) {
      flag = false;
    }

    return flag;
  }



  // should the resend confirmation button include invoice text?
  shouldDisplayConfirmationResendButtonInvoiceText(): boolean {
    let flag = this.invoiceRecipientHasEmailFlag();

    // if a payment has been made, don't display it
    if (!!this.planInvoicePaymentResult && this.planInvoicePaymentResult.successFlag) {
      flag = false;
    }

    // if there are other invoice recipients, don't display it
    if (!!(this.planOrderApplication.premiumInvoiceRecipients || []).length) {
      flag = false;
    }

    return flag;
  }



  // should the plan number be displayed
  shouldDisplayPlanNumber(): boolean {
    return this.savedPlanFlag();
  }


  // should the transaction type section be displayed?
  shouldDisplayTransactionTypeSection(): boolean {
    // do not display for existing plans
    return !this.planOrderApplication.planID;
  }


  // should the role section be displayed?
  shouldDisplayRoleSection(): boolean {
    // do not display for existing plans
    return !this.planOrderApplication.planID;
  }



  // is the customer address the same as the property address?
  isCustomerAddressSameAsProperty(customer: Customer): boolean {
    return this.planOrderApplication.customerHasPropertyAddress(customer);
  }



  shouldDisplayCoverageTerm(): boolean {
    let flag = !!this.planOrderApplication.planCoverageStarts && !!this.planOrderApplication.planCoverageEnds;

    if (!!this.planOrderApplication.planCancel) {
      flag = false;
    }

    return flag;
  }






  shouldDisplayCoverageDelayText(): boolean {
    return !!this.coverageDelayText();
  }





  coverageDelayText(): string {
    const product = this.planOrderApplication.product;
    const text = PlanOrderPaymentComponent.coverageDelayTextWithProduct(product, this.planOrderAuthService.productOffice);

    return text;
  }






  // should the No Optional Coverage message be displayed?
  shouldDisplayNoOptionalCoverageMessage(): boolean {
    return !this.optionalCoverages.length;
  }


  // is this coverage sellers coverage?
  isSellersCoverage(coverage: ProductCoverage): boolean {
    return coverage.coverageType.sellerCoverageFlag;
  }



  sellersCoveragePremium(indexCoverage: ProductCoverage): number {
    const sellersCoverageDays = this.planOrderApplication.sellersCoverageDays();

    let dayFlag = true;
    dayFlag = dayFlag && (sellersCoverageDays > 0);
    dayFlag = dayFlag && (sellersCoverageDays < indexCoverage.durationQty);

    let premium: number = null;

    if (dayFlag) {
      premium = this.planOrderApplication.sellersCoveragePremium();

    } else {
      premium = indexCoverage.premium;
    }

    return premium;
  }



  // should display premium per day
  shouldDisplayPremiumPerDayText(coverage: ProductCoverage): boolean {
    let displayFlag = true;

    if (!coverage.coverageType.sellerCoverageFlag) {
      displayFlag = false;
    }

    if (coverage.includedCoverageFlag) {
      displayFlag = false;
    }

    if (!coverage.dailyFlag) {
      displayFlag = false;
    }

    // test for sellers coverage days
    const sellersCoverageDays = this.planOrderApplication.sellersCoverageDays() || 0;

    if ((sellersCoverageDays !== 0) && (sellersCoverageDays !== coverage.durationQty)) {
      displayFlag = false;
    }



    return displayFlag;
  }


  // should the sellers coverage subtitle be displayed
  shouldDisplaySellersCoverageSubtitle(coverage: ProductCoverage): boolean {
    let displayFlag = true;

    // test for sellers coverage type
    if (!coverage.coverageType.sellerCoverageFlag) {
      displayFlag = false;
    }

    // test for included sellers coverage
    if (coverage.includedCoverageFlag) {
      displayFlag = false;
    }

    // if the coverage isn't daily
    if (!coverage.dailyFlag) {
      displayFlag = false;
    }

    // test for sellers coverage days
    const sellersCoverageDays = this.planOrderApplication.sellersCoverageDays() || 0;

    if (sellersCoverageDays === 0) {
      displayFlag = false;
    }

    if (sellersCoverageDays === coverage.durationQty) {
      displayFlag = false;
    }

    return displayFlag;
  }



  // should the option units be displayed?
  shouldDisplayOptionUnits(coverage: ProductCoverage): boolean {
    let displayFlag = true;

    if (!coverage.optionUnitsEditableFlag) {
      displayFlag = false;
    }

    if (coverage.includedCoverageFlag) {
      displayFlag = false;
    }

    if (coverage.coverageType.sellerCoverageFlag) {
      displayFlag = false;
    }


    // property type
    const propertyType = this.planOrderApplication.property.propertyType;

    if (!propertyType.multipleOptionUnitFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // option expiration date
  coverageExpirationDate(coverage: ProductCoverage): Date {
    const planOption = ArrayUtility.arrayFind(this.planOrderApplication.planOptions,
      (option: PlanOption): boolean => {
        return (option.coverageID === coverage.coverageID);
      });

    const optionCoverageEnds = (planOption ? planOption.optionCoverageEnds : null);

    return optionCoverageEnds;
  }


  // is the coverage expired?
  isCoverageExpired(coverage: ProductCoverage): boolean {
    let expiredFlag = false;

    const optionCoverageEnds = this.coverageExpirationDate(coverage);

    const planStatus = this.planOrderApplication.planStatus;
    const activeFlag = (planStatus ? (planStatus.buyerActiveFlag || planStatus.sellerActiveFlag) : false);

    if (optionCoverageEnds && activeFlag) {
      const currentDate = this.dateService.currentDate();

      if (optionCoverageEnds.getTime() < currentDate.getTime()) {
        expiredFlag = true;
      }
    }

    return expiredFlag;
  }



  // should the service history be displayed?
  shouldDisplayServiceHistory(): boolean {
    let displayFlag = true;

    if (!this.planOrderApplication.planClaimHistory) {
      displayFlag = false;
    }

    if (this.pageMode === PageMode.reviewNewPlan) {
      displayFlag = false;
    }

    if (this.pageMode === PageMode.confirmNewPlan) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // should the no service message be displayed?
  shouldDisplayNoServiceHistoryMessage(): boolean {
    let displayFlag = true;

    if (this.planOrderApplication.planClaimHistory) {
      displayFlag = displayFlag && (this.planOrderApplication.planClaimHistory.length === 0);
    }

    return displayFlag;
  }




  // should the initiating agent be editable?
  canEditInitiatingAgent(): boolean {
    let editFlag = this.canEditApplication();

    // if this is an existing plan
    const newPlanFlag = !this.planOrderApplication.planID;

    if (!newPlanFlag) {
      // if the Market Segment doesn't allow it
      const marketSegment = this.product.marketSegment;

      if (!marketSegment.promptForInitiatingAgentFlag) {
        editFlag = false;
      }
    }

    if (this.isAccountExecutive) {
      editFlag = false;
    }

    return editFlag;
  }




  // should the cooperating agent be editable?
  canEditCooperatingAgent(): boolean {
    let editFlag = this.canEditApplication();

    const newPlanFlag = !this.planOrderApplication.planID;

    // if this is a new plan
    if (newPlanFlag) {
      if (!this.planOrderStepService.promptForCooperatingAgent(this.planOrderApplication)) {
        editFlag = false;
      }

    // if this is not a new plan
    } else {
      // if the Market Segment doesn't allow it
      const marketSegment = this.product.marketSegment;

      if (!marketSegment.promptForCooperatingAgentFlag) {
        editFlag = false;
      }
    }

    if (this.isAccountExecutive) {
      editFlag = false;
    }

    return editFlag;
  }



  shouldDisplayInitiatingAgentSection(): boolean {
    let flag = true;

    if (!!this.planOrderApplication.transactionType && this.planOrderApplication.transactionType.directToConsumerFlag) {
      flag = false;
    }

    if (this.marketSegment.directFlag) {
      flag = false;
    }

    return flag;
  }




  shouldDisplayCooperatingAgentSection(): boolean {
    let flag = true;

    if (!!this.planOrderApplication.transactionType && this.planOrderApplication.transactionType.directToConsumerFlag) {
      flag = false;
    }

    if (this.marketSegment.directFlag) {
      flag = false;
    }

    return flag;
  }



  // should the closing section be displayed
  shouldDisplayClosingSection(): boolean {
    let displayFlag = true;

    const transactionType = this.planOrderApplication.transactionType;

    // if this is a new plan
    if (!this.planOrderApplication.planID) {
      if (!!transactionType && transactionType.activeListingFlag) {
        displayFlag = false;
      }
    }

    if (!!transactionType && transactionType.directToConsumerFlag) {
      displayFlag = false;
    }

    if (this.marketSegment.directFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }




  // should any closing info be displayed
  shouldDisplayClosingInfo(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || this.shouldDisplayClosingFileNumber();
    displayFlag = displayFlag || this.shouldDisplayEstimatedClosingDate();
    displayFlag = displayFlag || this.shouldDisplayActualClosingDate();
    displayFlag = displayFlag || this.shouldDisplayInvoiceRecipient();

    return displayFlag;
  }


  // should the file number be displayed
  shouldDisplayClosingFileNumber(): boolean {
    const closingFileNumber = (this.planOrderApplication.closingFileNumber || '');

    return (closingFileNumber !== '');
  }


  // should the estimated closing date be displayed?
  shouldDisplayEstimatedClosingDate(): boolean {
    return !!this.planOrderApplication.estimatedClosingDate;
  }


  // should the actual closing date be displayed?
  shouldDisplayActualClosingDate(): boolean {
    return !!this.planOrderApplication.actualClosingDate;
  }


  // should the 'no closing info' message be displayed?
  shouldDisplayNoClosingInfoMessage(): boolean {
    let displayFlag = true;

    displayFlag = displayFlag && !this.shouldDisplayClosingFileNumber();
    displayFlag = displayFlag && !this.shouldDisplayEstimatedClosingDate();
    displayFlag = displayFlag && !this.shouldDisplayActualClosingDate();

    return displayFlag;
  }




  shouldDisplayTelemarketingAgentSection(): boolean {
    let flag = !!this.planOrderApplication.telemarketingAgent;

    if (!!this.productOfficeToolbox && !!this.productOfficeToolbox.autoLoginGuestGUID) {
      flag = false;
    }

    return flag;
  }





  homeBuyerText(): string {
    return this.planOrderApplication.homeBuyerOrOwnerText;
  }






  // should the home buyer be editable?
  canEditHomeBuyer(): boolean {
    let editFlag = this.canEditApplication();

    if (!this.planOrderStepService.promptForBuyer(this.planOrderApplication)) {
      editFlag = false;
    }

    return editFlag;
  }



  shouldDisplayHomeSellerSection(): boolean {
    let flag = true;

    if (!!this.planOrderApplication.transactionType && this.planOrderApplication.transactionType.directToConsumerFlag) {
      flag = false;
    }

    if (this.marketSegment.directFlag) {
      flag = false;
    }

    return flag;
  }




  shouldDisplayPaymentSection(): boolean {
    let flag = false;

    // if the transaction type tells us to prompt for payment
    const transactionType = this.planOrderApplication.transactionType;

    if (transactionType && transactionType.promptForPaymentFlag) {
      flag = true;
    }

    // if a payment has been charged
    if (!!this.planOrderApplication.ccPaymentResult) {
      flag = true;
    }

    return flag;
  }



  canEditPayment(): boolean {
    let flag = false;

    if (this.canEditApplication()) {
      // pull the payment result
      const ccPaymentResult = this.planOrderApplication.ccPaymentResult;

      // if it doesn't exist, we can edit it
      if (!ccPaymentResult) {
        flag = true;

        // if it does exist, check for a payment failure
      } else {
        const successFlag = (!!ccPaymentResult.object ? ccPaymentResult.object.successFlag : false);

        if (!successFlag) {
          flag = true;
        }
      }
    }

    return flag;
  }




  shouldDisplayFirstPaymentAmount(): boolean {
    return (this.planOrderApplication.payNowFlag && !this.planOrderApplication.planID);
  }




  // should display invoice recipient
  shouldDisplayInvoiceRecipient(): boolean {
    return !!this.planOrderApplication.invoiceRecipient;
  }




  // invoice recipient text
  invoiceRecipientText(): string {
    const invoiceRecipient = this.planOrderApplication.invoiceRecipient;

    const invoiceRecipientText = (invoiceRecipient ? invoiceRecipient.description : null);

    return invoiceRecipientText;
  }


  lastInvoiceRecipientText(): string {
    let invoiceRecipient: PlanInvoiceRecipient = null;

    // check by CustomerID
    let customerID: number = null;

    // if there is a premium invoice, look for the last invoice delivery
    const premiumInvoice = this.planOrderApplication.premiumInvoice;

    if (premiumInvoice && premiumInvoice.lastInvoiceDelivery) {
      customerID = premiumInvoice.lastInvoiceDelivery.customerID;
    }

    // if we're checking by customerID
    if (customerID) {
      const relationship = this.planOrderApplication.relationshipForCustomerID(customerID);

      if (relationship) {
        let listAgentFlag: boolean = null;

        if (relationship.initiatingAgentFlag) {
          listAgentFlag = this.planOrderApplication.initiatingAgentListingFlag;

        } else if (relationship.cooperatingAgentFlag) {
          listAgentFlag = this.planOrderApplication.cooperatingAgentListingFlag;
        }

        invoiceRecipient = this.planLookupValueService.invoiceRecipientByRelationship(relationship, listAgentFlag);
      }
    }

    const invoiceRecipientText = (invoiceRecipient ? invoiceRecipient.description : null);

    return invoiceRecipientText;
  }





  // send invoice to message
  shouldDisplayInvoiceRecipientPrompt(): boolean {
    // if we're in Prompt User mode
    const displayFlag = (this.updateInvoiceRecipientMode === UpdateInvoiceRecipientMode.promptUser);

    return displayFlag;
  }


  transactionTypeInEscrow(): TransactionType {
    return this.planLookupValueService.transactionTypeInEscrow();
  }


  didClickInvoiceRecipientActiveListingPromptButton(flag: boolean) {
    // user clicked confirm
    if (flag) {
      this.planOrderApplication.transactionType = this.transactionTypeInEscrow();

      this.persistPlanOrderApplication();

      this.didClickEditClosingInfo();

    // user clicked cancel
    } else {
      this.updateInvoiceRecipientMode = UpdateInvoiceRecipientMode.none;
    }
  }




  // should the "no customers" message be displayed?
  shouldDisplayNoCustomers(customers: Customer[]): boolean {
    return !customers.length;
  }



  shouldDisplaySendInvoiceToSidebar(): boolean {
    let flag = true;

    if (!!this.planOrderApplication.transactionType && this.planOrderApplication.transactionType.directToConsumerFlag) {
      flag = false;
    }

    return flag;
  }




  // only display the Next button for new plans
  shouldDisplayNextButtion(): boolean {
    let displayFlag = false;

    if (this.pageMode === PageMode.reviewNewPlan) {
      displayFlag = true;
    }

    return displayFlag;
  }


  // only display the Back button for new plans
  shouldDisplayBackButton(): boolean {
    let displayFlag = false;

    if (this.pageMode === PageMode.reviewNewPlan) {
      displayFlag = true;
    }

    return displayFlag;
  }


  // only display the Start Over button for new plans
  shouldDisplayStartOverButton(): boolean {
    return !this.planOrderApplication.planID;
  }


  // only display the Disclosure for new plans
  shouldDisplayDisclosure(): boolean {
    return false;

    /*
    let displayFlag = true;

    displayFlag = displayFlag && (this.pageMode === PageMode.reviewNewPlan);

    const role = this.planOrderApplication.role;

    if (role) {
      displayFlag = displayFlag && role.promptForDisclosureFlag;
    }

    return displayFlag;

     */
  }


  // should the disclosure error display
  shouldDisplayDisclosureError(): boolean {
    let errorFlag = false;

    if (this.submitAttemptedFlag && this.shouldDisplayDisclosure()) {
      if (!this.disclosureFlag) {
        errorFlag = true;
      }
    }

    return errorFlag;
  }


  // should the confirmation message be displayed?
  shouldDisplayConfirmationMessage(): boolean {
    let flag = (this.confirmationMessage !== null);

    if (this.applicationHasFailedPayment()) {
      flag = false;
    }

    return flag;
  }


  applicationHasFailedPayment(): boolean {
    let flag = false;

    // if there was a failed payment
    const ccPaymentResult = this.planOrderApplication.ccPaymentResult;

    if (!!ccPaymentResult && !ccPaymentResult.object.successFlag) {
      flag = true;
    }

    return flag;
  }


  applicationFailedPaymentMessage(): string {
    let text: string = null;

    if (this.applicationHasFailedPayment()) {
      const ccPaymentResult = this.planOrderApplication.ccPaymentResult;

      // test for a sale result
      const ccSaleResult = (!!ccPaymentResult.object ? ccPaymentResult.object.creditCardSalesResult : null);

      let ccSaleStatusText = ccSaleResult.resultStatusText;

      if (ccSaleResult.ccExpiredFlag) {
        ccSaleStatusText = 'your credit card is expired';
      }

      text = 'We’re sorry. It looks like we were unable to process a payment with the card information entered (' + ccSaleStatusText +
          '). Please review the information and try again.';

      if (!!this.productOfficeToolbox && this.productOfficeToolbox.allowPremiumPayLaterFlag) {
        text += ' You can also select Pay Later to have an invoice sent via email.';
      }
    }

    return text;
  }




  didClickEditPayment() {
    const url = this.router.routerState.snapshot.url;

    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'review');

    const urlSegment = new UrlSegment('payment', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }




  // should the error message be displayed?
  shouldDisplayErrorMessage(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.saveErrors && !!this.saveErrors.length);

    return displayFlag;
  }



  didClickDisclosure(): void {
    this.disclosureFlag = !this.disclosureFlag;
  }



  validateForm(): boolean {
    let validFlag = true;

    // if the disclosure was displayed
    validFlag = validFlag && !this.shouldDisplayDisclosureError();

    return validFlag;
  }


  didClickNext(): void {
    try {
      this.submitAttemptedFlag = true;

      if (this.planOrderApplication.demoModeFlag) {
        this.planOrderApplication.demoModeSubmittedFlag = true;

        this.persistPlanOrderApplication();
      }

      // if the form is valid
      const validFlag = this.validateForm();

      if (validFlag) {
        const nextRoute = this.planOrderStepService.getNextRoute();
        const url = this.pathToReview + '../' + nextRoute;

        this.router.navigate([url], {relativeTo: this.route});
      }

    } catch (error) {
      this.planOrderApplication.logError('phx.toolbox.ui.ErrorReviewNextClick', error);
      this.persistPlanOrderApplication();
    }
  }


  shouldDisplayPlanListButton(): boolean {
    let displayFlag = true;

    if (this.pageMode === PageMode.reviewNewPlan) {
      displayFlag = false;
    }

    if (this.pageMode === PageMode.confirmUpdatePlan) {
//      displayFlag = false;
    }

    if (!!this.productOfficeToolbox && !!this.productOfficeToolbox.autoLoginGuestGUID) {
      displayFlag = false;
    }

    return displayFlag;
  }


  didClickPlanListButton() {
    const url = '/home';

    if (!!this.productOfficeToolbox && !!this.productOfficeToolbox.autoLoginGuestGUID) {
      this.didClickNewPlanButton();
    } else {

      this.router.navigate([url]);
    }
  }


  shouldDisplayNewPlanButton(): boolean {
    let displayFlag = true;

    if (this.pageMode === PageMode.reviewNewPlan) {
      displayFlag = false;
    }

    if (!!this.productOfficeToolbox && !!this.productOfficeToolbox.autoLoginGuestGUID) {
      displayFlag = false;
    }

    return displayFlag;
  }


  didClickNewPlanButton() {
    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'review');

    RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

    const urlSegment = new UrlSegment('clear-application', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }



  didClickPrintButton() {
    window.print();
  }


  didClickEmailButton() {
    this.sendEmailPageMode = SendEmailPageMode.promptUser;
  }


  didClickRouteToORHP() {
    this.router.navigateByUrl('/plan-order/clear-application', {replaceUrl: true});
    window.location.replace('https://www.orhp.com');
  }


  didClickCancelApplicationButton() {
    this.confirmationAutoSendMode = ConfirmationAutoSendMode.skip;

    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'review');

    const urlSegment = new UrlSegment('cancel', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }



  shouldDisplayMailingAddress(): boolean {
    let flag = true;

    const application = this.planOrderApplication;

    // if this is a new plan
    if (!application.planID) {
      flag = false;
    }

    // if there is no mailing address
    if (!application.mailingAddress) {
      flag = false;
    }

    return flag;
  }


  canUpdateMailingAddress(): boolean {
    let flag = true;

    const application = this.planOrderApplication;

    // if the status says not to update it it
    const planStatus = application.planStatus;

    if (planStatus && !planStatus.canUpdateMailingAddressFlag) {
      flag = false;
    }

    return flag;
  }



  shouldDisplayAdditionalEmail(): boolean {
    let pageModeFlag = false;

    pageModeFlag = pageModeFlag || (this.pageMode === PageMode.reviewNewPlan);
    pageModeFlag = pageModeFlag || (this.pageMode === PageMode.confirmNewPlan);

    let flag = pageModeFlag;
    flag = flag && !!this.updateAdditionalEmailMessage;

    return flag;

  }


  canUpdateAdditionalEmail(): boolean {
    let flag = true;

    flag = flag && (this.pageMode === PageMode.reviewNewPlan);

    return flag;
  }


  additionalEmailsText(): string {
    let emailText: string = null;

    (this.planOrderApplication.additionalEmailAddresses || []).forEach((indexEmail: string) => {
      emailText = (emailText ? emailText + '\n' : '') + indexEmail;
    });

    if (!emailText) {
      emailText = 'Not Selected';
    }

    return emailText;
  }


  additionalEmailTitleText(): string {
    return (this.updateAdditionalEmailMessage ? this.updateAdditionalEmailMessage.message : null);
  }

  additionalEmailInstructionText(): string {
    return (this.updateAdditionalEmailMessage ? this.updateAdditionalEmailMessage.details : null);
  }


  shouldDisplayAdditionalEmailEntry(): boolean {
    let flag = false;

    flag = flag || (this.updateAdditionalEmailMode === UpdateAdditionalEmailMode.promptUser);
    flag = flag || (this.updateAdditionalEmailMode === UpdateAdditionalEmailMode.promptError);

    return flag;
  }


  didClickUpdateAdditionalEmail() {
    let updateAdditionalEmailEditing = '';

    (this.planOrderApplication.additionalEmailAddresses || []).forEach((indexEmail: string) => {
      updateAdditionalEmailEditing += (updateAdditionalEmailEditing !== '' ? ', ' : '') + indexEmail;
    });

    this.updateAdditionalEmailEditing = updateAdditionalEmailEditing;

    this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.promptUser;
  }


  parseAdditionalEmailEntry(): string[] {
    // validate the entered emails
    let emails: string[] = null;

    if (this.updateAdditionalEmailEditing !== '') {
      emails = this.updateAdditionalEmailEditing.split(/[,; ]+/);

    } else {
      emails = [];
    }

    return emails;
  }


  shouldDisplayAdditionalEmailEntryError(): boolean {
    let flag = true;

    flag = flag && (this.updateAdditionalEmailMode === UpdateAdditionalEmailMode.promptError);
    flag = flag && !!this.additionalEmailEntryErrorText();

    return flag;
  }


  additionalEmailEntryErrorText(): string {
    const emails = this.parseAdditionalEmailEntry();

    const invalidEmails = [];

    emails.forEach((indexEmail: string) => {
      if (!StringUtility.emailValidate(indexEmail)) {
        invalidEmails.push(indexEmail);
      }
    });

    let errorText: string = null;

    invalidEmails.forEach((indexEmail: string) => {
      errorText = (errorText ? errorText + ', ' : '') + indexEmail;
    });

    if (errorText) {
      errorText = 'Invalid Email' + (invalidEmails.length > 1 ? 's' : '') + ': ' + errorText;
    }

    return errorText;
  }



  didClickConfirmAdditionalEmailEntry() {
    const errorText = this.additionalEmailEntryErrorText();

    if (!errorText) {
      this.planOrderApplication.additionalEmailAddresses = this.parseAdditionalEmailEntry();

      this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.none;

      this.updateAdditionalEmailEditing = '';

    } else {
      this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.promptError;
    }
  }


  didClickCancelAdditionalEmailEntry() {
    this.updateAdditionalEmailMode = UpdateAdditionalEmailMode.none;

    this.updateAdditionalEmailEditing = '';
  }








  didClickEditInvoiceRecipient() {
    let promptUserFlag = true;

    promptUserFlag = promptUserFlag && (this.transactionType ? this.transactionType.activeListingFlag : false);
    promptUserFlag = promptUserFlag && !this.planOrderApplication.planID;

    // if we're prompting the user
    if (promptUserFlag) {
      this.updateInvoiceRecipientMode = UpdateInvoiceRecipientMode.promptUser;

    // if we're going directly
    } else {
      this.didClickEditClosingInfo();
    }
  }



  didClickEditRoute(route: string) {
    this.confirmationAutoSendMode = ConfirmationAutoSendMode.skip;

    const url = this.router.routerState.snapshot.url;

    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'review');

    const urlSegment = new UrlSegment(route, {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


  didClickEmailAddress(email: string) {
    const subject = 'ORHP Warranty ' + this.formattedPlanID + ' | ' + this.property.address;

    const url = 'mailto:' + encodeURIComponent(email) +
      '?subject=' + encodeURIComponent(subject);

    window.open(url, '_self');
  }



  didClickEditTransactionType() {
    this.didClickEditRoute('transaction-type');
  }

  didClickEditPropertyType() {
    this.didClickEditRoute('property-type');
  }

  didClickEditRole() {
    this.didClickEditRoute('role');
  }

  didClickEditInitiatingAgent() {
    this.didClickEditRoute('initiating-agent');
  }

  didClickEditProduct() {
    this.didClickEditRoute('product');
  }

  didClickEditOptionalCoverage() {
    this.didClickEditRoute('optional-coverage');
  }

  didClickEditPropertyAddress() {
    this.didClickEditRoute('property-address');
  }

  didClickEditClosingInfo() {
    this.didClickEditRoute('closing-info');
  }

  didClickEditCooperatingAgent() {
    this.didClickEditRoute('cooperating-agent');
  }

  didClickEditHomeBuyer() {
    this.didClickEditRoute('home-buyer');
  }

  didClickEditHomeSeller() {
    this.didClickEditRoute('home-seller');
  }

  didClickEditMailingAddress() {
    this.didClickEditRoute('mailing-address');
  }


  // Email Modal Actions
  shouldDisplayEmailConfirmModal(): boolean {
    return (this.sendEmailPageMode !== SendEmailPageMode.none);
  }


  shouldDisplayEmailConfirmQuestion(): boolean {
    return (this.sendEmailPageMode === SendEmailPageMode.promptUser);
  }


  shouldDisplayEmailInProgress(): boolean {
    return (this.sendEmailPageMode === SendEmailPageMode.inProgress);
  }


  shouldDisplayEmailSuccess(): boolean {
    return (this.sendEmailPageMode === SendEmailPageMode.emailSuccess);
  }


  shouldDisplayEmailFailure(): boolean {
    return (this.sendEmailPageMode === SendEmailPageMode.emailFailure);
  }

  shouldDisplayResendCards(): boolean {
    return (this.canResendAnniversaryCard || this.canResendCongratulationsCard );
  }


  didClickEmailSuccessDismiss() {

    if (this.onEmailSendSuccess.length) {
      Observable.forkJoin(this.onEmailSendSuccess).subscribe((results: any[]) => {
        this.sendEmailPageMode = SendEmailPageMode.none;
      });

    } else {
      this.sendEmailPageMode = SendEmailPageMode.none;
    }

  }


  didClickEmailCancel() {
    this.sendEmailPageMode = SendEmailPageMode.none;
  }



  didClickConfirmChanges() {
    const successObservable = Observable.create((subscriber: Subscriber<boolean>) => {
      this.confirmationAutoSendEventEmitter.emit(true);

      this.didClickPlanListButton();

      subscriber.next(true);
      subscriber.complete();
    });

    this.onEmailSendSuccess.push(successObservable);

    this.sendConfirmationEmails(SendEmailRequestMode.userRequested);
  }


  didClickSendEmails() {
    this.emailTitleLanguage = 'Resend Confirmation / Premium Invoice';
    this.emailSuccessLanguage = 'The confirmation and premium invoice (if applicable) have been sent.';
    this.emailFailureLanguage = 'There was a problem sending the premium invoice and order confirmation emails.';

    this.sendEmailPageMode = SendEmailPageMode.promptUser;
  }


  didClickConfirmSendEmails() {
    this.sendConfirmationEmails(SendEmailRequestMode.userRequested);
  }


  sendConfirmationEmails(mode: SendEmailRequestMode) {
    this.sendEmailPageMode = SendEmailPageMode.inProgress;

    const planID = this.planOrderApplication.planID;

    const customer = this.planOrderAuthService.customer;
    const customerID = String(customer.customerID);

    // send the plan confirmations
    this.planConfirmationService.sendPlanConfirmations(planID, customerID)
      .subscribe((resultFlag: boolean) => {

      // if the result was successful
      if (resultFlag) {
        this.confirmationSentFlag = true;

        // if the user requested them
        if (mode === SendEmailRequestMode.userRequested) {
          this.emailSuccessLanguage = 'The confirmation and premium invoice (if applicable) have been sent.';
          this.sendEmailPageMode = SendEmailPageMode.emailSuccess;

        // if they were auto-sent
        } else if (mode === SendEmailRequestMode.autoSend) {
          this.confirmationAutoSendEventEmitter.emit(true);
        }


      // the result was failure
      } else {
        this.emailFailureLanguage = 'There was a problem sending the premium invoice and order confirmation emails.';
        this.sendEmailPageMode = SendEmailPageMode.emailFailure;
      }
    });

  }



  // should the confirmation be auto-sent?
  shouldAutoSendConfirmation(): boolean {
    let autoSendFlag = true;

    // if the confirmation has been sent, we don't want to resend it
    if (this.confirmationSentFlag) {
      autoSendFlag = false;
    }

    // if we're in skip mode, don't auto-send
    if (this.confirmationAutoSendMode === ConfirmationAutoSendMode.skip) {
      autoSendFlag = false;
    }

    // if the page mode is not ConfirmUpdatePlan, don't auto-send
    if (this.pageMode !== PageMode.confirmUpdatePlan) {
      autoSendFlag = false;
    }

    // if the plan is cancelled, don't auto-send
    if (this.planOrderApplication.planCancel) {
      autoSendFlag = false;
    }

    return autoSendFlag;
  }


  // called when auto-sending confirmation emails
  autoSendConfirmation() {
    // called when the emails are sent successfully
    const successObservable = Observable.create((subscriber: Subscriber<boolean>) => {
      // flag the the emails were sent
      this.confirmationAutoSendMode = ConfirmationAutoSendMode.sent;

      // notify interested parties that the confirmation was auto-sent
      this.confirmationAutoSendEventEmitter.emit(true);

      subscriber.next(true);
      subscriber.complete();
    });

    this.onEmailSendSuccess.push(successObservable);

    this.confirmationAutoSendMode = ConfirmationAutoSendMode.inProgress;

    this.sendConfirmationEmails(SendEmailRequestMode.autoSend);
  }



    didClickResendAnniversaryCard() {
      this.emailTitleLanguage = 'Resend Anniversary Card';
      this.sendEmailPageMode = SendEmailPageMode.inProgress;

      this.correspondenceService.resendAnniversaryCard(this.planOrderApplication.planID)
        .subscribe((resultFlag: boolean) => {
          if (resultFlag) {
            this.emailSuccessLanguage = 'The Anniversary E-Card has been sent.';
            this.canResendAnniversaryCard = false;
            this.sendEmailPageMode = SendEmailPageMode.emailSuccess;
          } else {
            this.emailFailureLanguage = 'There was a problem sending the Anniversary E-Card.';
            this.sendEmailPageMode = SendEmailPageMode.emailFailure;
          }
        }
      );
    }


    didClickResendCongratulationsCard() {
      this.emailTitleLanguage = 'Resend Congratulations Card';
      this.sendEmailPageMode = SendEmailPageMode.inProgress;

      this.correspondenceService.resendCongratulationsCard(this.planOrderApplication.planID)
        .subscribe((resultFlag: boolean) => {
          if (resultFlag) {
            this.emailSuccessLanguage = 'The Congratulations E-Card has been sent.';
            this.canResendCongratulationsCard = false;
            this.sendEmailPageMode = SendEmailPageMode.emailSuccess;
          } else {
            this.emailFailureLanguage = 'There was a problem sending the Congratulations E-Card.';
            this.sendEmailPageMode = SendEmailPageMode.emailFailure;
          }
        }
      );
    }

    addEmailConfirmation(message: string): string {
      let confirmMessage = message;

      const homeOwner = this.homeBuyers[0];
      const phones = this.displayPhonesForCustomer(homeOwner);
      const anEmail = phones.find((indexPhone: CustomerPhone) => indexPhone.phoneType.emailFlag);

      if (!!anEmail) {
        confirmMessage += ' Email confirmation has been sent to ' + anEmail.phoneNumber + '.';
      }

      return confirmMessage;
    }


    displayEditPaymentLink(): string {
      let message = 'Click here to correct your credit card';

      if (!!this.productOfficeToolbox && this.productOfficeToolbox.allowPremiumPayLaterFlag) {
        message += '/send an invoice via email';
      }

      return message;
    }


  shouldDisplayTag(): boolean {
    return !!this.productOfficeGroup && this.productOfficeGroup.homeBuyerEmailRequiredFlag;
  }


  displayAutoRenewYesOrNo(): string {
    return this.planOrderApplication.autoRenewFlag ? 'Yes' : 'No';
  }


  getFirstPayment(): PaymentPlanPayment {
    const firstPayment: PaymentPlanPayment = ArrayUtility.arrayFind(
      this.planOrderApplication.paymentPlan.payments,
      (testPayment: PaymentPlanPayment): boolean => {
        return (testPayment.paymentPlanNumber === 1);
      });

    return firstPayment;
  }


  amountToDisplay(amount: number): number {
    const selectedPaymentPlan = this.planOrderApplication.paymentPlan;
    let dollarAmount = amount;

    if (!!selectedPaymentPlan && selectedPaymentPlan.creditCardFlag && selectedPaymentPlan.paymentCount > 1 &&
      !!this.productOfficeToolbox && this.productOfficeToolbox.priceDisplayDurationCode === PremiumDurations.Month ) {
      dollarAmount = amount * this.getFirstPayment().paymentPlanPercent / 100.0;

      dollarAmount = Math.round(dollarAmount * 100.0) / 100.0;
    }

    return dollarAmount;
  }


  displayTotalText(): string {
    return 'Total' + this.displayFeeDuration() + ':';
  }


  displayFeeDuration(): string {
    const selectedPaymentPlan = this.planOrderApplication.paymentPlan;
    let duration = '';

    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag &&
          !!DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode]) {
      duration = ' ' + DurationEnum['Y'];

      if (selectedPaymentPlan && selectedPaymentPlan.creditCardFlag && selectedPaymentPlan.paymentCount > 1 &&
          this.productOfficeToolbox.priceDisplayDurationCode === PremiumDurations.Month ) {
        duration = ' ' + DurationEnum['M'];

      }
    }

    return duration;
  }

}


enum PageMode {
  reviewNewPlan = 100,
  confirmNewPlan = 200,
  confirmUpdatePlan = 300,
  reviewExistingPlan = 400
}


enum SendEmailPageMode {
  none = 100,
  promptUser = 200,
  inProgress = 300,
  emailSuccess = 400,
  emailFailure = 500
}


enum SendEmailRequestMode {
  userRequested = 100,
  autoSend = 200
}

enum ResendDecSheetPageMode {
  none = 100,
  inProgress = 300,
  resendSuccess = 400,
  resendFailure = 500
}

enum UpdateInvoiceRecipientMode {
  none = 100,
  promptUser = 200
}

enum UpdateAdditionalEmailMode {
  none = 100,
  promptUser = 200,
  promptError = 300
}


export enum ConfirmationAutoSendMode {
  none = 100,
  skip = 200,
  inProgress = 300,
  sent = 400
}

