import {Component, OnInit} from '@angular/core';
import {HubspotService} from '@orhp/phx-common-ui-module';
import {AuthenticationService} from '../../login/service/authentication.service';
import {Customer} from '@orhp/phx-customer-ui-module';
import {BannerItem} from '@orhp/phx-common-ui-module';
import {BannersCacheService} from './banners-cache.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  providers: [BannersCacheService]
})
export class BannersComponent implements OnInit {

  banner: BannerItem;

  pageFragmentId = 'workbench-sec-1';
  nonAECampaign = 'GENERIC-ALL-NON-AE';
  hubDbEndpoint = 'https://api.hubapi.com/hubdb/api/v1/tables/671895/rows?portalId=2596967';
  errorMessage;

  constructor(private hubspotService: HubspotService,
              private authenticationService: AuthenticationService,
              private bannersCacheService: BannersCacheService) { }

  ngOnInit() {
   this.getTheBanner();
  }

  getBannerByState() {
    this.hubspotService.getHubDbContentByState(this.hubDbEndpoint, this.pageFragmentId, this.stateCode)
      .subscribe((res) => {
          if (res.total !== 0) {
              this.bannersCacheService.cacheTheBanner(res);
              this.setBanner(res);
          } else {
            this.getGenericBanner();
          }
        },
        error => {this.errorMessage = error; } );
   }

  getGenericBanner() {
    this.hubspotService.getHubDbContent(this.hubDbEndpoint, this.pageFragmentId, this.nonAECampaign)
      .subscribe((res) => {
          this.bannersCacheService.cacheTheBanner(res);
          this.setBanner(res);
        },
        error => {this.errorMessage = error; } );
  }

  getTheBanner() {
    //  not in cache make an http call to hubDb
    if (!this.bannersCacheService.isCached()) {
          if (!!this.stateCode && this.stateCode.length) {
            this.getBannerByState();
          }
      } else {
        this.banner = this.bannersCacheService.getCachedBanner();
        this.setBanner(this.banner);
    }
  }

  setBanner(bannerItem) {
    if (bannerItem.objects[0].values[4] === undefined) {
      this.setTextBanner(bannerItem);
    } else {
      this.banner = {
        imageURL: bannerItem.objects[0].values[4].url,
        image: bannerItem.objects[0].values[12],
        imageAltText: bannerItem.objects[0].values[7],
        html: null,
        total: bannerItem.total
      };
    }
  }

  setTextBanner(bannerItem) {
    this.banner = {
      imageURL: null,
      image: null,
      imageAltText: null,
      html: bannerItem.objects[0].values[3],
      total: bannerItem.total
    };
}

  get orhpFlag(): boolean {
    let orhpFlag = false;

    const customer = <Customer>this.authenticationService.customer;

    if (customer && customer.employer) {
      if (customer.employer.employerType.orhpFlag) {
        orhpFlag = true;
      }
    }

    return orhpFlag;
  }

  get stateCode(): string {
    let stateCode = null;

    const customer = <Customer>this.authenticationService.customer;

    if (customer) {
      stateCode = customer.employer.physicalAddress.stateCode;
    }
    return stateCode;
  }
}
