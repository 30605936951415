


import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderService} from '../_services/plan-order.service';
import {Injectable} from '@angular/core';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {OfficeAgentLookupRelationshipType} from '../../office-agent-lookup/_src/lookup-relationship-type';
import {OfficeAgentLookupService} from '../../office-agent-lookup/_services/lookup.service';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';

@Injectable()

export class PlanOrderClosingInfoResolver implements Resolve<any> {

  constructor(private planOrderService: PlanOrderService,
              private urlSerializer: UrlSerializer,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private router: Router) {
  }

  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const returnObservable = Observable.create((subscriber: Subscriber<any>) => {

      const application = this.planOrderService.planOrderApplication;

      let redirectTo: string = null;

      // new plans
      if (!application.planID) {
        if (!application.property.address) {
          redirectTo = 'property-address';
        }
      }

      // if we're not allowing
      if (redirectTo) {
        const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, 'closing-info');

        const urlSegment = new UrlSegment(redirectTo, {});
        RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

        this.router.navigateByUrl(urlTree);

        subscriber.next(null);

        // validate the closing relationship
      } else {
        const relationship = OfficeAgentLookupRelationshipType.typeCodeEscrowOfficer;

        this.officeAgentLookupPersistenceService.validateLookupType(relationship);

        this.officeAgentLookupPersistenceService.lookupTypeCode = relationship;

        const params = {};
        params['lookupTypeCode'] = relationship;

        // pass the office and agent
        if (application.closingOffice) {
          this.officeAgentLookupPersistenceService.setEmployerIfDifferent(application.closingOffice);

          params['employer'] = application.closingOffice;
        }

        if (application.closingAgent) {
          this.officeAgentLookupPersistenceService.setCustomerIfDifferent(application.closingAgent);

          params['customer'] = application.closingAgent;
        }

        subscriber.next(params);
      }

      subscriber.complete();
    });

    return returnObservable;
  }

}
