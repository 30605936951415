import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from "./login.component";
import {HttpModule} from "@angular/http";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {WebStorageModule} from '@orhp/phx-localstorage-ui-module';
import {AuthGuard} from "./service/auth.guard";
import {AuthenticationService} from "./service/authentication.service";
import {UserService} from "./service/user.service";
import {WelcomeComponent} from './welcome.component';
import {LogoutComponent} from "./logout.component";
import {LoginPassthruComponent} from './login-passthru.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    WebStorageModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    UserService
  ],
  declarations: [
    LoginComponent,
    LoginPassthruComponent,
    WelcomeComponent,
    LogoutComponent
  ],
  exports: [
    LoginComponent,
    WelcomeComponent
  ]

})
export class LoginModule { }
