
import {Routes} from '@angular/router';
import {PlanOrderPropertyTypeComponent} from './property-type/property-type.component';
import {PlanOrderPropertyAddressComponent} from './property-address/property-address.component';
import {PlanOrderProductComponent} from './product/product.component';
import {PlanOrderOptionalCoverageComponent} from './optional-coverage/optional-coverage.component';
import {PlanOrderTransactionTypeComponent} from './transaction-type/transaction-type.component';
import {PlanOrderRoleComponent} from './role/role.component';
import {PlanOrderInitiatingAgentComponent} from './initiating-agent/initiating-agent.component';
import {PlanOrderHomeBuyerComponent} from './home-buyer/home-buyer.component';
import {PlanOrderHomeSellerComponent} from './home-seller/home-seller.component';
import {PlanOrderCooperatingAgentComponent} from './cooperating-agent/cooperating-agent.component';
import {PlanOrderClosingInfoComponent} from './closing-info/closing-info.component';
import {PlanOrderReviewComponent} from './review/review.component';
import {OfficeAgentLookupRoutes} from '../office-agent-lookup/office-agent-lookup.routes';
import {PlanOrderSaveInterstitialComponent} from './save-interstitial/save-interstitial.component';
import {PlanOrderConfirmationComponent} from './confirmation/confirmation.component';
import {PlanOrderRootResolver} from './_services/plan-order-root.resolver';
import {PlanOrderWildcardStepResolver} from './_services/plan-order-wildcard-step.resolver';
import {PlanOrderPropertyTypeResolver} from './property-type/property-type.resolver';
import {PlanOrderRoleResolver} from './role/role.resolver';
import {PlanOrderInitiatingAgentResolver} from './initiating-agent/initiating-agent.resolver';
import {PlanOrderProductResolver} from './product/product.resolver';
import {PlanOrderOptionalCoverageResolver} from './optional-coverage/optional-coverage.resolver';
import {PlanOrderPropertyAddressResolver} from './property-address/property-address.resolver';
import {PlanOrderCooperatingAgentResolver} from './cooperating-agent/cooperating-agent.resolver';
import {PlanOrderClosingInfoResolver} from './closing-info/closing-info.resolver';
import {PlanOrderHomeBuyerResolver} from './home-buyer/home-buyer.resolver';
import {PlanOrderHomeSellerResolver} from './home-seller/home-seller.resolver';
import {PlanOrderTransactionTypeResolver} from './transaction-type/transaction-type.resolver';
import {PlanOrderReviewResolver} from './review/review.resolver';
import {PlanOrderAuthGuard} from './_services/plan-order-auth.guard';
import {PlanOrderCancelComponent} from './cancel/cancel.component';
import {PlanOrderMailingAddressComponent} from './mailing-address/mailing-address.component';
import {PlanOrderReviewDeactivateGuard} from "./review/review-deactivate.guard";
import {PlanOrderPaymentComponent} from './payment/payment.component';
import {PlanOrderNoAuthGuard} from './_services/plan-order-no-auth.guard';
import {PlanOrderCustomUrlGuard} from './_services/plan-order-custom-url-guard';


export const PlanOrderRoutes: Routes = [

  {
    path: ':planID',
    canActivate: [PlanOrderCustomUrlGuard],
    resolve: [
      PlanOrderRootResolver
    ],
    children: [
      {
        path: 'transaction-type',
        component: PlanOrderTransactionTypeComponent,
        canActivate: [PlanOrderNoAuthGuard],
        resolve: [PlanOrderTransactionTypeResolver]
      },
      {
        path: 'property-type',
        resolve: [PlanOrderPropertyTypeResolver],
        canActivate: [PlanOrderNoAuthGuard],
        children: [
          {
            path: '',
            component: PlanOrderPropertyTypeComponent
          },
          {
            path: ':code',
            component: PlanOrderPropertyTypeComponent
          }
        ]
      },
      {
        path: 'role',
        component: PlanOrderRoleComponent,
        canActivate: [PlanOrderNoAuthGuard],
        resolve: [PlanOrderRoleResolver]
      },
      {
        path: 'initiating-agent',
        component: PlanOrderInitiatingAgentComponent,
        resolve: [PlanOrderInitiatingAgentResolver],
        canActivate: [PlanOrderNoAuthGuard],
        children: OfficeAgentLookupRoutes
      },
      {
        path: 'product',
        component: PlanOrderProductComponent,
        canActivate: [PlanOrderNoAuthGuard],
        resolve: [PlanOrderProductResolver]
      },
      {
        path: 'optional-coverage',
        component: PlanOrderOptionalCoverageComponent,
        canActivate: [PlanOrderNoAuthGuard],
        resolve: [PlanOrderOptionalCoverageResolver]
      },
      {
        path: 'property-address',
        component: PlanOrderPropertyAddressComponent,
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderPropertyAddressResolver]
      },
      {
        path: 'cooperating-agent',
        component: PlanOrderCooperatingAgentComponent,
        resolve: [PlanOrderCooperatingAgentResolver],
        canActivate: [PlanOrderAuthGuard],
        children: OfficeAgentLookupRoutes
      },
      {
        path: 'closing-info',
        component: PlanOrderClosingInfoComponent,
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderClosingInfoResolver],
        children: OfficeAgentLookupRoutes
      },
      {
        path: 'home-buyer',
        component: PlanOrderHomeBuyerComponent,
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderHomeBuyerResolver]
      },
      {
        path: 'home-seller/:intro-mode',
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderHomeSellerResolver],
        component: PlanOrderHomeSellerComponent
      },
      {
        path: 'home-seller',
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderHomeSellerResolver],
        component: PlanOrderHomeSellerComponent
      },
      {
        path: 'payment',
        canActivate: [PlanOrderAuthGuard],
        component: PlanOrderPaymentComponent
      },
      {
        path: 'review/:confirm-message',
        component: PlanOrderReviewComponent,
        canActivate: [PlanOrderAuthGuard],
        canDeactivate: [PlanOrderReviewDeactivateGuard],
        resolve: [PlanOrderReviewResolver]
      },
      {
        path: 'review',
        component: PlanOrderReviewComponent,
        canActivate: [PlanOrderAuthGuard],
        resolve: [PlanOrderReviewResolver]
      },
      {
        path: 'save',
        canActivate: [PlanOrderAuthGuard],
        component: PlanOrderSaveInterstitialComponent
      },
      {
        path: 'confirmation',
        canActivate: [PlanOrderAuthGuard],
        component: PlanOrderConfirmationComponent
      },
      {
        path: 'cancel',
        canActivate: [PlanOrderAuthGuard],
        component: PlanOrderCancelComponent
      },
      {
        path: 'mailing-address',
        canActivate: [PlanOrderAuthGuard],
        component: PlanOrderMailingAddressComponent
      },
      {
        path: '**',
        resolve: [
          PlanOrderWildcardStepResolver
        ],
        children: []
      }
    ]
  },
  {
      path: '',
      redirectTo: 'new-application',
      pathMatch: 'prefix'
    }

];
