import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormsModule} from '@angular/forms';
import {ResetAccountComponent} from "./reset-account/reset-account.component";
import {AccountService} from "./_services/account.service";
import {ProfileComponent} from "./profile/profile.component";
import {SignUpComponent} from "./sign-up/sign-up.component";
import {OfficeAgentLookupModule} from "../_modules/office-agent-lookup/office-agent-lookup.module";
import {ProfileService} from "./_services/profile.service";
import {ValidateEmailComponent} from "./validate-email/validate-email.component";
import {AccountValidationService} from "./_services/account-validation.service";
import {ProfileValidationService} from "./_services/profile-validation.service";
import {UserCorrespondencePreferenceComponent} from "./_modules/user-correspondence-preference/user-correspondence-preference.component";
import {UserEmployerComponent} from "./_modules/user-employer/user-employer.component";
import {UserCustomerComponent} from "./_modules/user-customer/user-customer.component";
import {UserGuestComponent} from "./_modules/user-guest/user-guest.component";
import {ResendValidationComponent} from "./resend-validation/resend-validation.component";
import {ProfileHelperService} from './_services/profile-helper.service';
import {UserJobroleComponent} from './_modules/user-jobrole/user-jobrole.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        OfficeAgentLookupModule
    ],
    declarations: [
        ResetAccountComponent,
        ProfileComponent,
        SignUpComponent,
        ValidateEmailComponent,
        UserCorrespondencePreferenceComponent,
        UserEmployerComponent,
        UserCustomerComponent,
        UserGuestComponent,
        UserJobroleComponent,
        ResendValidationComponent
    ],
    providers: [
        AccountService,
        ProfileService,
        AccountValidationService,
        ProfileValidationService,
        ProfileHelperService
    ]
})
export class UserModule {

}
