
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentErrorCollection} from '../_src/office-agent-error-collection';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {IOfficeResolverInterface} from '../_src/office-resolver-interface';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupPersistenceService} from '../_services/persistence.service';
import {IAgentResolverInterface} from '../_src/agent-resolver-interface';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {JobRole} from '@orhp/phx-customer-ui-module';

@Component({
  selector: 'app-agent-entry',
  templateUrl: './agent-entry.component.html',
  styleUrls: ['../office-agent-lookup.scss']
})


export class AgentEntryComponent implements OnInit {


  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private customerLookupValueService: CustomerLookupValueService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private officeAgentLookupService: OfficeAgentLookupService) {
  }


  customer: Customer = null;
  employer: Employer = null;


  private _lookupType: OfficeAgentLookupRelationshipType = null;

  get lookupType(): OfficeAgentLookupRelationshipType {
    if (!this._lookupType) {
      this._lookupType = new OfficeAgentLookupRelationshipType(this.officeAgentLookupPersistenceService.lookupTypeCode);
    }

    return this._lookupType;
  }



  get customerValidateResult(): CustomerValidateResult {
    return this.officeAgentLookupService.customerValidateResult;
  }


  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      const employerData = <IOfficeResolverInterface>data;

      this.employer = employerData.employer;

      const customerData = <IAgentResolverInterface>data;

      this.customer = customerData.customer;

      // link the employer to the customer
      this.customer.employer = this.employer;

      // set job role
      let jobRole: JobRole = null;

      if (this.lookupType.initiatingAgentFlag) {
        jobRole = this.customerLookupValueService.jobRoleRealEstateAgent;

      } else if (this.lookupType.cooperatingAgentFlag) {
        jobRole = this.customerLookupValueService.jobRoleRealEstateAgent;

      } else if (this.lookupType.escrowOfficerFlag) {
        jobRole = this.customerLookupValueService.jobRoleClosingAgent;
      }

      this.customer.jobRole = jobRole;
    });

  }


  // unknown escrow?
  unknownEscrowFlag(): boolean {
    let flag = false;

    if (this.lookupType && this.lookupType.escrowOfficerFlag) {
      if (this.customer.unknownEscrowFlag) {
        flag = true;
      }
    }

    return flag;
  }


  // page title
  pageTitle(): string {
    let title = 'New';

    if (this.unknownEscrowFlag()) {
      title = 'Unknown';
    }

    title += ' ' + this.lookupType.agentTerm;

    return title;
  }



  // should the different agent link be displayed?
  shouldDisplayDifferentAgentLink() {
    let flag = true;

    // if the employer is not selected
    if (!this.employer) {
      flag = false;

    // if the employer is selected
    } else {
      // if this is the unknown escrow
      if (this.unknownEscrowFlag()) {
        flag = false;
      }
    }

    return false;
  }


  shouldDisplayNewAgentEntry(): boolean {
    let flag = true;

    if (this.unknownEscrowFlag()) {
      flag = false;
    }

    return flag;
  }



  didClickFindDifferentAgent() {
    this.officeAgentLookupPersistenceService.customer = null;

    const url = this.router.routerState.snapshot.url;
    const urlTree = this.urlSerializer.parse(url);

    const urlSegment = new UrlSegment('search', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


  shouldDisplayGivenNameError(): boolean {
    return ((this.customerValidateResult.customerGivenNameError || '') !== '');
  }


  shouldDisplaySurNameError(): boolean {
    return ((this.customerValidateResult.customerSurNameError || '') !== '');
  }

}
