


import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';

@Injectable()

export class OfficeAgentLookupAgentClearResolver implements Resolve<Customer> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private employerLookupService: EmployerLookupService,
              private customerLookupValueService: CustomerLookupValueService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    const observable: Observable<Customer> = Observable.create((subscriber: Subscriber<Customer>) => {

      this.officeAgentLookupPersistenceService.customer = null;

      subscriber.next(null);
      subscriber.complete();
    });

    return observable;
  }

}
