

import {Injectable} from '@angular/core';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {
  ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment,
  UrlSerializer
} from '@angular/router';
import {PlanOrderService} from './plan-order.service';
import {PlanOrderLookupValueService} from './plan-order-lookup-value.service';
import {LocalStorageService} from '@orhp/phx-common-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {PlanOrderLookupService} from './plan-order-lookup.service';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {PlanOrderDomainFactoryService} from './plan-order-domain-factory.service';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';


@Injectable()

export class PlanOrderRootResolver implements Resolve<boolean> {


  constructor(private modalProgressService: PhxModalProgressService,
              private router: Router,
              private urlSerializer: UrlSerializer,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private planOrderService: PlanOrderService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private planOrderLookupService: PlanOrderLookupService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {

      // present the user with a loading progress indicator
      const progress = new ModalProgress('Loading Plan Quote/Plan Ordering', 'Please wait...', 100);
      this.modalProgressService.progress = progress;

      // initialize the module
      this.planOrderService.initModuleIfNeeded().subscribe((initResultFlag: boolean) => {

        let observable: Observable<boolean> = null;

        const currentApplication = this.planOrderService.planOrderApplication;

        // validate the plan number
        const planIDText = (snapshot.params['planID'] || this.planOrderService.newPlanRoute).toLowerCase();

        let validPlanID = false;
        validPlanID = validPlanID || (planIDText === this.planOrderService.newPlanRoute);
        validPlanID = validPlanID || (planIDText === this.planOrderService.forceNewPlanRoute);
        validPlanID = validPlanID || !isNaN(planIDText);

        // if the plan ID isn't valid
        if (!validPlanID) {
          observable = Observable.create((subscriber: Subscriber<boolean>) => {
            const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, planIDText);

            const urlSegment = new UrlSegment(this.planOrderService.newPlanRoute, {});

            RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

            this.router.navigateByUrl(urlTree);

            subscriber.next(true);
            subscriber.complete();
          });

        // if the plan ID is valid
        } else {
          const planID = (!isNaN(planIDText) ? Number(planIDText) : null);

          // if this is a new plan
          if (!planID) {
            // verify that the saved plan is new
            let forceNewPlanFlag = false;

            forceNewPlanFlag = forceNewPlanFlag || !!currentApplication.planID;
            forceNewPlanFlag = forceNewPlanFlag || (planIDText === this.planOrderService.forceNewPlanRoute);

            if (forceNewPlanFlag) {
              observable = Observable.create((subscriber: Subscriber<boolean>) => {
                this.officeAgentLookupPersistenceService.clearPersistedValues();

                // create and save a new application
                this.planOrderService.planOrderApplication = this.planOrderDomainFactoryService.newApplication();

                this.planOrderService.persistPlanOrderApplication();

                this.planOrderService.backupPlanOrderApplication();

                this.planOrderService.newApplicationEventEmitter.emit(this.planOrderService.planOrderApplication);

                subscriber.next(true);
                subscriber.complete();
              });
            }

          // if this is an existing plan
          } else {
            // verify that the plan numbers match
            if (planID !== currentApplication.planID) {
              observable = Observable.create((subscriber: Subscriber<boolean>) => {
                // create and populate a new application
                const newApplication = this.planOrderDomainFactoryService.newApplication();

                this.planOrderLookupService.fetchPlanByID(newApplication, planID)
                  .subscribe((lookupResult: boolean) => {

                  // if the lookup was successful
                  if (lookupResult) {
                    newApplication.confirmationSentFlag = true;

                    this.planOrderService.planOrderApplication = newApplication;

                    this.planOrderService.persistPlanOrderApplication();

                    this.planOrderService.backupPlanOrderApplication();

                    this.planOrderService.newApplicationEventEmitter.emit(this.planOrderService.planOrderApplication);

                    subscriber.next(true);
                    subscriber.complete();

                  // if the lookup was unsuccessful
                  } else {
                    this.planOrderService.planOrderApplication = null;

                    this.planOrderService.backupPlanOrderApplication();

                    subscriber.next(false);
                    subscriber.complete();
                  }
                });
              });
            }
          }
        }


        // if the observable is empty
        if (!observable) {
          observable = Observable.create((subscriber: Subscriber<boolean>) => {
            subscriber.next(true);
            subscriber.complete();
          });
        }

        // subscribe to the observable
        observable.subscribe((resultFlag: boolean) => {
          this.modalProgressService.progress = null;

          // if the result was successful
          if (resultFlag) {
            resolve(true);

          // if the result was unsuccessful
          } else {
            const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, planIDText);

            const urlSegment = new UrlSegment(this.planOrderService.newPlanRoute, {});

            RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

            this.router.navigateByUrl(urlTree);

            resolve(false);
          }

        });

      });

    });

    return promise;
  }
}
