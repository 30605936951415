


import {Component, Input, OnInit} from '@angular/core';
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer, UrlTree} from '@angular/router';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {IOfficeResolverInterface} from '../_src/office-resolver-interface';
import {IAgentResolverInterface} from '../_src/agent-resolver-interface';
import {OfficeAgentLookupPersistenceService} from '../_services/persistence.service';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';

@Component({
  selector: 'app-office-agent-selected',
  templateUrl: './office-agent-selected.component.html',
  styleUrls: [
    '../office-agent-lookup.scss',
    './office-agent-selected.component.scss'
  ]
})


export class OfficeAgentSelectedComponent implements OnInit {

  employer: Employer = null;
  customer: Customer = null;

  private _lookupRelationshipType: OfficeAgentLookupRelationshipType = null;

  get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
    if (!this._lookupRelationshipType) {
      this._lookupRelationshipType = new OfficeAgentLookupRelationshipType(
        this.officeAgentLookupPersistenceService.lookupTypeCode);
    }

    return this._lookupRelationshipType;
  }


  get allowDifferentOfficeSelectionFlag(): boolean {
    return this.officeAgentLookupService.allowDifferentOfficeSelectionFlag;
  }


  get allowDifferentAgentSelectionFlag(): boolean {
    return this.officeAgentLookupService.allowDifferentAgentSelectionFlag;
  }



  constructor(private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private urlSerializer: UrlSerializer,
              private router: Router,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      // pull out employer data
      const employerData = <IOfficeResolverInterface>data;

      this.employer = employerData.employer;

      // pull out customer data
      const customerData = <IAgentResolverInterface>data;

      this.customer = customerData.customer;
    });
  }


  customerUnknownEscrowFlag(): boolean {
    let unknownFlag = false;

    // if the customer is unknown
    const escrowFlag = (this.lookupRelationshipType ? this.lookupRelationshipType.escrowOfficerFlag : false);
    const unknownEscrowFlag = this.customer.unknownEscrowFlag;

    if (escrowFlag && unknownEscrowFlag) {
      unknownFlag = true;
    }

    return unknownFlag;
  }



  shouldDisplayEmployer(): boolean {
    return (this.employer && !!this.employer.employerID);
  }


  shouldDisplayCustomer(): boolean {
    let displayFlag = false;

    // if the customer is defined
    if (this.customer) {
      // if the customer is existing
      if (this.customer.customerID) {
        displayFlag = true;
      }

      // if the customer is unknown
      if (this.customerUnknownEscrowFlag()) {
        displayFlag = true;
      }
    }

    return displayFlag;
  }


  shouldDisplayCustomerName(): boolean {
    let displayFlag = false;

    // if this is an existing customer
    if (this.customer.customerID) {
      displayFlag = true;
    }

    return displayFlag;
  }


  shouldDisplayUnknownCustomerName(): boolean {
    let displayFlag = false;

    if (this.customerUnknownEscrowFlag()) {
      displayFlag = true;
    }

    return displayFlag;
  }



  didClickDifferentOffice() {
    const url = this.router.routerState.snapshot.url;
    const employerGUID = this.route.snapshot.params['employerGUID'];

    // look for the employerGUID
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, employerGUID);

    // remove the employerGUID
    RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

    // add the search
    const urlSegment = new UrlSegment('search', {});
    RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

    // clear the saved office
    this.officeAgentLookupPersistenceService.employer = null;

    this.router.navigateByUrl(urlTree);
  }


  didClickDifferentAgent() {
    let urlTree: UrlTree = null;

    const url = this.router.routerState.snapshot.url;
    const customerGUID = this.route.snapshot.params['customerGUID'];

    // look for the customerGUID
    if (customerGUID) {
      urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, customerGUID);

      // remove the customerGUID
      RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

      // add the search
      const urlSegment = new UrlSegment('search', {});
      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

    } else {
      urlTree = this.urlSerializer.parse(url);

      const urlSegment = new UrlSegment('search', {});

      RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);
    }

    // clear the saved agent
    this.officeAgentLookupPersistenceService.customer = null;

    this.router.navigateByUrl(urlTree);
  }

}
