import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProfileService, ProfileUpdateResult} from '../_services/profile.service';
import {OfficeAgentLookupService} from '../../_modules/office-agent-lookup/_services/lookup.service';
import {OfficeAgentLookupPersistenceService} from '../../_modules/office-agent-lookup/_services/persistence.service';
import {
  Customer,
  CustomerDomainFactoryService,
  CustomerLookupValueService,
  CustomerValidateResult,
  Employer,
  EmployerDomainFactoryService,
  EmployerValidateResult,
  JobRole
} from '@orhp/phx-customer-ui-module';
import {ProfileValidationResult, ProfileValidationService} from '../_services/profile-validation.service';
import {ArrayUtility, ModalProgress, PhxModalProgressService, PhxSerializer, StringUtility} from '@orhp/phx-common-ui-module';
import {Subscription} from 'rxjs/Rx';
import {ProductOffice, ProductOfficeGroupLookupParams, ProductOfficeGroupLookupService} from '@orhp/phx-product-ui-module';
import {AuthenticationService} from '../../login';
import {OfficeAgentLookupCacheService, OfficeLookupType} from '../../_modules/office-agent-lookup/_services/lookup-cache.service';


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html'
})
export class SignUpComponent implements OnInit, OnDestroy {
    currentGuest: any = {password: "", password2: "", email: "", email2: ""};
    currentEmployer: Employer;
    currentCustomer: Customer;
    correspondencesPreferences: any = [];

    employerErrorMessage: String = "";

    profileValidationResult: ProfileValidationResult = new ProfileValidationResult();

    dataLoaded: boolean = false;
    updateInProgress: boolean = false;
    signUpComplete: boolean = false;
    isError: boolean = false;

    get productOfficeID(): number {
      return PhxSerializer.toInteger(this.currentGuest.productOfficeID);
    }

    get employerValidateResult(): EmployerValidateResult {
        return this.officeAgentLookupService.employerValidateResult;
    }

    get customerValidateResult(): CustomerValidateResult {
        return this.officeAgentLookupService.customerValidateResult;
    }

    get employer(): Employer {
        if (this.officeAgentLookupPersistenceService.employer !== null) {
            this.employerErrorMessage = '';
            return this.officeAgentLookupPersistenceService.employer;
        } else {
            return this.currentEmployer;
        }
    }



    get productOfficeJobRole(): JobRole {
      const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);
      const forceJobRoleCode = (!!productOfficeToolbox ? productOfficeToolbox.forceCustomerJobRole : null) || '';

      let jobRole: JobRole = null;

      if (forceJobRoleCode !== '') {
        jobRole = ArrayUtility.arrayFind(
          this.customerLookupValueService.jobRoles,
          (testJobRole: JobRole): boolean => {
            return (testJobRole.jobRole.toLowerCase() === forceJobRoleCode.toLowerCase());
          });
      }

      return jobRole;
    }


    get guestEmail(): string {
      let guestEmail: string = null;

      // if there's a current guest
      if (this.currentGuest) {
        // test if the email is set
        const email1 = this.currentGuest.email || '';
        const email2 = this.currentGuest.email2 || '';

        let emailSetFlag = true;

        // are the emails valid?
        emailSetFlag = emailSetFlag && StringUtility.emailValidate(email1);
        emailSetFlag = emailSetFlag && StringUtility.emailValidate(email2);

        // do the emails match?
        emailSetFlag = emailSetFlag && (email1.toLowerCase() === email2.toLowerCase());

        // if the email's been set
        if (emailSetFlag) {
          guestEmail = this.currentGuest.email;
        }
      }

      return guestEmail;
    }


    get correspondencePrefsEmployer(): Employer {
      let employer: Employer = null;

      if (this.productOfficeLookupFlag && !!this.guestEmail) {
        employer = this.employer;
      }

      return employer;
    }


  jobRoleSelectedFlag = false;
    employerSelectSubscription: Subscription = null;


    get productOffice(): ProductOffice {
      return this.profileService.productOffice;
    }

    set productOffice(productOffice: ProductOffice) {
      this.profileService.productOffice = productOffice;
    }


    productOfficeLookupFlag = false;
    productOfficeLookupInProcessFlag = false;

    employerSelectedFlag = false;

    employerEmittedFlag = false;

    // defaultJobRole = this.customerLookupValueService.jobRoleRealEstateAgent;


    constructor(private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
                private officeAgentLookupService: OfficeAgentLookupService,
                private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
                private profileService: ProfileService,
                private router: Router,
                private productOfficeGroupLookupService: ProductOfficeGroupLookupService,
                private customerDomainFactoryService: CustomerDomainFactoryService,
                private employerDomainFactoryService: EmployerDomainFactoryService,
                private profileValidationService: ProfileValidationService,
                private modalProgressService: PhxModalProgressService,
                private authenticationService: AuthenticationService,
                private customerLookupValueService: CustomerLookupValueService) {
        console.log('in profile component');
    }

    ngOnInit() {
        this.officeAgentLookupService.clearAllData();
        this.officeAgentLookupPersistenceService.clearPersistedValues();
        this.officeAgentLookupCacheService.clearCache();

        this.officeAgentLookupCacheService.lastOfficeLookupType = OfficeLookupType.officePhone;

        this.currentEmployer = this.employerDomainFactoryService.newEmployer({});
        this.currentCustomer = this.customerDomainFactoryService.newCustomer({});
        // this.currentCustomer.jobRole = this.defaultJobRole;

        this.productOffice = this.authenticationService.productOffice;

        if (!!this.productOffice) {
          this.productOfficeLookupFlag = true;
        }

        this.officeAgentLookupService.shouldDisplayEmployerSearch = true;
        this.profileService.loadCorrespondences().subscribe(
            (r: any) => {
                this.correspondencesPreferences = r;
                this.dataLoaded = true;
            }
        );

        // register for employer updates if there's not a product office set
        if (!this.productOffice) {
          this.employerSelectSubscription = this.officeAgentLookupPersistenceService.employerEventEmitter
            .subscribe((employer: Employer) => {
              if (this.employerEmittedFlag) {
                this.employerSelectedFlag = !!employer;

                this.fetchEmployerProductOffice();

              } else {
                this.employerEmittedFlag = true;
              }
            });

        } else {
          if (this.productOfficeJobRole) {
            this.currentCustomer.jobRole = this.productOfficeJobRole;
            this.jobRoleSelectedFlag = true;
          }
        }
    }

    ngOnDestroy() {
      this.profileService.productOffice = null;

      if (this.employerSelectSubscription) {
        this.employerSelectSubscription.unsubscribe();
      }

      this.officeAgentLookupService.clearAllData();
      this.officeAgentLookupPersistenceService.clearPersistedValues();
      this.officeAgentLookupCacheService.clearCache();
      this.officeAgentLookupCacheService.lastOfficeLookupType = null;

      sessionStorage.clear();
      localStorage.clear();
    }



  fetchEmailProductOffice() {
    // pull the email
    const email = this.currentGuest.email || '';

    // if it's a valid email
    if (StringUtility.emailValidate(email)) {
      this.productOfficeLookupInProcessFlag = true;

      // set that we haven't performed the product/office lookup
      this.productOfficeLookupFlag = false;

      // pull the product office groups that the email matches
      const params = new ProductOfficeGroupLookupParams();
      params.email = email;
      params.jsonToolboxFlag = true;

      this.productOfficeGroupLookupService.fetchProductOfficeGroups(params)
        .subscribe((productOfficeGroups: ProductOffice[]) => {
          this.productOfficeLookupInProcessFlag = false;

          this.handleFetchedProductOfficeGroups(productOfficeGroups);
        });
    }
  }



    fetchEmployerProductOffice() {
      // set that we haven't performed the product/office lookup
      this.productOfficeLookupFlag = false;

      // pull the employerID
      const employerID = this.employer.employerID;

      // if it's an existing employer
      if (!!employerID) {
        this.productOfficeLookupInProcessFlag = true;

        // pull the product office groups that the employer is in
        const params = new ProductOfficeGroupLookupParams();
        params.employerID = this.employer.employerID;
        params.jsonToolboxFlag = true;

        this.productOfficeGroupLookupService.fetchProductOfficeGroups(params)
          .subscribe((productOfficeGroups: ProductOffice[]) => {
            this.productOfficeLookupInProcessFlag = false;

            this.handleFetchedProductOfficeGroups(productOfficeGroups);
          });

      // for a new employer, always set that the lookup is done
      } else {
        this.productOfficeLookupFlag = true;
      }
    }


    handleFetchedProductOfficeGroups(productOfficeGroups: ProductOffice[]) {
      // if a group was returned
      if (!!(productOfficeGroups || []).length) {
        this.productOffice = productOfficeGroups[0];

        // if a forced job role was specified
        if (!!this.productOfficeJobRole) {
          this.currentCustomer.jobRole = this.productOfficeJobRole;
          this.jobRoleSelectedFlag = true;
        }
      }

      // set the lookup was done
      this.productOfficeLookupFlag = true;
    }




  customOfficeDescription(): string {
    const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);

    return (!!productOfficeToolbox ? productOfficeToolbox.customOfficeDescription : null);
  }




  shouldDisplayEcardPreferences(): boolean {
    // default that we're showing the prefs if the employer is set
    let flag = !!this.employer;

    // has product/office been set?
    const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);

    if (!!productOfficeToolbox && !productOfficeToolbox.allowEcardPreferenceSelectionFlag) {
      flag = false;
    }

    return flag;
  }




    didClickSave() {
        this.updateInProgress = true;
        this.modalProgressService.progress = new ModalProgress('Creating your account', 'Please wait...', 100);

        this.profileValidationService.validate(
            this.currentGuest, this.currentCustomer, this.employer
        ).subscribe(
            (v: ProfileValidationResult) => {
                this.profileValidationResult = v;

                if (v.isValid) {
                    if (!this.shouldDisplayJobRoleError()) {

                        this.currentGuest['type'] = 'R';

                        this.currentCustomer.employer = this.employer;
                        this.currentCustomer.email.phoneNumber = this.currentGuest.email;

                        this.profileService.createProfile(
                            this.currentGuest,
                            this.currentCustomer,
                            this.employer,
                            this.correspondencesPreferences
                        ).subscribe(
                            (r: ProfileUpdateResult) => {
                                this.modalProgressService.progress = null;
                                this.updateInProgress = false;
                                this.signUpComplete = true;
                                this.modalProgressService.progress = null;
                                this.router.navigate(['/account/validate/show']);
                            }
                        );
                    } else {
                        this.updateInProgress = false;
                        this.modalProgressService.progress = null;
                    }
                } else {
                    this.employerErrorMessage = v.employerErrorMessage;
                    this.updateInProgress = false;
                    this.modalProgressService.progress = null;
                    window.scrollTo(0, 0);
                }
            },
            (error: Response) => {
                this.isError = true;
            }
        );
    }


    didChooseJobRole(jobRole: JobRole) {
      if (!!jobRole) {
        this.jobRoleSelectedFlag = true;
      }
    }




    shouldDisplayJobRoleError(): boolean {
      return !this.jobRoleSelectedFlag;
    }


    shouldDisplayValidationError(): boolean {
        return (
            (this.customerValidateResult.errorFlag ||
            this.employerValidateResult.errorFlag ||
            !this.profileValidationResult.isValid) && !this.signUpComplete
        );
    }

    didClickCancel() {
        this.router.navigate(['/home']);
    }


    didChangeGuestEmail(email: string) {
      this.fetchEmailProductOffice();
    }

}
