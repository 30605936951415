import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {HomeComponent} from "./home.component";
import {MyWarrantiesComponent} from './my-warranties/my-warranties.component';
import {MyWarrantiesGuard} from './my-warranties/my-warranties.guard';
import {MyWarrantiesService} from './my-warranties/my-warranties.service';
import {FormsModule} from '@angular/forms';
import {AEDetailComponent} from './ae-detail/ae-detail.component';
import {PhxCommonModule} from '@orhp/phx-common-ui-module';
import {HomeService} from "./home.service";
import {BannersComponent} from './banners/banners.component';
import {PageSelectorComponent} from './my-warranties/page-selector.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PhxCommonModule
  ],
  declarations: [
    HomeComponent,
    MyWarrantiesComponent,
    PageSelectorComponent,
    AEDetailComponent,
    BannersComponent
  ],
  exports: [
    BannersComponent
  ],
  providers: [
    MyWarrantiesGuard,
    MyWarrantiesService,
    HomeService
  ]
})
export class HomeModule { }
