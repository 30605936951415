
import {Injectable} from "@angular/core";

@Injectable()
export class AccountValidationService {
    constructor() {}

    validateEmail(email):AccountValidationResult {
        const result = new AccountValidationResult();
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        result.isValid = re.test(email);
        if(!result.isValid){
            result.message = "Invalid Email address.";
        } else {
            result.isValid = true;
        }

        return result;
    }

    validatePassword(password):AccountValidationResult{
        const result = new AccountValidationResult();
        if(password.length < 5) {
            result.isValid = false;
            result.message = "Passwords must be at least 5 characters.";
        } else {
            result.isValid = true;
        }

        return result;
    }
}

export class AccountValidationResult {
    isValid: boolean = false;
    message: String = "";
}
