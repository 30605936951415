
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderService} from './plan-order.service';
import {Injectable} from '@angular/core';
import {RoutingUtility} from '@orhp/phx-common-ui-module';


@Injectable()

export class PlanOrderWildcardStepResolver implements Resolve<boolean> {

  constructor(private router: Router,
              private urlSerializer: UrlSerializer,
              private planOrderService: PlanOrderService) {
  }

  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      // what's the plan # we're loading
      const planIDText = (snapshot.params['planID'] || this.planOrderService.newPlanRoute).toLowerCase();
      const validPlanFlag = (!isNaN(planIDText) || (planIDText === this.planOrderService.newPlanRoute.toLowerCase()));
      const newPlanFlag = (planIDText === this.planOrderService.newPlanRoute) || (isNaN(planIDText));

      const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, planIDText);

      let redirectTo: string = null;

      // if the plan number isn't valid
      if (!validPlanFlag) {
        RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

        this.router.navigateByUrl(urlTree);

        resolve(false);

        // if there are no children, we need to redirect somewhere
      } else {
        if (snapshot.children.length === 0) {
          // if the plan # is new, redirect to transaction type
          if (newPlanFlag) {
            redirectTo = 'transaction-type';

            // existing plans, redirect to review
          } else {
            redirectTo = 'review';
          }
        }

        // if we need to redirect somewhere
        if (redirectTo) {
          const newSegment = new UrlSegment(redirectTo, {});

          RoutingUtility.appendUrlSegmentToTree(urlTree, newSegment);

          this.router.navigateByUrl(urlTree);

          resolve(false);

          // if we're allowing the user through
        } else {
          resolve(true);
        }
      }


    });

    return promise;
  }

}

