import {Injectable} from '@angular/core';
import {ActivityResult, PhxSystemService, PhxUserLoginTokenService, UserLoginToken} from '@orhp/phx-common-ui-module';
import {Router} from '@angular/router';

@Injectable()
export class LegacyToolboxService {

  constructor(private userLoginTokenService: PhxUserLoginTokenService,
              private systemService: PhxSystemService,
              private router: Router
  ) { }



  redirectToLegacySite(code: any) {
    // retrieve a user login token
    this.userLoginTokenService.generateCurrentUserJumpToken().subscribe((result: ActivityResult<UserLoginToken>) => {
      const token = result.object;

      this.redirectWithLoginToken(code, token.guid);
    });
  }



  legacyUrlFromRedirectCode(code: string): string {
    let gotoUrl: string = null;

    switch (code) {
      case RedirectCode.marketingTools:
        gotoUrl = this.systemService.legacyToolboxUrl + 'tools/ct_portal.cfm';
        break;

      case RedirectCode.aeToolbox:
        gotoUrl = this.systemService.legacyToolboxUrl + 'ae_toolbox/';
        break;
    }

    return gotoUrl;
  }




  redirectWithLoginToken(redirectCode: string, loginToken: string) {
    // pull the URL to redirect to
    let gotoUrl = this.legacyUrlFromRedirectCode(redirectCode);

    // append the login token
    const urlAppender = (gotoUrl.indexOf('?') === -1 ? '?' : ':');

    gotoUrl += urlAppender + 'styxFerryman=' + encodeURIComponent(loginToken);
    gotoUrl += '&phxToolboxUrl=' + encodeURIComponent(this.systemService.currentProtocolAndHost());

    window.location.href = gotoUrl;
  }




}


export enum RedirectCode {
  marketingTools = 'marketing-tools',
  aeToolbox = 'ae-toolbox'
}

