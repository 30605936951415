import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerLookupValueService, Employer, JobRole} from '@orhp/phx-customer-ui-module';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {ProductOffice} from '@orhp/phx-product-ui-module';
import {b} from '@angular/core/src/render3';
import {OfficeAgentLookupPersistenceService} from '../../../_modules/office-agent-lookup/_services/persistence.service';
import {AuthenticationService} from '../../../login';

@Component({
  selector: 'phx-user-jobrole',
  templateUrl: './user-jobrole.component.html',
  styleUrls: [
    './user-jobrole.component.scss'
  ]
})

export class UserJobroleComponent {

  @Input()
  employer: Employer;

  @Input()
  employerSelectedFlag: boolean;

  @Input()
  currentCustomer: any;

  @Input()
  productOffice: ProductOffice;

  @Input()
  productOfficeLookupFlag: boolean;

  @Output()
  onJobRoleSelection = new EventEmitter<JobRole>();




  get selectedJobRole(): JobRole {
    const prevJobRole = (this.currentCustomer.jobRole as JobRole);

    // if there's no selected job role
    if (!this.currentCustomer.jobRole) {
      // are we forcing a job role?
      if (this.productOfficeJobRole) {
        this.currentCustomer.jobRole = this.productOfficeJobRole;
      }
    }

    // if there's no selected job role
    if (!this.currentCustomer.jobRole) {
      const defaultJobRole = this.customerLookupValueService.jobRoleRealEstateAgent;

      // look for the default
      this.currentCustomer.jobRole = ArrayUtility.arrayFind(this.jobRoles, (testJobRole: JobRole): boolean => {
        return (testJobRole.jobRole.toLowerCase() === defaultJobRole.jobRole.toLowerCase());
      });
    }

    // if there's no selected job role
    if (!this.currentCustomer.jobRole) {
      // just pull the first job role
      this.currentCustomer.jobRole = (this.jobRoles.length ? this.jobRoles[0] : null);
    }

    // if the previous and current job roles don't match, emit a selection
    const prevJobRoleCode = (prevJobRole ? prevJobRole.jobRole.toLowerCase() : '');
    const currentJobRole = (this.currentCustomer.jobRole as JobRole);
    const currentJobRoleCode = (currentJobRole ? currentJobRole.jobRole.toLowerCase() : '');

    if (prevJobRoleCode !== currentJobRoleCode) {
      setTimeout(() => {
        this.onJobRoleSelection.emit(currentJobRole);
      });
    }

    return this.currentCustomer.jobRole;
  }

  set selectedJobRole(jobRole: JobRole) {
    this.currentCustomer.jobRole = jobRole;

    this.onJobRoleSelection.emit(jobRole);
  }



  get jobRoles(): JobRole[] {
    const roles = [];

    // if we're forcing a job role
    if (this.productOfficeJobRole) {
      roles.push(this.productOfficeJobRole);

    // otherwise look for the roles
    } else {
      const defaultEmployerTypeCodes = ['R', 'E', 'I'];

      const currentEmployerType = (this.employer ? this.employer.employerType : null);

      this.customerLookupValueService.jobRoles.forEach((indexRole: JobRole) => {
        let includeFlag = false;

        defaultEmployerTypeCodes.forEach((indexCode: string) => {
          if (indexRole.employerType.employerType.toLowerCase() === indexCode.toLowerCase()) {
            includeFlag = true;

          } else if (indexRole.employerType.isEqual(currentEmployerType)) {
            includeFlag = true;
          }
        });

        if (includeFlag) {
          roles.push(indexRole);
        }
      });
    }

    return roles;
  }


  get productOfficeJobRole(): JobRole {
    const productOfficeToolbox = (!!this.productOffice ? this.productOffice.toolbox : null);
    const forceJobRoleCode = (!!productOfficeToolbox ? productOfficeToolbox.forceCustomerJobRole : null) || '';

    let jobRole: JobRole = null;

    if (forceJobRoleCode !== '') {
      jobRole = ArrayUtility.arrayFind(
        this.customerLookupValueService.jobRoles,
        (testJobRole: JobRole): boolean => {
          return (testJobRole.jobRole.toLowerCase() === forceJobRoleCode.toLowerCase());
        });
    }

    return jobRole;
  }


  constructor(private customerLookupValueService: CustomerLookupValueService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private authenticationService: AuthenticationService
  ) { }



  shouldDisplayJobRole(): boolean {
    // display if an employer has been selected
    let flag = this.employerSelectedFlag;

    // if we're logged in with an office
    if (this.authenticationService.employer) {
      // if an employer has been selected
      if (this.officeAgentLookupPersistenceService.employer !== null) {
        // only show the job role if it's a different employer
        flag = (this.authenticationService.employer.employerID !== this.employer.employerID);
      }
    }

    // if there's only one job role available, don't display it
    if (this.jobRoles.length === 1) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayJobRoleError(): boolean {
    let flag = false;

    if (!this.selectedJobRole) {
      flag = true;
    }

    if (!this.shouldDisplayJobRole()) {
      flag = false;
    }

    return flag;
  }



  shouldDisplayJobRoleSelections(): boolean {
    return this.productOfficeLookupFlag;
  }


  displayEmployerNameText(): string {
    let text = (this.employer.employerName || '').trim();

    if (text === '') {
      text = '(NO NAME SPECIFIED)';
    }

    return text;
  }


}
