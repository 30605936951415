
import {Injectable} from '@angular/core';
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {PlanOrderService} from './plan-order.service';
import {Observable} from 'rxjs/Observable';
import {IPlanQuoteResult, PlanQuoteResult} from '../_src/plan-quote-result';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {MessagingFormatter} from '@orhp/phx-common-ui-module';
import {ProductCoverage} from '@orhp/phx-product-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {PlanQuoteRequest} from '../_src/plan-quote-request';
import {Http, RequestOptionsArgs, Response} from '@angular/http';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {IMapItem, MapUtility} from '@orhp/phx-common-ui-module';
import {PlanOption} from '@orhp/phx-plan-ui-module';
import {AuthenticationService} from '../../../login';

@Injectable()

export class PlanQuoteService {

  constructor(private http: Http,
              private systemService: PhxSystemService,
              private planOrderService: PlanOrderService,
              private authenticationService: AuthenticationService
              ) {
  }


  // fetches a plan quote
  fetchPlanQuote(request: PlanQuoteRequest): Observable<PlanQuoteResult> {
    const options = <RequestOptionsArgs>{
      headers: this.systemService.headers()
    };

    options.headers.set('Content-Type', 'text/plain');

    const urlParams = new URLSearchParams();
    urlParams.set('pid', String(request.product.productID));
    urlParams.set('optionID', request.coverageIDList);
    urlParams.set('pt', request.propertyType.propertyType);

    if (request.emailAddress) {
      urlParams.set('emailAddress', request.emailAddress);
    }

    if (!!this.authenticationService.productOffice && !!this.authenticationService.productOffice.homeBuyerEmailRequiredFlag) {
      urlParams.set('dtc', '1');
    } else {
      urlParams.set('dtc', '0');
    }

    if (request.productCoverages) {
      request.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        const optionUnits = <PlanOption>request.optionUnitsByCoverageID[indexCoverage.coverageID];

        if (optionUnits) {
          const paramName = 'num' + String(indexCoverage.coverageID) + 'total';

          const paramValue = String(optionUnits.optionUnits);

          urlParams.set(paramName, paramValue);
        }
      });
    }


    let url = this.systemService.legacyToolboxUrl + 'plan/plan_quote_api.cfm';
    url = StringUtility.urlAppend(url, urlParams.toString());

    const observable: Observable<PlanQuoteResult> = Observable.create((subscriber: Subscriber<PlanQuoteResult>) => {

      this.http.get(url, options).subscribe((response: Response) => {
        const jsonObject = response.json();

        const result = (jsonObject ? this.newPlanQuoteResult(jsonObject) : null);

        subscriber.next(result);
        subscriber.complete();

      }, (error: Response) => {
        console.log('Error generating quote:');
        console.log(error);

        subscriber.next(null);
        subscriber.complete();
      });

    });

    return observable;
  }


  // generates a new plan quote request
  newPlanQuoteRequest(jsonObject?: any): PlanQuoteRequest {
    const request = new PlanQuoteRequest(this);

    return request;
  }


  // generates a new plan quote result
  newPlanQuoteResult(jsonObject?: any): PlanQuoteResult {
    const result = new PlanQuoteResult(this);

    if (jsonObject) {
      const jsonValues = <IPlanQuoteResult>jsonObject;

      result.emailSuccessFlag = MessagingFormatter.stringToBoolean(jsonValues.emailSuccessFlag);
      result.errorMessage = (jsonValues.errorMessage || '');
      result.quoteHtml = (jsonValues.quoteHtml || '');
    }

    return result;
  }


}

