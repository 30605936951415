import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AppSettingsLoadStatus, ModalProgress, PhxModalProgressService, PhxSystemService} from '@orhp/phx-common-ui-module';
import {
  ProductLookupValueFetchService,
  ProductOffice,
  ProductOfficeGroupLookupParams,
  ProductOfficeGroupLookupService
} from '@orhp/phx-product-ui-module';
import {CustomUrlParser} from '../_src/custom-url-parser';
import {Observable} from 'rxjs/Observable';
import {PlanLookupValueFetchService} from '@orhp/phx-plan-ui-module';
import {PropertyLookupValueFetchService} from '@orhp/phx-property-ui-module';
import {CustomerLookupValueFetchService} from '@orhp/phx-customer-ui-module';
import {HomeService} from '../home/home.service';
import {AuthenticationService} from '../login/service/authentication.service';
import {AccountingLookupValueFetchService} from '@orhp/phx-accounting-ui-module';
import {CorrespondenceLookupValuesService} from '@orhp/phx-correspondence-ui-module';

@Injectable()
export class AppSettingsGuard implements CanActivate {


  /**
   * Progress Title
   */
  readonly progressTitle = 'Initializing Toolbox';



  /**
   * Constructor
   * @param router
   * @param systemService
   * @param modalProgressService
   * @param authenticationService
   * @param accountingLookupValueFetchService
   * @param productLookupValueFetchService
   * @param correspondenceLookupValueService
   * @param planLookupValueFetchService
   * @param propertyLookupValueFetchService
   * @param customerLookupValueFetchService
   * @param homeService
   * @param productOfficeGroupLookupService
   */
  constructor(private router: Router,
              private systemService: PhxSystemService,
              private modalProgressService: PhxModalProgressService,
              private authenticationService: AuthenticationService,
              private accountingLookupValueFetchService: AccountingLookupValueFetchService,
              private productLookupValueFetchService: ProductLookupValueFetchService,
              private correspondenceLookupValueService: CorrespondenceLookupValuesService,
              private planLookupValueFetchService: PlanLookupValueFetchService,
              private propertyLookupValueFetchService: PropertyLookupValueFetchService,
              private customerLookupValueFetchService: CustomerLookupValueFetchService,
              private homeService: HomeService,
              private productOfficeGroupLookupService: ProductOfficeGroupLookupService) {
  }





  /**
   * Can the page activate?
   * @param route
   * @param state
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('!!!AppSettingsGuard Start');

    this.writeProgress('Reticulating splines...', 10);

    // loads app settings
    const appSettingsLoaded = await this.loadAppSettings();

    if (!appSettingsLoaded) {
      return false;
    }

    this.writeProgress('Loading data...', 30);

    // loads lookup data
    const lookupDataLoaded = await this.loadLookupData();

    if (!lookupDataLoaded) {
      return false;
    }

    this.writeProgress('Loading customizations...', 50);

    // is there a custom URL?
    let productOffice: ProductOffice;

    if (CustomUrlParser.getCustomUrl()) {
      productOffice = await this.processCustomUrl();

      // if no product office was found, redirect the user
      if (!productOffice) {
        window.location.href = '/';
        return false;
      }
    }

    this.authenticationService.urlProductOffice = productOffice;

    this.writeProgress('Almost there...', 70);

    // process the auto-login if there is one
    let autoLogin = false;

    if (productOffice) {
      autoLogin = await this.processAutoLogin(productOffice);
    }

    this.writeProgress('Finishing up...', 90);

    // if there's a login token but no customer
    if (this.authenticationService.loginToken && !this.authenticationService.customer) {
      await this.authenticationService.loginCompletionHandler();
    }

    this.modalProgressService.progress = null;

    if (autoLogin) {
      console.log('!!!Redirecting');
      this.router.navigate(['/plan-order'], {});
      return false;
    }

    return true;
  }


  /**
   * Writes a modal progress
   * @param text
   * @param percent
   */
  writeProgress(text: string, percent: number) {
    this.modalProgressService.progress = new ModalProgress(this.progressTitle, text, percent);
  }




  /**
   * Loads app settings
   */
  loadAppSettings(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // if app settings were loaded, return true
      if (this.systemService.appSettingsLoadStatus === AppSettingsLoadStatus.success) {
        resolve(true);
        return;
      }

      // loads app settings
      this.systemService.loadAppSettings().subscribe((flag: boolean) => {
        resolve(flag);
      });
    });
  }



  /**
   * Loads lookup data
   */
  loadLookupData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // load the home upper-right message async
      this.homeService.fetchHomeUpperRightMessage().subscribe();

      const observables: Observable<any>[] = [];

      observables.push(this.planLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.propertyLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.customerLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.accountingLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.productLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.correspondenceLookupValueService.fetchLookupValuesIfNeeded());

      Observable.forkJoin(observables).subscribe((results: any[]) => {
        resolve(true);
      });
    });
  }


  /**
   * Processes the custom URL
   */
  async processCustomUrl(): Promise<ProductOffice> {
    // is there a custom URL?
    let productOffice: ProductOffice;
    const customUrl = CustomUrlParser.getCustomUrl();

    if (customUrl) {
      // if the custom URL is different from what's stored, log the user out
      if (customUrl !== this.authenticationService.customUrlCode) {
        this.authenticationService.clearUserSession();
      }

      // pull the product office from the custom URL
      productOffice = await this.loadCustomUrlProductOffice();

      if (productOffice) {
        if (this.authenticationService.productOffice !== productOffice) {
          this.authenticationService.productOffice = productOffice;
        }
      } else {
        this.authenticationService.productOffice = undefined;
      }

    } else if (this.authenticationService.customUrlCode) {
      // if there's a saved custom URL code but no current URL code, remove it and clear the session
      this.authenticationService.customUrlCode = undefined;
      this.authenticationService.clearUserSession();
    }

    return productOffice;
  }






  /**
   * Loads custom URL detail
   */
  loadCustomUrlProductOffice(): Promise<ProductOffice> {
    return new Promise((resolve, reject) => {
      const customUrl = CustomUrlParser.getCustomUrl();

      if (!customUrl) {
        resolve(undefined);
        return;
      }

      // pull the existing product/office
      const productOffice = this.authenticationService.productOffice;
      const productOfficeToolbox = (productOffice ? productOffice.toolbox : undefined);
      const productOfficeUrl = (productOfficeToolbox ? productOfficeToolbox.customUrlPath : undefined);

      // if the custom URL code is the same, return the same product/office
      if (productOfficeUrl === CustomUrlParser.getCustomUrl()) {
        resolve(productOffice);
        return;
      }

      // otherwise look it up
      const params = new ProductOfficeGroupLookupParams();
      params.customToolboxUrlPath = CustomUrlParser.getCustomUrl();
      params.jsonToolboxFlag = true;
      params.jsonPropertyTypesFlag = true;
      params.generateAutoLoginToken = true;

      this.productOfficeGroupLookupService.fetchProductOfficeGroups(params)
        .subscribe((productOffices: ProductOffice[]) => {
          // if product/offices were returned
          if ((productOffices || []).length) {
            resolve(productOffices[0]);
          } else {
            resolve(undefined);
          }
        });
    });
  }


  /**
   * Processes the auto-login
   * @param productOffice
   */
  async processAutoLogin(productOffice: ProductOffice): Promise<boolean> {
    // if there's an auto-login
    const autoLoginJwt = (productOffice && productOffice.toolbox ? productOffice.toolbox.autoLoginToken : undefined);

    if (autoLoginJwt) {
      // if the auto-login was successful
      return await this.authenticationService.loginWithExistingJwt(autoLoginJwt);
    }

    return false;
  }




}
