


import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';

@Injectable()

export class OfficeAgentLookupAgentResolver implements Resolve<Customer> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private customerLookupService: CustomerLookupService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    const observable: Observable<Customer> = Observable.create((subscriber: Subscriber<Customer>) => {

      let progress: ModalProgress = null;

      progress = new ModalProgress('Loading Agent', 'Please wait...', 100);
      this.modalProgressService.progress = progress;

      const customerGUID = snapshot.params['customerGUID'];

      // look at the saved customer; reload if it's different
      const savedCustomer = this.officeAgentLookupPersistenceService.customer;

      let reloadCustomer = false;

      if (customerGUID) {
        // reload if no saved customer was found
        reloadCustomer = reloadCustomer || !savedCustomer;

        // reload if there is a saved customer, but the GUIDs don't match
        reloadCustomer = reloadCustomer || (savedCustomer && (savedCustomer.guid !== customerGUID));
      }

      // are we reloading or not?
      let customerFetchObservable: Observable<Customer>;

      if (reloadCustomer) {
        customerFetchObservable = this.customerLookupService.fetchCustomerByGUID(customerGUID);

      } else {
        customerFetchObservable = Observable.create((persistedSubscriber: Subscriber<Customer>) => {
          persistedSubscriber.next(savedCustomer);
          persistedSubscriber.complete();
        });
      }

      // perform the fetch
      customerFetchObservable.subscribe((customer: Customer) => {
        this.modalProgressService.progress = null;

        // if the customer was found
        if (customer) {
          this.officeAgentLookupPersistenceService.customer = customer;

          subscriber.next(customer);

          // if no customer was found
        } else {
          // redirect the user to the customer lookup
          const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, customerGUID);

          // remove the customerGUID from the URL
          RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

          // we're adding a search
          const urlSegment = new UrlSegment('search', {});
          RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

          this.router.navigateByUrl(urlTree);

          subscriber.next(null);
        }

        subscriber.complete();
      });
    });

    return observable;
  }

}
