import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthenticationService} from './service/authentication.service';
import {Router} from '@angular/router';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupService} from '../_modules/office-agent-lookup/_services/lookup.service';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';

@Component({
  selector: 'app-login-form',
  templateUrl: 'login.component.html',
  styleUrls: [
    './login.component.scss'
  ]
})

export class LoginComponent implements OnInit {

  @Input() modalFlag = true;
  @Input() displayCancelButton = true;
  @Output() onLoginStatusChange = new EventEmitter<LoginStatus>();

  loginFailureMessageParts: any = null;


  // login status
  private _loginStatus: LoginStatus = LoginStatus.notAttempted;

  get loginStatus(): LoginStatus {
    return this._loginStatus;
  }

  set loginStatus(loginStatus: LoginStatus) {
    if (loginStatus !== this._loginStatus) {
      this._loginStatus = loginStatus;

      this.onLoginStatusChange.emit(this._loginStatus);
    }
  }

  // last username
  get lastUsername(): string {
    return this.authenticationService.lastUsername;
  }

  set lastUsername(lastUsername: string) {
    this.authenticationService.lastUsername = lastUsername;
  }


  @ViewChild('usernameInput')
  usernameInput: ElementRef;

  @ViewChild('passwordInput')
  passwordInput: ElementRef;


  username = (this.lastUsername || '');
  password = '';


  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private officeAgentLookupService: OfficeAgentLookupService,
              private systemMessageLookupService: SystemMessageLookupService) {
  }


  ngOnInit() {
    // if no username was saved, focus the username
    if ((this.lastUsername || '') === '') {
      this.usernameInput.nativeElement.focus();

      // if a username was saved, focus the password
    } else {
      this.passwordInput.nativeElement.focus();
    }
  }


  // cancels the login
  didClickCancel() {
    this.loginStatus = LoginStatus.cancelled;

    // if this is a modal login
    if (this.modalFlag) {
      this.authenticationService.displayModalLoginFlag = false;

      this.authenticationService.cancelLogin();
    }
  }


  // performs the login
  didClickLogin() {
    this.loginStatus = LoginStatus.inProgress;
    const username = this.username.trim();

    this.authenticationService.login(username, this.password).then(
      (loginSuccessFlag: boolean) => {
        // if the login was successful
        if (loginSuccessFlag) {
          this.lastUsername = username;

          this.loginStatus = LoginStatus.success;

          if (this.modalFlag) {
            this.authenticationService.displayModalLoginFlag = false;
          }

          // if the login was not successful
        } else {

          this.authenticationService.checkEmailValidation(username).then(
            (isValidated: boolean) => {

              if (isValidated) {
                this.handleLoginFailure();

              } else {
                this.loginStatus = LoginStatus.notValidated;
              }
            },
            (e: any) => {
              console.log(e);
              this.handleLoginFailure();
            }
          );
        }
      },
      (e: any) => {
        console.log(e);
        this.handleLoginFailure();
      }
    );
  }



  handleLoginFailure() {
    // retrieve the login failure system message if not present
    this.authenticationService.getLoginFailureMessageObject().then((jsonObject: any) => {

      const messageValues = <ILoginFailureMessageObject>jsonObject;

      const primaryMessage = messageValues.primaryMessage;
      const resetPasswordMessage = messageValues.resetPasswordMessage;

      // look for the reset message link item
      const linkCode = '\$\$resetMessageLink\$\$';

      const primaryMessageResetLinkPosition = primaryMessage.indexOf(linkCode);

      let messageFirstPart: string = null;
      let messageLastPart: string = null;

      if (primaryMessageResetLinkPosition) {
        messageFirstPart = primaryMessage.substr(0, primaryMessageResetLinkPosition);

        const messageLastPartStartIndex = primaryMessageResetLinkPosition + linkCode.length;
        const messageLastPartLength = primaryMessage.length - messageLastPartStartIndex;
        messageLastPart = primaryMessage.substr(messageLastPartStartIndex, messageLastPartLength);

      } else {
        messageFirstPart = primaryMessage;
        messageLastPart = '';
      }

      this.loginFailureMessageParts = {
        messageFirstPart: messageFirstPart,
        messageLastPart: messageLastPart,
        resetPasswordMessagePart: resetPasswordMessage
      };

      this.loginStatus = LoginStatus.failure;
    });

  }



  didClickSignUp() {
    this.authenticationService.displayModalLoginFlag = false;

    this.router.navigate(['/signup']);
  }


  didClickReset() {
    this.authenticationService.displayModalLoginFlag = false;

    this.router.navigate(['/account/reset']);
  }


  didClickResendValidation() {
    this.authenticationService.displayModalLoginFlag = false;

    this.router.navigate(['/account/resend/validation/', this.username]);
  }


  shouldDisableLoginFields(): boolean {
    return (this.loginStatus === LoginStatus.inProgress);
  }


  shouldDisplayLoginError(): boolean {
    return (this.loginStatus === LoginStatus.failure);
  }


  shouldDisplayLoginInProgress(): boolean {
    return (this.loginStatus === LoginStatus.inProgress);
  }

  shouldDisplayEmailValidationError(): boolean {
    return (this.loginStatus === LoginStatus.notValidated);
  }
}


export enum LoginStatus {
  notAttempted = 10,
  inProgress = 20,
  failure = 30,
  success = 40,
  cancelled = 50,
  notValidated = 60
}


interface ILoginFailureMessageObject {
  primaryMessage: string;
  resetPasswordMessage: string;
}


interface ILoginFailureDisplayText {
  messageFirstPart: string;
  messageLastPart: string;
  resetPasswordMessagePart: string;
}

