

import {EventEmitter, Injectable} from '@angular/core';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {Observable} from 'rxjs/Observable';
import {EmployerLookupParams} from '@orhp/phx-customer-ui-module';
import {CustomerLookupService, EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AgentRole} from '@orhp/phx-plan-ui-module';
import {OfficeAgentErrorCollection} from '../_src/office-agent-error-collection';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {PlanLookupValueFetchService} from '@orhp/phx-plan-ui-module';
import {CustomerLookupValueFetchService} from '@orhp/phx-customer-ui-module';
import {
  ProductLookupValueFetchService,
  ProductOffice,
  ProductOfficeGroupLookupParams,
  ProductOfficeGroupLookupService, ProductOfficeToolbox
} from '@orhp/phx-product-ui-module';


@Injectable()

export class OfficeAgentLookupService {

  // has the module been intialized?
  moduleInitFlag = false;


  // lookup relationship type
  get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
    const lookupTypeCode = this.officeAgentLookupPersistenceService.lookupTypeCode;
    const relationshipType = new OfficeAgentLookupRelationshipType(lookupTypeCode);

    return relationshipType;
  }



  // employer validation
  private _employerValidateResult: EmployerValidateResult = null;

  get employerValidateResult(): EmployerValidateResult {
    if (!this._employerValidateResult) {
      const employer = this.officeAgentLookupPersistenceService.employer;

      this._employerValidateResult =  this.employerDomainFactoryService.newEmployerValidateResult(employer);
    }

    return this._employerValidateResult;
  }

  set employerValidateResult(result: EmployerValidateResult) {
    this._employerValidateResult = result;
  }



  // customer validation
  private _customerValidateResult: CustomerValidateResult = null;

  get customerValidateResult(): CustomerValidateResult {
    if (!this._customerValidateResult) {
      const customer = this.officeAgentLookupPersistenceService.customer;

      this._customerValidateResult = this.customerDomainFactoryService.newCustomerValidateResult(customer);
    }

    return this._customerValidateResult;
  }

  set customerValidateResult(result) {
    this._customerValidateResult = result;
  }



  // last employer and customer results for easy adding
  lastEmployerResults: Employer[] = null;
  lastCustomerResults: Customer[] = null;


  // caches employers and customers by GUID
  employersByGUID = new Map<string, Employer>();
  customersByGUID = new Map<string, Customer>();


  // event emitter for when the skip button is clicked
  skipEventEmitter = new EventEmitter<boolean>();


  allowDifferentOfficeSelectionFlag = true;
  allowDifferentAgentSelectionFlag = true;
  autoFocusOfficeSearchFlag = true;
  requiresProductOfficeFlag = false;

  productOffice: ProductOffice = null;
  productOfficeEmail: string = null;

  private _productOfficeID: number = null;

  get productOfficeID(): number {
    let productOfficeID = this._productOfficeID;

    if (!productOfficeID && !!this.productOffice) {
      productOfficeID = this.productOffice.productOfficeID;
    }

    return productOfficeID;
  }

  set productOfficeID(productOfficeID: number) {
    // if an ID was specified
    if (productOfficeID) {
      this._productOfficeID = productOfficeID;

      setTimeout(() => {
        this.fetchProductOfficeByID();
      });

    // if no ID was specified
    } else {
      const productOfficeObjectID = (this.productOffice ? this.productOffice.productOfficeID : null);

      if (this._productOfficeID === productOfficeObjectID) {
        this.productOffice = null;

      } else {
        this._productOfficeID = productOfficeID;
      }
    }
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return (!!this.productOffice ? this.productOffice.toolbox : null);
  }



  isPlanOrder = true;
  shouldDisplayEmployerSearch = true;

  constructor(private employerLookupService: EmployerLookupService,
              private customerLookupService: CustomerLookupService,
              private customerLookupValueFetchService: CustomerLookupValueFetchService,
              private customerLookupValueService: CustomerLookupValueService,
              private planLookupValueFetchService: PlanLookupValueFetchService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private productLookupValueFetchService: ProductLookupValueFetchService,
              private productOfficeGroupLookupService: ProductOfficeGroupLookupService
  ) {

    this.officeAgentLookupPersistenceService.employerEventEmitter.subscribe((employer: Employer) => {
      this.employerValidateResult = null;

      this.allowDifferentOfficeSelectionFlag = true;
    });

    this.officeAgentLookupPersistenceService.customerEventEmitter.subscribe((customer: Customer) => {
      this.customerValidateResult = null;

      this.allowDifferentAgentSelectionFlag = true;
    });
  }



  // initializes the module if needed
  initModuleIfNeeded(): Observable<boolean> {
    let observable: Observable<boolean> = null;

    // if the module has not been initialized yet
    if (!this.moduleInitFlag) {
      observable = this.initModule();

    // if the module has been initialized
    } else {
      observable = Observable.create((subscriber: Subscriber<boolean>) => {
        subscriber.next(true);
        subscriber.complete();
      });
    }


    return observable;
  }


  // initializes the module
  initModule(): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const observables: Observable<any>[] = [];

      observables.push(this.customerLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.planLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.productLookupValueFetchService.fetchLookupValuesIfNeeded());

      Observable.forkJoin(observables).subscribe((results: any[]) => {
        this.moduleInitFlag = true;

        subscriber.next(true);
        subscriber.complete();
      });

    });

    return observable;
  }


  /**
   * Fetches the product office by ID
   * @private
   */
  private fetchProductOfficeByID() {
    const productOfficeObjectID = (!!this.productOffice ? this.productOffice.productOfficeID : null);

    // if we need to load a product office
    if (!!this._productOfficeID && (this._productOfficeID !== productOfficeObjectID)) {
      const params = new ProductOfficeGroupLookupParams();
      params.productOfficeID = this._productOfficeID;
      params.jsonToolboxFlag = true;

      this.productOfficeGroupLookupService.fetchProductOfficeGroups(params).subscribe((productOffices: ProductOffice[]) => {
        console.log(productOffices);

        // if a product office was found
        if (!!(productOffices || []).length) {
          this.productOffice = productOffices[0];
          this._productOfficeID = null;

        // if no product offices were found
        } else {
          this.productOffice = null;
        }
      });
    }
  }




  // stores employers
  cacheEmployers(employers: Employer[]) {
    employers.forEach((indexEmployer: Employer) => {
      this.employersByGUID[indexEmployer.guid] = indexEmployer;
    });
  }



  // stores customers
  cacheCustomers(customers: Customer[]) {
    customers.forEach((indexCustomer: Customer) => {
      this.customersByGUID[indexCustomer.guid] = indexCustomer;
    });
  }


  clearAllData() {
      // reset persistent settings to allow plan order when they leave profile
      this.officeAgentLookupPersistenceService.employer = null;
      this.officeAgentLookupPersistenceService.customer = null;

      this.lastEmployerResults = null;
      this.lastCustomerResults = null;

      this.employerValidateResult = null;
      this.customerValidateResult = null;

    this.allowDifferentOfficeSelectionFlag = true;
    this.allowDifferentAgentSelectionFlag = true;
    this.autoFocusOfficeSearchFlag = true;
    this.requiresProductOfficeFlag = false;

    this.productOffice = null;
    this.productOfficeID = null;
    this.productOfficeEmail = null;

    this.isPlanOrder = true;
    this.shouldDisplayEmployerSearch = true;
  }



}
