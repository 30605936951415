

import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {TransactionType} from '@orhp/phx-plan-ui-module';
import {TransactionTypeGroup} from '@orhp/phx-plan-ui-module';
import {PlanOrderLookupValueService} from '../_services/plan-order-lookup-value.service';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {Observable, Subscription} from 'rxjs/Rx';
import {Subscriber} from 'rxjs/Subscriber';
import {SystemMessage, SystemMessageLookupParams, SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';


@Component({
  selector: 'app-plan-order-transaction-type',
  templateUrl: './transaction-type.component.html',
  styleUrls: [
    '../plan-order.scss',
    './transaction-type.component.scss'
  ]
})

export class PlanOrderTransactionTypeComponent
  extends PlanOrderBaseComponent
  implements OnInit, OnDestroy {

  transactionTypeGroups: TransactionTypeGroup[];
  selectedTransactionType: TransactionType;

  demoModeSelectedFlag = this.planOrderApplication.demoModeFlag;

  pageLoadedFlag = false;

  instructionText: string = null;


  newApplicationSubscription: Subscription = null;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private systemMessageLookupService: SystemMessageLookupService,
              private planLookupValueService: PlanLookupValueService,
              private planOrderAuthService: PlanOrderAuthService,
              private planOrderService: PlanOrderService,
              private planOrderLookupValueService: PlanOrderLookupValueService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }


  ngOnInit() {
    super.ngOnInit();

    this.newApplicationSubscription = this.planOrderService.newApplicationEventEmitter.subscribe(
      (application: PlanOrderApplication) => {
        this.autoSelectTransactionType();
      }
    );

    this.transactionTypeGroups = this.planLookupValueService.transactionTypeGroups;

    // if there's only one transaction type available, auto-select it
    this.autoSelectTransactionType();

    // pull the selected transaction type
    this.selectedTransactionType = this.planOrderApplication.transactionType;

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'transaction-type');

    // retrieve the instruction text
    this.retrieveInstructionText().subscribe((resultFlag: boolean) => {
      this.pageLoadedFlag = true;
    });
  }


  ngOnDestroy() {
    if (this.newApplicationSubscription) {
      this.newApplicationSubscription.unsubscribe();
    }
  }


  autoSelectTransactionType() {
    const transactionTypes = this.transactionTypesForDisplay();

    let gotoNextStepFlag = false;

    // if there's only one transaction type available
    if ((transactionTypes.length) === 1) {
      const transactionType = transactionTypes[0];

      // if the application transaction type is not selected
      if (!this.planOrderApplication.transactionType) {
        this.planOrderApplication.transactionType = transactionType;
        this.persistPlanOrderApplication();
      }

      // if the transaction types match, go to the next step
      if (transactionType.isEqual(this.planOrderApplication.transactionType)) {
        gotoNextStepFlag = true;
      }
    }

    if (gotoNextStepFlag) {
      setTimeout(() => {
        this.gotoNextStep();
      });
    }
  }



  retrieveInstructionText(): Observable<boolean> {
    const observable = new Observable((subscriber: Subscriber<boolean>) => {
      const params = new SystemMessageLookupParams();
      params.type = InstructionCodes.multipleTypes + ',' + InstructionCodes.singleType;

      this.systemMessageLookupService.systemMessageLookup(params).subscribe((messages: SystemMessage[]) => {
        // decide what message to use based on type count
        const typeCount = this.transactionTypesForDisplay();

        messages.forEach((indexMessage: SystemMessage) => {
          let useMessageFlag = false;

          if (this.shouldDisplaySingleTransactionType() && (indexMessage.type === InstructionCodes.singleType)) {
            useMessageFlag = true;

          } else if (this.shouldDisplayMultipleTransactionTypes() && (indexMessage.type === InstructionCodes.multipleTypes)) {
            useMessageFlag = true;
          }

          if (useMessageFlag) {
            this.instructionText = indexMessage.message;
          }
        });

        subscriber.next(true);
        subscriber.complete();
      });
    });

    return observable;
  }



  shouldDisplaySingleTransactionType(): boolean {
    return (this.transactionTypesForDisplay().length === 1);
  }


  shouldDisplayMultipleTransactionTypes(): boolean {
    return (this.transactionTypesForDisplay().length > 1);
  }




  transactionTypesForDisplay(): TransactionType[] {
    return this.planOrderLookupValueService.transactionTypesForDisplay();
  }



  // should the demo mode option be displayed?
  shouldDisplayDemoModeOption(): boolean {
    let displayFlag = false;

    const newPlanFlag = !this.planOrderApplication.planID;
    const demoModeSubmittedFlag = this.planOrderApplication.demoModeSubmittedFlag;
    const orhpUserFlag = this.planOrderAuthService.isOrhpUser();

    // only show the demo mode option for a new plan
    if (newPlanFlag && !demoModeSubmittedFlag && orhpUserFlag) {
      displayFlag = true;
    }

    return displayFlag;
  }


  // the user clicked the demo mode button
  didClickDemoMode() {
    this.demoModeSelectedFlag = !this.demoModeSelectedFlag;
  }




  // is this transaction type selected?
  isTransactionTypeSelected(transactionType: TransactionType): boolean {
    return (this.selectedTransactionType === transactionType);
  }


  // should the Next button be displayed
  shouldDisplayNextButton(): boolean {
    return !!this.selectedTransactionType;
  }


  // should the start over button be displayed
  shouldDisplayStartOverButton(): boolean {
    let displayFlag = false;

    if (this.planOrderApplication.transactionType) {
      displayFlag = true;
    }

    return displayFlag;
  }


  // user clicked a transaction type
  didClickTransactionType(transactionType: TransactionType): void {
    if (this.shouldDisplayMultipleTransactionTypes()) {
      this.selectedTransactionType = transactionType;

      this.gotoNextStep();
    }
  }


  // go to the next step
  gotoNextStep() {
    this.planOrderApplication.demoModeFlag = this.demoModeSelectedFlag;

    this.planOrderApplication.transactionType = this.selectedTransactionType;

    this.persistPlanOrderApplication();

    // next step logic
    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }


  // the user clicked the start over button
  didClickStartOverButton() {
    this.selectedTransactionType = null;
  }
}


enum InstructionCodes {
  multipleTypes = 'plan-order-transaction-type-multiple-instructions',
  singleType = 'plan-order-transaction-type-single-instructions'
}

