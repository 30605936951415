

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

import {PlanOrderFrameComponent} from './plan-order-frame.component';
import {PlanOrderPropertyTypeComponent} from './property-type/property-type.component';
import {PlanOrderProgressBarComponent} from './progress-bar/progress-bar.component';
import {PlanOrderPropertyAddressComponent} from './property-address/property-address.component';
import {PlanOrderProductComponent} from './product/product.component';
import {PlanOrderOptionalCoverageComponent} from './optional-coverage/optional-coverage.component';
import {PlanOrderTransactionTypeComponent} from './transaction-type/transaction-type.component';
import {PlanOrderRoleComponent} from './role/role.component';
import {PlanOrderInitiatingAgentComponent} from './initiating-agent/initiating-agent.component';
import {OfficeAgentLookupModule} from '../office-agent-lookup/office-agent-lookup.module';
import {PlanOrderHomeBuyerComponent} from './home-buyer/home-buyer.component';
import {PlanOrderHomeSellerComponent} from './home-seller/home-seller.component';
import {PlanOrderCooperatingAgentComponent} from './cooperating-agent/cooperating-agent.component';
import {PlanOrderClosingInfoComponent} from './closing-info/closing-info.component';
import {PlanOrderReviewComponent} from './review/review.component';
import {PhxProductModule} from '@orhp/phx-product-ui-module';
import {PlanOrderService} from './_services/plan-order.service';
import {PhxPropertyModule} from '@orhp/phx-property-ui-module';
import {PlanOrderRootResolver} from './_services/plan-order-root.resolver';
import {PlanOrderLookupValueService} from './_services/plan-order-lookup-value.service';
import {PlanOrderFormSubmitBarComponent} from './form-submit-bar/form-submit-bar.component';
import {PlanOrderGenericCustomerComponent} from './generic-customer/generic-customer.component';
import {PhxGeographyModule} from '@orhp/phx-geography-ui-module';
import {PhxAddressValidateModule} from '@orhp/phx-addressvalidate-ui-module';
import {PhxPlanModule} from '@orhp/phx-plan-ui-module';
import {PlanOrderSaveInterstitialComponent} from './save-interstitial/save-interstitial.component';
import {PlanOrderUpdateService} from './_services/plan-order-update.service';
import {PlanOrderLookupService} from './_services/plan-order-lookup.service';
import {PlanOrderConfirmationComponent} from './confirmation/confirmation.component';
import {PlanOrderStepService} from './_services/plan-order-step.service';
import {PlanOrderWildcardStepResolver} from './_services/plan-order-wildcard-step.resolver';
import {PlanOrderPropertyTypeResolver} from './property-type/property-type.resolver';
import {PlanOrderRoleResolver} from './role/role.resolver';
import {PlanOrderInitiatingAgentResolver} from './initiating-agent/initiating-agent.resolver';
import {PlanOrderProductResolver} from './product/product.resolver';
import {PlanOrderOptionalCoverageResolver} from './optional-coverage/optional-coverage.resolver';
import {PlanOrderPropertyAddressResolver} from './property-address/property-address.resolver';
import {PlanOrderCooperatingAgentResolver} from './cooperating-agent/cooperating-agent.resolver';
import {PlanOrderClosingInfoResolver} from './closing-info/closing-info.resolver';
import {PlanOrderHomeBuyerResolver} from './home-buyer/home-buyer.resolver';
import {PlanOrderHomeSellerResolver} from './home-seller/home-seller.resolver';
import {PlanOrderTransactionTypeResolver} from './transaction-type/transaction-type.resolver';
import {PlanOrderDomainFactoryService} from './_services/plan-order-domain-factory.service';
import {PlanOrderReviewResolver} from './review/review.resolver';
import {PhxAccountingModule} from '@orhp/phx-accounting-ui-module';
import {PlanOrderAuthGuard} from './_services/plan-order-auth.guard';
import {PlanOrderCancelComponent} from './cancel/cancel.component';
import {PhxClaimsModule} from '@orhp/phx-claims-ui-module';
import {PlanQuoteService} from './_services/plan-quote.service';
import {CalendarWidgetModule} from '@orhp/phx-calendar-widget-ui-module';
import {PlanOrderOfficeAutoMatchComponent} from './office-auto-match/office-auto-match.component';
import {PhxCommonModule} from '@orhp/phx-common-ui-module';
import {PlanOrderAgentAutoMatchComponent} from './agent-auto-match/agent-auto-match.component';
import {PlanOrderMailingAddressComponent} from './mailing-address/mailing-address.component';
import {CorrespondenceService} from '../../user/_services/correspondence.service';
import {PlanOrderEmailQuoteComponent} from './plan-quote/email-quote.component';
import {PlanOrderReviewDeactivateGuard} from './review/review-deactivate.guard';
import {PlanOrderReviewActionButtonsComponent} from './review/review-action-buttons.component';
import {ProgressBarImageService} from './progress-bar/progress-bar-image.service';
import {PlanOrderPaymentComponent} from './payment/payment.component';
import {PlanOrderReviewPaymentComponent} from './review/payment/review-payment.component';
import {PlanOrderAuthService} from './_services/plan-order-auth.service';
import {PlanOrderReviewAgentComponent} from './review/agent/review-agent.component';
import {PlanOrderNoAuthGuard} from './_services/plan-order-no-auth.guard';
import {PhxCorrespondenceModule} from '@orhp/phx-correspondence-ui-module';
import {HomeModule} from '../../home/home.module';
import {PlanOrderCustomUrlGuard} from './_services/plan-order-custom-url-guard';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    OfficeAgentLookupModule,
    PhxCommonModule,
    PhxCorrespondenceModule,
    PhxPropertyModule,
    PhxProductModule,
    PhxGeographyModule,
    PhxPlanModule,
    PhxAddressValidateModule,
    PhxAccountingModule,
    PhxClaimsModule,
    CalendarWidgetModule,
    HomeModule
  ],
  declarations: [
    PlanOrderFrameComponent,
    PlanOrderFormSubmitBarComponent,
    PlanOrderProgressBarComponent,
    PlanOrderGenericCustomerComponent,
    PlanOrderOfficeAutoMatchComponent,
    PlanOrderAgentAutoMatchComponent,
    PlanOrderEmailQuoteComponent,

    PlanOrderTransactionTypeComponent,
    PlanOrderPropertyTypeComponent,
    PlanOrderRoleComponent,
    PlanOrderInitiatingAgentComponent,
    PlanOrderProductComponent,
    PlanOrderOptionalCoverageComponent,
    PlanOrderPropertyAddressComponent,
    PlanOrderCooperatingAgentComponent,
    PlanOrderClosingInfoComponent,
    PlanOrderHomeBuyerComponent,
    PlanOrderHomeSellerComponent,
    PlanOrderPaymentComponent,

    PlanOrderReviewComponent,
    PlanOrderReviewAgentComponent,
    PlanOrderReviewPaymentComponent,
    PlanOrderReviewActionButtonsComponent,

    PlanOrderSaveInterstitialComponent,
    PlanOrderConfirmationComponent,
    PlanOrderCancelComponent,
    PlanOrderMailingAddressComponent
  ],
  providers: [
    PlanOrderRootResolver,
    PlanOrderWildcardStepResolver,
    PlanOrderService,
    PlanOrderAuthService,
    PlanOrderLookupValueService,
    PlanOrderUpdateService,
    PlanOrderLookupService,
    PlanOrderStepService,
    PlanOrderDomainFactoryService,
    PlanQuoteService,
    CorrespondenceService,
    ProgressBarImageService,

    PlanOrderAuthGuard,
    PlanOrderNoAuthGuard,
    PlanOrderCustomUrlGuard,
    PlanOrderReviewDeactivateGuard,

    PlanOrderTransactionTypeResolver,
    PlanOrderPropertyTypeResolver,
    PlanOrderRoleResolver,
    PlanOrderInitiatingAgentResolver,
    PlanOrderProductResolver,
    PlanOrderOptionalCoverageResolver,
    PlanOrderPropertyAddressResolver,
    PlanOrderCooperatingAgentResolver,
    PlanOrderClosingInfoResolver,
    PlanOrderHomeBuyerResolver,
    PlanOrderHomeSellerResolver,
    PlanOrderReviewResolver
  ],
  exports: [
    PlanOrderFrameComponent
  ]
})


export class PlanOrderModule {

}

