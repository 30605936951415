import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PropertyLookupService} from '@orhp/phx-property-ui-module';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanDomainFactoryService, PlanUpdateService} from '@orhp/phx-plan-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderService} from '../_services/plan-order.service';
import {AddressValidateService} from '@orhp/phx-addressvalidate-ui-module';
import {PlanLookupService} from '@orhp/phx-plan-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {AddressValidateRequest, AddressValidateResult} from '@orhp/phx-addressvalidate-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {Address} from '@orhp/phx-common-ui-module';
import {validate} from 'codelyzer/walkerFactory/walkerFn';

@Component({
  selector: 'app-plan-order-mailing-address',
  templateUrl: './mailing-address.component.html',
  styleUrls: [
    '../plan-order.scss',
    './mailing-address.component.scss'
  ]
})

export class PlanOrderMailingAddressComponent extends PlanOrderBaseComponent implements OnInit {

  addressValidationState = AddressValidationState.none;

  titleText = 'Update Home Buyer Mailing Address';

  originalAddress: Address = null;

  userAddress = '';
  userCity = '';
  userStateCode = '';
  userZipCode = '';

  validatedAddress = '';
  validatedCity = '';
  validatedStateCode = '';
  validatedZipCode = '';


  get property(): Property {
    return this.planOrderApplication.property;
  }


  mailingAddressSameAsPropertyFlag = false;


  @ViewChild('addressInput')
  addressInput: ElementRef;


  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private systemMessageLookupService: SystemMessageLookupService,
              private addressValidateService: AddressValidateService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService,
              private planUpdateService: PlanUpdateService) {
    super(planOrderService);
  }


  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'mailing-address');

    const mailingAddress = this.planOrderApplication.mailingAddress;

    this.originalAddress = mailingAddress;

    if (mailingAddress) {
      this.userAddress = (mailingAddress.address || '');
      this.userCity = (mailingAddress.city || '');
      this.userStateCode = (mailingAddress.stateCode || '');
      this.userZipCode = (mailingAddress.zipCode || '');

      this.reconcileMailingAddressSameAsPropertyFlag();
    }

    if (this.userAddress === '') {
      this.addressInput.nativeElement.focus();
    }
  }


  reconcileMailingAddressSameAsPropertyFlag() {
    let addressMatch = true;
    addressMatch = addressMatch && (this.userAddress === this.property.address);
    addressMatch = addressMatch && (this.userZipCode === this.property.zipCode);

    this.mailingAddressSameAsPropertyFlag = addressMatch;
  }


  didClickMailingAddressSameAsProperty() {
    this.mailingAddressSameAsPropertyFlag = !this.mailingAddressSameAsPropertyFlag;

    if (this.mailingAddressSameAsPropertyFlag) {
      this.userAddress = this.property.address;
      this.userCity = this.property.city;
      this.userStateCode = this.property.stateCode;
      this.userZipCode = this.property.zipCode;

    } else {
      this.userAddress = '';
      this.userCity = '';
      this.userStateCode = '';
      this.userZipCode = '';

      setTimeout(() => {
        this.addressInput.nativeElement.focus();
      });
    }
  }


  shouldDisableAddressFields(): boolean {
    return this.mailingAddressSameAsPropertyFlag;
  }


  shouldDisplayAddressValidationSuccess(): boolean {
    return (this.addressValidationState === AddressValidationState.validationSuccess);
  }


  shouldDisplayAddressValidationError(): boolean {
    return (this.addressValidationState === AddressValidationState.validationFailure);
  }


  shouldDisplayRunningMessage(): boolean {
    return (this.addressValidationState === AddressValidationState.running);
  }




  streetAddressError(): string {
    let error: string = null;

    if (this.addressValidationState === AddressValidationState.attemped) {
      if (this.userAddress === '') {
        error = 'Street Address is required';
      }
    }

    return error;
  }


  cityError(): string {
    let error: string = null;

    if (this.addressValidationState === AddressValidationState.attemped) {
      if (this.userCity === '') {
        error = 'City is required';
      }
    }

    return error;
  }


  stateCodeError(): string {
    let error: string = null;

    if (this.addressValidationState === AddressValidationState.attemped) {
      if (this.userStateCode === '') {
        error = 'State is required';
      }
    }

    return error;
  }


  zipCodeError(): string {
    let error: string = null;

    if (this.addressValidationState === AddressValidationState.attemped) {
      if (this.userZipCode === '') {
        error = 'Zip Code is required';
      }
    }

    return error;
  }


  didClickNext() {
    let addressChangeFlag = false;

    if (!this.originalAddress) {
      addressChangeFlag = true;

    } else {
      addressChangeFlag = addressChangeFlag || (this.userAddress !== this.originalAddress.address);
      addressChangeFlag = addressChangeFlag || (this.userCity !== this.originalAddress.city);
      addressChangeFlag = addressChangeFlag || (this.userStateCode !== this.originalAddress.stateCode);
      addressChangeFlag = addressChangeFlag || (this.userZipCode !== this.userZipCode);
    }


    // if the address wasn't updated
    if (!addressChangeFlag) {
      this.gotoNextStep();

    // if the property address is different than the mailing address
    } else if (!this.mailingAddressSameAsPropertyFlag) {
      this.addressValidationState = AddressValidationState.attemped;

      let addressOkayFlag = true;
      addressOkayFlag = addressOkayFlag && !this.streetAddressError();
      addressOkayFlag = addressOkayFlag && !this.cityError();
      addressOkayFlag = addressOkayFlag && !this.stateCodeError();
      addressOkayFlag = addressOkayFlag && !this.zipCodeError();

      if (addressOkayFlag) {
        const request = new AddressValidateRequest();

        request.address = this.userAddress;
        request.city = this.userCity;
        request.stateCode = this.userStateCode;
        request.zipCode = this.userZipCode;

        this.addressValidationState = AddressValidationState.running;

        this.addressValidateService.validateAddress(request)
          .subscribe((result: AddressValidateResult) => {

            // if the address was validated
            if (result.validatedFlag) {
              this.validatedAddress = result.cleanAddress;
              this.validatedCity = result.cleanCity;
              this.validatedStateCode = result.cleanStateCode;
              this.validatedZipCode = result.cleanZipCode;

              // if the validated address matches
              let validatedMatchFlag = true;
              validatedMatchFlag = validatedMatchFlag && (this.userAddress === this.validatedAddress);
              validatedMatchFlag = validatedMatchFlag && (this.userCity === this.validatedCity);
              validatedMatchFlag = validatedMatchFlag && (this.userStateCode === this.validatedStateCode);
              validatedMatchFlag = validatedMatchFlag && (this.userZipCode === this.validatedZipCode);

              if (validatedMatchFlag) {
                this.didClickSuccessContinue();

              } else {
                this.addressValidationState = AddressValidationState.validationSuccess;
              }

              // if the address was not validated
            } else {
              this.addressValidationState = AddressValidationState.validationFailure;
            }
          });
      }

    // if the property address is the same as the mailing address
    } else {
      const address = new Address();

      address.address = this.property.address;
      address.city = this.property.city;
      address.stateCode = this.property.stateCode;
      address.zipCode = this.property.zipCode;

      this.planOrderApplication.mailingAddress = address;

      this.gotoNextStep();
    }

  }




  didClickUpdateAddress() {
    this.addressValidationState = AddressValidationState.none;
  }



  didClickSuccessContinue() {
    const address = new Address();

    address.address = this.validatedAddress;
    address.city = this.validatedCity;
    address.stateCode = this.validatedStateCode;
    address.zipCode = this.validatedZipCode;

    this.planOrderApplication.mailingAddress = address;

    this.gotoNextStep();
  }


  didClickErrorContinue() {
    const address = new Address();

    address.address = this.userAddress.toUpperCase();
    address.city = this.userCity.toUpperCase();
    address.stateCode = this.userStateCode.toUpperCase();
    address.zipCode = this.userZipCode.toUpperCase();

    this.planOrderApplication.mailingAddress = address;

    this.gotoNextStep();
  }


  gotoNextStep() {

    this.submitMailingAddress();
    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }


  submitMailingAddress() {
    this.planUpdateService.updatePlanHomeOwnerMailingAddress(this.planOrderApplication.planID, this.planOrderApplication.mailingAddress, true).subscribe();
  }

}




enum AddressValidationState {
  none = 10,
  attemped = 15,
  running = 20,
  validationSuccess = 40,
  validationFailure = 50
}

