

import {
  ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer,
  UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupService} from './lookup.service';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';

@Injectable()

export class OfficeAgentLookupEmptyAgentResolver implements Resolve<boolean> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const urlTree = this.urlSerializer.parse(state.url);

      RoutingUtility.removeLastUrlSegmentFromTree(urlTree);

      this.router.navigateByUrl(urlTree);

      subscriber.next(false);

      subscriber.complete();
    });

    return observable;
  }


}
