
import {PlanQuoteService} from '../_services/plan-quote.service';

export class PlanQuoteResult {

  planQuoteService: PlanQuoteService = null;

  emailSuccessFlag = false;
  errorMessage: string = null;
  quoteHtml: string = null;


  constructor(planQuoteService: PlanQuoteService) {
    this.planQuoteService = planQuoteService;
  }


}


export interface IPlanQuoteResult {
  emailSuccessFlag: string;
  errorMessage: string;
  quoteHtml: string;
}

