import {Component, OnInit} from '@angular/core';
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {CustomerLookupValueService, Employer} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from '../_services/persistence.service';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentNavigationService} from '../_services/office-agent-navigation.service';

@Component({
    selector: 'app-office-entry',
    templateUrl: './office-entry.component.html',
    styleUrls: ['../office-agent-lookup.scss']
})


export class OfficeEntryComponent implements OnInit {

    public officeAgentLookupService: OfficeAgentLookupService;

    constructor(officeAgentLookupService: OfficeAgentLookupService,
                private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
                private customerLookupValueService: CustomerLookupValueService,
                private employerDomainFactoryService: EmployerDomainFactoryService,
                private officeAgentNavigationService: OfficeAgentNavigationService,
                private router: Router,
                private route: ActivatedRoute,
                private urlSerializer: UrlSerializer) {
        this.officeAgentLookupService = officeAgentLookupService;
    }


    // lookup relationship type
    get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
        const lookupTypeCode = this.officeAgentLookupPersistenceService.lookupTypeCode;
        const lookupType = new OfficeAgentLookupRelationshipType(lookupTypeCode);

        return lookupType;
    }


    employer: Employer = null;


    get employerValidateResult(): EmployerValidateResult {
        return this.officeAgentLookupService.employerValidateResult;
    }

    get isPlanOrder(): boolean {
        return this.officeAgentLookupService.isPlanOrder;
    }


    ngOnInit() {
        this.route.data.subscribe((data: any) => {
            this.employer = data.employer;
        });
    }


    // is the office loaded
    isOfficeLoaded(): boolean {
        return !!this.employer;
    }


    // the user clicked to search for the office again
    didClickSearchOffice() {
        this.officeAgentNavigationService.gotoOfficeSearch();
    }


    shouldDisplayEmployerNameError(): boolean {
        return ((this.employerValidateResult.employerNameError || '') !== '');
    }


    shouldDisplayPhysicalStreetAddressError(): boolean {
        let displayFlag = false;

        if ((this.employerValidateResult.employerPhysicalStreetAdressError || '') !== '') {
            displayFlag = true;
        }

        if ((this.employerValidateResult.employerPhysicalAddressValidationError || '') !== '') {
            displayFlag = true;
        }

        return displayFlag;
    }


    shouldDisplayPhysicalCityError(): boolean {
        let displayFlag = false;

        if ((this.employerValidateResult.employerPhysicalCityError || '') !== '') {
            displayFlag = true;
        }

        if ((this.employerValidateResult.employerPhysicalAddressValidationError || '') !== '') {
            displayFlag = true;
        }

        return displayFlag;
    }


    shouldDisplayPhysicalStateCodeError(): boolean {
        let displayFlag = false;

        if ((this.employerValidateResult.employerPhysicalStateCodeError || '') !== '') {
            displayFlag = true;
        }

        if ((this.employerValidateResult.employerPhysicalAddressValidationError || '') !== '') {
            displayFlag = true;
        }

        return displayFlag;
    }


    shouldDisplayPhysicalZipCodeError(): boolean {
        let displayFlag = false;

        if ((this.employerValidateResult.employerPhysicalZipCodeError || '') !== '') {
            displayFlag = true;
        }


        if ((this.employerValidateResult.employerPhysicalAddressValidationError || '') !== '') {
            displayFlag = true;
        }

        return displayFlag;
    }


    shouldDisplayMainPhoneError(): boolean {
        return ((this.employerValidateResult.employerMainPhoneError || '') !== '');
    }


    shouldDisplayMainFaxError(): boolean {
        return ((this.employerValidateResult.employerMainFaxError || '') !== '');
    }

}
