
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductOffice, ProductOfficeToolbox, ProductSectionLookupService} from '@orhp/phx-product-ui-module';
import {ProductSection} from '@orhp/phx-product-ui-module';
import {ProductGroupProduct} from '@orhp/phx-product-ui-module';
import {ProductGroup} from '@orhp/phx-product-ui-module';
import {PlanOrderService} from '../_services/plan-order.service';
import {ProductLookupParams} from '@orhp/phx-product-ui-module';
import {Product} from '@orhp/phx-product-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {ArrayUtility, MessagingFormatter} from '@orhp/phx-common-ui-module';
import {ProductGroupType} from '@orhp/phx-product-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {SystemMessage} from '@orhp/phx-system-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {SystemMessageLookupParams} from '@orhp/phx-system-ui-module';
import {RegionLookupService} from '@orhp/phx-geography-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {DurationEnum} from '../_src/price-duration-enum';


@Component({
  selector: 'app-plan-order-product',
  templateUrl: './product.component.html',
  styleUrls: [
    '../plan-order.scss',
    './product.component.scss'
  ]
})

export class PlanOrderProductComponent extends PlanOrderBaseComponent implements OnInit {

  formSeries: string[] = null;

  allProductSections: ProductSection[] = null;

  selectedProductSection: ProductSection = null;

  noProductsMessage: string = null;
  cresMemberConfirmationMessage: string = null;
  nonCresMemberConfirmationMessage: string = null;

  step1Text: string = null;
  step1RegionText: string = null;
  step2Text: string = null;

  productsLoadedFlag = false;

  selectedProduct: Product = null;

  basicCoverages = ['Heating System', 'Electrical System', 'Plumbing System', 'Kitchen Appliances', 'Rekey Service'];

  deluxeCoverages = ['Everything In Basic', 'Air Conditioner/Cooler', 'Washer/Dryer', 'Code Violations', 'Permits/Haul Away', 'Deluxe Enhancements'];

  eliteCoverages = ['Everything In Deluxe', 'Pre-season HVAC Tune-Up', 'No HVAC Refrigerant Limit', 'Ice/Water Dispensers', 'Doubles Appliance Limit', 'Doubles Washer/Dryer Limit', 'Doubles Enhancement Limit'];

  get productSections(): ProductSection[] {
    const sections = [];

    this.allProductSections.forEach((indexProductSection: ProductSection) => {
      sections.push(indexProductSection);
    });

    return sections;
  }

  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }

  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }

  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private planOrderAuthService: PlanOrderAuthService,
              private systemService: PhxSystemService,
              private systemMessageLookupService: SystemMessageLookupService,
              private regionLookupService: RegionLookupService,
              private productSectionLookupService: ProductSectionLookupService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }


  // on init
  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'product');

    const observables = [];

    observables.push(this.fetchProducts());
    observables.push(this.fetchSystemMessages());
    observables.push(this.fetchMarketingHeaderMessage());

    Observable.forkJoin(observables).subscribe((results: any[]) => {
      this.productsLoadedFlag = true;
    });

  }


  // is the current user a CRES user
  isCresUser(): boolean {
    let cresFlag = false;

    const customer = this.planOrderAuthService.customer;

    if (customer) {
      if (customer.cresFlag) {
        cresFlag = true;
      }

      if (customer.employer && customer.employer.cresFlag) {
        cresFlag = true;
      }
    }

    return cresFlag;
  }


  // loads system messages
  fetchSystemMessages(): Observable<boolean> {
    const observable = Observable.create((subscriber: Subscriber<boolean>) => {
      const params = new SystemMessageLookupParams();

      params.type =
        SystemMessageTypes.cresMemberConfirmation + ',' +
        SystemMessageTypes.nonCresMemberConfirmation + ',' +
        SystemMessageTypes.step1Message + ',' +
        SystemMessageTypes.step2Message;

      // fetch the system messages
      this.systemMessageLookupService.systemMessageLookup(params).subscribe((messages: SystemMessage[]) => {
        // loop over the results
        messages.forEach((indexMessage: SystemMessage) => {

          const indexMessageType = indexMessage.type.toLowerCase();

          if (indexMessageType === SystemMessageTypes.cresMemberConfirmation) {
            this.cresMemberConfirmationMessage = indexMessage.message;

          } else if (indexMessageType === SystemMessageTypes.nonCresMemberConfirmation) {
            this.nonCresMemberConfirmationMessage = indexMessage.message;

          } else if (indexMessageType === SystemMessageTypes.step1Message) {
            this.step1Text = indexMessage.message;

          } else if (indexMessageType === SystemMessageTypes.step2Message) {
            this.step2Text = indexMessage.message;
          }
        });

        subscriber.next(true);
        subscriber.complete();
      });
    });

    return observable;
  }




  // fetches the marketing header message
  fetchMarketingHeaderMessage(): Observable<boolean> {
    const observable = Observable.create((subscriber: Subscriber<boolean>) => {
      const params = new SystemMessageLookupParams();

      params.type = SystemMessageTypes.step1MessageByRegion;
      params.regionID = MessagingFormatter.integerToString(this.planOrderApplication.propertyRegionID);

      this.systemMessageLookupService.systemMessageLookup(params).subscribe((systemMessages: SystemMessage[]) => {
        // if a message was returned
        if ((systemMessages || []).length) {
          const systemMessage = systemMessages[0];

          this.step1RegionText = systemMessage.message;
        }


        subscriber.next(true);
        subscriber.complete();
      });

    });

    return observable;
  }





  // load products
  fetchProducts(): Observable<boolean> {
    const observable = Observable.create((subscriber: Subscriber<boolean>) => {
      const property = this.planOrderApplication.property;

      const params = new ProductLookupParams();

      params.zipCode = property.zipCode;

      if (property.propertyType) {
        params.propertyType = property.propertyType.propertyType;
      }

      // if the user specified a transaction type
      const fetchTransactionType = this.planOrderApplication.transactionType;

      if (fetchTransactionType) {
        params.transactionTypeID = fetchTransactionType.transactionTypeID;

        // if the user selected a New Construction Flag
        if (this.planOrderApplication.newConstructionFlag) {
          params.newConstructionFlagID = this.planOrderApplication.newConstructionFlag.newConstructionFlagID;
        }

        // what's the orderer role
        const role = this.planOrderApplication.role;

        // if a role was specified
        if (role) {
          if (role.onlyQuoteFlag) {
            params.onlyQuoteFlag = true;

          } else {
            params.hasInitiatingAgentFlag = this.planOrderApplication.hasInitiatingAgent();
            params.hasTelemarketingAgentFlag = !!this.planOrderApplication.telemarketingAgent;
          }
        }

      // if we're looking up based on market segment
      } else if (this.planOrderApplication.marketSegmentID) {
        params.marketSegmentID = this.planOrderApplication.marketSegmentID;
      }


      // test for agent
      const initiatingAgent = this.planOrderApplication.initiatingAgent;
      const initiatingOffice = (initiatingAgent ? initiatingAgent.employer : null);

      if (initiatingOffice && initiatingOffice.employerID) {
        params.employerID = String(initiatingOffice.employerID);
      }


      // if no agent was specified, test for the current user
      if (!params.employerID) {
        const employer = this.planOrderAuthService.employer;

        if (!!employer) {
          params.employerID = String(employer.employerID);
        }
      }



      this.productSectionLookupService.fetchProductSections(params)
        .subscribe((fetchedProductSections: ProductSection[]) => {

          const filteredProductSections = [];
          const transactionType = this.planOrderApplication.transactionType;

          fetchedProductSections.forEach((indexSection: ProductSection) => {
            let includeSectionFlag = true;

            // filter out CRES products for Realtor Referrals
            if (indexSection.cresFlag && transactionType && transactionType.realtorReferralFlag) {
              includeSectionFlag = false;
            }

            if (includeSectionFlag) {
              filteredProductSections.push(indexSection);
            }
          });


          let formSeries = [];
          const productSections = [];

          // loop over product sections, pulling out groups
          filteredProductSections.forEach((indexSection: ProductSection) => {
            // if the section has products, include it
            const hasProductsFlag = !!indexSection.productGroups.length;

            if (hasProductsFlag) {
              productSections.push(indexSection);

              // look for the form series
              const formSeriesFound = !!ArrayUtility.arrayFind(formSeries, (testSeries: string): boolean => {
                return (indexSection.formSeries.toLowerCase() === testSeries.toLowerCase());
              });

              // if the form series wasn't found, add it
              if (!formSeriesFound) {
                formSeries.push(indexSection.formSeries);
              }
            }
          });

          // if there are fewer than 4 product sections, do NOT group by series
          if (productSections.length <= 4) {
            formSeries = ['all'];
          }

          this.formSeries = formSeries;
          this.allProductSections = productSections;


          // if a product has already been selected
          const selectedProduct = this.planOrderApplication.product;

          if (selectedProduct) {
            this.selectedProductSection = ArrayUtility.arrayFind(this.productSections,
              (testSection: ProductSection): boolean => {

                return testSection.hasProduct(selectedProduct);
            });
          }


          // if there was no product section selected and the user is CRES
          if (!this.selectedProductSection && this.isCresUser()) {
            // loop over sections and pick the first CRES one
            this.productSections.forEach((indexSection: ProductSection) => {
              if (indexSection.cresFlag && !this.selectedProductSection) {
                this.selectedProductSection = indexSection;
              }
            });
          }


          // if there was no product section selected and there are product sections
          if (!this.selectedProductSection && this.productSections.length) {
            // default to the first product section
            this.selectedProductSection = this.productSections[0];
          }


          // if there are no product sections, load the "no products" message
          if (!this.productSections.length) {
            this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/no-products-found');
            let lookupMessageType = 'plan-order-no-products-found-message';
            if (this.isConsumerSpecificOnly()) {
              lookupMessageType = 'plan-order-no-dtc-specific-products-message';
            }

            this.systemMessageLookupService.systemMessageLookupByType(lookupMessageType)
              .subscribe((systemMessage: SystemMessage) => {
                let messageText = systemMessage.message;

                // substitute in the apps phone number
                const appsPhoneNumber = this.planOrderAuthService.appsPhoneNumber;

                messageText = messageText.replace('{{appsPhoneNumber}}', appsPhoneNumber);

                this.noProductsMessage = messageText;

                subscriber.next(false);
                subscriber.complete();
              });

          } else {
            subscriber.next(true);
            subscriber.complete();
          }

        });
    });

    return observable;
  }



  productSectionsByCresFlag(cresFlag: boolean): ProductSection[] {
    return this.filteredProductSections(null, cresFlag);
  }




  step1DisplayInstructions(): string {
    const text = (!!this.step1RegionText ? this.step1RegionText : this.step1Text);

    return text;
  }


  step2DisplayInstructions(): string {
    return this.step2Text;
  }


  isDirectToConsumer(): boolean {
    return !!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag;
  }


  isConsumerSpecificOnly(): boolean {
    return !!this.productOfficeToolbox && !!this.productOfficeToolbox.autoLoginGuestGUID;
  }


  displayPlanFeeDuration(): string {
    let textToDisplay = '';

    if (this.isDirectToConsumer() && !!DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode]) {
      let theDuration = (DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode].toString().split('/')[1]).trim();
      if (theDuration === 'day') {
        theDuration = 'dai';
      }

      const firstCharToUpper = theDuration.charAt(0);
      textToDisplay = firstCharToUpper.toUpperCase() + theDuration.substring(1) + 'ly';
    }

    return textToDisplay;
  }


  displayTradeCallFeeText(): string {
    let textToDisplay = 'Trade Call Fee';

    if (this.isDirectToConsumer() && !!DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode]) {
      textToDisplay = textToDisplay + '*';
    }

    return textToDisplay;
  }


  // returns product sections by CRES flag
  filteredProductSections(formSeries: string, cresFlag: boolean): ProductSection[] {
    const sections = [];

    if (this.productSections) {
      this.productSections.forEach((indexSection: ProductSection) => {
        let includeFlag = true;

        if (indexSection.cresFlag !== cresFlag) {
          includeFlag = false;
        }

        if (formSeries) {
          let formValidFlag = false;

          formValidFlag = formValidFlag || (indexSection.formSeries === formSeries);
          formValidFlag = formValidFlag || (formSeries === 'all');

          if (!formValidFlag) {
            includeFlag = false;
          }
        }

        if (includeFlag) {
          sections.push(indexSection);
        }
      });
    }

    return sections;
  }



  // should the CRES Members Only message be displayed
  shouldDisplayCresMembersOnlyMessage(formSeries: string, cresFlag: boolean): boolean {
    let displayFlag = true;

    // if this is not a CRES section
    if (!cresFlag) {
      displayFlag = false;

    // if this is a CRES section
    } else {
      let hasProductsFlag = false;

      const productSections = this.filteredProductSections(formSeries, cresFlag);

      productSections.forEach((indexSection: ProductSection) => {
        indexSection.productGroups.forEach((indexGroup: ProductGroup) => {
          hasProductsFlag = hasProductsFlag || !!indexGroup.products.length;
        });
      });

      if (!hasProductsFlag) {
        displayFlag = false;
      }
    }


    return displayFlag;
  }


  /**
   * Returns the title for the given product group
   * @param group
   */
  subtitleTexts(group: ProductGroup): string[] {
    const texts: string[] = [];
    const products = (group.products || []);

    // if there are any products, pull the first product
    if (products.length) {
      const text = products[0].subtitleText;

      if (!!text) {
        text.split('\n').forEach((ixString: string) => {
          const ixTestString = ixString.trim();

          if (ixTestString.length) {
            texts.push(ixTestString);
          }
        });
      }
    }

    return texts;
  }





  productPricesForGroup(productGroup: ProductGroup): ProductDisplayPrice[] {
    const productPricesList = [];

    // loop over the products
    productGroup.products.forEach((indexProductGroupProduct: ProductGroupProduct) => {
      const indexProduct = indexProductGroupProduct.product;

      // look for an existing matching price
      let productDisplayPrice: ProductDisplayPrice = ArrayUtility.arrayFind(
        productPricesList,
        (testPrice: ProductDisplayPrice): boolean => {

          let flag = true;

          flag = flag && (testPrice.premium === indexProduct.premium);
          flag = flag && (testPrice.tradeCallFee === indexProduct.tradeCallFee);

          return flag;
        });

      // if it wasn't found, create one
      if (!productDisplayPrice) {
        productDisplayPrice = new ProductDisplayPrice();
        productDisplayPrice.premium = indexProduct.premium;
        productDisplayPrice.tradeCallFee = indexProduct.tradeCallFee;

        productPricesList.push(productDisplayPrice);
      }

      productDisplayPrice.productGroupProducts.push(indexProductGroupProduct);
    });

    return productPricesList;
  }






  productRowsForSelectedSection(): ProductDisplayRow[] {
    const section = this.selectedProductSection;

    const productPricesByGroupID = {};


    let maxProductPricePerGroupCount = 0;


    // pull unique pricing from all of the products
    section.productGroups.forEach((indexProductGroup: ProductGroup) => {
      const indexProductGroupID = indexProductGroup.productGroupID;

      const productPricesList = this.productPricesForGroup(indexProductGroup);

      if (productPricesList.length > maxProductPricePerGroupCount) {
        maxProductPricePerGroupCount = productPricesList.length;
      }

      productPricesByGroupID[indexProductGroupID] = productPricesList;
    });



    // create an array of rows
    const rows = [];

    // loop until the maximum number of unique prices per group
    for (let productPriceIndex = 0; productPriceIndex < maxProductPricePerGroupCount; productPriceIndex++) {
      // what is the maximum number of products per unique price?
      let maxProductsPerPrice = 0;

      section.productGroups.forEach((indexProductGroup: ProductGroup) => {
        // retrieve product prices for the group
        const productPricesList = productPricesByGroupID[indexProductGroup.productGroupID] || [];

        productPricesList.forEach((indexProductDisplayPrice: ProductDisplayPrice) => {
          const indexProductCount = indexProductDisplayPrice.productGroupProducts.length;

          if (indexProductCount > maxProductsPerPrice) {
            maxProductsPerPrice = indexProductCount;
          }
        });
      });




      // loop over the maximum products per price
      for (let productInPriceIndex = 0; productInPriceIndex < maxProductsPerPrice; productInPriceIndex++) {
        const row = new ProductDisplayRow();
        row.rowType = ProductDisplayRowType.productButton;

        let rowHasProductFlag = false;

        // loop over the product groups
        section.productGroups.forEach((indexProductGroup: ProductGroup) => {
          const column = new ProductDisplayColumn();
          column.row = row;

          row.columns.push(column);

          // pull the produce prices for the group
          const productPricesForGroup = productPricesByGroupID[indexProductGroup.productGroupID] || [];

          // pull the current product price
          let productPrice: ProductDisplayPrice = null;

          if (productPriceIndex < productPricesForGroup.length) {
            productPrice = productPricesForGroup[productPriceIndex];
          }

          // pull the current ProductGroupProduct
          if (productPrice) {
            if (productInPriceIndex < productPrice.productGroupProducts.length) {
              column.productGroupProduct = productPrice.productGroupProducts[productInPriceIndex];

              rowHasProductFlag = true;
            }
          }
        });


        if (rowHasProductFlag) {
          rows.push(row);
        }
      }


      // Add a price row
      const priceRow = new ProductDisplayRow();
      priceRow.rowType = ProductDisplayRowType.priceDisplay;

      let includePriceRow = false;

      // loop over the product groups
      section.productGroups.forEach((indexProductGroup: ProductGroup) => {
        const priceColumn = new ProductDisplayColumn();
        priceColumn.row = priceRow;

        priceRow.columns.push(priceColumn);

        // pull the produce prices for the group
        const productPricesForGroup = productPricesByGroupID[indexProductGroup.productGroupID] || [];

        // pull the current product price
        let productPrice: ProductDisplayPrice = null;

        if (productPriceIndex < productPricesForGroup.length) {
          productPrice = productPricesForGroup[productPriceIndex];
        }

        // pull the current ProductGroupProduct
        if (productPrice) {
          priceColumn.premium = productPrice.premium;
          priceColumn.tradeCallFee = productPrice.tradeCallFee;
          priceColumn.priceFlag = true;

          includePriceRow = true;
        }
      });


      if (includePriceRow) {
        rows.push(priceRow);
      }

    }



    // loop over all of the rows
    for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
      const indexCurrentRow: ProductDisplayRow = rows[rowIndex];
      const indexPrevRow: ProductDisplayRow = rows[rowIndex - 1];

      // loop over the columns
      for (let columnIndex = 0; columnIndex < indexCurrentRow.columns.length; columnIndex++) {
        const indexCurrentCol: ProductDisplayColumn = indexCurrentRow.columns[columnIndex];
        const indexPrevCol: ProductDisplayColumn = indexPrevRow.columns[columnIndex];

        let flag = false;

        // if the previous row is a button row
        if (this.isProductDisplayRowButton(indexPrevRow)) {
          // if the previous row had a product and the new column has a product
          flag = flag || (!!indexPrevCol.product && !!indexCurrentCol.product);

          // if the current row is a price row and it has a price in it
          flag = flag || (this.isProductDisplayRowPrice(indexCurrentRow) && indexCurrentCol.priceFlag);
        }

        // if the previous row is a price row
        if (this.isProductDisplayRowPrice(indexPrevRow)) {
          // if the current row has a product
          flag = flag || !!indexCurrentCol.product;
        }

        if (flag) {
          indexCurrentCol.borderTopFlag = true;
        }
      }
    }



    return rows;
  }







  isProductDisplayRowButton(row: ProductDisplayRow): boolean {
    return (row.rowType === ProductDisplayRowType.productButton);
  }



  shouldDisplayProductSelectorButton(column: ProductDisplayColumn): boolean {
    let flag = true;

    flag = flag && this.isProductDisplayRowButton(column.row);

    flag = flag && !!column.product;

    return flag;
  }



  isProductDisplayRowPrice(row: ProductDisplayRow): boolean {
    return (row.rowType === ProductDisplayRowType.priceDisplay);
  }


  shouldDisplayProductPrice(column: ProductDisplayColumn): boolean {
    let flag = true;

    flag = flag && this.isProductDisplayRowPrice(column.row);

    flag = flag && (column.premium !== null);

    return flag;
  }



  // should the existing product be displayed?
  shouldDisplaySelectedProduct(): boolean {
    return !!this.planOrderApplication.product;
  }


  // should display No Products found
  shouldDisplayNoProductsFound(): boolean {
    let displayFlag = true;

    if (this.productSections.length) {
      displayFlag = false;
    }

    if (!this.noProductsMessage) {
      displayFlag = false;
    }

    return displayFlag;
  }



  cssClassForProductSectionSelectorButton(section: ProductSection): any {
    const css = {};

    css['btn'] = true;
    css['btn-product-section-selector'] = true;
    css[section.cssClassName] = true;

    return css;
  }






  selectedBrochureUrl(language: ProductBrochureLanguage): string {
    let url: string = null;

    const productGroup = (this.selectedProductSection ? this.selectedProductSection.firstProductGroup : null);

    if (productGroup) {
      const form = productGroup.planForm;

      let filename: string = null;

      if (language === ProductBrochureLanguage.english) {
        filename = form.englishFormURL;

      } else if (language === ProductBrochureLanguage.spanish) {
        filename = form.spanishFormURL;
      }

      if (filename) {
        url = this.systemService.productPdfUrlForFile(filename);
      }
    }

    return url;
  }




  viewBrochureButtonText(language: ProductBrochureLanguage): string {
    let text: string = null;

    let languageText = '';

    if (language === ProductBrochureLanguage.english) {
      languageText = 'English';

    } else if (language === ProductBrochureLanguage.spanish) {
      languageText = 'Spanish';
    }

    const englishUrl = this.selectedBrochureUrl(ProductBrochureLanguage.english);
    const spanishUrl = this.selectedBrochureUrl(ProductBrochureLanguage.spanish);


    // if there is both an english and spanish url
    if (englishUrl && spanishUrl) {
      text = this.selectedProductSection.viewBrochureMultiLanguageText;
      if (this.isDirectToConsumer()) {
        text = 'View Sample Coverage';
        text = text.replace(' Brochure', '');
      }

      // if there is an english url
    } else if (englishUrl && (language === ProductBrochureLanguage.english)) {
      text = this.selectedProductSection.viewBrochureSingleLanguageText;
      if (this.isDirectToConsumer()) {
        text = 'View Sample Coverage';
        text = text.replace(' Brochure', '');
      }

    // if there is a spanish url
    } else if (spanishUrl && (language === ProductBrochureLanguage.spanish)) {
      text = this.selectedProductSection.viewBrochureSingleLanguageText;
    }

    if (text) {
      text = text.replace('{{language}}', languageText);
    }

    return text;

  }



  // should the brochure buttons be displayed?
  shouldDisplayBrochureButtons(): boolean {
    const displayFlag = !!this.viewBrochureEnglishButtonText() || !!this.viewBrochureSpanishButtonText();

    return displayFlag;
  }


  // should the View Current Brochure button be displayed
  viewBrochureEnglishButtonText(): string {
    return this.viewBrochureButtonText(ProductBrochureLanguage.english);
  }


  viewBrochureSpanishButtonText(): string {
    return this.viewBrochureButtonText(ProductBrochureLanguage.spanish);
  }



  // should the section tab bar be displayed
  shouldDisplaySectionTabBar(): boolean {
    return (this.allProductSections.length > 1);
  }




  // should the "Choose your Coverage" text display?
  shouldDisplayChooseYourCoverageText(): boolean {
    return this.shouldDisplaySectionTabBar();
  }




  // should the marketing text be displayed for the section?
  shouldDislayMarketingTextForSection(productSection: ProductSection): boolean {
    const marketingText = (productSection.marketingText || '');
    const displayFlag = (marketingText !== '');

    return displayFlag;
  }


  // user clicked a section
  didClickProductSection(productSection: ProductSection): void {
    this.selectedProductSection = productSection;
  }


  // is the section selected?
  isSectionSelected(productSection: ProductSection): boolean {
    return (this.selectedProductSection === productSection);
  }


  // is the product selected?
  isProductGroupProductSelected(productGroupProduct: ProductGroupProduct): boolean {
    return this.isProductSelected(productGroupProduct.product);
  }



  // splits the button title for a product
  productSelectionTitleParts(productGroupProduct: ProductGroupProduct): string[] {
    let text = productGroupProduct.selectionTitle;
    text = text.replace(/\\n/g, '\n');

    const textParts = text.split('\n');

    return textParts;
  }



  // button title
  buttonTitleForProduct(productGroupProduct: ProductGroupProduct): string {
    const textParts = this.productSelectionTitleParts(productGroupProduct);

    return textParts[0];
  }


  // button text
  buttonTextForProduct(productGroupProduct: ProductGroupProduct): string[] {
    const text = productGroupProduct.selectionText || '';

    const textParts: string[] = [];

    if (text === '') {
      const titleParts = this.productSelectionTitleParts(productGroupProduct);

      for (let partIndex = 1; partIndex < titleParts.length; partIndex++) {
        textParts.push(titleParts[partIndex]);
      }

    } else {
      textParts.push(text);
    }

    return textParts;
  }



  // is the product selected?
  isProductSelected(product: Product): boolean {
    let selectedFlag = false;

    const selectedProduct = this.planOrderApplication.product;

    if (selectedProduct && selectedProduct.isEqual(product)) {
      selectedFlag = true;
    }

    return selectedFlag;
  }



  shouldDisplayMobileSeparatorLine(productGroupProduct: ProductGroupProduct): boolean {
    const productPrices = this.productPricesForGroup(productGroupProduct.productGroup);

    let lineFlag = false;

    for (let priceIndex = 0; priceIndex < productPrices.length; priceIndex++) {
      const indexPrice = productPrices[priceIndex];

      for (let productIndex = 0; productIndex < indexPrice.productGroupProducts.length; productIndex++) {
        const indexProductGroupProduct = indexPrice.productGroupProducts[productIndex];

        if (productGroupProduct === indexProductGroupProduct) {
          if (productIndex > 0) {
            lineFlag = true;
          }
        }
      }
    }

    return lineFlag;
  }




  // user clicked the PDF for the product
  didClickEnglishBrochureButton() {
    this.didClickBrochureButton(ProductBrochureLanguage.english);
  }


  didClickSpanishBrochureButton() {
    this.didClickBrochureButton(ProductBrochureLanguage.spanish);
  }


  didClickBrochureButton(language: ProductBrochureLanguage) {
    this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/product-pdf/' + language);

    const pdfUrl = this.selectedBrochureUrl(language);

    if (pdfUrl) {
      setTimeout(() => {
        window.open(pdfUrl, '_blank');
      });
    }
  }




  // user clicked to continue to the optional coverage screen
  didClickExistingProduct() {
    const url = '../optional-coverage';

    this.router.navigate([url], { relativeTo: this.route });
  }


  // user clicked a product
  didClickProductGroupProduct(productGroupProduct: ProductGroupProduct): void {
    this.selectedProduct = productGroupProduct.product;

    if (!this.shouldDisplayCresConfirmation()) {
      this.gotoNextStep();
    }
  }


  // go to the next step
  gotoNextStep() {
    this.planOrderApplication.product = this.selectedProduct;

    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }



  // should the CRES dialog display
  shouldDisplayCresConfirmation(): boolean {
    let displayFlag = false;

    if (this.shouldDisplayCresMemberConfirmation()) {
      displayFlag = true;
    }

    if (this.shouldDisplayNonCresMemberConfirmation()) {
      displayFlag = true;
    }

    return displayFlag;
  }


  // should the CRES-member dialog display
  shouldDisplayCresMemberConfirmation(): boolean {
    let displayFlag = false;

    if (this.selectedProduct) {
      const productCresFlag = this.selectedProduct.cresFlag;
      const cresUserFlag = this.isCresUser();

      const cresProducts = this.productSectionsByCresFlag(true);
      const hasCresProductsFlag = !!cresProducts.length;

      const orhpUserFlag = this.planOrderAuthService.isOrhpUser();

      if (cresUserFlag && !productCresFlag && hasCresProductsFlag && !orhpUserFlag) {
        displayFlag = true;
      }
    }

    return displayFlag;
  }


  // should the non-CRES member dialog display?
  shouldDisplayNonCresMemberConfirmation(): boolean {
    let displayFlag = false;

    if (this.selectedProduct) {
      const productCresFlag = this.selectedProduct.cresFlag;
      const cresUserFlag = this.isCresUser();

      const nonCresProducts = this.productSectionsByCresFlag(false);
      const hasNonCresProductsFlag = !!nonCresProducts.length;

      const orhpUserFlag = this.planOrderAuthService.isOrhpUser();

      if (!cresUserFlag && productCresFlag && hasNonCresProductsFlag && !orhpUserFlag) {
        displayFlag = true;
      }
    }

    return displayFlag;
  }



  // the user clicked the CRES redo button
  didClickCresRedo() {
    if (this.selectedProduct) {
      let productSections: ProductSection[] = null;

      // if the selected product is CRES
      if (this.selectedProduct.cresFlag) {
        productSections = this.productSectionsByCresFlag(false);

      // if the selected product is not CRES
      } else {
        productSections = this.productSectionsByCresFlag(true);
      }

      if (productSections && productSections.length) {
        this.selectedProductSection = productSections[0];
      }
    }

    this.selectedProduct = null;
  }


  // the user clicking to continue
  didClickCresContinue() {
    this.gotoNextStep();
  }


  didClickAgentToolbox() {
    self.window.location.replace('/home');
  }

}


enum ProductBrochureLanguage {
  english = 100,
  spanish = 200
}


class SystemMessageTypes {
  static cresMemberConfirmation = 'plan-order-product-cres-member-confirmation';
  static nonCresMemberConfirmation = 'plan-order-product-non-cres-member-confirmation';
  static step1MessageByRegion = 'plan-order-product-step-1-message-by-region';
  static step1Message = 'plan-order-product-step-1-message';
  static step2Message = 'plan-order-product-step-2-message';
}



class ProductDisplayPrice {
  premium: number;
  tradeCallFee: number;
  productGroupProducts: ProductGroupProduct[] = [];
}


enum ProductDisplayRowType {
  productButton = 10,
  priceDisplay = 20
}


class ProductDisplayRow {

  rowType: ProductDisplayRowType = null;

  columns: ProductDisplayColumn[] = [];

}


class ProductDisplayColumn {

  row: ProductDisplayRow = null;

  productGroupProduct: ProductGroupProduct = null;

  priceFlag = false;

  premium: number = null;
  tradeCallFee: number = null;

  borderTopFlag = false;

  get product(): Product {
    return (this.productGroupProduct ? this.productGroupProduct.product : null);
  }

  get productGroup(): ProductGroup {
    return (this.productGroupProduct ? this.productGroupProduct.productGroup : null);
  }

}






