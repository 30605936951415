import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileValidationResult} from "../../_services/profile-validation.service";
import {ProductOffice, ProductOfficeGroupLookupParams, ProductOfficeGroupLookupService} from '@orhp/phx-product-ui-module';
import {OfficeAgentLookupCacheService, OfficeLookupType} from '../../../_modules/office-agent-lookup/_services/lookup-cache.service';
import {StringUtility} from '@orhp/phx-common-ui-module';

@Component({
    selector: 'phx-user-guest',
    templateUrl: './user-guest.component.html',
    styleUrls: ['./user-guest.component.scss']
})
export class UserGuestComponent implements OnInit {

    _guest: any = {password:"", password2:"", email:"", email2:""};


    get email(): string{
        return this._guest.email;
    }

    set email(e: string){
        this.profileValidationResult.emailIsValid = true;
        this.profileValidationResult.emailErrorMessage = "";
        this.profileValidationResult.guestIsValid = true;
        this.profileValidationResult.guestErrorMessage = "";
        this._guest.email = e.trim();
        this._guest.productOfficeID = '';

        this.testEmailChange();

        this.officeLookupCacheService.clearCache();
        this.officeLookupCacheService.lastOfficeLookupType = OfficeLookupType.none;
    }


    get email2():string{
        return this._guest.email2;
    }

    set email2(e: string){
        this.profileValidationResult.email2IsValid = true;
        this.profileValidationResult.email2ErrorMessage = "";
        this._guest.email2 = e.trim();

        this.testEmailChange();
    }

    get password():string{
        return this._guest.password;
    }

    set password(p){
        this.profileValidationResult.passwordIsValid = true;
        this.profileValidationResult.passwordErrorMessage = "";
        this._guest.password = p;
    }


    get password2():string{
        return this._guest.password2;
    }

    set password2(p){
        this.profileValidationResult.password2IsValid = true;
        this.profileValidationResult.password2ErrorMessage = "";
        this._guest.password2 = p;
    }


    @Input()
    set guest(g: any) {
        this._guest = g;

        // if this is the initial email
        if (!this.initialEmail) {
          this.initialEmail = this._guest.email;
        }
    }

    get guest(): any{
        return this._guest;
    }

    @Input()
    profileValidationResult: ProfileValidationResult;

    @Input()
    productOffice: ProductOffice;

    @Output()
    onEmailChange = new EventEmitter<string>();


    initialEmail: string = null;


    constructor(private officeLookupCacheService: OfficeAgentLookupCacheService
    ) { }



    ngOnInit() {
    }



    testEmailChange() {
      const email1 = this._guest.email || '';
      const email2 = this._guest.email2 || '';

      let notifyEmailChangeFlag = true;

      // are the emails valid?
      notifyEmailChangeFlag = notifyEmailChangeFlag && StringUtility.emailValidate(email1);
      notifyEmailChangeFlag = notifyEmailChangeFlag && StringUtility.emailValidate(email2);

      // do the emails match?
      notifyEmailChangeFlag = notifyEmailChangeFlag && (email1.toLowerCase() === email2.toLowerCase());

      if (notifyEmailChangeFlag) {
        this.onEmailChange.emit(email1);
      }
    }


}
