
import {TransactionRole} from '@orhp/phx-plan-ui-module';
import {TransactionTypeGroup} from '@orhp/phx-plan-ui-module';
import {TransactionType} from '@orhp/phx-plan-ui-module';
import {NewConstructionFlag} from '@orhp/phx-plan-ui-module';
import {Observable} from 'rxjs/Observable';

import {PropertyLookupValueService} from '@orhp/phx-property-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {ProductLookupValueService} from '@orhp/phx-product-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';

import {Response} from '@angular/http';
import {Injectable} from '@angular/core';

import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {ProductLookupValueFetchService} from '@orhp/phx-product-ui-module';
import {PlanLookupValueFetchService} from '@orhp/phx-plan-ui-module';
import {PropertyLookupValueFetchService} from '@orhp/phx-property-ui-module';
import {CustomerLookupValueFetchService} from '@orhp/phx-customer-ui-module';
import {PlanOrderAuthService} from './plan-order-auth.service';
import {AccountingLookupValueFetchService} from '@orhp/phx-accounting-ui-module';
import {CorrespondenceLookupValuesService} from '@orhp/phx-correspondence-ui-module';



@Injectable()

export class PlanOrderLookupValueService {

  lookupValuesLoaded = false;


  constructor(private propertyLookupValueFetchService: PropertyLookupValueFetchService,
              private customerLookupValueFetchService: CustomerLookupValueFetchService,
              private productLookupValueFetchService: ProductLookupValueFetchService,
              private planLookupValueService: PlanLookupValueService,
              private planLookupValueFetchService: PlanLookupValueFetchService,
              private planOrderAuthService: PlanOrderAuthService,
              private accountingLookupValueFetchService: AccountingLookupValueFetchService,
              private correspondenceLookupValueService: CorrespondenceLookupValuesService,
              private http: PhxHttpService) {
  }


  // loads values if needed
  loadValuesIfNeeded(): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber) => {
      // if lookup values aren't loaded
      if (!this.lookupValuesLoaded) {
        this.loadValues().subscribe((boolValue) => {
          subscriber.next(true);
          subscriber.complete();
        });

      // if lookup values are loaded
      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });

    return observable;
  }


  // loads values
  loadValues(): Observable<boolean> {

    const observable: Observable<boolean> = Observable.create((subscriber) => {

      // set up the observables to call
      const observables: Observable<boolean>[] = [];

      observables.push(this.accountingLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.customerLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.productLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.propertyLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.planLookupValueFetchService.fetchLookupValuesIfNeeded());
      observables.push(this.correspondenceLookupValueService.fetchLookupValuesIfNeeded());

      // call all observables
      Observable.forkJoin(observables).subscribe((results: boolean[]) => {
        let resultFlag = true;

        results.forEach((indexResultFlag: boolean) => {
          resultFlag = resultFlag && indexResultFlag;
        });

        subscriber.next(resultFlag);
        subscriber.complete();
      });
    });

    return observable;
  }




  // returns transaction types for display
  transactionTypesForDisplay(): TransactionType[] {
    const employerType = (!!this.planOrderAuthService.employer ? this.planOrderAuthService.employer.employerType : null);
    const telemarketingFlag = (employerType ? employerType.telemarketingFlag : false);

    const types = [];

    // filter transaction types
    const transactionTypeGroups = this.planLookupValueService.transactionTypeGroups;

    transactionTypeGroups.forEach((indexGroup: TransactionTypeGroup) => {
      indexGroup.transactionTypes.forEach((indexType: TransactionType) => {
        let flag = true;

        if (indexType.telemarketingFlag !== telemarketingFlag) {
          flag = false;
        }

        if (flag) {
          types.push(indexType);
        }
      });
    });

    return types;
  }





}

