
import {EventEmitter, Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';

import {PlanOrderApplication, PlanOrderApplicationUpdates} from '../_models/plan-order-application';

import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';

import {ProductLookupValueService, ProductLookupService} from '@orhp/phx-product-ui-module';

import {ActivityResult, IActivityResult, LocalStorageService, PhxHttpClientService, PhxHttpService} from '@orhp/phx-common-ui-module';

import {PropertyLookupValueService} from '@orhp/phx-property-ui-module';

import {PlanOrderLookupValueService} from './plan-order-lookup-value.service';
import {PlanOrderProgressBarStep} from '../_models/progress-bar-step';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderUpdateService} from './plan-order-update.service';
import {PlanOrderStepService} from './plan-order-step.service';
import {SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {MessagingFormatter} from '@orhp/phx-common-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {PropertyDomainFactoryService} from '@orhp/phx-property-ui-module';
import {PlanOrderDomainFactoryService} from './plan-order-domain-factory.service';
import {PlanOrderAuthService} from './plan-order-auth.service';
import {PlanOrderConfirmationRequest} from '../_src/plan-order-confirmation-request';
import {HttpErrorResponse} from '@angular/common/http';
import {CustomUrlParser} from '../../../_src/custom-url-parser';


@Injectable()

export class PlanOrderService {

  // we're storing the application here
  @SessionStorage('phx.toolbox.ui.PlanOrderApplicationJsonObject')
  planOrderApplicationJsonObject: any;


  // we'll keep the backup version here
  @SessionStorage('phx.toolbox.ui.PlanOrderApplicationBackupJsonObject')
  planOrderApplicationBackupJsonObject: any;



  @SessionStorage('phx.toolbox.ui.PlanOrderCustomOrderingUrl')
  planOrderCustomOrderingUrl: string;



  // here's a converted version of the application
  private _planOrderApplication: PlanOrderApplication = null;

  get planOrderApplication(): PlanOrderApplication {
    // if the application hasn't been loaded
    if (!this._planOrderApplication) {
      this._planOrderApplication = this.planOrderDomainFactoryService.newApplication();

      // validate that the custom URLs match
      const currentCustomUrl = CustomUrlParser.getCustomUrl() || '';
      const appCustomUrl = this.planOrderCustomOrderingUrl || '';
      const customUrlMatch = currentCustomUrl === appCustomUrl;

      // look for an existing application saved
      const json = this.planOrderApplicationJsonObject;

      // if the application was saved and the custom URLs match, load it
      if (json && customUrlMatch) {
        this.planOrderDomainFactoryService.populatePersistedApplication(this._planOrderApplication, json);

      // we have a new application, so persist it as-is
      } else {
        this.planOrderCustomOrderingUrl = CustomUrlParser.getCustomUrl();
        this.persistPlanOrderApplication();
      }
    }

    return this._planOrderApplication;
  }

  set planOrderApplication(application: PlanOrderApplication) {
    this._planOrderApplication = application;

    this.persistPlanOrderApplication();
  }


  newApplicationEventEmitter = new EventEmitter<PlanOrderApplication>();



  newPlanRoute = 'new-application';
  forceNewPlanRoute = 'clear-application';


  moduleInitFlag = false;




  constructor(private planOrderLookupValueService: PlanOrderLookupValueService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private planOrderAuthService: PlanOrderAuthService,
              private httpClient: PhxHttpClientService,
              private http: PhxHttpService) {

    // on logout, clear the plan order application
    this.planOrderAuthService.onLogout.subscribe((logoutFlag: boolean) => {
      if (logoutFlag) {
        this.planOrderApplication = null;
      }
    });

  }


  /**
   * Returns the auth service
   */
  authService(): PlanOrderAuthService {
    return this.planOrderAuthService;
  }



  // initializes the module, if needed
  initModuleIfNeeded(): Observable<boolean> {
    let observable: Observable<boolean> = null;

    // if the module has not been init
    if (!this.moduleInitFlag) {
      observable = this.initModule();

    // if the module has been init
    } else {
      observable = Observable.create((subscriber: Subscriber<boolean>) => {
        subscriber.next(true);
        subscriber.complete();
      });
    }

    return observable;
  }


  // initializes the module
  initModule(): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber) => {
      this.planOrderLookupValueService.loadValues().subscribe((boolValue) => {
        this.moduleInitFlag = true;

        subscriber.next(true);
        subscriber.complete();
      });
    });

    return observable;
  }








  // saves the application
  persistPlanOrderApplication(application?: PlanOrderApplication): void {
    let formBody = '{}';

    if (!application) {
      application = this._planOrderApplication;
    }

    // pull the persistence JSON values
    if (!application) {
      this.planOrderApplicationJsonObject = null;

      this.planOrderApplicationBackupJsonObject = null;

    } else {
      const applicationJsonObject = application.persistenceJsonValues();

      this.planOrderApplicationJsonObject = applicationJsonObject;

      const logObject = {
        'currentStep': (application.currentStep || ''),
        'applicationJson': JSON.stringify(applicationJsonObject)
      };

      formBody = JSON.stringify(logObject);
    }

    const apiUrl = 'planOrder/logTracking';

    this.http.post(apiUrl, formBody).subscribe((response: Response) => {
      // don't do anything here
    });
  }


  // backs up a copy of the application
  backupPlanOrderApplication()  {
    this.planOrderApplicationBackupJsonObject = this.planOrderApplicationJsonObject;
  }


  // restores a copy of the application
  restorePlanOrderApplicationBackup() {
    this.planOrderApplicationJsonObject = this.planOrderApplicationBackupJsonObject;

    this._planOrderApplication = null;
  }



  // sends a plan order confirmation
  sendPlanOrderConfirmation(request: PlanOrderConfirmationRequest): Observable<ActivityResult> {
    const observable = new Observable((subscriber: Subscriber<ActivityResult>) => {
      const url = 'planOrder/sendOrderConfirmations';
      const params = request.jsonValues();

      this.httpClient.post(url, params).subscribe((json: IActivityResult) => {
        let result: ActivityResult = null;

        if (json) {
          result = new ActivityResult<boolean>(json);
        }

        subscriber.next(result);
        subscriber.complete();

      }, (error: HttpErrorResponse) => {
        console.error('Error sending order confirmation:');
        console.error(error);

        subscriber.next(null);
        subscriber.complete();
      });
    });

    return observable;
  }


  /**
   * Clears the application
   */
  clear() {
    this.planOrderApplication = null;
    this.planOrderCustomOrderingUrl = null;
  }





}

