import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {LegacyLoginResult} from '../login/model/legacy-login-result';
import {AuthenticationService} from '../login/service';
import {ActivityResult, PhxSystemService, PhxUserLoginTokenService, UserLoginToken} from '@orhp/phx-common-ui-module';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-legacy-toolbox',
  templateUrl: './legacy-toolbox.component.html',
  styleUrls: []
})

export class LegacyToolboxComponent implements OnInit, OnDestroy {

  get redirectCode(): string {
    return <string>(this.route.snapshot.params['redirectTo'] || '').toLowerCase();
  }


  get loadingMessage(): string {
    const title = 'Loading ' + this.featureTitle;
    return title;
  }


  get featureTitle(): string {
    let title: string = null;

    switch (this.redirectCode) {
      case 'marketing-tools':
        title = 'Marketing Tools';
        break;

      case 'ae-toolbox':
        title = 'Reports Center';
        break;
    }

    return title;
  }



  pageChangeSubscription: Subscription = null;

  pageStatus = PageStatus.none;


  // valid legacy codes
  legacyCodes = ['marketing-tools', 'ae-toolbox'];


  constructor(private route: ActivatedRoute,
              private router: Router,
              private systemService: PhxSystemService,
              private userLoginTokenService: PhxUserLoginTokenService,
              private authenticationService: AuthenticationService) {

  }


  ngOnInit() {
    // validate the redirect code
    this.validateRedirectCode();

    // if the user is not logged in
    if (!this.authenticationService.loggedIn()) {
      this.pageStatus = PageStatus.login;

    // if the user is logged in
    } else {
      this.registerNavigationHandler();

      this.performLegacyLogin();
    }
  }


  ngOnDestroy() {
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
  }



  registerNavigationHandler() {
    // subscribe to a page change event
    this.pageChangeSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.performLegacyLogin();
      }
    });

  }


  performLegacyLogin() {
    // perform a legacy login
    this.pageStatus = PageStatus.loading;

    // retrieve a user login token
    this.userLoginTokenService.generateCurrentUserJumpToken().subscribe((result: ActivityResult<UserLoginToken>) => {
      const token = result.object;

      this.redirectWithLoginToken(this.redirectCode, token.guid);
    });

    /*

    this.authenticationService.legacyLogin().subscribe((loginResult: LegacyLoginResult) => {
      const loginSuccessFlag = (loginResult ? loginResult.loginSuccessFlag : false);

      // if the login was successful
      if (loginSuccessFlag) {
        this.pageStatus = PageStatus.success;

        // determine where to go
        switch (this.redirectCode) {
          case 'marketing-tools':
            this.redirectToMarketingTools();
            break;

          case 'ae-toolbox':
            this.redirectToAeToolbox();
            break;
        }

        // if the login was unsuccessful
      } else {
        this.pageStatus = PageStatus.failure;

      }
    });

     */
  }



  shouldDisplayLogin(): boolean {
    return (this.pageStatus === PageStatus.login);
  }


  shouldDisplayLoading(): boolean {
    return (this.pageStatus === PageStatus.loading);
  }


  shouldDisplaySuccess(): boolean {
    return (this.pageStatus === PageStatus.success);
  }

  shouldDisplayFailure(): boolean {
    return (this.pageStatus === PageStatus.failure);
  }



  // validates the redirect code
  validateRedirectCode(): void {
    let codeFoundFlag = false;

    this.legacyCodes.forEach((indexCode: string) => {
      if (indexCode.toLowerCase() === this.redirectCode) {
        codeFoundFlag = true;
      }
    });

    // if the code wasn't found, redirect to the home page
    if (!codeFoundFlag) {
      this.router.navigate(['/'], {});
    }
  }


  legacyUrlFromRedirectCode(code: string): string {
    let gotoUrl: string = null;

    switch (code) {
      case RedirectCode.marketingTools:
        gotoUrl = this.systemService.legacyToolboxUrl + 'tools/ct_portal.cfm';
        break;

      case RedirectCode.aeToolbox:
        gotoUrl = this.systemService.legacyToolboxUrl + 'ae_toolbox/';
        break;
    }

    return gotoUrl;
  }




  redirectWithLoginToken(redirectCode: string, loginToken: string) {
    // pull the URL to redirect to
    let gotoUrl = this.legacyUrlFromRedirectCode(redirectCode);

    // append the login token
    const urlAppender = (gotoUrl.indexOf('?') === -1 ? '?' : ':');

    gotoUrl += urlAppender + 'styxFerryman=' + encodeURIComponent(loginToken);

    window.open(gotoUrl);
  }



  didClickLogin() {
    this.router.navigate(['/'], {});
  }


  didClickCreateAccount() {
    this.router.navigate(['/signup'], {});
  }

}



enum RedirectCode {
  marketingTools = 'marketing-tools',
  aeToolbox = 'ae-toolbox'
}


enum PageStatus {
  none = 10,
  loading = 20,
  success = 30,
  failure = 40,
  login = 50
}
