

import {ActivatedRoute, Router} from '@angular/router';
import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Customer, Employer} from '@orhp/phx-customer-ui-module';

import {PlanOrderApplication} from '../_models/plan-order-application';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {CustomerUpdateService} from '@orhp/phx-customer-ui-module';
import {EmployerUpdateService} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {Observable} from 'rxjs/Observable';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {OfficeAgentLookupService} from '../../office-agent-lookup/_services/lookup.service';
import {PlanInvoiceRecipient} from '@orhp/phx-plan-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {DatePart, DateUtility} from '@orhp/phx-common-ui-module';
import {
  IOfficeAgentLookupValidateResult,
  OfficeAgentLookupValidateService
} from '../../office-agent-lookup/_services/validate.service';
import {OfficeAgentLookupRelationshipType} from '../../office-agent-lookup/_src/lookup-relationship-type';
import {PhxSystemModule} from '@orhp/phx-system-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PhxDateService} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupCacheService} from '../../office-agent-lookup/_services/lookup-cache.service';
import {Subscription} from 'rxjs/Subscription';
import {Address} from '@orhp/phx-common-ui-module';
import {PlanOrdererRoleDefault} from '@orhp/phx-plan-ui-module';



@Component({
  selector: 'app-plan-order-closing-info',
  templateUrl: './closing-info.component.html',
  styleUrls: [
    '../plan-order.scss',
    './closing-info.component.scss'
  ]
})


export class PlanOrderClosingInfoComponent
  extends PlanOrderBaseComponent
  implements OnInit, OnDestroy {

  // what is the lookup relationship passed to the component
  lookupRelationshipType = 'escrow-officer';

  // selected office and agent
  selectedEmployer: Employer = this.officeAgentLookupPersistenceService.employer;
  selectedCustomer: Customer = this.officeAgentLookupPersistenceService.customer;
  relationshipSkippedFlag = (this.planOrderApplication.closingAgentSkippedFlag || false);

  // validation error collection
  validationStatus: ValidationStatus = ValidationStatus.none;
  employerValidateResult: EmployerValidateResult = null;
  customerValidateResult: CustomerValidateResult = null;


  // if we're auto-matching to an office
  employerAutoMatch: Employer = null;
  customerAutoMatch: Customer = null;


  // closing info
  closingDateMode = ClosingDateMode.none;
  closingFileNumber = (this.planOrderApplication.closingFileNumber || '');
  estimatedClosingDateText: string = null;
  actualClosingDateText: string = null;


  get estimatedClosingDate(): Date {
    return this.dateService.parseDate(this.estimatedClosingDateText, true);
  }

  get actualClosingDate(): Date {
    return this.dateService.parseDate(this.actualClosingDateText, true);
  }


  // invoice recipients
  private _invoiceRecipients: PlanInvoiceRecipient[] = null;

  get invoiceRecipients(): PlanInvoiceRecipient[] {
    if (!this._invoiceRecipients) {
      // decide which invoice recipients to show
      const invoiceRecipients: PlanInvoiceRecipient[] = [];

      const hasInitiatingAgent = !!this.planOrderApplication.initiatingAgent;
      const hasCooperatingAgent = !!this.planOrderApplication.cooperatingAgent;

      this.planLookupValueService.invoiceRecipients.forEach((indexInvoiceRecipient: PlanInvoiceRecipient) => {
        let displayFlag = false;

        // test for listing agent
        if (indexInvoiceRecipient.listingAgentFlag) {
          if (hasInitiatingAgent && this.planOrderApplication.initiatingAgentListingFlag) {
            displayFlag = true;

          } else if (hasCooperatingAgent && this.planOrderApplication.cooperatingAgentListingFlag) {
            displayFlag = true;
          }

        // test for selling agent
        } else if (indexInvoiceRecipient.sellingAgentFlag) {
          if (hasInitiatingAgent && !this.planOrderApplication.initiatingAgentListingFlag) {
            displayFlag = true;

          } else if (hasCooperatingAgent && !this.planOrderApplication.cooperatingAgentListingFlag) {
            displayFlag = true;
          }

        // test for closing
        } else if (indexInvoiceRecipient.closingAgentFlag) {
          displayFlag = true;

        // test for home buyer/seller
        } else if (indexInvoiceRecipient.homeBuyerFlag || indexInvoiceRecipient.homeSellerFlag) {
          displayFlag = true;
        }


        if (displayFlag) {
          invoiceRecipients.push(indexInvoiceRecipient);
        }
      });

      this._invoiceRecipients = invoiceRecipients;
    }

    return this._invoiceRecipients;
  }


  // selected invoice recipient
  private _selectedInvoiceRecipientID: number = null;

  get selectedInvoiceRecipientID(): number {
    if (!this._selectedInvoiceRecipientID) {
      const appInvoiceRecipient = this.planOrderApplication.invoiceRecipient;

      if (appInvoiceRecipient) {
        this._selectedInvoiceRecipientID = appInvoiceRecipient.invoiceRecipientID;
      }
    }

    if (!this._selectedInvoiceRecipientID) {
      // look for the default invoice recipient
      this.invoiceRecipients.forEach((indexInvoiceRecipient: PlanInvoiceRecipient) => {
        if (indexInvoiceRecipient.defaultFlag) {
          this._selectedInvoiceRecipientID = indexInvoiceRecipient.invoiceRecipientID;
        }
      });
    }

    return this._selectedInvoiceRecipientID;
  }

  set selectedInvoiceRecipientID(selectedInvoiceRecipientID: number) {
    this._selectedInvoiceRecipientID = Number(selectedInvoiceRecipientID);
  }


  get selectedInvoiceRecipient(): PlanInvoiceRecipient {
    let recipient: PlanInvoiceRecipient = null;

    this.invoiceRecipients.forEach((indexInvoiceRecipient: PlanInvoiceRecipient) => {
      if (indexInvoiceRecipient.invoiceRecipientID === this.selectedInvoiceRecipientID) {
        recipient = indexInvoiceRecipient;
      }
    });

    return recipient;
  }


  employerEventSubscription: Subscription = null;
  customerEventSubscription: Subscription = null;
  skipEventSubscription: Subscription = null;

  allowDifferentOfficeSelectionFlag = true;
  allowDifferentAgentSelectionFlag = true;


  @ViewChild('estimatedClosingDateInput') estimatedClosingDateInput: ElementRef;
  @ViewChild('actualClosingDateInput') actualClosingDateInput: ElementRef;



  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private dateService: PhxDateService,
              private employerUpdateService: EmployerUpdateService,
              private customerUpdateService: CustomerUpdateService,
              private customerLookupValueService: CustomerLookupValueService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupValidateService: OfficeAgentLookupValidateService,
              private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
              private planLookupValueService: PlanLookupValueService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  // called when loaded
  ngOnInit() {
    super.ngOnInit();

    this.planLookupValueService.ordererRoleDefaults.forEach((indexRoleDefault: PlanOrdererRoleDefault) => {
      let flag = true;

      flag = flag && (indexRoleDefault.planRole === this.planOrderApplication.role);
      flag = flag && (indexRoleDefault.planTransactionType === this.planOrderApplication.transactionType);

      if (flag && indexRoleDefault.skipRoleStepFlag) {
        this.allowDifferentOfficeSelectionFlag = false;
        this.allowDifferentAgentSelectionFlag = false;
      }
    });

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'closing-info');

    this.estimatedClosingDateText = (this.dateService.formatDate(this.planOrderApplication.estimatedClosingDate, false) || '');
    this.actualClosingDateText = (this.dateService.formatDate(this.planOrderApplication.actualClosingDate, false) || '');

    const savedFileNumber = (this.planOrderApplication.closingFileNumber || '');

    // if we've been here before
    if (savedFileNumber !== '') {
      // what is the current closing date mode
      if (this.planOrderApplication.estimatedClosingDate) {
        this.closingDateMode = ClosingDateMode.estimatedClosingDate;

      } else if (this.planOrderApplication.actualClosingDate) {
        this.closingDateMode = ClosingDateMode.actualClosingDate;

      } else {
        this.closingDateMode = ClosingDateMode.noEstimatedClosingDate;
      }
    }


    // subscribe to employer updates
    this.employerEventSubscription = this.officeAgentLookupPersistenceService.employerEventEmitter.subscribe(
      (employer: Employer) => {

        this.selectedEmployer = employer;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to customer updates
    this.customerEventSubscription = this.officeAgentLookupPersistenceService.customerEventEmitter.subscribe(
      (customer: Customer) => {

        this.selectedCustomer = customer;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to skip events
    this.skipEventSubscription = this.officeAgentLookupService.skipEventEmitter.subscribe(
      (skipFlag: boolean) => {

        if (skipFlag) {
          this.didClickSkip();
        }
      });

  }


  ngOnDestroy() {
    this.officeAgentLookupCacheService.clearCache();

    this.employerEventSubscription.unsubscribe();
    this.customerEventSubscription.unsubscribe();
    this.skipEventSubscription.unsubscribe();
  }


  isNoClosingDateSelected(): boolean {
    return (this.closingDateMode === ClosingDateMode.noEstimatedClosingDate);
  }


  didClickSkipEstimatedClosingDate() {
    this.closingDateMode = ClosingDateMode.noEstimatedClosingDate;

    this.estimatedClosingDateText = '';
    this.actualClosingDateText = '';
  }


  didFocusEstimatedClosingDate() {
    this.closingDateMode = ClosingDateMode.estimatedClosingDate;

    if (this.shouldDisplayEstimatedClosingDateFocusWarning()) {
      this.estimatedClosingDateInput.nativeElement.blur();
    }
  }


  shouldDisplayEstimatedClosingDateFocusWarning(): boolean {
    let flag = true;

    if (this.actualClosingDateText === '') {
      flag = false;
    }

    let modeFlag = false;
    modeFlag = modeFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDate);
    modeFlag = modeFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDateCalendar);

    if (!modeFlag) {
      flag = false;
    }

    return flag;
  }


  didFocusActualClosingDate() {
    this.closingDateMode = ClosingDateMode.actualClosingDate;

    if (this.shouldDisplayActualClosingDateFocusWarning()) {
      this.actualClosingDateInput.nativeElement.blur();
    }
  }

  shouldDisplayActualClosingDateFocusWarning(): boolean {
    let flag = true;

    if (this.estimatedClosingDateText === '') {
      flag = false;
    }

    let modeFlag = false;
    modeFlag = modeFlag || (this.closingDateMode === ClosingDateMode.actualClosingDate);
    modeFlag = modeFlag || (this.closingDateMode === ClosingDateMode.actualClosingDateCalendar);

    if (!modeFlag) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayDateFocusWarning(): boolean {
    let flag = false;

    flag = flag || this.shouldDisplayEstimatedClosingDateFocusWarning();
    flag = flag || this.shouldDisplayActualClosingDateFocusWarning();

    return flag;
  }


  didClickCancelDatePrompt() {
    if (this.estimatedClosingDateText !== '') {
      this.closingDateMode = ClosingDateMode.estimatedClosingDate;

    } else if (this.actualClosingDateText !== '') {
      this.closingDateMode = ClosingDateMode.actualClosingDate;
    }
  }


  didClickClearOtherDate() {
    let estimatedFlag = false;
    estimatedFlag = estimatedFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDate);
    estimatedFlag = estimatedFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDateCalendar);

    let actualFlag = false;
    actualFlag = actualFlag || (this.closingDateMode === ClosingDateMode.actualClosingDate);
    actualFlag = actualFlag || (this.closingDateMode === ClosingDateMode.actualClosingDateCalendar);

    if (estimatedFlag) {
      this.actualClosingDateText = '';

      if (this.closingDateMode === ClosingDateMode.estimatedClosingDate) {
        this.estimatedClosingDateInput.nativeElement.focus();
      }

    } else if (actualFlag) {
      this.estimatedClosingDateText = '';

      if (this.closingDateMode === ClosingDateMode.actualClosingDate) {
        this.actualClosingDateInput.nativeElement.focus();
      }
    }

  }



  didClickEstimatedClosingDateCalendarButton() {
    // if the calendar is open, close it
    if (this.closingDateMode === ClosingDateMode.estimatedClosingDateCalendar) {
      this.closingDateMode = ClosingDateMode.estimatedClosingDate;

    // the calendar is not open so open it
    } else {
      this.closingDateMode = ClosingDateMode.estimatedClosingDateCalendar;
    }
  }


  didClickActualClosingDateCalendarButton() {
    // if the calendar is open, close it
    if (this.closingDateMode === ClosingDateMode.actualClosingDateCalendar) {
      this.closingDateMode = ClosingDateMode.actualClosingDate;

    // the calendar is not open so open it
    } else {
      this.closingDateMode = ClosingDateMode.actualClosingDateCalendar;
    }
  }


  didSelectEstimatedClosingDate(date: Date) {
    this.closingDateMode = ClosingDateMode.estimatedClosingDate;

    this.estimatedClosingDateText = this.dateService.formatDate(date, true);
  }


  didSelectActualClosingDate(date: Date) {
    this.closingDateMode = ClosingDateMode.actualClosingDate;

    this.actualClosingDateText = this.dateService.formatDate(date, true);
  }



  shouldDisplayEstimatedClosingDateCalendar(): boolean {
    let flag = true;

    if (this.closingDateMode !== ClosingDateMode.estimatedClosingDateCalendar) {
      flag = false;
    }

    if (this.shouldDisplayEstimatedClosingDateFocusWarning()) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayActualClosingDateCalendar(): boolean {
    let flag = true;

    if (this.closingDateMode !== ClosingDateMode.actualClosingDateCalendar) {
      flag = false;
    }

    if (this.shouldDisplayActualClosingDateFocusWarning()) {
      flag = false;
    }

    return flag;
  }




  shouldDisplayEmployerAutoMatch(): boolean {
    return !!this.employerAutoMatch;
  }


  shouldDisplayCustomerAutoMatch(): boolean {
    return !!this.customerAutoMatch;
  }


  // should the Next button be displayed
  shouldDisplayNextButton(): boolean {
    return (this.selectedCustomer !== null);
  }


  // user clicked the Back button
  didClickBackButton(): void {

  }


  // user clicked the Next button
  didClickNextButton(): void {
    this.performValidation();
  }


  // performs validation
  performValidation(): void {

    const newEmployerFlag = !this.selectedEmployer.employerID;

    // validate the closing dates against the mode
    const skipClosingDateFlag = (this.closingDateMode === ClosingDateMode.noEstimatedClosingDate);
    const missingEstimatedClosingDateFlag = (this.estimatedClosingDateText === '');
    const missingActualClosingDateFlag = (this.actualClosingDateText === '');

    if (missingEstimatedClosingDateFlag && missingActualClosingDateFlag && !skipClosingDateFlag) {
      this.closingDateMode = ClosingDateMode.none;
    }


    const lookupTypeCode = OfficeAgentLookupRelationshipType.typeCodeEscrowOfficer;

    this.officeAgentLookupValidateService.performValidation(this.selectedEmployer, this.selectedCustomer, lookupTypeCode)
      .subscribe((results: IOfficeAgentLookupValidateResult) => {

        this.customerValidateResult = results.customerValidateResult;
        this.employerValidateResult = results.employerValidateResult;


        // are we allowing auto-match?
        let allowAutoMatch = true;

        if (this.validationError()) {
          allowAutoMatch = false;
        }

        if (allowAutoMatch) {
          this.customerAutoMatch = results.customerAutoMatch;
          this.employerAutoMatch = results.employerAutoMatch;
        }


        // set the validation status
        let validationStatus = ValidationStatus.success;

        // if there was a customer auto-match
        if (this.customerAutoMatch) {
          validationStatus = ValidationStatus.customerAutoMatch;

          // if there was an employer auto-match
        } else if (this.employerAutoMatch) {
          validationStatus = ValidationStatus.employerAutoMatch;

        // if there are any validation errors
        } else if (this.validationError()) {
          validationStatus = ValidationStatus.validationFailure;

          // checks specific to new offices
        } else if (newEmployerFlag) {

          // if the physical address was updated with validation
          if (this.employerValidateResult.employerPhysicalAddressUpdated()) {
            validationStatus = ValidationStatus.addressValidationSuccess;

            // if the physical address wasn't validated
          } else if (!this.employerValidateResult.employerPhysicalAddressValidated()) {
            validationStatus = ValidationStatus.addressValidationFailure;
          }

        }

        this.validationStatus = validationStatus;


        // if the validation was successful
        if (validationStatus === ValidationStatus.success) {
          this.gotoNextStep();
        }

      });
  }





  employerPhysicalAddressForValidation(): Address[] {
    const addresses = [];

    if (this.employerValidateResult) {
      const address = this.employerValidateResult.employerPhysicalAddress();

      addresses.push(address);
    }

    return addresses;
  }


  shouldDisplayAddressValidationModal(): boolean {
    let flag = false;

    flag = flag || (this.validationStatus === ValidationStatus.addressValidationSuccess);
    flag = flag || (this.validationStatus === ValidationStatus.addressValidationFailure);

    return flag;
  }


  handleAddressValidationUserResponse(flag: boolean) {
    // if the user wants to continue
    if (flag) {
      const physicalAddress = this.selectedEmployer.physicalAddress;

      // if the address was validated
      if (this.employerValidateResult.employerPhysicalAddressValidated()) {
        const validatedAddress = this.employerValidateResult.employerValidatedPhysicalAddress();

        physicalAddress.address = validatedAddress.address;
        physicalAddress.city = validatedAddress.city;
        physicalAddress.stateCode = validatedAddress.stateCode;
        physicalAddress.zipCode = validatedAddress.zipCode;
      }

      this.gotoNextStep();

      // if the user wants to make changes
    } else {
      this.validationStatus = ValidationStatus.none;
    }
  }






  // should the validation error be displayed
  shouldDisplayValidationError(): boolean {
    let displayFlag = false;

    if (this.validationStatus === ValidationStatus.validationFailure) {
      displayFlag = this.validationError();
    }

    return displayFlag;
  }


  validationError(): boolean {
    let errorFlag = false;

    if (this.employerValidateResult) {
      errorFlag = errorFlag || this.employerValidateResult.errorFlag;
    }

    if (this.customerValidateResult) {
      errorFlag = errorFlag || this.customerValidateResult.errorFlag;
    }

    if (this.closingDateMode === ClosingDateMode.actualClosingDate) {
      errorFlag = errorFlag || !!this.actualClosingDateErrorText();
    }

    if (this.closingDateMode === ClosingDateMode.estimatedClosingDate) {
      errorFlag = errorFlag || !!this.estimatedClosingDateErrorText();
    }

    errorFlag = errorFlag || this.generalClosingDateError();

    errorFlag = errorFlag || this.fileNumberError();

    return errorFlag;
  }




  // should the closing info be displayed
  shouldDisplayClosingInfo(): boolean {
    return !!this.selectedCustomer;
  }


  // should the file number error be displayed?
  shouldDisplayFileNumberError(): boolean {
    let errorFlag = false;

    if (this.validationStatus !== ValidationStatus.none) {
      errorFlag = this.fileNumberError();
    }

    return errorFlag;
  }



  fileNumberError(): boolean {
    let errorFlag = false;

    const fileNumber = (this.closingFileNumber || '');

    if (fileNumber === '') {
      errorFlag = true;
    }

    return errorFlag;
  }




  // should the estimated closing date error be displayed
  shouldDisplayEstimatedClosingDateError(): boolean {
    let errorFlag = false;

    if (this.validationStatus !== ValidationStatus.none) {
      let estimatedFlag = false;
      estimatedFlag = estimatedFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDate);
      estimatedFlag = estimatedFlag || (this.closingDateMode === ClosingDateMode.estimatedClosingDateCalendar);

      if (estimatedFlag && this.estimatedClosingDateErrorText()) {
        errorFlag = true;
      }
    }

    return errorFlag;
  }


  estimatedClosingDateErrorText(): string {
    let errorText: string = null;

    const testDate = this.dateService.parseDate(this.estimatedClosingDateText, true);

    if (!errorText) {
      if (!testDate) {
        errorText = 'Estimated Closing Date is required (' + this.displayDateMask + ')';
      }
    }

    if (!errorText) {
      const minDate = DateUtility.midnightDate(DateUtility.timeZoneLocalDate(this.planOrderApplication.planReceivedDate));
      const maxDate = DateUtility.dateAdd(DatePart.years, 1, minDate);


      if (testDate.toISOString().split("T")[0] > maxDate.toISOString().split("T")[0]) {
        errorText = 'Estimated Closing Date must be on or before ' + this.dateService.formatDate(maxDate, true);

      } else if (testDate.toISOString().split("T")[0] < minDate.toISOString().split("T")[0]) {
        errorText = 'Estimated Closing Date must be on or after ' + this.dateService.formatDate(minDate, true);
      }
    }

    return errorText;
  }


  // should the actual closing date error be displayed
  shouldDisplayActualClosingDateError(): boolean {
    let errorFlag = false;

    if (this.validationStatus !== ValidationStatus.none) {
      let actualFlag = false;
      actualFlag = actualFlag || (this.closingDateMode === ClosingDateMode.actualClosingDate);
      actualFlag = actualFlag || (this.closingDateMode === ClosingDateMode.actualClosingDateCalendar);

      if (actualFlag && this.actualClosingDateErrorText()) {
        errorFlag = true;
      }
    }

    return errorFlag;
  }


  actualClosingDateErrorText(): string {
    let errorText: string = null;

    const testDate = this.dateService.parseDate(this.actualClosingDateText, true);

    if (!errorText) {
      if (!testDate) {
        errorText = 'Actual Closing Date is required (' + this.displayDateMask + ')';
      }
    }

    if (!errorText) {
      const minDate = DateUtility.dateAdd(DatePart.years, -1, this.planOrderApplication.planReceivedDate);
      const maxDate = DateUtility.dateAdd(DatePart.years, 1, this.planOrderApplication.planReceivedDate);

      if (testDate.toISOString().split("T")[0] > maxDate.toISOString().split("T")[0]) {
        errorText = 'Actual Closing Date must be on or before ' + this.dateService.formatDate(maxDate, true);

      } else if (testDate.toISOString().split("T")[0] < minDate.toISOString().split("T")[0]) {
        errorText = 'Actual Closing Date must be on or after ' + this.dateService.formatDate(minDate, true);
      }
    }

    return errorText;
  }




  // should the general closing date error be displayed
  shouldDisplayGeneralClosingDateError(): boolean {
    let errorFlag = false;

    if (this.validationStatus !== ValidationStatus.none) {
      errorFlag = this.generalClosingDateError();
    }

    return errorFlag;
  }


  generalClosingDateError(): boolean {
    let errorFlag = false;

    if (this.closingDateMode === ClosingDateMode.none) {
      errorFlag = true;
    }

    return errorFlag;
  }




  // the user responded to the office auto-match
  didClickOfficeAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the office
    if (responseFlag) {
      this.selectedEmployer = this.employerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();


      // if the user does not want to accept the office
    } else {
      this.employerAutoMatch = null;
    }
  }



  // the user clicked the agent auto match response
  didClickAgentAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the agent
    if (responseFlag) {
      this.selectedCustomer = this.customerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();

      // if the user does not want to accept the agent
    } else {
      this.customerAutoMatch = null;
    }

  }



  // user clicked to Skip
  didClickSkip(): void {
    this.relationshipSkippedFlag = true;
    this.selectedEmployer = null;
    this.selectedCustomer = null;

    this.gotoNextStep();
  }


  // go to the next step
  gotoNextStep() {
    this.planOrderApplication.closingFileNumber = this.closingFileNumber;
    this.planOrderApplication.estimatedClosingDate = DateUtility.timeZoneRemoteDate(this.estimatedClosingDate);
    this.planOrderApplication.actualClosingDate = DateUtility.timeZoneRemoteDate(this.actualClosingDate);

    this.planOrderApplication.invoiceRecipient = this.selectedInvoiceRecipient;

    this.planOrderApplication.closingAgent = this.selectedCustomer;
    this.planOrderApplication.closingAgentSkippedFlag = !this.selectedCustomer;


    this.officeAgentLookupPersistenceService.clearPersistedValues();

    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }


}



enum ClosingDateMode {
  none = 100,
  noEstimatedClosingDate = 200,
  estimatedClosingDate = 300,
  estimatedClosingDateCalendar = 350,
  actualClosingDate = 400,
  actualClosingDateCalendar = 450
}




enum ValidationStatus {
  none = 10,
  validationFailure = 20,
  addressValidationFailure = 30,
  addressValidationSuccess = 40,
  employerAutoMatch = 50,
  customerAutoMatch = 60,
  success = 70
}



