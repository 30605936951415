
import {Product} from '@orhp/phx-product-ui-module';
import {ProductCoverage} from '@orhp/phx-product-ui-module';
import {PropertyType} from '@orhp/phx-property-ui-module';
import {PlanQuoteService} from '../_services/plan-quote.service';
import {UsefulMap} from '@orhp/phx-common-ui-module';

export class PlanQuoteRequest {

  planQuoteService: PlanQuoteService = null;

  product: Product = null;
  productCoverages: ProductCoverage[] = null;
  propertyType: PropertyType = null;
  emailAddress: string = null;

  optionUnitsByCoverageID: any = null;


  get coverageIDList(): string {
    let coverageIDList = '';

    if (this.productCoverages) {
      this.productCoverages.forEach((indexProductCoverage: ProductCoverage) => {
        coverageIDList = (coverageIDList !== '' ? coverageIDList + ',' : '') + String(indexProductCoverage.coverageID);
      });
    }

    return coverageIDList;
  }



  constructor(planQuoteService: PlanQuoteService) {
    this.planQuoteService = planQuoteService;
  }



  duplicate(): PlanQuoteRequest {
    const request = this.planQuoteService.newPlanQuoteRequest();

    // duplicate basic values
    request.product = this.product;
    request.propertyType = this.propertyType;
    request.emailAddress = this.emailAddress;

    // duplicate coverage array
    if (this.productCoverages) {
      const productCoverages = [];
      const optionUnitsByCoverageID = {};

      this.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        productCoverages.push(indexCoverage);

        const optionUnits = optionUnitsByCoverageID[indexCoverage.coverageID];
        optionUnitsByCoverageID[indexCoverage.coverageID] = optionUnits;
      });

      request.productCoverages = productCoverages;
      request.optionUnitsByCoverageID = optionUnitsByCoverageID;
    }


    return request;
  }

}
