
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {ActivatedRoute, Router} from '@angular/router';
import {OfficeAgentLookupService} from './_services/lookup.service';
import {OfficeAgentLookupRelationshipType} from './_src/lookup-relationship-type';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentErrorCollection} from './_src/office-agent-error-collection';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {ProductOffice} from '@orhp/phx-product-ui-module';

@Component({
  selector: 'app-office-agent-lookup',
  templateUrl: './office-agent-lookup.component.html',
  styleUrls: ['./office-agent-lookup.scss']
})


export class OfficeAgentLookupComponent implements OnInit, OnDestroy {

  @Input()
  set employerValidateResult(result: EmployerValidateResult) {
    this.officeAgentLookupService.employerValidateResult = result;
  }


  @Input()
  set customerValidateResult(result: CustomerValidateResult) {
    this.officeAgentLookupService.customerValidateResult = result;
  }


  @Input()
  set isPlanOrder(result: boolean) {
    this.officeAgentLookupService.isPlanOrder = result;
  }


  @Input()
  set shouldDisplayEmployerSearch(result: boolean) {
    this.officeAgentLookupService.shouldDisplayEmployerSearch = result;
  }


  @Input()
  set allowDifferentOfficeSelectionFlag(value: boolean) {
    this.officeAgentLookupService.allowDifferentOfficeSelectionFlag = value;
  }


  @Input()
  set allowDifferentAgentSelectionFlag(value: boolean) {
    this.officeAgentLookupService.allowDifferentAgentSelectionFlag = value;
  }


  @Input()
  set productOffice(productOffice: ProductOffice) {
    this.officeAgentLookupService.productOffice = productOffice;
  }



  @Input()
  set productOfficeEmail(productOfficeEmail: string) {
    this.officeAgentLookupService.productOfficeEmail = productOfficeEmail;
  }



  @Input()
  set productOfficeID(productOfficeID: number) {
    this.officeAgentLookupService.productOfficeID = productOfficeID;
  }



  @Input()
  set requiresProductOfficeFlag(flag: boolean) {
    if ((flag !== null) && (flag !== undefined)) {
      this.officeAgentLookupService.requiresProductOfficeFlag = flag;
    }
  }


  @Input()
  set autoFocusOfficeSearchFlag(flag: boolean) {
    if ((flag !== null) && (flag !== undefined)) {
      this.officeAgentLookupService.autoFocusOfficeSearchFlag = flag;
    }
  }





    constructor(private router: Router,
                private route: ActivatedRoute,
                private officeAgentLookupService: OfficeAgentLookupService) {
  }


  ngOnInit() {
  }


  ngOnDestroy() {
      // this.officeAgentLookupService.clearAllData();
  }


}
