
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {PhxCommonModule} from '@orhp/phx-common-ui-module';
import {PhxCustomerModule} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupComponent} from './office-agent-lookup.component';
import {OfficeLookupComponent} from './office-lookup/office-lookup.component';
import {OfficeAgentLookupService} from './_services/lookup.service';
import {AgentLookupComponent} from './agent-lookup/agent-lookup.component';
import {OfficeAgentSelectedComponent} from './office-agent-selected/office-agent-selected.component';
import {OfficeEntryComponent} from './office-entry/office-entry.component';
import {AgentComponent} from './agent/agent.component';
import {AgentContactComponent} from './agent-contact/agent-contact.component';
import {AgentEntryComponent} from './agent-entry/agent-entry.component';
import {OfficeAgentLookupRootResolver} from './_services/root.resolver';
import {OfficeAgentLookupOfficeResolver} from './_services/office.resolver';
import {OfficeAgentLookupAgentResolver} from './_services/agent.resolver';
import {OfficeAgentLookupPersistenceService} from './_services/persistence.service';
import {OfficeAgentLookupRootWildcardResolver} from './_services/root-wildcard.resolver';
import {OfficeAgentLookupEmptyOfficeResolver} from './_services/empty-office.resolver';
import {OfficeAgentLookupOfficeWildcardResolver} from './_services/office-wildcard.resolver';
import {OfficeAgentLookupEmptyAgentResolver} from './_services/empty-agent.resolver';
import {OfficeAgentLookupOfficeNewResolver} from './_services/office-new.resolver';
import {OfficeAgentLookupAgentNewResolver} from './_services/agent-new.resolver';
import {OfficeAgentLookupOfficeClearResolver} from './_services/office-clear.resolver';
import {OfficeAgentLookupAgentClearResolver} from './_services/agent-clear.resolver';
import {OfficeAgentLookupCacheService} from './_services/lookup-cache.service';
import {OfficeAgentLookupValidateService} from './_services/validate.service';
import {OfficeAgentLookupAgentUnknownResolver} from './_services/agent-unknown.resolver';
import {PhxProductModule} from '@orhp/phx-product-ui-module';
import {OfficeAgentNavigationService} from './_services/office-agent-navigation.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PhxCommonModule,
    PhxCustomerModule,
    PhxProductModule
  ],
  declarations: [
    OfficeAgentLookupComponent,
    OfficeAgentSelectedComponent,
    OfficeLookupComponent,
    OfficeEntryComponent,
    AgentLookupComponent,
    AgentComponent,
    AgentEntryComponent,
    AgentContactComponent
  ],
  providers: [
    OfficeAgentLookupService,
    OfficeAgentLookupPersistenceService,
    OfficeAgentLookupCacheService,
    OfficeAgentLookupValidateService,
    OfficeAgentNavigationService,

    OfficeAgentLookupRootResolver,
    OfficeAgentLookupRootWildcardResolver,
    OfficeAgentLookupOfficeResolver,
    OfficeAgentLookupOfficeNewResolver,
    OfficeAgentLookupOfficeClearResolver,
    OfficeAgentLookupEmptyOfficeResolver,
    OfficeAgentLookupOfficeWildcardResolver,
    OfficeAgentLookupAgentResolver,
    OfficeAgentLookupAgentNewResolver,
    OfficeAgentLookupAgentUnknownResolver,
    OfficeAgentLookupAgentClearResolver,
    OfficeAgentLookupEmptyAgentResolver
  ],
  exports: [
    OfficeAgentLookupComponent
  ]
})

export class OfficeAgentLookupModule {

}
