import {Injectable} from '@angular/core';
import {AuthenticationService} from '../login';
import {ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';

@Injectable()
export class ToolboxSettingsService {

  get productOffice(): ProductOffice {
    const productOffices = (this.authenticationService.productOffices || []);

    return (!!productOffices.length ? productOffices[0] : null);
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return (!!this.productOffice ? this.productOffice.toolbox : null);
  }



  get appsPhoneNumber(): string {
    let phone = '800.445.6999';

    if (!!this.productOfficeToolbox && ((this.productOfficeToolbox.appsPhoneNumber || '') !== '')) {
      phone = this.productOfficeToolbox.appsPhoneNumber;
    }

    return phone;
  }



  constructor(private authenticationService: AuthenticationService
  ) {}

}
