import {Component, OnInit} from '@angular/core';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PlanOrderService} from '../_services';
import {ActivityResult, ActivityResultError, ArrayUtility, PremiumDurations, StringUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {
  Product,
  ProductCoverage,
  ProductLookupService,
  ProductOffice,
  ProductOfficeToolbox,
  ProductPaymentPlan
} from '@orhp/phx-product-ui-module';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs/Rx';
import {Subscriber} from 'rxjs/Subscriber';
import {
  AccountingDomainFactoryService,
  AccountingLookupValueService,
  CreditCard,
  CreditCardSaveResult,
  CreditCardService,
  CustomerInvoiceRecipient,
  PaymentPlan
} from '@orhp/phx-accounting-ui-module';
import {PlanOrderApplicationUpdates} from '../_models';
import {PaymentPlanPayment} from '@orhp/phx-accounting-ui-module/_models/payment-plan-payment';
import {PlanOrderPaymentEditingEmail} from './plan-order-payment-editing-email';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {CorrespondenceLookupValuesService, InvoiceCommunicationType} from '@orhp/phx-correspondence-ui-module';
import {DurationEnum} from '../_src/price-duration-enum';

@Component({
  selector: 'app-plan-order-payment',
  templateUrl: './payment.component.html',
  styleUrls: [
    '../plan-order.scss',
    './payment.component.scss'
  ]
})

export class PlanOrderPaymentComponent extends PlanOrderBaseComponent implements OnInit {

  creditCardPaymentPlans: PaymentPlan[] = null;
  checkPaymentPlans: PaymentPlan[] = null;

  defaultEmailCount = 2;

  invoiceEditingEmails: PlanOrderPaymentEditingEmail[] = null;

  selectedPaymentPlan: PaymentPlan = null;

  cardholderName = this.planOrderApplication.ccCardHolderName || '';
  initialCardholderName = this.planOrderApplication.ccCardHolderName || 'abcd12344321';

  creditCardNumber = this.planOrderApplication.ccNumber || '';
  initialCreditCardNumber = this.planOrderApplication.ccNumber || 'a';

  maxCreditCardYears = 7;

  expirationMonth = String(this.planOrderApplication.ccExpirationMonth || (new Date()).getMonth() + 1);
  initialExpirationMonth = String(this.planOrderApplication.ccExpirationMonth || '');

  expirationYear = String(this.planOrderApplication.ccExpirationYear || (new Date()).getFullYear());
  initialExpirationYear = String(this.planOrderApplication.ccExpirationYear || '');
  cancellationEmail = 'cancellation@orhp.com';


  private _payNowFlag: boolean = null;

  get payNowFlag(): boolean {
    if (this._payNowFlag === null) {
      this._payNowFlag = this.planOrderApplication.payNowFlag;
    }

    if (this._payNowFlag === null) {
      const paymentPlan = this.planOrderApplication.paymentPlan;

      if (!!paymentPlan) {
        this._payNowFlag = paymentPlan.creditCardFlag;
      }
    }

    if (this._payNowFlag === null) {
      this._payNowFlag = true;
    }

    return this._payNowFlag;
  }

  set payNowFlag(payNowFlag: boolean) {
    this._payNowFlag = payNowFlag;
  }




  private _expirationMonths: string[] = null;

  get expirationMonths(): string[] {
    if (!this._expirationMonths) {
      this._expirationMonths = [];

      for (let index = 1; index <= 12; index++) {
        this._expirationMonths.push(String(index));
      }
    }

    return this._expirationMonths;
  }


  private _expirationYears: string[] = null;

  get expirationYears(): string[] {
    if (!this._expirationYears) {
      const now = new Date();

      this._expirationYears = [];

      for (let index = -1; index <= this.maxCreditCardYears; index++) {
        this._expirationYears.push(String(now.getFullYear() + index));
      }
    }

    return this._expirationYears;
  }


  get product(): Product {
    return this.planOrderApplication.product;
  }


  private _productCoverages: ProductCoverage[] = null;

  get productCoverages(): ProductCoverage[] {
    if (!this._productCoverages) {
      const displayCodes = this.planOrderApplication.selectedDisplayCodes;
      const productCoverages = [];

      this.planOrderApplication.productCoverages.forEach((indexCoverage: ProductCoverage) => {
        let coverageFlag = !!ArrayUtility.arrayFind(displayCodes, (testCode: string): boolean => {
          return (testCode.toLowerCase() === indexCoverage.displayCode.toLowerCase());
        });

        if (!indexCoverage.premium) {
          coverageFlag = true;
        }

        if (coverageFlag) {
          productCoverages.push(indexCoverage);
        }
      });

      this._productCoverages = productCoverages;
    }

    return this._productCoverages;
  }


  get totalAmount(): number {
    let amount = this.product.premium;

    this.productCoverages.forEach((indexCoverage: ProductCoverage) => {
      amount += indexCoverage.premium;
    });

    return amount;
  }


  get firstPaymentAmount(): number {
    let paymentAmount: number = null;

    if (this.selectedPaymentPlan) {
      paymentAmount = this.totalAmount * this.getFirstPayment().paymentPlanPercent / 100.0;

      paymentAmount += this.installmentFeeForPaymentPlan(this.selectedPaymentPlan);

      paymentAmount = Math.round(paymentAmount * 100.0) / 100.0;
    }

    return paymentAmount;
  }


  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }





  paymentPlansLoadingFlag = true;

  displayPaymentOptionsFlag = false;

  displayConvenienceFeeMessageFlag = false;

  validateFlag = false;

  saveInProcessFlag = false;
  saveResult: ActivityResult<CreditCardSaveResult> = null;


  constructor(private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService,
              private planOrderAuthService: PlanOrderAuthService,
              private creditCardService: CreditCardService,
              private productLookupService: ProductLookupService,
              private accountingLookupValueService: AccountingLookupValueService,
              private accountingDomainFactoryService: AccountingDomainFactoryService,
              private correspondenceLookupValueService: CorrespondenceLookupValuesService,
              private router: Router,
              private route: ActivatedRoute
              ) {
    super(planOrderService);
    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag) {
      this.payNowFlag = true;
    }
  }


  static invoiceDescriptionWithInfo(correspondenceLookupValueService: CorrespondenceLookupValuesService,
                                    productOfficeGroup: ProductOffice): string {
    let text = 'Invoice';

    // if a custom invoice communication type was specified
    if (!!productOfficeGroup && productOfficeGroup.invoiceCommunicationTypeID) {
      const invoiceCommTypeID = productOfficeGroup.invoiceCommunicationTypeID;

      const invoiceCommType: InvoiceCommunicationType = ArrayUtility.arrayFind(
        correspondenceLookupValueService.invoiceCommunicationTypes,
        (testType: InvoiceCommunicationType): boolean => {
          return (testType.invoiceCommunicationTypeID === invoiceCommTypeID);
        });

      const testText = (invoiceCommType ? invoiceCommType.description : null) || '';

      if (testText !== '') {
        text = invoiceCommType.description;
      }
    }

    return text;
  }





  static coverageDelayTextWithProduct(product: Product, productOffice: ProductOffice): string {
    let text: string = null;

    const coverageDelayDays = product.effectiveDateDelay || 0;

    // show text if there's a delay
    let textFlag = !!coverageDelayDays;

    // don't display for new construction
    if (product.marketSegment.newConstructionFlag) {
      textFlag = false;
    }

    // don't display if the config says not to
    if (!!productOffice && !!productOffice.toolbox) {
      if (!productOffice.toolbox.displayCoverageDelayTextFlag) {
        textFlag = false;
      }
    }

    if (textFlag) {
      text = 'Coverage becomes effective ' + String(coverageDelayDays) + ' days from receipt of payment.';
    }

    return text;
  }







  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'payment');

    this.fetchProductPaymentPlans();

    this.populateInvoiceEditingEmails();
  }


  populateInvoiceEditingEmails() {
    this.invoiceEditingEmails = [];

    const existingRecipients = (this.planOrderApplication.premiumInvoiceRecipients || []);

    existingRecipients.forEach((indexRecipient: CustomerInvoiceRecipient) => {
      this.invoiceEditingEmails.push(new PlanOrderPaymentEditingEmail(indexRecipient));
    });

    if (!this.invoiceEditingEmails.length) {
      for (let emailIndex = 0; emailIndex < this.defaultEmailCount; emailIndex++) {
        this.invoiceEditingEmails.push(new PlanOrderPaymentEditingEmail());
      }
    }

  }




  fetchProductPaymentPlans() {
    const productID = this.planOrderApplication.product.productID;

    this.productLookupService.fetchPaymentPlans(productID)
      .subscribe((productPaymentPlans: ProductPaymentPlan[]) => {
        const creditCardPaymentPlans = [];
        const checkPaymentPlans = [];

        const allPaymentPlans = this.accountingLookupValueService.paymentPlans;

        allPaymentPlans.forEach((indexPaymentPlan: PaymentPlan) => {
          let useFlag = false;

          // loop over product payment plans
          productPaymentPlans.forEach((indexProductPaymentPlan: ProductPaymentPlan) => {
            // if the payment plan is valid for the product
            if (indexPaymentPlan.paymentPlan.toLowerCase() === indexProductPaymentPlan.paymentPlan.toLowerCase()) {
              useFlag = true;
            }
          });

          // if we're using the payment plan
          if (useFlag) {
            // is it a credit card payment plan?
            if (indexPaymentPlan.creditCardFlag) {
              creditCardPaymentPlans.push(indexPaymentPlan);

            // is it a check payment plan?
            } else {
              checkPaymentPlans.push(indexPaymentPlan);
            }
          }
        });

        this.creditCardPaymentPlans = creditCardPaymentPlans;
        this.checkPaymentPlans = checkPaymentPlans;
        this.selectedPaymentPlan = this.planOrderApplication.paymentPlan;

        if (!this.selectedPaymentPlan) {
          if (this.payNowFlag && !!this.creditCardPaymentPlans.length) {
            this.selectedPaymentPlan = this.creditCardPaymentPlans[0];
          }

          if (!this.payNowFlag && !!this.checkPaymentPlans.length) {
            this.selectedPaymentPlan = this.checkPaymentPlans[0];
          }
        }


        // if we're not allowing Pay Now or Pay Later, do not display payment options
        const optionTotal =
          (this.shouldDisplayPayNow() ? 1 : 0) +
          (this.shouldDisplaySendBill() ? 1 : 0);

        if (optionTotal === 2) {
          this.displayPaymentOptionsFlag = true;
        }

        this.paymentPlansLoadingFlag = false;
    });
  }


  multiplePaymentPlansAvailable(): boolean {
    const paymentPlans = (this.payNowFlag ? this.creditCardPaymentPlans : this.checkPaymentPlans);

    return ((paymentPlans || []).length > 1);
  }





  coverageDelayText(): string {
    const product = this.planOrderApplication.product;
    const text = PlanOrderPaymentComponent.coverageDelayTextWithProduct(product, this.planOrderAuthService.productOffice);

    return text;
  }


  shouldDisplayCoverageDelayText(): boolean {
    return !!this.coverageDelayText();
  }


  /**
   * When should the Pay Now button display
   */
  shouldDisplayPayNow(): boolean {
    return true;
  }


  /**
   * When should "Send Bill" be displayed
   */
  shouldDisplaySendBill(): boolean {

    return !!this.productOfficeToolbox && !!this.productOfficeToolbox.allowPremiumPayLaterFlag;
  }





  isPayNow(): boolean {
    return (this.payNowFlag === true);
  }


  isPayLater(): boolean {
    return (this.payNowFlag === false);
  }



  didClickPayNow() {
    if (!this.payNowFlag) {
      this.validateFlag = false;

      this.payNowFlag = true;

      this.selectedPaymentPlan = this.creditCardPaymentPlans[0];
    }
  }


  didClickPayLater() {
    if (this.payNowFlag) {
      this.validateFlag = false;

      this.payNowFlag = false;

      this.selectedPaymentPlan = this.checkPaymentPlans[0];
    }
  }






  invoiceDescription(): string {
    return PlanOrderPaymentComponent.invoiceDescriptionWithInfo(
      this.correspondenceLookupValueService,
      this.productOfficeGroup
    );
  }



  shouldDisplayAddEmailButton(emailIndex: number): boolean {
    return (emailIndex === (this.invoiceEditingEmails.length - 1));
  }


  didClickAddEmail() {
    this.invoiceEditingEmails.push(new PlanOrderPaymentEditingEmail());
  }



  resetValidation() {
    this.validateFlag = false;
  }



  isPaymentPlanSelected(paymentPlan: PaymentPlan): boolean {
    return paymentPlan.isEqual(this.selectedPaymentPlan);
  }


  didClickPaymentPlan(paymentPlan: PaymentPlan) {
    this.selectedPaymentPlan = paymentPlan;
  }



  installmentFeeForPaymentPlan(paymentPlan: PaymentPlan): number {
    let fee = 0;
    this.displayConvenienceFeeMessageFlag = false;

    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag) {
      if (paymentPlan.assessInstallmentFee) {
        if (this.product.paymentInstallmentFee !== null) {
          fee = this.product.paymentInstallmentFee;
        } else {
          fee = this.getInstallmentFee(paymentPlan, fee);
        }
      }
    }else {
        fee = this.getInstallmentFee(paymentPlan, fee);
    }


    if (fee > 0) {
      this.displayConvenienceFeeMessageFlag = true;
    }
     return fee;
  }

  getInstallmentFee(paymentPlan, fee) {
      if (paymentPlan.installmentFeeAmount) {
        fee = paymentPlan.installmentFeeAmount;
      } else if (this.accountingLookupValueService.defaultInstallmentFee) {
        fee = this.accountingLookupValueService.defaultInstallmentFee;
      }
        return fee;
  }


  nameForMonth(monthIndexAsString: string): string {
    let name = '';

    const monthIndex = Number(monthIndexAsString);

    switch (monthIndex) {
      case 1: name = 'Jan'; break;
      case 2: name = 'Feb'; break;
      case 3: name = 'Mar'; break;
      case 4: name = 'Apr'; break;
      case 5: name = 'May'; break;
      case 6: name = 'Jun'; break;
      case 7: name = 'Jul'; break;
      case 8: name = 'Aug'; break;
      case 9: name = 'Sep'; break;
      case 10: name = 'Oct'; break;
      case 11: name = 'Nov'; break;
      case 12: name = 'Dec'; break;

    }

    return name;
  }




  maskCreditCardNumber(enteredNumber: string): string {
    return StringUtility.maskCreditCardNumber(enteredNumber, 4);
  }


  emailRequiredErrorText(): string {
    let text: string = null;

    if (!this.payNowFlag) {
      let emailCount = 0;

      this.invoiceEditingEmails.forEach((indexEmail: PlanOrderPaymentEditingEmail) => {
        const indexEmailAddress = indexEmail.emailAddress || '';

        if (indexEmailAddress !== '') {
          emailCount++;
        }
      });

      if (!emailCount) {
        text = 'At least one Email Address is required';
      }
    }

    return text;
  }



  editingEmailErrorText(email: PlanOrderPaymentEditingEmail): string {
    let text: string = null;

    if (!this.payNowFlag) {
      const emailText = email.emailAddress || '';

      if (!this.payNowFlag && (emailText !== '') && !StringUtility.emailValidate(emailText)) {
        text = 'Email Address requires a valid email address';
      }
    }

    return text;
  }


  paymentPlanDisplayText(paymentPlan: PaymentPlan): string {
    return StringUtility.nullIfEmptyString(paymentPlan.internetDescription) || paymentPlan.paymentPlan;
  }


  shouldDisplayImmediateChargeInstructions(): boolean {
    let flag = false;

    // if the plan has been created, it's an immediate charge
    if (!!this.planOrderApplication.planID) {
      flag = true;
    }

    return flag;
  }


  shouldDisplayReviewChargeInstructions(): boolean {
    return !this.shouldDisplayImmediateChargeInstructions();
  }


  nextButtonInstructionText(): string {
    let text: string = null;

    if (this.planOrderApplication.planID) {
      text = 'Click Next to attempt your credit card charge immediately.';

    } else {
      text = 'Click Next to view order summary. Your credit card will not be charged until you review your order.';
    }

    return text;
  }



  paymentPlanErrorText(): string {
    let text: string = null;

    if (this.payNowFlag && !this.selectedPaymentPlan) {
      text = 'Payment Plan is required';
    }

    return text;
  }




  cardholderNameErrorText(): string {
    let text: string = null;

    if (this.payNowFlag && ((this.cardholderName || '') === '')) {
      text = 'Cardholder Name is required';
    }

    return text;
  }



  creditCardNumberErrorText(): string {
    let text: string = null;

    // if a new credit card number was entered
    if (this.payNowFlag && (this.creditCardNumber !== this.initialCreditCardNumber)) {
      // remove all characters from the credit card number
      const rawCreditCardNumber = StringUtility.stripNonNumeric(this.creditCardNumber) || '';

      if (rawCreditCardNumber.length < 4) {
        text = 'Credit Card # requires at least 4 digits';
      }
    }

    return text;
  }



  creditCardNumberSaveErrorText(): string {
    let text: string = null;
    // if there's a save result that returned an invalid number
    const errorIdentifier = 'phx.legacy.api.InvalidCreditCardNumber';

    if (!!this.saveResult && this.saveResult.containsErrorIdentifier(errorIdentifier)) {
      text = 'Invalid Credit Card Number';
    }

    return text;
  }






  creditCardExpirationDateErrorText(): string {
    let tooEarlyFlag = false;
    const now = new Date();

    const expirationYear = Number(this.expirationYear);
    const expirationMonth = Number(this.expirationMonth) - 1;

    // early year
    if (expirationYear < now.getFullYear()) {
      tooEarlyFlag = true;

    // current year
    } else if ((expirationYear === now.getFullYear()) && (expirationMonth < now.getMonth())) {
      tooEarlyFlag = true;
    }


    let text: string = null;

    if (tooEarlyFlag) {
      text = 'Credit Card is expired';
    }


    return text;
  }



  creditCardSaveErrorText(): string {
    let text: string = null;

    if (this.saveResult) {
      this.saveResult.errors.forEach((indexError: ActivityResultError) => {
        text = (!!text ? text + ', ' : '') + indexError.message + ' (' + indexError.identifier + ')';
      });
    }

    return text;
  }




  errorText(): string {
    let text: string = null;

    // errors for pay by invoice
    if (!this.payNowFlag) {
      // assemble the text from the editing emails
      this.invoiceEditingEmails.forEach((indexEmail: PlanOrderPaymentEditingEmail) => {
        const indexEmailText = this.editingEmailErrorText(indexEmail);

        if (!!indexEmailText) {
          text = (!!text ? text + '\n' : '') + indexEmailText;
        }
      });

      // is an invoice email required?
      if (!!this.emailRequiredErrorText()) {
        text = (!!text ? text + '\n' : '') + this.emailRequiredErrorText();
      }
    }


    // errors for pay by credit card
    if (this.payNowFlag) {
      if (!!this.paymentPlanErrorText()) {
        text = (!!text ? text + '\n' : '') + this.paymentPlanErrorText();
      }

      if (!!this.cardholderNameErrorText()) {
        text = (!!text ? text + '\n' : '') + this.cardholderNameErrorText();
      }

      if (!!this.creditCardNumberErrorText()) {
        text = (!!text ? text + '\n' : '') + this.creditCardNumberErrorText();
      }

      if (!!this.creditCardSaveErrorText()) {
        text = (!!text ? text + '\n' : '') + this.creditCardSaveErrorText();
      }

      if (!!this.creditCardExpirationDateErrorText()) {
        text = (!!text ? text + '\n' : '') + this.creditCardExpirationDateErrorText();
      }    }

    return text;
  }


  creditCardNumberErrorDisplayText(): string {
    let text = null;

    if (this.validateFlag && !!this.creditCardNumberErrorText()) {
      text = this.creditCardNumberErrorText();

    } else if (!!this.creditCardNumberSaveErrorText()) {
      text = this.creditCardNumberSaveErrorText();
    }

    return text;
  }



  shouldDisplayErrors(): boolean {
    let flag = false;

    if (this.validateFlag && !!this.errorText()) {
      flag = true;
    }

    if (!!this.creditCardSaveErrorText()) {
      flag = true;
    }

    return flag;
  }



  validateForm(): boolean {
    let flag = false;

    // invoice errors
    if (!this.payNowFlag) {
      this.invoiceEditingEmails.forEach((indexEmail: PlanOrderPaymentEditingEmail) => {
        const indexEmailText = this.editingEmailErrorText(indexEmail);
        flag = flag || !!indexEmailText;
      });

      flag = flag || !!this.emailRequiredErrorText();
    }


    // credit card errors
    if (this.payNowFlag) {
      flag = flag || !!this.paymentPlanErrorText();
      flag = flag || !!this.cardholderNameErrorText();
      flag = flag || !!this.creditCardNumberErrorText();
      flag = flag || !!this.creditCardExpirationDateErrorText();
    }

    return flag;
  }


  /**
   * Saves the entered credit card
   */
  saveCreditCard(): Observable<ActivityResult<CreditCardSaveResult>> {
    const observable = new Observable((subscriber: Subscriber<ActivityResult<CreditCardSaveResult>>) => {
      // save if there is no credit card on file
      let saveFlag = ((this.planOrderApplication.creditCardGUID || '') === '');

      // if the credit card info hasn't changed, don't save it
      saveFlag = saveFlag || (this.creditCardNumber !== this.initialCreditCardNumber);
      saveFlag = saveFlag || (this.expirationYear !== this.initialExpirationYear);
      saveFlag = saveFlag || (this.expirationMonth !== this.initialExpirationMonth);
      saveFlag = saveFlag || (this.cardholderName !== this.initialCardholderName);

      if (saveFlag) {
        const creditCard = new CreditCard();
        creditCard.expirationMonth = Number(this.expirationMonth);
        creditCard.expirationYear = Number(this.expirationYear);
        creditCard.cardholder = this.cardholderName;

        if (this.creditCardNumber !== this.initialCreditCardNumber) {
          creditCard.creditCardNumber = this.creditCardNumber;

        } else {
          creditCard.creditCardGUID = this.planOrderApplication.creditCardGUID;
        }

        this.creditCardService.creditCardSave(creditCard).subscribe(
          (result: ActivityResult<CreditCardSaveResult>) => {
            // if success was returned
            if (result.object && result.object.successFlag) {
              this.validateFlag = false;
            }

            subscriber.next(result);
            subscriber.complete();
        });

      // if a save isn't necessary
      } else {
        const saveResult = new CreditCardSaveResult();
        saveResult.successFlag = true;
        saveResult.creditCardGUID = this.planOrderApplication.creditCardGUID;

        const result = new ActivityResult<CreditCardSaveResult>();
        result.object = saveResult;

        subscriber.next(result);
        subscriber.complete();
      }
    });

    return observable;
  }



  didClickDismissSaveWindow() {
    this.saveInProcessFlag = false;
  }




  didClickNext() {
    this.validateFlag = true;

    // if there's no validation problem
    if (!this.validateForm()) {
      // if we're processing a credit card payment
      if (this.payNowFlag) {
        this.saveResult = null;

        this.saveInProcessFlag = true;

        this.saveCreditCard().subscribe((result: ActivityResult<CreditCardSaveResult>) => {
          this.saveInProcessFlag = false;

          const saveResult = (result ? result.object : null);

          // if the save was successful
          if (saveResult && saveResult.successFlag) {
            this.planOrderApplication.premiumInvoiceRecipients = [];

            this.planOrderApplication.payNowFlag = this.payNowFlag;
            this.planOrderApplication.paymentPlan = this.selectedPaymentPlan;
            this.planOrderApplication.firstPaymentAmount = this.firstPaymentAmount;
            this.planOrderApplication.creditCardGUID = saveResult.creditCardGUID;
            this.planOrderApplication.ccCardHolderName = this.cardholderName.toUpperCase();
            this.planOrderApplication.ccExpirationMonth = Number(this.expirationMonth);
            this.planOrderApplication.ccExpirationYear = Number(this.expirationYear);

            if (this.creditCardNumber !== this.initialCreditCardNumber) {
              this.planOrderApplication.ccNumber = this.maskCreditCardNumber(this.creditCardNumber);
            }

            this.planOrderApplication.flagUpdate(PlanOrderApplicationUpdates.payment);

            this.persistPlanOrderApplication();

            this.gotoNextStep();

            // if the save was not successful
          } else {
            this.saveResult = result;
          }

        });

      // if we're paying by invoice
      } else {
        this.planOrderApplication.payNowFlag = this.payNowFlag;
        this.planOrderApplication.paymentPlan = this.selectedPaymentPlan;

        const recipients = [];

        this.invoiceEditingEmails.forEach((indexEmail: PlanOrderPaymentEditingEmail) => {
          // if there is an email address
          if ((indexEmail.emailAddress || '') !== '') {
            let indexRecipient = indexEmail.customerInvoiceRecipient;

            // if it's a new recipient
            if (!indexRecipient) {
              indexRecipient = new CustomerInvoiceRecipient();
            }

            indexRecipient.emailAddress = indexEmail.emailAddress;

            recipients.push(indexRecipient);
          }
        });

        this.planOrderApplication.premiumInvoiceRecipients = recipients;

        this.planOrderApplication.firstPaymentAmount = null;
        this.planOrderApplication.creditCardGUID = null;
        this.planOrderApplication.ccNumber = null;
        this.planOrderApplication.ccCardHolderName = null;
        this.planOrderApplication.ccExpirationMonth = null;
        this.planOrderApplication.ccExpirationYear = null;
        this.planOrderApplication.ccPaymentResult = null;

        this.planOrderApplication.flagUpdate(PlanOrderApplicationUpdates.payment);

        this.persistPlanOrderApplication();

        this.gotoNextStep();
      }


    }
  }



  gotoNextStep() {
    // next step logic
    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }


  getFirstPayment(): PaymentPlanPayment {
    const firstPayment: PaymentPlanPayment = ArrayUtility.arrayFind(
      this.selectedPaymentPlan.payments,
      (testPayment: PaymentPlanPayment): boolean => {
        return (testPayment.paymentPlanNumber === 1);
      });

    return firstPayment;
  }


  determineAmount(amount: number): number {
    let dollarAmount = amount;

    if (this.selectedPaymentPlan && !!this.productOfficeToolbox &&
        this.productOfficeToolbox.priceDisplayDurationCode === PremiumDurations.Month &&
        this.selectedPaymentPlan.creditCardFlag && this.selectedPaymentPlan.paymentCount > 1) {
      dollarAmount = amount * this.getFirstPayment().paymentPlanPercent / 100.0;

      dollarAmount = Math.round(dollarAmount * 100.0) / 100.0;
    }

    return dollarAmount;
  }


  displayTotalText(): string {
    let duration = '';

    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag &&
          !!DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode]) {
      duration = ' (per' + DurationEnum['Y'].toString().split('/')[1] + ')';

      if (this.selectedPaymentPlan && this.productOfficeToolbox.priceDisplayDurationCode === PremiumDurations.Month &&
            this.selectedPaymentPlan.creditCardFlag && this.selectedPaymentPlan.paymentCount > 1) {
        duration = ' (per' + DurationEnum['M'].toString().split('/')[1] + ')';

      }
    }

    return 'Total' + duration + ':';
  }


  shouldDisplayAutoRenewal(): boolean {
    if (!!this.productOfficeGroup && this.productOfficeGroup.homeBuyerEmailRequiredFlag &&
          this.planOrderApplication.autoRenewFlag === null) {
      this.planOrderApplication.autoRenewFlag = false;
    }
    return !!this.productOfficeGroup && this.productOfficeGroup.homeBuyerEmailRequiredFlag;
  }


  isAutoRenewalSelected(): boolean {
    return this.planOrderApplication.autoRenewFlag;
  }


  didClickAutoRenewalOption() {
    this.planOrderApplication.autoRenewFlag = !this.planOrderApplication.autoRenewFlag;
  }


  didClickEmailAddress() {
    const subject =
      'ORHP Warranty Cancellation/Remove Automatic Renewal: ' + this.planOrderApplication.formattedPlanID;

    const url = 'mailto:' + encodeURIComponent(this.cancellationEmail) +
      '?subject=' + encodeURIComponent(subject);

    window.open(url, '_self');
  }

}


