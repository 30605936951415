
export class PlanOrderProgress {

  progressPercent = 0;
  progressMessage = null;
  successFlag = true;

  planID: number = null;
  planGUID: string = null;

  get completeFlag(): boolean {
    return (this.progressPercent === 100);
  }

  get progressPercentText(): string {
    return (String(this.progressPercent) + '%');
  }


  constructor(progressPercent: number, progressMessage: string, successFlag: boolean) {
    this.progressPercent = progressPercent;
    this.progressMessage = progressMessage;
    this.successFlag = successFlag;
  }

}
