
import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {PlanOrderService} from './_services/plan-order.service';
import {PlanOrderLookupService} from './_services/plan-order-lookup.service';
import {PlanOrderBaseComponent} from './plan-order-base-component';
import {ModalProgress, PhxModalProgressService, UserAuthorities} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from './_services/plan-order-auth.service';

@Component({
  selector: 'app-plan-order-frame',
  templateUrl: './plan-order-frame.component.html',
  styleUrls: ['./plan-order.scss']
})


export class PlanOrderFrameComponent extends PlanOrderBaseComponent implements OnInit, OnDestroy {

  @Input() rootActivatedRoute: ActivatedRoute;



  loadedFlag = true;



  constructor(private router: Router,
              private route: ActivatedRoute,
              private planOrderService: PlanOrderService,
              private modalProgressService: PhxModalProgressService,
              private planOrderAuthService: PlanOrderAuthService) {
    super(planOrderService);
  }


  ngOnInit() {
  }


  ngOnDestroy() {
  }


  shouldDisplayPlanOrder(): boolean {
    return this.loadedFlag;
  }


}

