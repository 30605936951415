import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';
import {PlanQuoteResult} from '../_src/plan-quote-result';
import {PlanQuoteRequest} from '../_src/plan-quote-request';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {PlanQuoteService} from '../_services/plan-quote.service';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {IPlan} from '@orhp/phx-plan-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';


@Component({
  selector: 'app-plan-order-email-quote',
  templateUrl: './email-quote.component.html',
  styleUrls: [
    '../plan-order.scss',
    './email-quote.component.scss'
  ]
})

export class PlanOrderEmailQuoteComponent implements OnInit {

  @Input() quoteRequest: PlanQuoteRequest;

  @Output() onComplete = new EventEmitter<boolean>();


  quoteStatus: QuoteStatus = QuoteStatus.emailPrompt;


  // user email
  get userEmailAddress(): string {
    let email: string = null;

    if (this.planOrderAuthService.loggedIn()) {
      const customer = this.planOrderAuthService.customer;

      if (customer && customer.email) {
        email = customer.email.phoneNumber;
      }
    }

    return email;
  }


  // quote email address
  private _quoteEmailAddress: string = null;

  get quoteEmailAddress(): string {
    if (!this._quoteEmailAddress && this.shouldDefaultLoggedInEmail()) {
      this._quoteEmailAddress = '';
    }

    if (this._quoteEmailAddress === null) {
      this._quoteEmailAddress = '';
    }

    return this._quoteEmailAddress;
  }

  set quoteEmailAddress(quoteEmailAddress: string) {
    this._quoteEmailAddress = quoteEmailAddress;
  }




  constructor(private planOrderAuthService: PlanOrderAuthService,
              private planQuoteService: PlanQuoteService,
              private systemService: PhxSystemService) {
  }



  ngOnInit() {

  }


  /**
   * Should the component default to the logged-in email address?
   */
  shouldDefaultLoggedInEmail(): boolean {
    let flag = this.planOrderAuthService.loggedIn();

    // pull the product/office info
    const productOffice = this.planOrderAuthService.productOffice;
    const productOfficeToolbox = (productOffice ? productOffice.toolbox : null);

    // if the user is an auto-login, do not populate
    if (productOfficeToolbox && productOfficeToolbox.autoLoginGuestGUID) {
      flag = false;
    }

    // if the user has a telemarketing employer, do not populate
    const customer = this.planOrderAuthService.customer;
    const employer = (customer ? customer.employer : null);
    const employerType = (employer ? employer.employerType : null);

    if (employerType && employerType.telemarketingFlag) {
      flag = false;
    }

    return flag;
  }




  // should display quote email prompt
  shouldDisplayQuoteEmailModal(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.quoteStatus !== QuoteStatus.none);

    return displayFlag;
  }


  shouldDisplayQuoteEmailForm(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.quoteStatus === QuoteStatus.emailPrompt);
    displayFlag = displayFlag || (this.quoteStatus === QuoteStatus.validationError);
    displayFlag = displayFlag || (this.quoteStatus === QuoteStatus.emailError);

    return displayFlag;
  }


  // should the quote error display
  shouldDisplayQuoteEmailError(): boolean {
    return (this.quoteStatus === QuoteStatus.emailError);
  }


  // should we display a quote success message
  shouldDisplayQuoteEmailSuccess(): boolean {
    return (this.quoteStatus === QuoteStatus.emailSent);
  }



  // user clicked to send the email quote
  didClickConfirmEmailQuoteButton(): void {
    this.validateQuote();
  }


  // user clicked to cancel sending the email quote
  didClickCancelEmailQuoteButton(): void {
    this.quoteStatus = QuoteStatus.emailPrompt;

    this.quoteEmailAddress = null;

    this.onComplete.emit(false);
  }


  // user clicked to dismiss the confirmation modal
  didClickDismissModal() {
    this.quoteStatus = QuoteStatus.emailPrompt;

    this.onComplete.emit(true);
  }


  shouldDisplayQuoteInProgress(): boolean {
    return (this.quoteStatus === QuoteStatus.emailInProgress);
  }


  shouldDisplayQuoteSuccess(): boolean {
    return (this.quoteStatus === QuoteStatus.emailSent);
  }


  // performs quote validation
  validateQuote() {
    let flag = true;

    // if there is a validation error
    if (this.quoteValidationErrorText()) {
      flag = false;
    }

    // if we should proceed with the quote
    if (flag) {
      this.generateQuote();

    // if there was a validation error
    } else {
      this.quoteStatus = QuoteStatus.validationError;
    }
  }


  // should the quote validation error text be displayed
  shouldDisplayQuoteValidationErrorText(): boolean {
    let flag = true;

    // if there is no error text, there's no flag
    if (!this.quoteValidationErrorText()) {
      flag = false;
    }

    // if the quote status isn't validation error, don't display
    if (this.quoteStatus !== QuoteStatus.validationError) {
      flag = false;
    }

    return flag;
  }


  // is there a validation error
  quoteValidationErrorText(): string {
    let text: string = null;

    // validate email address
    if (!text) {
      const emailAddress = (this.quoteEmailAddress || '');

      if (!StringUtility.emailValidate(emailAddress)) {
        text = 'Valid Email Address is required';
      }
    }

    return text;
  }




  // generates the quote to the specified recipient
  generateQuote() {

    // we're in progress
    this.quoteStatus = QuoteStatus.emailInProgress;

    // send Google Analytics tracking
    this.systemService.sendGoogleAnalyticsTracking('/plan-quote/email');

    // duplicate the request
    const request = this.quoteRequest.duplicate();

    // sprinkle in the email address
    request.emailAddress = this.quoteEmailAddress;

    // fetch the plan quote
    this.planQuoteService.fetchPlanQuote(request).subscribe((result: PlanQuoteResult) => {

      // if the email was successful
      if (result && result.emailSuccessFlag) {
        this.quoteStatus = QuoteStatus.emailSent;

      // if the email was unsuccessful
      } else {
        this.quoteStatus = QuoteStatus.emailError;
      }

    });

  }



}







enum QuoteStatus {
  none = 10,
  validationError = 20,
  emailPrompt = 40,
  emailInProgress = 60,
  emailSent = 70,
  emailError = 80
}





