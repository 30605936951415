


import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {AuthenticationService} from '../login';

@Component({
  selector: 'app-plan-order',
  templateUrl: './plan-order.component.html',
  styleUrls: []
})

export class PlanOrderComponent implements OnInit {

  get activatedRoute(): ActivatedRoute {
    return this.route;
  }



  constructor(private route: ActivatedRoute
              ) {

  }

  ngOnInit() {
  }



}
