import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthenticationService} from "./service/authentication.service";
import {Router} from "@angular/router";
import {OfficeAgentLookupService} from "../_modules/office-agent-lookup/_services/lookup.service";

@Component({
    selector: 'phx-logout',
    template: '...'
})
export class LogoutComponent implements OnInit {

    constructor(private authenticationService: AuthenticationService, private officeAgentLookupService: OfficeAgentLookupService, private router: Router) {

    }

    ngOnInit() {
        this.authenticationService.logout();
        this.officeAgentLookupService.clearAllData();
        this.router.navigate(['/login']);
    }

}
