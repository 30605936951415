


import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment,
  UrlSerializer
} from '@angular/router';
import {SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {MyWarrantiesService} from './my-warranties.service';

@Injectable()

export class MyWarrantiesGuard implements CanActivate {

  constructor(private router: Router,
              private urlSerializer: UrlSerializer,
              private myWarrantiesService: MyWarrantiesService
  ) { }



  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const urlTree = this.urlSerializer.parse(state.url);

    const lastPageNumberLoaded = this.myWarrantiesService.lastPageNumberLoaded;
    const pageNumber = (lastPageNumberLoaded ? lastPageNumberLoaded : 1);

    const urlSegment = new UrlSegment(String(pageNumber), {});
    RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);

    return false;
  }


}

