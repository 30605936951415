
export class CustomUrlParser {


  /**
   * Returns a Custom URL
   */
  static getCustomUrl(): string {
    let customUrl: string = null;

    // pull the first path part
    const pathParts = window.location.pathname.split('/');
    let firstPathPart = pathParts[0];

    if ((firstPathPart === '') && (pathParts.length > 1)) {
      firstPathPart = pathParts[1];
    }

    // if the first path part contains something
    if (firstPathPart !== '') {
      // we're ignoring these paths
      const ignorePaths = [
        'plan-order',
        'home',
        'login-passthru',
        'login',
        'logout',
        'welcome',
        'account',
        'my-profile',
        'signup',
        'legacy-toolbox',
        'debug',
        'reset'
      ];

      // if the first path part does not match any of these, it's custom
      if (!ignorePaths.includes(firstPathPart)) {
        customUrl = firstPathPart;
      }
    }

    return customUrl;
  }



  /**
   * Returns the base HREF
   */
  static getBaseHref(): string {
    // default the HREF
    let baseHref = '/';

    // pull the custom URL
    const customUrl = CustomUrlParser.getCustomUrl();

    // if there's a custom URL, make it the base href
    if (customUrl) {
      baseHref = '/' + customUrl;
    }

    return baseHref;
  }

}


