import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../login/service/authentication.service';


@Injectable()
export class NoAutoLoginGuard implements CanActivate {

  /**
   * Constructor
   * @param router
   * @param authenticationService
   */
  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
  }


  /**
   * Can the page activate?
   * @param route
   * @param state
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('!!!NoAutoLoginGuard Start');

    // if there's an auto-login
    if (this.authenticationService.isAutoLogin()) {
      // redirect to plan order
      this.router.navigate(['/plan-order'], {});
      return false;
    }

    return true;
  }




}
