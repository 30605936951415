﻿import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LocalStorage} from '@orhp/phx-localstorage-ui-module';
import {LoginToken} from '@orhp/phx-common-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  get authenticationService(): AuthenticationService {
    return <AuthenticationService>this.loginService.authenticationService;
  }

  constructor(private router: Router,
              private loginService: PhxLoginService,
              private systemService: PhxSystemService
              ) { }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      this.systemService.loadAppSettings().subscribe((appSettingsLoadedFlag: boolean) => {

        // if the user is not logged in
        if (!this.authenticationService.loggedIn()) {

          const url = '/login';

          this.router.navigate([url]);

          subscriber.next(false);
          subscriber.complete();

        // if the user is logged in, validate their login
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      });

    });

    return observable;
  }
}
