

import {Component, OnInit} from '@angular/core';
import {HubspotService} from '@orhp/phx-common-ui-module';
import {AuthenticationService} from '../../login/service/authentication.service';
import {Customer} from '@orhp/phx-customer-ui-module';
import {AcctExecLookupService} from '@orhp/phx-geography-ui-module';
import {AcctExec} from '@orhp/phx-geography-ui-module';
import {AcctExecLookupParams} from '@orhp/phx-geography-ui-module';
import {LocalStorage} from '@orhp/phx-localstorage-ui-module';
import {Http, Response} from '@angular/http';
import {PhxSystemService} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-ae-detail',
  moduleId: module.id,
  templateUrl: 'ae-detail.component.html',
  styleUrls: [
    './ae-detail.component.scss'
  ]
})

export class AEDetailComponent implements OnInit {

  acctExecImageUrl: string = null;

  pageStatus = PageStatus.acctExecImageLoading;


  private _acctExecs: AcctExec[] = null;

  set acctExecs(acctExecs: AcctExec[]) {
    this._acctExecs = acctExecs;

    this.populateDisplayAcctExecs(acctExecs);
  }

  get acctExecs(): AcctExec[] {
    return this._acctExecs;
  }

  displayAcctExecs: AcctExecDisplay[] = null;


  get orhpFlag(): boolean {
    return this.authenticationService.orhpUserFlag;
  }


  constructor(private acctExecLookupService: AcctExecLookupService,
              private authenticationService: AuthenticationService,
              private systemService: PhxSystemService,
              private ngHttp: Http
              ) {
  }


  ngOnInit() {

    // if this isn't an ORHP user, fetch account execs
    if (!this.orhpFlag) {
      this.fetchAcctExecs();
    }
  }

  fetchAcctExecs() {
    try {
        if (this.authenticationService.acctExecs !== null && this.authenticationService.acctExecs.length > 0) {
            this.acctExecs = this.authenticationService.acctExecs;

            this.populateAcctExecImageUrl();

        } else {
            const customer = <Customer>this.authenticationService.customer;

            const params = new AcctExecLookupParams();

            const territory = customer.employer.parentTerritory.toUpperCase();

            if (territory !== 'EEE-800') {
                params.territory = territory;

            } else {
                const address = customer.employer.physicalAddress;

                params.zipCode = (address ? address.zipCode.substr(0, 5) : '');
            }

            this.acctExecLookupService.fetchAccountExecs(params).subscribe((acctExecs: AcctExec[]) => {
                this.acctExecs = acctExecs;

                this.authenticationService.acctExecs = acctExecs;

                this.populateAcctExecImageUrl();
            });
        }
    } catch (e) {
      console.log(e.toString());
    }

  }


  populateDisplayAcctExecs(acctExecs: AcctExec[]) {
    const displayAcctExecs: AcctExecDisplay[] = [];

    // loop over account execs
    if (acctExecs) {
      acctExecs.forEach((indexAcctExec: AcctExec) => {

        // loop over names/emails
        const personCount = indexAcctExec.names.length;

        for (let personIndex = 0; personIndex < personCount; personIndex++) {
          const indexName = indexAcctExec.names[personIndex];
          const indexEmail = (personIndex < indexAcctExec.emails.length ? indexAcctExec.emails[personIndex] : null);

          const displayAcctExec = new AcctExecDisplay();
          displayAcctExec.name = indexName;
          displayAcctExec.email = indexEmail;
          displayAcctExec.phone = indexAcctExec.phone;
          displayAcctExec.url = indexAcctExec.website;

          let displayAcctExecExistsFlag = false;

          displayAcctExecs.forEach((indexDisplayAcctExec: AcctExecDisplay) => {
            if (displayAcctExec.name.toLowerCase() === indexDisplayAcctExec.name.toLowerCase()) {
              displayAcctExecExistsFlag = true;
            }
          });

          if (!displayAcctExecExistsFlag) {
            displayAcctExecs.push(displayAcctExec);
          }
        }

      });

    }

    this.displayAcctExecs = displayAcctExecs;
  }




  acctExecListTitle(): string {
    let title = 'Lorem Ipsum';

    const acctExecCount = this.displayAcctExecs.length;

    let cresFlag = false;

    this.acctExecs.forEach((indexAcctExec: AcctExec) => {
      cresFlag = cresFlag || indexAcctExec.cresFlag;
    });


    // 1 Single Acct Exec
    if (acctExecCount === 1) {
      title = 'Your Local Account Executive';

    // Multiple Acct Execs - CRES
    } else if (cresFlag) {
      title = 'Your CRES Home Warranty Team';

    // Multiple Acct Execs - Not CRES
    } else if (!cresFlag) {
      title = 'Your Local Account Executives';
    }

    return title;
  }


  shouldDisplayAcctExecBox(): boolean {
    let displayFlag = true;

    if (this.pageStatus === PageStatus.acctExecImageLoadFailure) {
      displayFlag = false;
    }

    if (this.orhpFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }


  shouldDisplayAcctExecList(): boolean {
    const customer = <Customer>this.authenticationService.customer;

    let displayFlag = true;

    // user must be logged in
    displayFlag = displayFlag && !!customer;

    // at least 1 Acct Exec must be returned
    displayFlag = displayFlag && ((this.acctExecs || []).length > 0);

    // Acct Exec image must have loaded successfully
    displayFlag = displayFlag && (this.pageStatus === PageStatus.acctExecImageLoadSuccess);

    // User is not ORHP
    displayFlag = displayFlag && !this.orhpFlag;

    return displayFlag;
  }


  shouldDisplayAcctExecLink(displayAcctExec: AcctExecDisplay): boolean {
    const url = (displayAcctExec.url || '');
    const displayFlag = (url !== '');

    return displayFlag;
  }



  didClickEmail(email: string) {
    const url = 'mailto:' + encodeURIComponent(email);

    window.open(url, '_self');

  }


  didClickVisitWebSite(displayAcctExec: AcctExecDisplay) {
    const url = displayAcctExec.url;

    window.open(url, 'MyORHP');
  }


  shouldDisplayAcctExecImage(): boolean {
    return !!this.acctExecImageUrl;
  }


  populateAcctExecImageUrl() {
    const customer = this.authenticationService.customer;
    const employer = (customer ? customer.employer : null);
    const territory = (employer ? employer.parentTerritory : '');

    let url: string = null;

    if (territory !== '') {
      url = this.systemService.acctExecImageUrl.replace(/(\$\$territory\$\$)/, encodeURIComponent(territory));
    }

    this.acctExecImageUrl = url;
  }


  didLoadAcctExecImage() {
    this.pageStatus = PageStatus.acctExecImageLoadSuccess;
  }


  didErrorOnAcctExecImage() {
    this.pageStatus = PageStatus.acctExecImageLoadFailure;
  }


}


class AcctExecDisplay {
  name: string = null;
  email: string = null;
  phone: string = null;
  url: string = null;
}


enum PageStatus {
  acctExecImageLoading = 10,
  acctExecImageLoadSuccess = 20,
  acctExecImageLoadFailure = 30
}

