
import {Injectable} from '@angular/core';
import {PlanOrderProgressBarStep} from '../_models/progress-bar-step';
import {PlanOrderApplication, PlanOrderApplicationUpdates} from '../_models/plan-order-application';
import {PlanOrderService} from './plan-order.service';
import {
  PlanLookupParams,
  PlanDomainFactoryService,
  PlanLookupService,
  PlanLookupValueDomainFactoryService,
  PlanLookupValueService,
  PlanOrdererRole, Plan, TransactionRole, PlanOrdererRoleDefault
} from '@orhp/phx-plan-ui-module';
import {PlanOrderRoleResolver} from '../role/role.resolver';
import {HttpErrorResponse} from '@angular/common/http';
import {Response} from '@angular/http';
import {PlanOrderAuthService} from './plan-order-auth.service';
import {PlanOrderLookupValueService} from './plan-order-lookup-value.service';

@Injectable()

export class PlanOrderStepService {


  currentStep: PlanOrderProgressBarStep = null;

  get allSteps(): PlanOrderProgressBarStep[] {
    return this.buildSteps();
  }




  constructor(private planOrderAuthService: PlanOrderAuthService,
              private planOrderService: PlanOrderService,
              private planOrderLookupValueService: PlanOrderLookupValueService,
              private planLookupValueService: PlanLookupValueService
  ) {}



  // is the user logged in?
  loggedIn(): boolean {
    return this.planOrderAuthService.loggedIn();
  }



  // is this the current step?
  isCurrentStepByCode(stepCode: string): boolean {
    let flag = false;

    if (this.currentStep) {
      flag = (this.currentStep.code.toLowerCase() === (stepCode || '').toLowerCase());
    }

    return flag;
  }




  // sets the current step by code
  setCurrentStepByCode(application: any, stepCode?: string) {
    let step: PlanOrderProgressBarStep = null;

    this.allSteps.forEach((indexStep: PlanOrderProgressBarStep) => {
      if (indexStep.code === stepCode) {
        step = indexStep;
      }
    });

    this.currentStep = step;

    application.currentStep = stepCode;
  }




  isAccountExecutive(planID: number, guestGUID: string, planOrdererRole: PlanOrdererRole ): boolean {
    let planGuestGUID = '';

    if (planOrdererRole !== null) {
      planGuestGUID = planOrdererRole.guestGUID;
    }

    const hasPlanID = (planID !== null && planID !== undefined);
    const differentPlanAndCreatorGUIDS = (guestGUID || '').toLowerCase() !== planGuestGUID.toLowerCase();
    const planAndCreatorGUIDSAreNull = guestGUID === null && planGuestGUID === '';
    const hasTerritories = (this.planOrderAuthService.aeTerritories || []).length > 0;


    if (hasPlanID && hasTerritories &&
      ((differentPlanAndCreatorGUIDS && guestGUID !== null) || planAndCreatorGUIDSAreNull)) {
      return true;
    } else {
      return false;
    }
  }







  buildSteps(): PlanOrderProgressBarStep[] {
    const allSteps = [];
    const application = this.planOrderService.planOrderApplication;

    const existingPlanFlag = !!application.planID;

    const reviewStepFlag = this.isCurrentStepByCode( 'review');


    let step: PlanOrderProgressBarStep = null;


    // Transaction Type
    let transactionTypeDisplayFlag = false;
    let transactionTypeEnabledFlag = false;

    const transactionTypeStepFlag = this.isCurrentStepByCode( 'transaction-type');
    const transactionTypeUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.transactionType);

    // if this is a new plan
    if (!existingPlanFlag) {
      transactionTypeDisplayFlag = true;

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        transactionTypeEnabledFlag = transactionTypeEnabledFlag || transactionTypeUpdateFlag;
        transactionTypeEnabledFlag = transactionTypeEnabledFlag || transactionTypeStepFlag;
        transactionTypeEnabledFlag = transactionTypeEnabledFlag || reviewStepFlag;

      // all steps haven't been completed yet
      } else {
        transactionTypeEnabledFlag = true;
      }

      // if a transaction type has been selected, and only one is available
      const selectedTransactionType = application.transactionType;
      const transactionTypesForDisplay = this.planOrderLookupValueService.transactionTypesForDisplay();

      if (!!selectedTransactionType && (transactionTypesForDisplay.length === 1)) {
        // if they match, don't display the progress bar item
        if (selectedTransactionType.isEqual(transactionTypesForDisplay[0])) {
          transactionTypeDisplayFlag = false;
        }
      }

    }

    if (transactionTypeDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'transaction-type',
        'Transaction',
        'transaction-type',
        true,
        null,
        transactionTypeEnabledFlag
      );

      allSteps.push(step);
    }





    // Property Type
    let propertyTypeEnabledFlag = false;
    const propertyTypeDisplayFlag = true;

    let propertyTypeUpdateFlag = false;
    propertyTypeUpdateFlag = propertyTypeUpdateFlag || application.hasEdit(PlanOrderApplicationUpdates.propertyZipCode);
    propertyTypeUpdateFlag = propertyTypeUpdateFlag || application.hasEdit(PlanOrderApplicationUpdates.propertyType);

    const propertyTypeStepFlag = this.isCurrentStepByCode( 'property-type');

    // if this is an existing plan
    if (existingPlanFlag) {
      propertyTypeEnabledFlag = propertyTypeEnabledFlag || propertyTypeUpdateFlag;
      propertyTypeEnabledFlag = propertyTypeEnabledFlag || propertyTypeStepFlag;

    // if this is a new plan
    } else {
      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        propertyTypeEnabledFlag = propertyTypeEnabledFlag || propertyTypeUpdateFlag;
        propertyTypeEnabledFlag = propertyTypeEnabledFlag || propertyTypeStepFlag;
        propertyTypeEnabledFlag = propertyTypeEnabledFlag || reviewStepFlag;


      // if the steps have not been completed
      } else {
        propertyTypeEnabledFlag = true;
      }
    }


    if (propertyTypeDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'property-type',
        'Property Type',
        'property-type',
        true,
        null,
        propertyTypeEnabledFlag
      );

      allSteps.push(step);
    }



    // Role
    let roleDisplayFlag = false;
    let roleEnabledFlag = false;

    const roleStepFlag = this.isCurrentStepByCode( 'role');
    const roleUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.role);

    // if this is a new plan
    if (!existingPlanFlag) {
      // default the role display to true
      roleDisplayFlag = true;

      // if the user is logged in
      if (this.planOrderAuthService.loggedIn()) {
        const jobRole = this.planOrderAuthService.jobRole;

        const jobRoleCode = (!!jobRole ? jobRole.jobRole : null);

        let defaultRole: PlanOrdererRoleDefault = null;

        if (!!application.transactionType && !!jobRoleCode) {
          defaultRole = this.planLookupValueService.ordererRoleDefaultByJobRole(application.transactionType, jobRoleCode);
        }

        // if we're skipping the Role step
        if (defaultRole && defaultRole.skipRoleStepFlag) {
          roleDisplayFlag = false;
        }
      }

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        roleEnabledFlag = roleEnabledFlag || roleUpdateFlag;
        roleEnabledFlag = roleEnabledFlag || roleStepFlag;
        roleEnabledFlag = roleEnabledFlag || reviewStepFlag;

      // all steps have not been completed yet
      } else {
        roleEnabledFlag = true;
      }
    }


    if (roleDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'role',
        'Role',
        'role',
        true,
        null,
        roleEnabledFlag
      );

      allSteps.push(step);
    }





    // Initiating Agent
    let initiatingAgentEnabledFlag = false;
    let initiatingAgentDisplayFlag = false;

    const initiatingAgentStepFlag = this.isCurrentStepByCode( 'initiating-agent');
    const initiatingAgentUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.initiatingAgent);

    // if this is an existing plan
    if (existingPlanFlag) {
      initiatingAgentDisplayFlag = true;

      if (!!application.transactionType && application.transactionType.directToConsumerFlag) {
        initiatingAgentDisplayFlag = false;
      }

      initiatingAgentEnabledFlag = initiatingAgentEnabledFlag || initiatingAgentUpdateFlag;
      initiatingAgentEnabledFlag = initiatingAgentEnabledFlag || initiatingAgentStepFlag;

    // if this is a new plan
    } else {
      let promptFlag = true;

      // test for transaction type
      if (!!application.transactionType && !application.transactionType.promptForInitiatingAgentFlag) {
        promptFlag = false;
      }

      // test for role
      if (application.role && !application.role.promptForInitiatingAgentFlag) {
        promptFlag = false;
      }

      if (promptFlag) {
        initiatingAgentDisplayFlag = true;
      }

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        initiatingAgentEnabledFlag = initiatingAgentEnabledFlag || initiatingAgentUpdateFlag;
        initiatingAgentEnabledFlag = initiatingAgentEnabledFlag || initiatingAgentStepFlag;
        initiatingAgentEnabledFlag = initiatingAgentEnabledFlag || reviewStepFlag;

      // if the steps have not been completed
      } else {
        initiatingAgentEnabledFlag = true;
      }
    }

    // if the user is an AE, do not show the Initiating Agent step
    if (this.isAccountExecutive(application.planID, this.planOrderAuthService.guestGUID, application.planOrdererRole)) {
      initiatingAgentDisplayFlag = false;
    }


    if (initiatingAgentDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'initiating-agent',
        'Initiating Agent',
        'initiating-agent',
        true,
        null,
        initiatingAgentEnabledFlag
      );

      allSteps.push(step);
    }





    // Product
    const optionalCoverageUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.optionalCoverages);

    let productEnabledFlag = false;
    const productDisplayFlag = true;

    const productStepFlag = this.isCurrentStepByCode( 'product');
    const productSelectedFlag = !!application.product;
    const productUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.product);

    // if this is an existing plan
    if (existingPlanFlag) {
      productEnabledFlag = productEnabledFlag || productStepFlag;
      productEnabledFlag = productEnabledFlag || !productSelectedFlag;
      productEnabledFlag = productEnabledFlag || productUpdateFlag;

    // if this is a new plan
    } else {
      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        productEnabledFlag = productEnabledFlag || productStepFlag;
        productEnabledFlag = productEnabledFlag || !productSelectedFlag;
        productEnabledFlag = productEnabledFlag || productUpdateFlag;
        productEnabledFlag = productEnabledFlag || reviewStepFlag;
        productEnabledFlag = productEnabledFlag || optionalCoverageUpdateFlag;

      // if all steps haven't been completed yet
      } else {
        productEnabledFlag = true;
      }
    }


    if (productDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'product',
        'Product',
        'product',
        true,
        null,
        productEnabledFlag
      );

      allSteps.push(step);
    }

    // Optional Coverage
    let optionalCoverageEnabledFlag = false;
    const optionalCoverageDisplayFlag = true;

    let optionalCoverageHideFlag = false;

    const optionalCoverageStepFlag = this.isCurrentStepByCode( 'optional-coverage');
    const optionalCoverageSelectedFlag = !!application.productCoverages;

    // if this is an existing plan
    if (existingPlanFlag) {
      optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || optionalCoverageStepFlag;
      optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || !optionalCoverageSelectedFlag;
      optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || optionalCoverageUpdateFlag;

    // if this is a new plan
    } else {
      optionalCoverageHideFlag = true;

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || optionalCoverageStepFlag;
        optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || !optionalCoverageSelectedFlag;
        optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || optionalCoverageUpdateFlag;
        optionalCoverageEnabledFlag = optionalCoverageEnabledFlag || reviewStepFlag;

      // if all steps haven't been completed
      } else {
        optionalCoverageEnabledFlag = true;
      }
    }


    if (optionalCoverageDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'optional-coverage',
        'Optional Coverage',
        'optional-coverage',
        !optionalCoverageHideFlag,
        (optionalCoverageHideFlag ? 'product' : null),
        optionalCoverageEnabledFlag
      );

      allSteps.push(step);
    }




    // Property Address
    let propertyAddressEnabledFlag = false;
    const propertyAddressDisplayFlag = true;

    let propertyAddressUpdateFlag = false;
    propertyAddressUpdateFlag = propertyAddressUpdateFlag || application.hasEdit(PlanOrderApplicationUpdates.propertyZipCode);
    propertyAddressUpdateFlag = propertyAddressUpdateFlag || application.hasEdit(PlanOrderApplicationUpdates.propertyAddress);

    const propertyAddressStepFlag = this.isCurrentStepByCode( 'property-address');
    const propertyAddressEnteredFlag = (application.property.address !== '');

    // if this is an existing plan
    if (existingPlanFlag) {
      propertyAddressEnabledFlag = propertyAddressEnabledFlag || propertyAddressUpdateFlag;
      propertyAddressEnabledFlag = propertyAddressEnabledFlag || propertyAddressStepFlag;

    // if this is a new plan
    } else {
      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        propertyAddressEnabledFlag = propertyAddressEnabledFlag || propertyAddressUpdateFlag;
        propertyAddressEnabledFlag = propertyAddressEnabledFlag || propertyAddressStepFlag;
        propertyAddressEnabledFlag = propertyAddressEnabledFlag || reviewStepFlag;

      // all steps have not been completed
      } else {
        propertyAddressEnabledFlag = true;
      }
    }


    if (propertyAddressDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'property-address',
        'Property Address',
        'property-address',
        true,
        null,
        propertyAddressEnabledFlag
      );

      allSteps.push(step);
    }





    // Cooperating Agent
    let cooperatingAgentEnabledFlag = false;
    let cooperatingAgentDisplayFlag = true;

    const cooperatingAgentStepFlag = this.isCurrentStepByCode( 'cooperating-agent');
    const cooperatingAgentUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.cooperatingAgent);

    // if this is an existing plan
    if (existingPlanFlag) {
      cooperatingAgentDisplayFlag = this.promptForCooperatingAgent(application);

      cooperatingAgentEnabledFlag = cooperatingAgentEnabledFlag || cooperatingAgentStepFlag;
      cooperatingAgentEnabledFlag = cooperatingAgentEnabledFlag || cooperatingAgentUpdateFlag;

    // if this is a new plan
    } else {
      cooperatingAgentDisplayFlag = this.promptForCooperatingAgent(application);

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        cooperatingAgentEnabledFlag = cooperatingAgentEnabledFlag || cooperatingAgentStepFlag;
        cooperatingAgentEnabledFlag = cooperatingAgentEnabledFlag || cooperatingAgentUpdateFlag;
        cooperatingAgentEnabledFlag = cooperatingAgentEnabledFlag || reviewStepFlag;

      // if all steps have not been completed
      } else {
        cooperatingAgentEnabledFlag = true;
      }
    }

    if (this.isAccountExecutive(application.planID, this.planOrderAuthService.guestGUID, application.planOrdererRole)) {
      cooperatingAgentDisplayFlag = false;
    }

    if (cooperatingAgentDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'cooperating-agent',
        'Cooperating Agent',
        'cooperating-agent',
        true,
        null,
        cooperatingAgentEnabledFlag
      );

      allSteps.push(step);
    }




    // Closing Info
    let escrowEnabledFlag = false;
    let escrowDisplayFlag = true;

    const escrowStepFlag = this.isCurrentStepByCode( 'closing-info');
    const escrowUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.closingAgent);

    // if this is an existing plan
    if (existingPlanFlag) {
      if (!!application.transactionType && application.transactionType.directToConsumerFlag) {
        escrowDisplayFlag = false;
      }

      escrowEnabledFlag = escrowEnabledFlag || escrowStepFlag;
      escrowEnabledFlag = escrowEnabledFlag || escrowUpdateFlag;

    // if this is a new plan
    } else {
      const promptForEscrowFlag = (!!application.transactionType ? application.transactionType.promptForEscrowFlag : true);

      if (!promptForEscrowFlag) {
        escrowDisplayFlag = false;
      }

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        escrowEnabledFlag = escrowEnabledFlag || escrowStepFlag;
        escrowEnabledFlag = escrowEnabledFlag || escrowUpdateFlag;
        escrowEnabledFlag = escrowEnabledFlag || reviewStepFlag;

      // if all steps have not been completed
      } else {
        escrowEnabledFlag = true;
      }
    }


    if (escrowDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'closing-info',
        'Closing\nInfo',
        'closing-info',
        true,
        null,
        escrowEnabledFlag
        );

      allSteps.push(step);
    }





    // Home Buyers
    let buyerEnabledFlag = false;
    let buyerDisplayFlag = true;

    const buyerStepFlag = this.isCurrentStepByCode( 'home-buyer');
    const buyerUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.homeBuyer);

    // if this is an existing plan
    if (existingPlanFlag) {
      buyerEnabledFlag = buyerEnabledFlag || buyerStepFlag;
      buyerEnabledFlag = buyerEnabledFlag || buyerUpdateFlag;

    // if this is a new plan
    } else {
      if (!this.promptForBuyer(application)) {
        buyerDisplayFlag = false;
      }

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        buyerEnabledFlag = buyerEnabledFlag || buyerStepFlag;
        buyerEnabledFlag = buyerEnabledFlag || buyerUpdateFlag;
        buyerEnabledFlag = buyerEnabledFlag || reviewStepFlag;

      // if all steps haven't been completed
      } else {
        buyerEnabledFlag = true;
      }
    }


    if (buyerDisplayFlag) {
      let homeBuyerOwnerText = 'Buyer';

      if (application.transactionType && application.transactionType.directToConsumerFlag) {
        homeBuyerOwnerText = 'Owner';
      }

      const stepName = application.homeBuyerOrOwnerText;

      step = new PlanOrderProgressBarStep(
        'home-buyer',
        stepName,
        'home-buyer',
        true,
        null,
        buyerEnabledFlag
      );

      allSteps.push(step);
    }






    // Home Sellers
    let sellerEnabledFlag = false;
    let sellerDisplayFlag = true;

    const sellerStepFlag = this.isCurrentStepByCode( 'home-seller');
    const sellerUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.homeSeller);

    // if this is an existing plan
    if (existingPlanFlag) {
      if (!!application.transactionType && application.transactionType.directToConsumerFlag) {
        sellerDisplayFlag = false;
      }

      sellerEnabledFlag = sellerEnabledFlag || sellerStepFlag;
      sellerEnabledFlag = sellerEnabledFlag || sellerUpdateFlag;

      // if this is a new plan
    } else {
      // are we hiding the seller?
      if (!!application.transactionType && !application.transactionType.promptForHomeSellerFlag) {
        sellerDisplayFlag = false;
      }

      // if all steps have been completed
      if (application.allStepsCompletedFlag) {
        sellerEnabledFlag = sellerEnabledFlag || sellerStepFlag;
        sellerEnabledFlag = sellerEnabledFlag || sellerUpdateFlag;
        sellerEnabledFlag = sellerEnabledFlag || reviewStepFlag;

      // if all steps haven't been completed
      } else {
        sellerEnabledFlag = true;
      }
    }


    if (sellerDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'home-seller',
        'Home\nSeller',
        'home-seller',
        true,
        null,
        sellerEnabledFlag
      );

      allSteps.push(step);
    }



    // Mailing Address
    let mailingAddressEnabledFlag = false;
    let mailingAddressDisplayFlag = false;

    const mailingAddressStepFlag = this.isCurrentStepByCode( 'mailing-address');
    const mailingAddressUpdateFlag = application.hasEdit(PlanOrderApplicationUpdates.mailingAddress);

    mailingAddressDisplayFlag = mailingAddressDisplayFlag || mailingAddressStepFlag;
    mailingAddressDisplayFlag = mailingAddressDisplayFlag || mailingAddressUpdateFlag;

    mailingAddressEnabledFlag = mailingAddressDisplayFlag;

    if (mailingAddressDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'mailing-address',
        'Mailing Address',
        'mailing-address',
        false,
        null,
        mailingAddressEnabledFlag
      );

      allSteps.push(step);
    }



    // Payment step
    const paymentEnabledFlag = true;
    let paymentDisplayFlag = false;


    // if a transaction type has been selected
    if (!!application.transactionType && application.transactionType.promptForPaymentFlag) {
      paymentDisplayFlag = true;
    }


    // if there was an attempted payment but it failed
    const ccPaymentResult = application.ccPaymentResult;
    const planInvoicePaymentResult = (!!ccPaymentResult ? ccPaymentResult.object : null);

    if (!!planInvoicePaymentResult && !planInvoicePaymentResult.successFlag) {
      paymentDisplayFlag = true;
    }

    if (paymentDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'payment',
        'Payment',
        'payment',
        true,
        null,
        paymentDisplayFlag
      );

      allSteps.push(step);
    }





    // Review step
    const reviewEnabledFlag = true;
    let reviewDisplayFlag = false;

    // if this is a new plan
    if (!existingPlanFlag) {
      reviewDisplayFlag = true;
    }

    if (reviewDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'review',
        'Review',
        'review',
        true,
        null,
        reviewEnabledFlag
      );

      allSteps.push(step);
    }


    // Save step
    const saveEnabledFlag = true;
    const saveDisplayFlag = true;

    if (saveDisplayFlag) {
      step = new PlanOrderProgressBarStep(
        'save',
        'Save',
        'save',
        false,
        null,
        saveEnabledFlag
      );

      allSteps.push(step);
    }

    return allSteps;
  }


  // returns the index of the step
  indexOfStepByCode(stepCode: string): number {
    let stepIndex = -1;

    for (let testStepIndex = 0; testStepIndex < this.allSteps.length; testStepIndex++) {
      const indexStep = this.allSteps[testStepIndex];

      // if this is the step
      if (indexStep.code === stepCode) {
        stepIndex = testStepIndex;
      }
    }

    return stepIndex;
  }


  // returns the index of the step
  indexOfStep(step: PlanOrderProgressBarStep): number {
    return this.indexOfStepByCode(step.code);
  }


  // returns the next step
  getNextStep(): PlanOrderProgressBarStep {
    let nextStep: PlanOrderProgressBarStep = null;

    if (this.currentStep) {
      const currentStepIndex = this.indexOfStep(this.currentStep);

      if (currentStepIndex < (this.allSteps.length - 1)) {
        let nextStepIndex = currentStepIndex + 1;

        while (!nextStep && (nextStepIndex < this.allSteps.length)) {
          const indexStep = this.allSteps[nextStepIndex];

          if (indexStep.enabledFlag) {
            nextStep = indexStep;
          }

          nextStepIndex++;
        }
      }
    }

    return nextStep;
  }


  // returns the next step code
  getNextRoute(): string {
    const nextStep = this.getNextStep();

    let nextRoute = 'review';

    if (nextStep) {
      nextRoute = nextStep.route;
    }

    return nextRoute;
  }


  // are we prompting for home buyer?
  promptForBuyer(application: PlanOrderApplication): boolean {
    const newPlanFlag = !application.planID;

    let promptFlag = true;

    // new plans with a transaction type defined
    if (newPlanFlag && application.transactionType) {
      if (!application.transactionType.promptForHomeBuyerFlag) {
        promptFlag = false;
      }
    }

    return promptFlag;
  }


  // are we prompting for cooperating agent?
  promptForCooperatingAgent(application: PlanOrderApplication): boolean {
    const newPlanFlag = !application.planID;

    let flag = true;

    if (newPlanFlag) {
      const doubleEndFlag = (application.role ? application.role.realtorListingSellingFlag : false);

      if (doubleEndFlag) {
        flag = false;
      }
    }


    // if a transaction type has been selected
    const promptForCooperatingAgentFlag =
      (application.transactionType ?
        application.transactionType.promptForCooperatingAgentFlag :
        true);

    if (!promptForCooperatingAgentFlag) {
      flag = false;
    }

    // if the initiating agent has been skipped
    if (application.initiatingAgentSkippedFlag) {
      flag = false;
    }

    return flag;
  }

}








