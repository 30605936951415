

import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from './plan-order-auth.service';
import {Subscription} from 'rxjs/Rx';



@Injectable()
export class PlanOrderNoAuthGuard implements CanActivate {

  loginCompletionSubscription: Subscription = null;
  loginCancelSubscription: Subscription = null;


  constructor(private router: Router,
              private planOrderAuthService: PlanOrderAuthService,
              private systemService: PhxSystemService
  ) { }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const nextUrl = state.url;

      this.systemService.loadAppSettings().subscribe((appSettingsLoadedFlag: boolean) => {
        // only force a login if there's a product office group
        const forceLogin = !this.planOrderAuthService.loggedIn() && !!this.planOrderAuthService.productOffice;

        // if the user is not logged in
        if (forceLogin) {
          // trigger the modal login
          this.planOrderAuthService.triggerModalLoginEventEmitter.emit(true);

          // register for login completion
          this.loginCompletionSubscription = this.planOrderAuthService.onLoginCompletion
            .subscribe((successFlag: boolean) => {
              if (successFlag) {
                this._unsubscribe();

                this.router.navigate([nextUrl]);
              }
          });

          // register for login cancellation
          this.loginCancelSubscription = this.planOrderAuthService.loginCancelEventEmitter
            .subscribe((resultFlag: boolean) => {
              this._unsubscribe();

              this.router.navigate(['/']);
          });

          subscriber.next(false);
          subscriber.complete();

          // if the user is logged in
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      });

    });

    return observable;
  }


  private _unsubscribe() {
    if (this.loginCompletionSubscription) {
      this.loginCompletionSubscription.unsubscribe();
    }

    if (this.loginCancelSubscription) {
      this.loginCancelSubscription.unsubscribe();
    }
  }


}

