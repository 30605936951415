
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Employer} from '@orhp/phx-customer-ui-module';


@Component({
  selector: 'app-plan-order-office-auto-match',
  templateUrl: './office-auto-match.component.html',
  styleUrls: ['./office-auto-match.component.scss']
})


export class PlanOrderOfficeAutoMatchComponent implements OnInit {


  @Input() employer: Employer = null;

  @Output() onOfficeConfirm = new EventEmitter<boolean>();


  constructor() {
  }


  ngOnInit() {
  }


  didClickCorrectOffice() {
    this.onOfficeConfirm.emit(false);
  }


  didClickAcceptOffice() {
    this.onOfficeConfirm.emit(true);
  }


}
