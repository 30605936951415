
import {EventEmitter, Injectable} from '@angular/core';
import {SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';

@Injectable()

export class OfficeAgentLookupPersistenceService {

  // where we're storing info
  @SessionStorage('phx.common.ui.officeAgentLookup.LookupTypeCode')
  private _lookupTypeCode: string;

  @SessionStorage('phx.common.ui.officeAgentLookup.Employer')
  private _employerJsonValues: any;

  @SessionStorage('phx.common.ui.officeAgentLookup.Customer')
  private _customerJsonValues: any;


  // Lookup Type
  get lookupTypeCode(): string {
    return this._lookupTypeCode;
  }

  set lookupTypeCode(lookupTypeCode: string) {
    this._lookupTypeCode = lookupTypeCode;
  }


  // Employer
  private _employer: Employer = null;

  get employer(): Employer {
    if (!this._employer) {
      const employerJsonValues = this._employerJsonValues;

      if (employerJsonValues) {
        this._employer = this.employerDomainFactoryService.newEmployer(employerJsonValues);
      }
    }

    return this._employer;
  }

  set employer(employer: Employer) {
    // are the employers different? if so, clear the customer too
    let employersDifferentFlag = false;

    if (this._employer && employer) {
      if (!this._employer.isEqualToEmployer(employer)) {
        employersDifferentFlag = true;
      }

    } else {
      employersDifferentFlag = true;
    }

    if (employersDifferentFlag) {
      this.customer = null;
    }


    this._employer = employer;

    if (employer) {
      this._employerJsonValues = employer.jsonValues();

    } else {
      this._employerJsonValues = null;
    }

    this.employerEventEmitter.emit(employer);
  }




  // Customer
  private _customer: Customer = null;

  get customer(): Customer {
    if (!this._customer) {
      const customerJsonValues = this._customerJsonValues;

      if (customerJsonValues) {
        this._customer = this.customerDomainFactoryService.newCustomer(customerJsonValues);
      }
    }

    return this._customer;
  }

  set customer(customer: Customer) {
    this._customer = customer;

    if (customer) {
      this._customerJsonValues = customer.jsonValues();

    } else {
      this._customerJsonValues = null;
    }

    this.customerEventEmitter.emit(customer);
  }


  // Event Emitters
  employerEventEmitter = new EventEmitter<Employer>();
  customerEventEmitter = new EventEmitter<Customer>();


  constructor(private customerLookupValueService: CustomerLookupValueService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerDomainFactoryService: EmployerDomainFactoryService
              ) {
  }



  // validates the lookup type
  validateLookupType(lookupTypeCode: string): boolean {
    const validFlag = (lookupTypeCode === this.lookupTypeCode);

    if (!validFlag) {
      this.clearPersistedValues();
    }

    return validFlag;
  }


  // updates the employer if it's different
  setEmployerIfDifferent(newEmployer: Employer): boolean {
    // check that the saved employer matches the one supplied
    let updateSavedEmployerFlag = false;

    // both the application and saved employer
    if (newEmployer && this.employer) {
      if (!this.employer.isEqualToEmployer(newEmployer)) {
        updateSavedEmployerFlag = true;
      }

    // one or the other is empty, force an update
    } else {
      updateSavedEmployerFlag = true;
    }

    if (updateSavedEmployerFlag) {
      this.employer = newEmployer;
    }

    return updateSavedEmployerFlag;
  }


  // updates the customer if it's different
  setCustomerIfDifferent(newCustomer: Customer): boolean {
    // check that the saved customer matches the one supplied
    let updateSavedCustomerFlag = false;

    // both the new and saved customer
    if (newCustomer && this.customer) {
      if (!this.customer.isEqualToCustomer(newCustomer)) {
        updateSavedCustomerFlag = true;
      }

    // one of the other is empty, force an update
    } else {
      updateSavedCustomerFlag = true;
    }

    if (updateSavedCustomerFlag) {
      this.customer = newCustomer;
    }

    return updateSavedCustomerFlag;
  }


  // clears persisted values
  clearPersistedValues() {
    this.lookupTypeCode = null;
    this.employer = null;
    this.customer = null;
  }


}
