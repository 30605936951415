

import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {
  ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment,
  UrlSerializer
} from '@angular/router';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {RoutingUtility} from '@orhp/phx-common-ui-module';

@Injectable()

export class OfficeAgentLookupOfficeWildcardResolver implements Resolve<boolean> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private employerLookupService: EmployerLookupService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const employerGUID = snapshot.params['employerGUID'];

      let urlSegment: UrlSegment;

      const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, state.url, employerGUID);

      urlSegment = new UrlSegment('agent', {});
      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

      const customer = this.officeAgentLookupPersistenceService.customer;

      if (customer) {
        urlSegment = new UrlSegment(customer.guid, {});

      } else {
        urlSegment = new UrlSegment('search', {});
      }

      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

      this.router.navigateByUrl(urlTree);

      subscriber.next(false);
      subscriber.complete();
    });

    return observable;
  }

}


