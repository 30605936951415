import {
  Customer,
  CustomerDomainFactoryService,
  CustomerLookupValueService,
  Employer,
  EmployerDomainFactoryService,
  JobRole
} from '@orhp/phx-customer-ui-module';
import {IProductOffice, ProductDomainFactoryService, ProductOffice} from '@orhp/phx-product-ui-module';
import {AcctExec} from '@orhp/phx-geography-ui-module';
import {
  LoginToken,
  MessagingFormatter,
  PhxHttpClientService,
  PhxHttpService,
  PhxLoginService, PhxSystemService,
  UserAuthorities
} from '@orhp/phx-common-ui-module';
import {UserService} from './user.service';
import {Headers, Http, RequestOptionsArgs, Response} from '@angular/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {SystemMessage, SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {ILegacyLoginResult, LegacyLoginResult} from '../model/legacy-login-result';
import {Subscriber} from 'rxjs/Subscriber';
import {PlanOrderAuthService} from '../../_modules/plan-order/_services/plan-order-auth.service';
import {LocalStorage, SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {CustomUrlParser} from '../../_src/custom-url-parser';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationService {


  /**
   * Stores the URL product office
   * @private
   */
  private _urlProductOffice: ProductOffice;


  /**
   * Returns the URL product office
   */
  get urlProductOffice(): ProductOffice {
    return this._urlProductOffice;
  }


  /**
   * Sets the URL Product office
   * @param urlProductOffice
   */
  set urlProductOffice(urlProductOffice: ProductOffice) {
    this._urlProductOffice = urlProductOffice;
  }




  /**
   * Stores the current product/office
   * @private
   */
  private _productOffice: ProductOffice;


  /**
   * Returns the current product/office
   */
  get productOffice(): ProductOffice {
    return this._urlProductOffice || this._productOffice;
  }


  /**
   * Sets the product/office
   * @param productOffice
   */
  set productOffice(productOffice: ProductOffice) {
    this._productOffice = productOffice;

    if (productOffice && productOffice.toolbox) {
      this.customUrlCode = productOffice.toolbox.customUrlPath;
    } else {
      this.customUrlCode = null;
    }
  }



  /**
   * Compatibility function - Returns the current product/office
   */
  get productOffices(): ProductOffice[] {
    return [this.productOffice];
  }


  /**
   * Stores the Custom URL Code
   */
  @SessionStorage('phx.toolbox.ui.CustomUrlCode')
  customUrlCode: string;







  /**
   * Stores the current customer
   * @private
   */
  private _customer: Customer;


  /**
   * Returns the current customer
   */
  get customer(): Customer {
    return this._customer;
  }


  /**
   * Sets the customer
   * @param customer
   */
  set customer(customer: Customer) {
    this._customer = customer;
  }



  /**
   * Returns the current employer
   */
  get employer(): Employer {
    return (this.customer ? this.customer.employer : null);
  }


  /**
   * Stores the job role
   * @private
   */
  private _jobRole: JobRole;


  /**
   * Returns the job role
   */
  get jobRole(): JobRole {
    return this._jobRole;
  }


  /**
   * Stores the guest guid
   * @private
   */
  private _guestGUID: string;


  /**
   * Returns the guest gui
   */
  get guestGUID(): string {
    return this._guestGUID;
  }


  /**
   * Local storage login token JSON
   */
  @LocalStorage('phx.toolbox.ui.LocalStorageLoginTokenJson')
  localStorageLoginTokenJson: any;


  /**
   * Session storage login token JSON
   */
  @SessionStorage('phx.toolbox.ui.SessionStorageLoginTokenJson')
  sessionStorageLoginTokenJson: any;




  /**
   * Stores the login token
   * @private
   */
  private _loginToken: LoginToken;



  /**
   * Returns the login token
   */
  get loginToken(): LoginToken {
    if (!this._loginToken) {
      // attempt to pull a login token
      if (this.localStorageLoginTokenJson) {
        this._loginToken = new LoginToken(this.localStorageLoginTokenJson);
      } else if (this.sessionStorageLoginTokenJson) {
        this._loginToken = new LoginToken(this.sessionStorageLoginTokenJson);
      }
    }

    return this._loginToken;
  }



  /**
   * Stores account executives
   * @private
   */
  private _acctExecs: AcctExec[];


  /**
   * Returns account executives
   */
  get acctExecs(): AcctExec[] {
    return (this._acctExecs ? this._acctExecs.filter(() => true ) : null);
  }


  /**
   * Sets account executives
   * @param acctExecs
   */
  set acctExecs(acctExecs: AcctExec[]) {
    this._acctExecs = (acctExecs ? acctExecs.filter(() => true) : null);
  }



  /**
   * Stores user territories
   * @private
   */
  private _userTerritories: string[];


  /**
   * Returns user territories
   */
  get userTerritories(): string[] {
    return (this._userTerritories ? this._userTerritories.filter(() => true) : null);
  }



  /**
   * Returns the telemarketing employer flag
   */
  get telemarketingEmployerFlag(): boolean {
    return (this.employer && this.employer.employerType && this.employer.employerType.telemarketingFlag);
  }


  /**
   * Stores user authorities
   * @private
   */
  private _userAuthorities: UserAuthorities;


  /**
   * Returns user authorities
   */
  get userAuthorities(): UserAuthorities {
    return this._userAuthorities;
  }






  /**
   * Event emitter for login completion
   */
  readonly onLoginCompletion = new EventEmitter<boolean>();



  /**
   * Is the user an ORHP user?
   */
  get orhpUserFlag(): boolean {
    return (this._userAuthorities ? this._userAuthorities.hasAuthority('ROLE_ORHP') : false);
  }



  /**
   * Is the user an ORHP MIS user?
   */
  get orhpMisUserFlag(): boolean {
    return (this._userAuthorities ? this._userAuthorities.hasAuthority('ROLE_ORHP_MIS') : false);
  }


  /**
   * Stores whether to display a modal login
   */
  displayModalLoginFlag = false;


  /**
   * Stores the last username
   * TODO
   */
  lastUsername: string;



  /**
   * Event Emitter triggers a modal login event
   */
  readonly triggerModalLoginEventEmitter = new EventEmitter<boolean>();


  /**
   * Event Emitter triggers a login cancellation
   */
  readonly loginCancelEventEmitter = new EventEmitter<boolean>();








  /**
   * Constructor
   * @param httpClient
   * @param userService
   * @param loginService
   * @param http
   * @param ngHttp
   * @param router
   * @param systemService
   * @param planOrderAuthService
   * @param systemMessageLookupService
   * @param customerDomainFactoryService
   * @param employerDomainFactoryService
   * @param customerLookupValueService
   * @param productDomainFactoryService
   */
  constructor(private httpClient: PhxHttpClientService,
              private userService: UserService,
              private loginService: PhxLoginService,
              private http: PhxHttpService,
              private ngHttp: Http,
              private router: Router,
              private systemService: PhxSystemService,
              private planOrderAuthService: PlanOrderAuthService,
              private systemMessageLookupService: SystemMessageLookupService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private customerLookupValueService: CustomerLookupValueService,
              private productDomainFactoryService: ProductDomainFactoryService) {

    // inject the authentication service manually
    this.loginService.authenticationService = this;

    // set the login getter for the plan order auth
    this.planOrderAuthService.loggedInGetter = ((): boolean => {
      return this.loggedIn();
    });

    // retrieves the login token
    this.loginService.loginTokenGetter = (): LoginToken => {
      return this.loginToken;
    };


    // sets the login token
    this.loginService.loginTokenSetter.subscribe((loginToken: LoginToken) => {
      this._loginToken = loginToken;
    });


    // when a login is completed
    this.onLoginCompletion.subscribe((success: boolean) => {
    });


    // when a modal login window is requested
    this.triggerModalLoginEventEmitter.subscribe((flag: boolean) => {
      this.displayModalLoginFlag = flag;
    });


    // cancels a login event
    this.loginCancelEventEmitter.subscribe((flag: boolean) => {
      this.cancelLogin();
    });

    // sets up getters in the PlanOrderAuthService
    this.planOrderAuthService.guestGuidGetter = (): string => this.guestGUID;
    this.planOrderAuthService.customerGetter = (): Customer => this.customer;
    this.planOrderAuthService.employerGetter = (): Employer => this.employer;
    this.planOrderAuthService.jobRoleGetter = (): JobRole => this.jobRole;
    this.planOrderAuthService.productOfficeGetter = (): ProductOffice => this.productOffice;
    this.planOrderAuthService.userAuthoritiesGetter = (): UserAuthorities => this.userAuthorities;
    this.planOrderAuthService.aeTerritoriesGetter = (): string[] => this.userTerritories;
    this.planOrderAuthService.triggerModalLoginEventEmitterGetter = (): EventEmitter<boolean> =>
      this.triggerModalLoginEventEmitter;
    this.planOrderAuthService.loginCancelEventEmitterGetter = (): EventEmitter<boolean> => this.loginCancelEventEmitter;

  }



  /**
   * Logs the user in
   * @param username
   * @param password
   */
  login(username: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dbUsername = 'R:' + username;

      this.loginService.performLogin(dbUsername, password).subscribe((success: boolean) => {
        if (success) {
          this.loginCompletionHandler().then((completion: boolean) => {
            resolve(completion);
          });
        } else {
          resolve(false);
        }
      });
    });
  }



  /**
   * Logs in with an existing JWT
   * @param tokenText
   */
  loginWithExistingJwt(tokenText: string): Promise<boolean> {
    return new Promise((resolve, reject) => {

      // create a login token
      const jwt = JSON.parse(tokenText);
      const testToken = new LoginToken(jwt);

      // if the login user matches, don't refresh
      const existingUser = (this.loginToken ? this.loginToken.username : null);

      if (this.loggedIn() && (existingUser === testToken.username)) {
        resolve(false);
        return;
      }

      // attempt a login
      this.loginService.performLoginWithExistingJwt(tokenText).subscribe((success: boolean) => {
        if (success) {
          this.loginCompletionHandler().then((completion: boolean) => {
            resolve(completion);
          });
        } else {
          resolve(false);
        }
      });

    });
  }





  /**
   * Logs in with a passthru token
   * @param guid
   */
  loginPassthru(guid: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // attempt a login
      this.loginService.performUserTokenLogin(guid).subscribe((success: boolean) => {
        if (success) {
          this.loginCompletionHandler().then((completion: boolean) => {
            resolve(completion);
          });
        } else {
          resolve(false);
        }
      });
    });
  }



  legacyLogin(): Observable<LegacyLoginResult> {
    const observable: Observable<LegacyLoginResult> =
      Observable.create((subscriber: Subscriber<LegacyLoginResult>) => {

        const url = this.systemService.legacyToolboxUrl + 'token_validate.cfm';

        const options = <RequestOptionsArgs>{};
        options.headers = new Headers();
        options.withCredentials = true;

        this.http.injectAuthorizationHeader(options.headers);

        this.ngHttp.get(url, options).subscribe((response: Response) => {
          const jsonValues = <ILegacyLoginResult>response.json();
          const result = new LegacyLoginResult();

          result.loginAttemptedFlag = MessagingFormatter.stringToBoolean(jsonValues.loginAttemptedFlag);
          result.loginSuccessFlag = MessagingFormatter.stringToBoolean(jsonValues.loginSuccessFlag);

          subscriber.next(result);
          subscriber.complete();

        }, (error: Response) => {
          console.log('Error with legacy login:');
          console.log(error);

          const result = new LegacyLoginResult();

          subscriber.next(result);
          subscriber.complete();
        });
      });

    return observable;
  }



  /**
   * Should the login token be persisted in session storage instead of local storage
   */
  loginTokenPersistInSessionStorage(): boolean {
    let flag = false;

    // ORHP users go in session storage
    if (this.orhpUserFlag) {
      flag = true;
    }

    // auto-login users to in session storage
    const toolbox = (this.productOffice ? this.productOffice.toolbox : null);

    if (toolbox && toolbox.autoLoginToken) {
      flag = true;
    }

    return flag;
  }





  /**
   * Clears the user session
   */
  clearUserSession() {
    this._customer = null;
    this._jobRole = null;
    this._guestGUID = null;
    this._loginToken = null;
    this._acctExecs = null;
    this._userTerritories = null;
    this._userAuthorities = null;

    this.sessionStorageLoginTokenJson = null;
    this.localStorageLoginTokenJson = null;

    this.refreshPlanOrderAuth();
  }




  /**
   * Logs out
   */
  logout() {
    this.clearUserSession();
  }



  /**
   * Is the user logged in?
   */
  loggedIn(): boolean {
    // if there's no login token
    if (!this.loginToken) {
      return false;
    }

    // if the login token has expired
    const expirationDate = this.loginToken.expirationDate;

    if (expirationDate && (expirationDate.getTime() < (new Date()).getTime())) {
      return false;
    }

    // logged in
    return true;
  }


  /**
   * Returns whether the current user is an auto-login
   */
  isAutoLogin(): boolean {
    const toolbox = (this.productOffice ? this.productOffice.toolbox : null);
    return (toolbox ? !!toolbox.autoLoginToken : false);
  }



  /**
   * Handler for processing a login completion
   */
  loginCompletionHandler(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // if the user is logged in
      if (this.loggedIn()) {
        const promises = [];
        promises.push(this._fetchCurrentCustomer());
        promises.push(this._fetchCurrentGuestGuid());
        promises.push(this._fetchUserTerritories());
        promises.push(this._fetchUserAuthorities());

        Promise.all(promises).then((results: any[]) => {
          // verify that all fetches succeeded
          const success = (results || []).filter((value: any): boolean => {
            return !!value;
          });

          if (success.length !== promises.length) {
            console.error('Error loading lookup values');
            resolve(false);
            return;
          }

          // handle customer result
          const customerResult = results[0] as ICurrentCustomer;

          if (customerResult) {
            this._customer = customerResult.customer;
            this._jobRole = customerResult.jobRole;

            const userProductOffices = customerResult.productOffices || [];
            const userProductOffice = (userProductOffices.length ? userProductOffices[0] : null);
            const userProductOfficeToolbox = (userProductOffice ? userProductOffice.toolbox : null);
            const userProductOfficeCustomUrl = (userProductOfficeToolbox ? userProductOfficeToolbox.customUrlPath : null) || '';

            const urlProductOfficeToolbox = (this.urlProductOffice ? this.urlProductOffice.toolbox : null);
            const urlProductOfficeCustomUrl = (urlProductOfficeToolbox ? urlProductOfficeToolbox.customUrlPath : null) || '';


            // is this a valid login?
            let validLogin = true;

            if (userProductOfficeCustomUrl !== urlProductOfficeCustomUrl) {
              validLogin = false;
            }

            // if the login isn't valid, clear the session and send the user home
            if (!validLogin) {
              this.clearUserSession();
              this.router.navigate(['/'], {});
              resolve(false);
              return;
            }

            if (userProductOffice) {
              this._productOffice = userProductOffice;
            }


          } else {
            this._customer = null;
            this._jobRole = null;
            this._productOffice = null;
          }


          // handle Guest GUID
          const guestGUID = results[1] as string;

          if (guestGUID) {
            this._guestGUID = guestGUID;
          } else {
            this._guestGUID = null;
          }


          // handle user territories
          const userTerritories = results[2] as string[];

          if ((userTerritories || []).length) {
            this._userTerritories = userTerritories.filter(() => true);
          } else {
            this._userTerritories = null;
          }


          // handle user authorities
          const authorities = results[3] as string[];
          this._userAuthorities = new UserAuthorities(authorities || []);


          // persist the login token
          const tokenJson = this._loginToken.jsonValues();

          if (this.loginTokenPersistInSessionStorage()) {
            this.sessionStorageLoginTokenJson = tokenJson;
            this.localStorageLoginTokenJson = null;
          } else {
            this.sessionStorageLoginTokenJson = null;
            this.localStorageLoginTokenJson = tokenJson;
          }


          // refresh the plan order auth
          this.refreshPlanOrderAuth();

          resolve(true);
        });

      } else {
        // clears the user session
        this.clearUserSession();

        // refresh the plan order auth
        this.refreshPlanOrderAuth();

        resolve(true);
      }
    });
  }





  /**
   * Fetches the current customer
   */
  private _fetchCurrentCustomer(): Promise<ICurrentCustomer> {
    return new Promise((resolve, reject) => {
      // fetches current customer info
      this.httpClient.get('user/currentCustomer').subscribe((json: ICurrentCustomerResponse) => {
        // validate results
        if (json && json.customer && json.employer && json.jobRole) {
          // build a customer
          const customer = this.customerDomainFactoryService.newCustomer(json.customer);
          const employer = this.employerDomainFactoryService.newEmployer(json.employer);
          const jobRole = this.customerLookupValueService.jobRoleByCode(json.jobRole);

          customer.employer = employer;

          // pull product/offices
          const productOffices = [];

          (json.productOffices || []).forEach((ixJson: IProductOffice) => {
            const ixProductOffice = this.productDomainFactoryService.newProductOffice(ixJson);
            productOffices.push(ixProductOffice);
          });

          const result = {} as ICurrentCustomer;
          result.customer = customer;
          result.jobRole = jobRole;
          result.productOffices = productOffices;

          resolve(result);

        } else {
          resolve(null);
        }
      });

    });
  }



  /**
   * Fetches the current guest guid
   */
  private _fetchCurrentGuestGuid(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.userService.loadCurrentGuest().subscribe((response: Response) => {
        const json = (response && response.json() ? response.json() : null);

        if (json) {
          resolve(json['id']);
        } else {
          resolve(null);
        }
      });
    });
  }



  /**
   * Fetches user territories
   */
  private _fetchUserTerritories(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.userService.loadCurrentUserTerritories().subscribe((response: Response) => {
        const json = (response && response.json() ? response.json() : null);

        if (json) {
          const territoryObjects = response.json();
          const territories = territoryObjects.map(indexTerritory => indexTerritory.territory);
          resolve(territories);

        } else {
          resolve(null);
        }
      });
    });
  }



  /**
   * Fetches user authorities
   * @private
   */
  private _fetchUserAuthorities(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.loginService.retrieveCurrentAuthorities(this.httpClient).subscribe((authorities: string[]) => {
        if (authorities) {
          resolve(authorities.filter(() => true));
        } else {
          resolve(null);
        }
      });
    });
  }


  /**
   * Cancels an in-process login attempt
   */
  cancelLogin() {
    this.displayModalLoginFlag = false;
  }



  /**
   * Validates the current customer
   */
  validateCurrentCustomer(): Observable<boolean> {
    return new Observable<boolean>();
  }


  /**
   * Retrieves the current customer
   */
  retrieveCurrentCustomer(): Observable<Customer> {
    return new Observable<Customer>();
  }




  /**
   * Refreshes the plan order auth
   */
  refreshPlanOrderAuth() {
    // deprecated
  }




    /**
   * Checks email validation
   * @param email
   */
  checkEmailValidation(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const request = {email: email, type: 'R'};

      this.httpClient.post('/account/checkEmailValidation', request).subscribe((json: any) => {
        try {
          const validated = (json && json['isValidated']) || false;
          resolve(validated);

        } catch (e) {
          // if it's an error assume it was validated for the more appropriate error message
          resolve(null);
        }
      });
    });
  }


  // retrieves login failure message HTML
  getLoginFailureMessageObject(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.systemMessageLookupService.systemMessageLookupByType('toolbox-invalid-login-json')
        .subscribe((systemMessage: SystemMessage) => {

          const json = (systemMessage ? JSON.parse(systemMessage.message) : null);
          resolve(json);
        });
    });
  }




}


interface ICurrentCustomerResponse {
  customer: any;
  employer: any;
  jobRole: string;
  productOffices: IProductOffice[];
}


export interface ICurrentCustomer {
  customer: Customer;
  jobRole: JobRole;
  productOffices: ProductOffice[];
}
