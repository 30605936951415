import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PlanOrderApplication} from '../../_models';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {Customer, Employer, EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {PlanOrderReviewAgentType} from './plan-order-review-agent-type';
import {PlanOrderAuthService} from '../../_services/plan-order-auth.service';
import {ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';

@Component({
  selector: 'app-plan-order-review-agent',
  templateUrl: './review-agent.component.html',
  styleUrls: [
    '../../plan-order.scss',
    '../review.component.scss',
    './review-agent.component.scss'
  ]
})

export class PlanOrderReviewAgentComponent {

  @Input()
  canEditApplication: boolean;

  @Input()
  application: PlanOrderApplication;

  @Input()
  agentType: number;

  @Output()
  onEditAgent = new EventEmitter<number>();



  get customer(): Customer {
    let customer: Customer = null;

    switch (this.agentType) {
      case PlanOrderReviewAgentType.initiatingAgent:
        customer = this.application.initiatingAgent;
        break;

      case PlanOrderReviewAgentType.cooperatingAgent:
        customer = this.application.cooperatingAgent;
        break;

      case PlanOrderReviewAgentType.closingOfficer:
        customer = this.application.closingAgent;
        break;

      case PlanOrderReviewAgentType.telemarketing:
        customer = this.application.telemarketingAgent;
        break;
    }

    return customer;
  }



  get listingSellingText(): string {
    let text: string = null;

    switch (this.agentType) {
      case PlanOrderReviewAgentType.initiatingAgent:
        text = (this.application.initiatingAgentListingFlag ? 'Listing' : 'Selling') + ' Agent';
        break;

      case PlanOrderReviewAgentType.cooperatingAgent:
        text = (this.application.cooperatingAgentListingFlag ? 'Listing' : 'Selling') + ' Agent';
        break;
    }

    if (!!text && (this.application.initiatingAgent.isEqualToCustomer(this.application.cooperatingAgent))) {
      text = 'Both Listing & Selling Agent';
    }

    return text;
  }




  get employer(): Employer {
    let employer: Employer = null;

    switch (this.agentType) {
      case PlanOrderReviewAgentType.initiatingAgent:
        employer = this.application.initiatingAgentOffice;
        break;

      case PlanOrderReviewAgentType.cooperatingAgent:
        employer = this.application.cooperatingAgentOffice;
        break;

      case PlanOrderReviewAgentType.closingOfficer:
        employer = this.application.closingOffice;
        break;

      case PlanOrderReviewAgentType.telemarketing:
        employer = this.application.telemarketingAgentOffice;
        break;
    }

    return employer;
  }



  get agentText(): string {
    let agentText = '';

    if (this.isUserCustomer && !!this.customRoleDisplayText) {
      agentText = this.customRoleDisplayText;

    } else {
      switch (this.agentType) {
        case PlanOrderReviewAgentType.initiatingAgent:
          agentText = 'Initiating Agent';
          break;

        case PlanOrderReviewAgentType.cooperatingAgent:
          agentText = 'Cooperating Agent';
          break;

        case PlanOrderReviewAgentType.closingOfficer:
          agentText = 'Closing Officer';
          break;

        case PlanOrderReviewAgentType.telemarketing:
          agentText = 'Telemarketing Agent';

          break;
      }
    }

    return agentText;
  }



  get noAgentSelectedText(): string {
    let agentText = '';

    switch (this.agentType) {
      case PlanOrderReviewAgentType.initiatingAgent:
      case PlanOrderReviewAgentType.cooperatingAgent:
      case PlanOrderReviewAgentType.telemarketing:
        agentText = this.agentText;
        break;

      case PlanOrderReviewAgentType.closingOfficer:
        agentText = 'Closing Company';
        break;
    }

    return agentText;
  }



  get productOfficeToolbox(): ProductOfficeToolbox {
    const productOffice = this.planOrderAuthService.productOffice;

    return (!!productOffice ? productOffice.toolbox : null);
  }



  get isUserCustomer(): boolean {
    let flag = false;

    if (!!this.customer && !!this.planOrderAuthService.customer) {
      flag = this.customer.isEqualToCustomer(this.planOrderAuthService.customer);
    }

    return flag;
  }


  get customRoleDisplayText(): string {
    let text: string = null;

    if (!!this.productOfficeToolbox) {
      if ((this.productOfficeToolbox.customPlanRoleDisplayText || '') !== '') {
        text = this.productOfficeToolbox.customPlanRoleDisplayText;
      }
    }

    return text;
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private planOrderAuthService: PlanOrderAuthService,
              private employerLookupService: EmployerLookupService
  ) {

  }






  // should the agent's name be displayed?
  shouldDisplayAgentName(): boolean {
    return !this.shouldDisplayUnknownClosingAgent();
  }


  // should UNKNOWN be displayed
  shouldDisplayUnknownClosingAgent(): boolean {
    let flag = false;

    if (this.agentType === PlanOrderReviewAgentType.closingOfficer) {
      if (this.customer && this.customer.unknownEscrowFlag) {
        flag = true;
      }
    }

    return flag;
  }



  employerDisplayName(employer: Employer): string {
    return this.employerLookupService.employerDisplayName(employer);
  }




  didClickEditAgent() {
    this.onEditAgent.emit(this.agentType);
  }



  didClickEmailAddress(email: string) {
    const mailToUrl = 'mailto:' + encodeURIComponent(email);

    window.location.href = mailToUrl;
  }


}
