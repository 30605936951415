
import {Injectable} from '@angular/core';
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {Subscriber} from 'rxjs/Subscriber';
import {Response} from '@angular/http';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PlanOrderService} from './plan-order.service';
import {CustomerLookupParams} from '@orhp/phx-customer-ui-module';
import {CustomerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {EmployerLookupParams} from '@orhp/phx-customer-ui-module';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {ProductLookupService} from '@orhp/phx-product-ui-module';
import {Product} from '@orhp/phx-product-ui-module';
import {MessagingFormatter} from '@orhp/phx-common-ui-module';
import {ProductCoverage} from '@orhp/phx-product-ui-module';
import {PropertyLookupService} from '@orhp/phx-property-ui-module';
import {PropertyLookupParams} from '@orhp/phx-property-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {PlanOrderLookupValueService} from './plan-order-lookup-value.service';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {PlanOrderDomainFactoryService} from './plan-order-domain-factory.service';
import {AuthenticationService} from '../../../login';
import {CustomUrlParser} from '../../../_src/custom-url-parser';

@Injectable()


export class PlanOrderLookupService {

  constructor(private http: PhxHttpService,
              private planOrderService: PlanOrderService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private authenticationService: AuthenticationService,
              private employerDomainFactoryService: EmployerDomainFactoryService) {
  }


  applicationLookupInProcess = false;


  // fetches a plan by GUID
  fetchPlanByID(application: PlanOrderApplication, planID?: number): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      const url = 'planOrder/get';

      const productOfficeGroup = this.authenticationService.productOffice;
      const directProcessFlag = !!productOfficeGroup && !!productOfficeGroup.homeBuyerEmailRequiredFlag;

      const jsonValues = {
        'planID': MessagingFormatter.integerToString(planID || application.planID),
        'directProcessFlag': MessagingFormatter.booleanToString(directProcessFlag)
      };

      const formBody = JSON.stringify(jsonValues);

      this.applicationLookupInProcess = true;

      this.http.post(url, formBody).subscribe((response: Response) => {

        const jsonObject = response.json();

        this.planOrderDomainFactoryService.populateFetchedApplication(application, jsonObject)
          .subscribe((fetchResult: boolean) => {
            this.planOrderService.planOrderCustomOrderingUrl = CustomUrlParser.getCustomUrl();

            subscriber.next(fetchResult);
            subscriber.complete();
          });

      });

    });

    return observable;
  }



}

