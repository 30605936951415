import {EventEmitter, Injectable, Input} from '@angular/core';
import {ArrayUtility, ObservableUtility, UserAuthorities} from '@orhp/phx-common-ui-module';
import {Customer, CustomerLookupParams, CustomerLookupService, Employer, JobRole} from '@orhp/phx-customer-ui-module';
import {Observable, Subscription} from 'rxjs/Rx';
import {Subscriber} from 'rxjs/Subscriber';
import {
  ProductOffice,
  ProductOfficeToolbox
} from '@orhp/phx-product-ui-module';
import {PlanOrderService} from './plan-order.service';

@Injectable()
export class PlanOrderAuthService {

  /**
   * Returns the current GuestGUID
   */
  get guestGUID(): string {
    return this.guestGuidGetter();
  }


  /**
   * Returns the current Customer
   */
  get customer(): Customer {
    return this.customerGetter();
  }


  /**
   * Returns the current Employer
   */
  get employer(): Employer {
    return this.employerGetter();
  }


  /**
   * Returns the current job role
   */
  get jobRole(): JobRole {
    return this.jobRoleGetter();
  }


  /**
   * Returns the current product/office
   */
  get productOffice(): ProductOffice {
    return this.productOfficeGetter();
  }


  /**
   * Returns the user authorities
   */
  get userAuthorities(): UserAuthorities {
    return this.userAuthoritiesGetter();
  }


  /**
   * AE Territories
   */
  get aeTerritories(): string[] {
    return this.aeTerritoriesGetter();
  }


  /**
   * Returns the modal login trigger event emitter
   */
  get triggerModalLoginEventEmitter(): EventEmitter<boolean> {
    return this.triggerModalLoginEventEmitterGetter();

  }


  /**
   * Returns the login cancel event emitter
   */
  get loginCancelEventEmitter(): EventEmitter<boolean> {
    return this.loginCancelEventEmitterGetter();
  }


  get productOfficeToolbox(): ProductOfficeToolbox {
    return (!!this.productOffice ? this.productOffice.toolbox : null);
  }


  get appsPhoneNumber(): string {
    let phone = '800.445.6999';

    const productOfficePhone = (!!this.productOffice ? this.productOffice.appsPhoneNumber : null) || '';

    if (productOfficePhone !== '') {
      phone = productOfficePhone;
    }

    return phone;
  }




  onLoginCompletion = new EventEmitter<boolean>();



  onLogout = new EventEmitter<boolean>();


  loggedInGetter: () => boolean;



  constructor() {}




  /**
   * Getter to provide the GuestGUID
   */
  guestGuidGetter: () => string;


  /**
   * Getter to provide the Customer
   */
  customerGetter: () => Customer;


  /**
   * Getter to provide the Employer
   */
  employerGetter: () => Employer;


  /**
   * Getter to provide the JobRole
   */
  jobRoleGetter: () => JobRole;


  /**
   * Getter to provide the ProductOffice
   */
  productOfficeGetter: () => ProductOffice;


  /**
   * Getter to provide the User authorities
   */
  userAuthoritiesGetter: () => UserAuthorities;


  /**
   * Getter to provide AE territories
   */
  aeTerritoriesGetter: () => string[];



  /**
   * Getter to provide modal login event emitter trigger
   */
  triggerModalLoginEventEmitterGetter: () => EventEmitter<boolean>;


  /**
   * Getter to provide cancel event emitter trigger
   */
  loginCancelEventEmitterGetter: () => EventEmitter<boolean>;











  // we're logged in if there's an auth with a customer
  loggedIn(): boolean {
    return (this.loggedInGetter ? this.loggedInGetter() : false);
  }



  isOrhpUser(): boolean {
    let orhpUserFlag = false;

    if (this.userAuthorities && this.userAuthorities.hasAuthority('ROLE_ORHP')) {
      orhpUserFlag = true;
    }

    return orhpUserFlag;
  }



}


