import {Component, Input, OnInit} from '@angular/core';
import {CustomerValidateResult, Employer, EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {ProductOffice} from '@orhp/phx-product-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupCacheService, OfficeLookupType} from '../../../_modules/office-agent-lookup/_services/lookup-cache.service';
import {OfficeAgentNavigationService} from '../../../_modules/office-agent-lookup/_services/office-agent-navigation.service';

@Component({
    selector: 'phx-user-employer',
    templateUrl: './user-employer.component.html',
    styleUrls: ['./user-employer.component.scss']
})
export class UserEmployerComponent implements OnInit {
    _employer: Employer = null;
    _productOffice: ProductOffice = null;
    _productOfficeEmail: string;
    _productOfficeID: number;
    _employerValidateResult: EmployerValidateResult = null;
    _customerValidateResult: CustomerValidateResult = null;

    _shouldDisplayEmployerSearch = true;

    _customOfficeDescription: string = null;

    @Input()
    set shouldDisplayEmployerSearch(v: boolean) {
        this._shouldDisplayEmployerSearch = v;
    }

    get shouldDisplayEmployerSearch(): boolean {
        return this._shouldDisplayEmployerSearch;
    }

    @Input()
    set employerValidateResult(v: EmployerValidateResult) {
        this._employerValidateResult = v;
    }

    get employerValidateResult(): EmployerValidateResult {
        return this._employerValidateResult;
    }

    @Input()
    set customerValidateResult(v: CustomerValidateResult) {
        this._customerValidateResult = v;
    }

    get customerValidateResult(): CustomerValidateResult {
        return this._customerValidateResult;
    }


    @Input()
    set employer(employer: Employer) {
      // if it's not a new employer, don't display the search
      if (!!employer.employerID) {
        this.shouldDisplayEmployerSearch = false;
      }

      this._employer = employer;
    }



    @Input()
    set productOfficeEmail(productOfficeEmail: string) {
      this._productOfficeEmail = productOfficeEmail;
    }

    get productOfficeEmail(): string {
      return this._productOfficeEmail;
    }


    @Input()
    set productOfficeID(productOfficeID: number) {
      this._productOfficeID = productOfficeID;
    }

    get productOfficeID(): number {
      return this._productOfficeID;
    }


  @Input()
  set productOffice(productOffice: ProductOffice) {
    this._productOffice = productOffice;
  }

  get productOffice(): ProductOffice {
    return this._productOffice;
  }






  @Input()
    set customOfficeDescription(text: string) {
      if ((text || '').trim() !== '') {
        this._customOfficeDescription = text;

      } else {
        this._customOfficeDescription = null;
      }
    }

    get customOfficeDescription() {
      return this._customOfficeDescription;
    }




    get employer() {
        return this._employer;
    }

    constructor(private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
                private officeAgentNavigationService: OfficeAgentNavigationService
    ) { }


    ngOnInit() {
      this.officeAgentNavigationService.gotoOfficeSearch();
    }



    didClickShowOfficesSearch() {
      this.officeAgentLookupCacheService.clearCache();
      this.officeAgentLookupCacheService.selectedOfficeLookupType = OfficeLookupType.officePhone;

      this.shouldDisplayEmployerSearch = true;
    }


    officeDescription(): string {
      return this._customOfficeDescription || 'Real Estate Office';
    }


    shouldDisplayNoProductOfficeEmailText(): boolean {
      return !!this.noProductOfficeEmailText() && this.shouldDisplayEmployerSearch;
    }


    noProductOfficeEmailText(): string {
      let text: string = null;

      if (!StringUtility.emailValidate(this.productOfficeEmail || '')) {
        text = 'Email is required to search for Office';
      }

      return text;
    }


    displayEmployerSearch(): boolean {
      let flag = this.shouldDisplayEmployerSearch;

      if (!!this.noProductOfficeEmailText()) {
        flag = false;
      }

      return flag;
    }




}
