


import {Component, OnInit} from '@angular/core';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PlanOrderService} from '../_services/plan-order.service';
import {Customer} from '@orhp/phx-customer-ui-module';
import {Relationship} from '@orhp/phx-plan-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {CancelReason} from '@orhp/phx-plan-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {PlanCancelService} from '@orhp/phx-plan-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {PlanCancelParams} from '@orhp/phx-plan-ui-module';
import {PlanCancel} from '@orhp/phx-plan-ui-module';
import {ActivityResult} from '@orhp/phx-common-ui-module';
import {Router, UrlSegment, UrlSerializer} from '@angular/router';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderLookupService} from '../_services/plan-order-lookup.service';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';


@Component({
  selector: 'app-plan-order-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: [
    '../plan-order.scss',
    './cancel.component.scss'
  ]
})


export class PlanOrderCancelComponent extends PlanOrderBaseComponent implements OnInit {


  cancellationCustomers: CancelRelationshipCustomer[] = null;

  cancelReasons: CancelReason[] = null;
  otherWarrantyCancelReasons: CancelReason[] = null;

  selectedCancellationCustomer: CancelRelationshipCustomer = null;

  validationAttemptedFlag = false;
  pageStatus: PageStatus = PageStatus.none;

  planCancelErrorTitle: string = null;
  planCancelErrorText: string = null;

  userConfirmFlag = false;


  private _selectedCancelReasonCode: string = null;

  get selectedCancelReasonCode(): string {
    return this._selectedCancelReasonCode;
  }

  set selectedCancelReasonCode(selectedCancelReasonCode: string) {
    if (this._selectedCancelReasonCode !== selectedCancelReasonCode) {
      this._selectedCancelReasonCode = selectedCancelReasonCode;

      const cancelReason = this.planLookupValueService.cancelReasonByCode(selectedCancelReasonCode);

      if (cancelReason && !cancelReason.otherWarrantyCompanyGenericFlag) {
        this.selectedOtherCompanyCancelReasonCode = null;
      }
    }
  }

  selectedOtherCompanyCancelReasonCode: string = null;



  get property(): Property {
    return this.planOrderApplication.property;
  }


  get selectedCancelReason(): CancelReason {
    return this.planLookupValueService.cancelReasonByCode(this.selectedCancelReasonCode);
  }


  get selectedOtherCompanyCancelReason(): CancelReason {
    return this.planLookupValueService.cancelReasonByCode(this.selectedOtherCompanyCancelReasonCode);
  }



  constructor(private urlSerializer: UrlSerializer,
              private router: Router,
              private planOrderService: PlanOrderService,
              private planOrderLookupService: PlanOrderLookupService,
              private planLookupValueService: PlanLookupValueService,
              private planCancelService: PlanCancelService,
              private planOrderAuthService: PlanOrderAuthService,
              private modalProgressService: PhxModalProgressService) {
    super(planOrderService);
  }


  ngOnInit() {
    super.ngOnInit();

    // we need customers to be cancelling
    const customers = [];

    this.planLookupValueService.relationships.forEach((indexRelationship: Relationship) => {

      // initiating agent
      if (indexRelationship.initiatingAgentFlag && this.planOrderApplication.initiatingAgent) {
        const customer = new CancelRelationshipCustomer();
        customer.customer = this.planOrderApplication.initiatingAgent;
        customer.relationship = indexRelationship;

        customers.push(customer);
      }

      // cooperating agent
      if (indexRelationship.cooperatingAgentFlag && this.planOrderApplication.cooperatingAgent) {
        const customer = new CancelRelationshipCustomer();
        customer.customer = this.planOrderApplication.cooperatingAgent;
        customer.relationship = indexRelationship;

        customers.push(customer);
      }

      // closing agent
      if (indexRelationship.closingFlag && this.planOrderApplication.closingAgent) {
        const customer = new CancelRelationshipCustomer();
        customer.customer = this.planOrderApplication.closingAgent;
        customer.relationship = indexRelationship;

        customers.push(customer);
      }

      // home buyers
      if (indexRelationship.ownerFlag) {
        let customerIndex = 1;

        this.planOrderApplication.homeBuyers.forEach((indexCustomer: Customer) => {
          const customer = new CancelRelationshipCustomer();
          customer.customer = indexCustomer;
          customer.relationship = indexRelationship;
          customer.relationshipText = '#' + String(customerIndex);

          customers.push(customer);

          customerIndex++;
        });
      }

      // home sellers
      if (indexRelationship.sellerFlag) {
        let customerIndex = 1;

        this.planOrderApplication.homeSellers.forEach((indexCustomer: Customer) => {
          const customer = new CancelRelationshipCustomer();
          customer.customer = indexCustomer;
          customer.relationship = indexRelationship;
          customer.relationshipText = '#' + String(customerIndex);

          customers.push(customer);

          customerIndex++;
        });
      }

    });


    const authenticatedCustomer = this.planOrderAuthService.customer;

    if (authenticatedCustomer) {
      customers.forEach((indexCustomer: CancelRelationshipCustomer) => {
        let selectCustomerFlag = true;

        if (authenticatedCustomer.customerID !== indexCustomer.customer.customerID) {
          selectCustomerFlag = false;
        }

        if (this.selectedCancellationCustomer) {
          selectCustomerFlag = false;
        }

        if (selectCustomerFlag) {
          this.selectedCancellationCustomer = indexCustomer;
        }
      });
    }



    const sortedCustomers = customers.sort(
      (customer1: CancelRelationshipCustomer, customer2: CancelRelationshipCustomer): number => {
        let sortValue = 0;

        const relationship1 = customer1.relationship;
        const relationship2 = customer2.relationship;

        if (relationship1.sortOrder < relationship2.sortOrder) {
          sortValue = -1;

        } else if (relationship1.sortOrder > relationship2.sortOrder) {
          sortValue = 1;
        }

        return sortValue;
    });

    this.cancellationCustomers = sortedCustomers;



    // we need reasons for why we're cancelling
    const reasons = [];
    const otherWarrantyReasons = [];

    this.planLookupValueService.cancelReasons.forEach((indexReason: CancelReason) => {
      // if we're allowing selection
      if (indexReason.allowSelectionFlag) {
        // if this reason has another warranty company name
        if (indexReason.otherWarrantyCompanyNameFlag) {
          otherWarrantyReasons.push(indexReason);

          // if this is a general reason
        } else {
          reasons.push(indexReason);
        }
      }
    });

    this.cancelReasons = reasons;
    this.otherWarrantyCancelReasons = otherWarrantyReasons;
  }



  shouldDisplayOtherWarrantyCompanySelection(): boolean {
    let displayFlag = false;

    if (this.selectedCancelReason && this.selectedCancelReason.otherWarrantyCompanyGenericFlag) {
      displayFlag = true;
    }

    return displayFlag;
  }



  shouldDisplayCancellationCustomerQuestion(): boolean {
    return (this.cancellationCustomers.length > 0);
  }



  isCustomerSelected(customer: CancelRelationshipCustomer): boolean {
    return (this.selectedCancellationCustomer === customer);
  }


  didClickCancellationCustomer(customer: CancelRelationshipCustomer) {
    this.selectedCancellationCustomer = customer;
  }


  didClickUserConfirm() {
    this.userConfirmFlag = !this.userConfirmFlag;
  }



  shouldDisplayValidationFailed(): boolean {
    return (this.pageStatus === PageStatus.validationFailed);
  }


  shouldDisplayCustomerCancelError(): boolean {
    let displayFlag = true;

    displayFlag = displayFlag && this.validationAttemptedFlag;
    displayFlag = displayFlag && this.shouldDisplayCancellationCustomerQuestion();

    if (this.shouldDisplayCancellationCustomerQuestion()) {
      displayFlag = displayFlag && !this.selectedCancellationCustomer;
    }

    return displayFlag;
  }


  shouldDisplayCancelReasonError(): boolean {
    const displayFlag = (this.validationAttemptedFlag && !this.selectedCancelReasonCode);

    return displayFlag;
  }


  shouldDisplayOtherCompanyError(): boolean {
    let displayFlag = false;

    if (this.validationAttemptedFlag) {
      const otherCompanyFlag = (this.selectedCancelReason ? this.selectedCancelReason.otherWarrantyCompanyGenericFlag : false);

      displayFlag = otherCompanyFlag && !this.selectedOtherCompanyCancelReasonCode;
    }

    return displayFlag;
  }


  shouldDisplayConfirmationError(): boolean {
    const displayFlag = (this.validationAttemptedFlag && !this.userConfirmFlag);

    return displayFlag;
  }


  shouldDisplayCancelProgressBar(): boolean {
    return (this.pageStatus === PageStatus.cancelInProgress);
  }


  didClickNextButton() {
    this.validationAttemptedFlag = true;

    let validationSuccessFlag = true;
    validationSuccessFlag = validationSuccessFlag && !this.shouldDisplayCustomerCancelError();
    validationSuccessFlag = validationSuccessFlag && !this.shouldDisplayCancelReasonError();
    validationSuccessFlag = validationSuccessFlag && !this.shouldDisplayOtherCompanyError();
    validationSuccessFlag = validationSuccessFlag && !this.shouldDisplayConfirmationError();

    if (validationSuccessFlag) {
      this.pageStatus = PageStatus.cancelInProgress;

      const progress = new ModalProgress('Cancelling Application', 'Please wait...', 100);

      this.modalProgressService.progress = progress;

      this.performPlanCancellation();

    } else {
      this.pageStatus = PageStatus.validationFailed;
    }

  }



  // performs the plan cancellation
  performPlanCancellation() {
    const params = new PlanCancelParams();

    params.planID = this.planOrderApplication.planID;
    params.planGUID = this.planOrderApplication.planGUID;
    params.cancelReason = this.selectedOtherCompanyCancelReason || this.selectedCancelReason;

    if (this.selectedCancellationCustomer) {
      params.customerID = this.selectedCancellationCustomer.customer.customerID;

    } else {
      params.customerID = this.planOrderApplication.defaultHomeownerCustomerID;
    }

    this.planCancelService.performCancellation(params).subscribe((planCancel: PlanCancel) => {
      // on success
      const planID = this.planOrderApplication.planID;

      // re-fetch the plan to get the updated status
      this.planOrderLookupService.fetchPlanByID(this.planOrderApplication, planID)
        .subscribe((resultFlag: boolean) => {

        this.persistPlanOrderApplication();

        this.modalProgressService.progress = null;

        const url = this.router.routerState.snapshot.url;
        const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'cancel');

        let urlSegment: UrlSegment = null;

        urlSegment = new UrlSegment('review', {});
        RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

        urlSegment = new UrlSegment('confirm-cancel', {});
        RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

        this.router.navigateByUrl(urlTree);
      });


    }, (errorResult: ActivityResult) => {
      // on failure
      this.modalProgressService.progress = null;

      const errors = errorResult.errors || [];

      if (errors.length) {
        const error = errors[0];

        this.planCancelErrorTitle = error.message;
        this.planCancelErrorText = error.identifier;

      } else {
        this.planCancelErrorTitle = 'There was an unknown error cancelling this Application';
        this.planCancelErrorText = 'phx.toolbox.ui.PlanCancelGenericError';
      }

    });
  }



  shouldDisplayPlanCancelError(): boolean {
    return !!this.planCancelErrorTitle;
  }


}



enum PageStatus {
  none = 10,
  validationFailed = 20,
  cancelInProgress = 30,
  cancelComplete = 40
}




class CancelRelationshipCustomer {
  customer: Customer = null;
  relationship: Relationship = null;
  relationshipText: string = null;
}

