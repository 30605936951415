

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlanOrderService} from '../_services/plan-order.service';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderLookupService} from '../_services/plan-order-lookup.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderDomainFactoryService} from '../_services/plan-order-domain-factory.service';

@Component({
  selector: 'app-plan-order-form-submit-bar',
  templateUrl: './form-submit-bar.component.html',
  styleUrls: [
    '../plan-order.scss',
    './form-submit-bar.component.scss'
  ]
})

export class PlanOrderFormSubmitBarComponent implements OnInit {

  defaultNextButtonTitle = 'Next';

  @Input() showBackButton = false;
  @Input() showPrintQuoteButton = false;
  @Input() showNextButton = false;
  @Input() showStartOverButton = true;
  @Input() nextButtonTitle = this.defaultNextButtonTitle;

  @Output() nextButtonEventEmitter = new EventEmitter<boolean>();
  @Output() printQuoteButtonEventEmitter = new EventEmitter<boolean>();
  @Output() startOverButtonEventEmitter = new EventEmitter<boolean>();

  showStartOverConfirmationFlag = false;

  showReturnToReviewConfirmationFlag = false;

  constructor(private planOrderService: PlanOrderService,
              private planOrderLookupService: PlanOrderLookupService,
              private planOrderStepService: PlanOrderStepService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService,
              private urlSerializer: UrlSerializer,
              private router: Router,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
  }


  didClickBackButton() {
    self.window.history.back();
  }


  shouldDisplayFormSubmitBar(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || this.showBackButton;
    displayFlag = displayFlag || this.showNextButton;
    displayFlag = displayFlag || this.showStartOverButton;
    displayFlag = displayFlag || this.showPrintQuoteButton;

    return displayFlag;
  }


  didClickPrintQuoteButton() {
    this.printQuoteButtonEventEmitter.emit(true);
  }


  didClickNextButton() {
    this.nextButtonEventEmitter.emit(true);
  }


  didClickStartOverButton() {
    this.showStartOverConfirmationFlag = true;
  }


  shouldDisplayReturnToReviewButton() {
    let flag = false;

    const application = this.planOrderService.planOrderApplication;

    if (application && application.allStepsCompletedFlag) {
      flag = true;
    }

    const currentStep = this.planOrderStepService.currentStep;

    if (currentStep && (currentStep.code === 'review')) {
      flag = false;
    }

    return flag;
  }


  didClickReturnToReviewButton() {
    this.showReturnToReviewConfirmationFlag = true;
  }


  didClickReturnToReviewConfirm(confirmFlag: boolean) {
    this.showReturnToReviewConfirmationFlag = false;

    if (confirmFlag) {
      // restore a backup of the plan
      this.planOrderService.restorePlanOrderApplicationBackup();

      const planIDText = (this.route.snapshot.params['planID'] || this.planOrderService.newPlanRoute).toLowerCase();

      const url = this.router.routerState.snapshot.url;
      const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, planIDText);

      const urlSegment = new UrlSegment('review', {});
      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

      this.router.navigateByUrl(urlTree);
    }
  }





  didClickStartOverCancel() {
    this.showStartOverConfirmationFlag = false;
  }


  didClickStartOverProceed() {
    this.showStartOverConfirmationFlag = false;

    this.startOverButtonEventEmitter.emit(true);

    const planIDText = (this.route.snapshot.params['planID'] || this.planOrderService.newPlanRoute).toLowerCase();

    let redirectTo: string = null;

    // new plan
    if (planIDText === this.planOrderService.newPlanRoute) {
      this.planOrderService.planOrderApplication = this.planOrderDomainFactoryService.newApplication();

      redirectTo = 'transaction-type';

      // existing plan
    } else {
      redirectTo = 'review';
    }

    if (redirectTo) {
      const url = this.router.routerState.snapshot.url;
      const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, planIDText);

      const urlSegment = new UrlSegment(redirectTo, {});
      RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

      this.router.navigateByUrl(urlTree);
    }
  }


  shouldDisplayVerticalMobileButtons(): boolean {
    return (this.nextButtonTitle !== this.defaultNextButtonTitle);
  }

}


