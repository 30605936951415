
export class PlanOrderReviewAgentType {

  static initiatingAgent = 10;
  static cooperatingAgent = 20;
  static closingOfficer = 30;
  static telemarketing = 40;


  get initiatingAgent(): number {
    return PlanOrderReviewAgentType.initiatingAgent;
  }


  get cooperatingAgent(): number {
    return PlanOrderReviewAgentType.cooperatingAgent;
  }


  get closingOfficer(): number {
    return PlanOrderReviewAgentType.closingOfficer;
  }


  get telemarketing(): number {
    return PlanOrderReviewAgentType.telemarketing;
  }


}
