

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OfficeAgentLookupService} from '../_services/lookup.service';

import {Customer, CustomerPhone} from '@orhp/phx-customer-ui-module';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {OfficeAgentErrorCollection} from '../_src/office-agent-error-collection';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {IAgentResolverInterface} from '../_src/agent-resolver-interface';

@Component({
  selector: 'app-agent-contact',
  templateUrl: './agent-contact.component.html',
  styleUrls: ['../office-agent-lookup.scss']
})


export class AgentContactComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private planLookupValueService: PlanLookupValueService,
              private officeAgentLookupService: OfficeAgentLookupService) {
  }


  customer: Customer = null;


  get customerValidateResult(): CustomerValidateResult {
    return this.officeAgentLookupService.customerValidateResult;
  }


  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      const customerData = <IAgentResolverInterface>data;

      this.customer = customerData.customer;
    });
  }




  // should the contact info be allowed to be edited?
  shouldAllowContactEditing(customerPhone: CustomerPhone): boolean {
    return ((customerPhone.guid || '') === '');
  }


  shouldSolicitMobilePhone(): boolean {
    const lookupRelationshipType = this.officeAgentLookupService.lookupRelationshipType;

    let flag = true;

    if (lookupRelationshipType && !lookupRelationshipType.solicitMobilePhoneFlag) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayEmailError(): boolean {
    return ((this.customerValidateResult.customerEmailError || '') !== '');
  }


  shouldDisplayMobilePhoneError(): boolean {
    return ((this.customerValidateResult.customerMobilePhoneError || '') !== '');
  }

}
