


import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {CustomerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';

@Injectable()

export class OfficeAgentLookupAgentNewResolver implements Resolve<Customer> {



  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerLookupService: EmployerLookupService,
              private customerLookupValueService: CustomerLookupValueService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    const observable: Observable<Customer> = Observable.create((subscriber: Subscriber<Customer>) => {

      // look at the saved customer; reload if it already exists
      const savedCustomer = this.officeAgentLookupPersistenceService.customer;

      let createNewCustomerFlag = false;

      if (!savedCustomer) {
        createNewCustomerFlag = true;

      } else if (savedCustomer.guid) {
        createNewCustomerFlag = true;
      }


      let customer: Customer;

      if (createNewCustomerFlag) {
        customer = this.customerDomainFactoryService.newCustomer();

        this.officeAgentLookupPersistenceService.customer = customer;

      } else {
        customer = savedCustomer;
      }

      subscriber.next(customer);
      subscriber.complete();
    });

    return observable;
  }

}
