import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {ProductOffice} from '@orhp/phx-product-ui-module';

@Component({
    selector: 'phx-user-customer',
    templateUrl: './user-customer.component.html',
    styleUrls: ['./user-customer.component.scss']
})
export class UserCustomerComponent implements OnInit {
    _customer: Customer = null;
    _customerValidateResult: CustomerValidateResult = null;

    @Input()
    set customerValidateResult(v: CustomerValidateResult) {
        this._customerValidateResult = v;
    }

    get customerValidateResult(): CustomerValidateResult {
        return this._customerValidateResult;
    }

    get mobilePhone(): string {
        return this._customer.mobilePhone.phoneNumber;
    }
    set mobilePhone(phone: string) {
        this._customer.mobilePhone.phoneNumber = phone;
    }

    get workPhone(): string {
        return this._customer.workPhone.phoneNumber;
    }
    set workPhone(phone: string) {
        this._customer.workPhone.phoneNumber = phone;
    }

    @Input()
    set customer(c: Customer) {
        this._customer = c;
    }

    get customer() {
        return this._customer;
    }

    get givenName():string{
        return this.customer.givenName;
    }

    set givenName(n){
        this.customerValidateResult.customerGivenNameError='';
        this.customer.givenName = n;
    }

    get surName():string{
        return this.customer.surName;
    }

    set surName(n){
        this.customerValidateResult.customerSurNameError='';
        this.customer.surName = n;
    }

    @Input()
    productOffice: ProductOffice;



    constructor() {
    }

    ngOnInit() {
    }

    shouldDisplayMobilePhoneError(): boolean {
        return ((this.customerValidateResult.customerMobilePhoneError || '') !== '');
    }

    shouldDisplaySurnameError(): boolean {
        return ((this.customerValidateResult.customerSurNameError || '') !== '');
    }

    shouldDisplayGivenNameError(): boolean {
        return ((this.customerValidateResult.customerGivenNameError || '') !== '');
    }

    shouldDisplayValidationError(): boolean {
        return (
            this.shouldDisplayGivenNameError() ||
            this.shouldDisplaySurnameError() ||
            this.shouldDisplayMobilePhoneError()
        );
    }




    firstNameLabel(): string {
      let label = 'First Name';

      if (!!this.productOffice && ((this.productOffice.agentFirstNameLabel || '') !== '')) {
        label = this.productOffice.agentFirstNameLabel;
      }

      return label;
    }


    lastNameLabel(): string {
      let label = 'Last Name';

      if (!!this.productOffice && ((this.productOffice.agentLastNameLabel || '') !== '')) {
        label = this.productOffice.agentLastNameLabel;
      }

      return label;
    }


}
