
import {PlanOrderService} from './_services/plan-order.service';
import {PlanOrderApplication} from './_models/plan-order-application';
import {AfterViewInit, OnInit} from '@angular/core';

export class PlanOrderBaseComponent implements OnInit, AfterViewInit {

  // keep a local copy of the service
  private basePlanOrderService: PlanOrderService;


  // what date mask do we want to display?
  displayDateMask = 'mm/dd/yyyy';


  // constructor
  constructor(planOrderService: PlanOrderService) {
    this.basePlanOrderService = planOrderService;
  }

  get planOrderApplication(): PlanOrderApplication {
    return this.basePlanOrderService.planOrderApplication;
  }

  set planOrderApplication(application: PlanOrderApplication) {
    this.basePlanOrderService.planOrderApplication = application;
  }




  ngOnInit() {
  }



  ngAfterViewInit() {
    // scroll to the top of the window
    window.scrollTo(0, 0);
  }




  // saves the application
  persistPlanOrderApplication(): void {
    this.basePlanOrderService.persistPlanOrderApplication();
  }


}
