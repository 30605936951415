

import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from './plan-order-auth.service';
import {PlanOrderService} from './plan-order.service';
import {CustomUrlParser} from '../../../_src/custom-url-parser';



@Injectable()
export class PlanOrderCustomUrlGuard implements CanActivate {



  constructor(private router: Router,
              private planOrderService: PlanOrderService,
              private systemService: PhxSystemService
  ) { }


  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUrl = CustomUrlParser.getCustomUrl() || '';
    const appUrl = this.planOrderService.planOrderCustomOrderingUrl || '';

    // if the URLs are different, clear and reset
    if (currentUrl !== appUrl) {
      this.planOrderService.clear();
      this.planOrderService.planOrderCustomOrderingUrl = currentUrl;
      this.router.navigate(['/plan-order'], {});

      return false;
    } else {
      return true;
    }
  }



}

