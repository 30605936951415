import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {AccountValidationResult, AccountValidationService} from "./account-validation.service";
import {Subscriber} from "rxjs/Subscriber";
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupService} from "../../_modules/office-agent-lookup/_services/lookup.service";
import {EmployerUpdateService} from '@orhp/phx-customer-ui-module';
import {CustomerUpdateService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {isUndefined} from "util";
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {Response} from '@angular/http';

@Injectable()
export class ProfileValidationService {

    constructor(private http:PhxHttpService,
                private officeAgentLookupService: OfficeAgentLookupService,
                private customerUpdateService: CustomerUpdateService,
                private employerUpdateService: EmployerUpdateService,
                private accountValidationService: AccountValidationService) {

    }

    validate(guest: any, customer: Customer, employer: Employer): Observable<ProfileValidationResult> {

        const observable: Observable<ProfileValidationResult> =
            Observable.create((subscriber: Subscriber<ProfileValidationResult>) => {
                const email: string = guest["email"];
                const email2: string = guest["email2"];
                const password: string = guest["password"];
                const password2: string = guest["password2"];

                let result = new ProfileValidationResult();
                //result.isValid = true;

                let emailValidation: AccountValidationResult = this.accountValidationService.validateEmail(email);

                if (!emailValidation.isValid) {
                    //result.isValid = false;

                    result.emailIsValid = false;
                    result.emailErrorMessage = "Please enter a valid email address."
                }

                if (email.length > 0 && email2.length == 0) {
                    //result.isValid = false;

                    result.email2IsValid = false;
                    result.email2ErrorMessage = 'Re-enter E-mail Address is required.';
                } else if (email != email2) {
                    //result.isValid = false;

                    result.email2IsValid = false;
                    result.email2ErrorMessage = "Email and Re-enter Email do not match.";
                }

                if (customer.customerID == null && password.length == 0) {
                    //result.isValid = false;

                    result.passwordIsValid = false;
                    result.passwordErrorMessage = "Password is required.";
                }

                if(password.length > 0){
                    if (password.length < 5) {
                        //result.isValid = false;

                        result.passwordIsValid = false;
                        result.passwordErrorMessage = "Password must be at least 5 characters.";
                    }

                    if (password2.length == 0) {
                        //result.isValid = false;

                        result.password2IsValid = false;
                        result.password2ErrorMessage = "Re-enter Password is required."
                    } else if (password !== password2) {
                        //result.isValid = false;

                        result.password2IsValid = false;
                        result.password2ErrorMessage = "Password and Re-enter Password do not match."
                    }
                }

                if(employer.employerName == null || isUndefined(employer.employerName)) {
                    //result.isValid = false;

                    result.employerErrorMessage = "Office is required."
                }



                this.performGuestValidation(guest).subscribe(
                    (gv:GuestValidationResult) => {

                        result.guestIsValid = gv.isValid;
                        result.guestErrorMessage = gv.errorMessage;

                        //result.isValid = gv.isValid && result.isValid;

                        this.customerUpdateService.performCustomerValidation(customer).subscribe(
                            (r: CustomerValidateResult) => {
                                //result.isValid = !(r.errorFlag) && result.isValid;

                                result.customerIsValid = !(r.errorFlag)

                                this.officeAgentLookupService.customerValidateResult = r;

                                if (employer.employerID == null) {
                                    this.employerUpdateService.performEmployerValidation(employer).subscribe(
                                        (r: EmployerValidateResult) => {
                                            //result.isValid = !(r.errorFlag) && result.isValid;
                                            result.employerIsValid = !(r.errorFlag);

                                            this.officeAgentLookupService.employerValidateResult = r;
                                            subscriber.next(result);
                                            subscriber.complete();
                                        },
                                        (e) => {
                                            //result.isValid = false;
                                            result.employerIsValid = false;

                                            subscriber.next(result);
                                            subscriber.complete();
                                        }
                                    );
                                } else {
                                    subscriber.next(result);
                                    subscriber.complete();
                                }
                            },
                            (e) => {
                                //result.isValid = false;
                                result.customerIsValid = false;

                                subscriber.next(result);
                                subscriber.complete();
                            }
                        );
                    }
                )

            });
        return observable;
    }

    performGuestValidation(guest: any): Observable<GuestValidationResult> {
        const observable: Observable<GuestValidationResult> =
            Observable.create((subscriber: Subscriber<GuestValidationResult>) => {

                const url = 'profile/validate';

                guest['type']='R';
                const formBody = JSON.stringify(guest);

                this.http.post(url, formBody).subscribe(
                        (response: Response) => {
                        const jsonObject = response.json();

                        let result = new GuestValidationResult();

                        result.isValid = jsonObject.isValid;
                        result.errorMessage = jsonObject.errorMessage;

                        subscriber.next(result);
                        subscriber.complete();
                    }
                );
            });

        return observable;
    }

}

export class GuestValidationResult {
    isValid: boolean = true;
    errorMessage: String = "";
}



export class ProfileValidationResult {
    customerIsValid: boolean = true;

    // isValid: boolean = true;

    get isValid() : boolean {
        return (this.email2IsValid && this.emailIsValid && this.passwordIsValid && this.password2IsValid && this.guestIsValid && this.customerIsValid && this.employerIsValid)
    }

    emailIsValid: boolean = true;
    emailErrorMessage: String = "";

    email2IsValid: boolean = true;
    email2ErrorMessage: String = "";

    passwordIsValid: boolean = true;
    passwordErrorMessage: String = "";

    password2IsValid: boolean = true;
    password2ErrorMessage: String = "";

    employerIsValid: boolean = true;
    employerErrorMessage: String = "";

    guestIsValid: boolean = true;
    guestErrorMessage: String = "";

}

