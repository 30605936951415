import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';

@Injectable()


export class ResetGuard implements CanActivate {

  constructor() {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable = new Observable((subscriber: Subscriber<boolean>) => {

      sessionStorage.clear();
      localStorage.clear();

      location.href = '/';

      subscriber.next(true);
      subscriber.complete();
    });

    return observable;
  }

}
