import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../_services/account.service";
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'phx-validate-email',
    templateUrl: './validate-email.component.html'
})
export class ValidateEmailComponent implements OnInit, OnDestroy {

    id: String = "";
    isValid: boolean = true;
    message: String = "";
    private sub: any;
    currentUrl: string = "";

    mode: number = 0; // 0 perform validation 1 show succes message 2 just show message 3 resend the email

    constructor(@Inject(DOCUMENT) private document: Document,
                private route: ActivatedRoute,
                private router: Router,
                private accountService: AccountService) {
        this.currentUrl = this.document.location.href;
    }

    ngOnInit() {
        let parent = this;
        this.sub = this.route.params.subscribe(params => {
            if (params['id'] != undefined)
                parent.id = params['id'];

            if (parent.id.length > 0) {
                if (parent.id === 'success') {
                    parent.mode = 1;
                } else if (parent.id === 'show') {
                    parent.mode = 2;
                } else {
                    parent.accountService.validatePasswordReset(parent.id).subscribe(r => {
                        const response = r.json();
                        if (response.messageObject.isValid) {
                            parent.mode = 3;
                            setTimeout(function() {
                                parent.router.navigate(['/login']);
                            }, 4500);
                        } else {
                            if (response.errorText === 'used') {
                                parent.message = "This validation code has already been used."
                            } else {
                                parent.message = "This validation code is not valid."
                            }
                            parent.isValid = false;
                        }

                    });
                }
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }


    shouldDisplayProgressBar(): boolean {
      let flag = (this.mode === 0);

      if (!this.isValid) {
        flag = false;
      }

      return flag;
    }


}
