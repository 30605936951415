

import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from './plan-order-auth.service';



@Injectable()
export class PlanOrderAuthGuard implements CanActivate {



  constructor(private router: Router,
              private planOrderAuthService: PlanOrderAuthService,
              private systemService: PhxSystemService
  ) { }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      const nextUrl = state.url;

      this.systemService.loadAppSettings().subscribe((appSettingsLoadedFlag: boolean) => {
        // if the user is not logged in
        if (!this.planOrderAuthService.loggedIn()) {
          // trigger the modal login
          this.planOrderAuthService.triggerModalLoginEventEmitter.emit(true);

          // register for login completion
          this.planOrderAuthService.onLoginCompletion.subscribe((successFlag: boolean) => {
            this.router.navigate([nextUrl]);
          });

          subscriber.next(false);
          subscriber.complete();

          // if the user is logged in
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      });

    });

    return observable;
  }
}

