
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '@orhp/phx-customer-ui-module';


@Component({
  selector: 'app-plan-order-agent-auto-match',
  templateUrl: './agent-auto-match.component.html',
  styleUrls: ['./agent-auto-match.component.scss']
})


export class PlanOrderAgentAutoMatchComponent implements OnInit {


  @Input() customer: Customer = null;
  @Input() agentTitle = 'agent';

  @Output() onAgentConfirm = new EventEmitter<boolean>();


  constructor() {
  }


  ngOnInit() {
  }


  didClickCorrectAgent() {
    this.onAgentConfirm.emit(false);
  }


  didClickAcceptAgent() {
    this.onAgentConfirm.emit(true);
  }


}
