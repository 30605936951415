import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ArrayUtility} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector-component.html',
  styleUrls: [
    './page-selector.component.scss'
  ]
})

export class PageSelectorComponent {

  @Input()
  totalItems: number;

  @Input()
  currentPageNumber: number;

  @Input()
  itemsPerPage: number;

  @Output()
  onPageNumberSelection = new EventEmitter<number>();



  get minPageNumber(): number {
    return 1;
  }


  get totalPageCount(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }


  activeGroupPageCount = 6;


  shouldDisplayPaging(): boolean {
    let flag = true;

    if (!this.totalItems) {
      flag = false;
    }

    if (!this.currentPageNumber) {
      flag = false;
    }

    if (!this.itemsPerPage) {
      flag = false;
    }

    if (flag) {
      flag = flag && (this.totalPageCount > 1);
    }

    return flag;
  }


  leftPageNumbers(): number[] {
    const pageNumbers = [this.minPageNumber];

    return pageNumbers;
  }


  middlePageNumbers(): number[] {
    const pageNumbers = [];

    let minPageNumber = this.currentPageNumber - (this.activeGroupPageCount / 2);

    if (minPageNumber <= 1) {
      minPageNumber = 2;
    }

    let maxPageNumber = this.currentPageNumber + (this.activeGroupPageCount / 2);

    if (maxPageNumber >= this.totalPageCount) {
      maxPageNumber = this.totalPageCount - 1;
    }

    for (let indexPageNumber = minPageNumber; indexPageNumber <= maxPageNumber; indexPageNumber++) {
      pageNumbers.push(indexPageNumber);
    }

    return pageNumbers;
  }


  rightPageNumbers(): number[] {
    const pageNumbers = [this.totalPageCount];

    return pageNumbers;
  }



  shouldDisplayLeftEllipse(): boolean {
    const leftNumbers = this.leftPageNumbers();
    const middleNumbers = this.middlePageNumbers();

    let flag = true;

    if (!middleNumbers.length) {
      flag = false;

    } else if (!!leftNumbers.length) {
      if (leftNumbers[leftNumbers.length - 1] === (middleNumbers[0] - 1)) {
        flag = false;
      }
    }

    return flag;
  }


  shouldDisplayRightEllipse(): boolean {
    const rightNumbers = this.rightPageNumbers();
    const middleNumbers = this.middlePageNumbers();

    let flag = true;

    if (!middleNumbers.length) {
      flag = false;

    } else if (!!rightNumbers.length) {
      if (middleNumbers[middleNumbers.length - 1] === (rightNumbers[0] - 1)) {
        flag = false;
      }
    }

    return flag;
  }


  isCurrentPageNumber(pageNumber: number): boolean {
    return (this.currentPageNumber === pageNumber);
  }


  isFirstPage(): boolean {
    return (this.currentPageNumber === this.minPageNumber);
  }


  isLastPage(): boolean {
    return (this.currentPageNumber === this.totalPageCount);
  }


  didClickPageNumber(pageNumber: number) {
    this.onPageNumberSelection.emit(pageNumber);
  }


  didClickPreviousPage() {
    this.onPageNumberSelection.emit(this.currentPageNumber - 1);
  }


  didClickNextPage() {
    this.onPageNumberSelection.emit(this.currentPageNumber + 1);
  }


}

