
import {Routes, RouterModule} from '@angular/router';
import {PlanOrderRoutes} from './_modules/plan-order/plan-order.routes';
import {PlanOrderModule} from './_modules/plan-order/plan-order.module';
import {PlanOrderComponent} from './plan-order/plan-order.component';
import {HomeRoutes} from "./home/home.routes";
import {LoginComponent} from "./login/login.component";
import {AppSettingsGuard} from './_services/app-settings.guard';
import {WelcomeComponent} from './login/welcome.component';
import {UserRoutes} from "./user/user.routes";
import {LogoutComponent} from "./login/logout.component";
import {DebugComponent} from './debug/debug.component';
import {DebugGuard} from './debug/debug.guard';
import {LegacyToolboxComponent} from './legacy-toolbox/legacy-toolbox.component';
import {ResetGuard} from './_services/reset.guard';
import {LoginPassthruComponent} from './login/login-passthru.component';
import {NoAutoLoginGuard} from './_services/no-auto-login-guard';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [AppSettingsGuard],
    children: [
      {
        path: 'plan-order',
        component: PlanOrderComponent,
        children: PlanOrderRoutes
      },
      ...HomeRoutes,
      {
        path: 'login-passthru',
        children: [
          {
            path: ':loginTokenGUID/x/:externalRedirectTo',
            component: LoginPassthruComponent
          },
          {
            path: ':loginTokenGUID/:redirectTo',
            component: LoginPassthruComponent
          },
          {
            path: ':loginTokenGUID',
            component: LoginPassthruComponent
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/welcome'
          }
        ]
      },
      {
        path: 'login',
        pathMatch: 'full',
        redirectTo: 'welcome'
      },
      {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [NoAutoLoginGuard]
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [NoAutoLoginGuard]
      },
      ...UserRoutes,
      {
        path: 'legacy-toolbox',
        canActivate: [NoAutoLoginGuard],
        children: [
          {
            path: '',
            component: LegacyToolboxComponent
          },
          {
            path: ':redirectTo',
            component: LegacyToolboxComponent
          }
        ]
      },
      {
        path: 'debug',
        component: DebugComponent,
        canActivate: [DebugGuard, NoAutoLoginGuard]
      },
      {
        path: 'reset',
        canActivate: [ResetGuard],
        children: []
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/'
  }

];


export const routes = RouterModule.forRoot(appRoutes);


export function loadPlanOrderModule() {
  return PlanOrderModule;
}

