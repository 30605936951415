

import {Component} from '@angular/core';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {PlanOrderService} from '../_modules/plan-order/_services/plan-order.service';
import {Customer} from '@orhp/phx-customer-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderDomainFactoryService} from '../_modules/plan-order/_services/plan-order-domain-factory.service';
import {Router} from '@angular/router';
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {MessagingFormatter} from '@orhp/phx-common-ui-module';
import {Response} from '@angular/http';


@Component({
  selector: 'app-debug-console',
  templateUrl: './debug.component.html',
  styleUrls: [
    './debug.component.scss'
  ]
})

export class DebugComponent {

  selectedDebugMode = DebugMode.none;

  jsonSpacing = '  ';

  private _loginTokenJsonString: string = null;
  private _loggedInCustomerJsonString: string = null;
  private _planOrderApplicationJsonString: string = null;

  planOrderApplicationSuccessFlag = false;

  cacheResetResult: boolean = null;


  get loginTokenJsonString(): string {
    if (!this._loginTokenJsonString) {
      let jsonText = '';

      const loginToken = this.loginService.loginToken;

      if (loginToken) {
        jsonText = JSON.stringify(loginToken.jsonValues(), null, this.jsonSpacing);
      }

      this._loginTokenJsonString = jsonText;
    }


    return this._loginTokenJsonString;
  }

  set loginTokenJsonString(loginTokenJsonString: string) {
    this._loginTokenJsonString = loginTokenJsonString;
  }




  get loggedInCustomerJsonString(): string {
    if (!this._loggedInCustomerJsonString) {
      let jsonText = '';

      const customer = <Customer>this.loginService.authenticationService.customer;

      if (customer) {
        jsonText = JSON.stringify(customer.jsonValues(), null, this.jsonSpacing);
      }

      this._loggedInCustomerJsonString = jsonText;
    }

    return this._loggedInCustomerJsonString;
  }


  set loggedInCustomerJsonString(loggedInCustomerJsonString: string) {
    this._loggedInCustomerJsonString = loggedInCustomerJsonString;
  }



  get planOrderApplicationJsonString(): string {
    if (!this._planOrderApplicationJsonString) {
      let jsonText = '';

      const jsonObject = this.planOrderService.planOrderApplicationJsonObject;

      if (jsonObject) {
        jsonText = JSON.stringify(jsonObject, null, this.jsonSpacing);
      }

      this._planOrderApplicationJsonString = jsonText;
    }

    return this._planOrderApplicationJsonString;
  }



  set planOrderApplicationJsonString(planOrderApplicationJsonString: string) {
    this._planOrderApplicationJsonString = planOrderApplicationJsonString;
  }




  constructor(private loginService: PhxLoginService,
              private systemService: PhxSystemService,
              private http: PhxHttpService,
              private router: Router,
              private planOrderService: PlanOrderService,
              private planOrderDomainFactoryService: PlanOrderDomainFactoryService) {
  }


  ngServerName(): string {
    return (this.systemService.serverName || '(undefined)');
  }



  setDebugMode(debugMode: DebugMode) {
    this.selectedDebugMode = (this.selectedDebugMode !== debugMode ? debugMode : null);

    this.planOrderApplicationSuccessFlag = false;
  }


  isLoginTokenSelected(): boolean {
    return (this.selectedDebugMode === DebugMode.loginToken);
  }


  didClickLoginToken() {
    this.setDebugMode(DebugMode.loginToken);
  }




  isLoggedInCustomerSelected(): boolean {
    return (this.selectedDebugMode === DebugMode.loggedInCustomer);
  }



  didClickLoggedInCustomer() {
    this.setDebugMode(DebugMode.loggedInCustomer);
  }




  isPlanOrderApplicationSelected(): boolean {
    return (this.selectedDebugMode === DebugMode.persistedPlanOrderApplication);
  }


  didClickPlanOrderApplication() {
    this.setDebugMode(DebugMode.persistedPlanOrderApplication);
  }




  didClickUpdatePlanOrderApplication() {
    // parse the JSON into an object
    const jsonObject = JSON.parse(this.planOrderApplicationJsonString);

    const application = this.planOrderDomainFactoryService.newPersistedApplication(jsonObject);

    this.planOrderService.planOrderApplication = application;

    this.planOrderApplicationSuccessFlag = true;
  }


  didClickGotoPlanOrder() {
    const url = '/plan-order/clear-application';

    this.router.navigate([url]);
  }


  didClickMicroserviceCaches() {
    this.selectedDebugMode = DebugMode.resetMicroserviceCacheForm;
  }


  isResetMicroserviceCachesSelected(): boolean {
    let flag = false;

    flag = flag || (this.selectedDebugMode === DebugMode.resetMicroserviceCacheForm);
    flag = flag || (this.selectedDebugMode === DebugMode.resetMicroserviceCacheSuccess);
    flag = flag || (this.selectedDebugMode === DebugMode.resetMicroserviceCacheFailure);

    return flag;
  }


  didClickResetMicroserviceCaches() {
    const url = 'systemConfig/clearCaches';
    const formBody = {};

    this.http.post(url, formBody).subscribe((response: Response) => {
      const json = response.json();

      let resultFlag = false;

      if (json) {
        resultFlag = true;
      }

      if (resultFlag) {
        this.selectedDebugMode = DebugMode.resetMicroserviceCacheSuccess;

      } else {
        this.selectedDebugMode = DebugMode.resetMicroserviceCacheFailure;
      }

    }, (error: Response) => {
      this.selectedDebugMode = DebugMode.resetMicroserviceCacheFailure;
    });
  }


  shouldDisplayMicroserviceCacheResetSuccess(): boolean {
    return (this.selectedDebugMode === DebugMode.resetMicroserviceCacheSuccess);
  }


  shouldDisplayMicroserviceCacheResetFailure(): boolean {
    return (this.selectedDebugMode === DebugMode.resetMicroserviceCacheFailure);
  }

}


enum DebugMode {
  none = 100,
  loginToken = 200,
  loggedInCustomer = 300,
  persistedPlanOrderApplication = 400,
  resetMicroserviceCacheForm = 500,
  resetMicroserviceCacheSuccess = 510,
  resetMicroserviceCacheFailure = 520
}

