import {Injectable} from '@angular/core';
import {BannerItem} from '@orhp/phx-common-ui-module';
import { SessionStorage } from '@orhp/phx-localstorage-ui-module';

@Injectable()
export class BannersCacheService {

  @SessionStorage('phx.toolbox.ui.SessionStorageBanner')
  private _SessionStorageBannerObject: any;

  banner: BannerItem;


  getCachedBanner() {
    return this._SessionStorageBannerObject;
  }

  cacheTheBanner(bannerItem) {
    const bannerObject = bannerItem;
    this._SessionStorageBannerObject = bannerObject;
  }

  isCached() {
    if (!this._SessionStorageBannerObject) {
      return false;
    } else {
      return true;
    }
  }
}
