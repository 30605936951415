import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../_services/account.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'phx-resend-validation',
  templateUrl: './resend-validation.component.html'
})
export class ResendValidationComponent implements OnInit, OnDestroy {

    sub: any;
    currentUrl: string = "";
    email: string = "";

    constructor(@Inject(DOCUMENT) private document: Document,
              private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService) {

      this.currentUrl = this.document.location.href;
        //let url = document.location.protocol +'//'+ document.location.hostname + ':my_port';
        let url = window.location.href;
        let arr = url.split("/");
        this.currentUrl = arr[0] + "//" + arr[2];
  }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            if (params['email'] != undefined) {
                this.email = params['email'];
                this.accountService.resendValidationEmail(this.email, this.currentUrl + '/account/validate').subscribe(r => {
                    const response = r.json();
                });
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
