

import {ActivatedRoute, Router} from '@angular/router';
import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';

import {Customer, Employer} from '@orhp/phx-customer-ui-module';

import {PlanOrderApplication} from '../_models/plan-order-application';

import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {CustomerUpdateService} from '@orhp/phx-customer-ui-module';

import {EmployerUpdateService} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {Observable} from 'rxjs/Observable';
import {EmployerValidateResult} from '@orhp/phx-customer-ui-module';
import {CustomerValidateResult} from '@orhp/phx-customer-ui-module';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {OfficeAgentLookupPersistenceService} from '../../office-agent-lookup/_services/persistence.service';
import {OfficeAgentLookupService} from '../../office-agent-lookup/_services/lookup.service';
import {
  IOfficeAgentLookupValidateResult,
  OfficeAgentLookupValidateService
} from '../../office-agent-lookup/_services/validate.service';
import {OfficeAgentLookupRelationshipType} from '../../office-agent-lookup/_src/lookup-relationship-type';
import {OfficeAgentLookupCacheService} from '../../office-agent-lookup/_services/lookup-cache.service';
import {PlanOrderApplicationUpdates} from '../_models';
import {Subscription} from 'rxjs/Subscription';
import {Address} from '@orhp/phx-common-ui-module';



@Component({
  selector: 'app-plan-order-cooperating-agent',
  templateUrl: './cooperating-agent.component.html',
  styleUrls: ['../plan-order.scss']
})


export class PlanOrderCooperatingAgentComponent
  extends PlanOrderBaseComponent
  implements OnInit, OnDestroy {

  // what is the lookup relationship passed to the component
  lookupRelationshipType = 'cooperating-agent';

  // selected office and agent
  selectedEmployer: Employer = this.officeAgentLookupPersistenceService.employer;
  selectedCustomer: Customer = this.officeAgentLookupPersistenceService.customer;
  relationshipSkippedFlag = (this.planOrderApplication.cooperatingAgentSkippedFlag || false);

  // validation error collection
  validationStatus: ValidationStatus = ValidationStatus.none;
  employerValidateResult: EmployerValidateResult = null;
  customerValidateResult: CustomerValidateResult = null;

  // if we're auto-matching to an office or agent
  employerAutoMatch: Employer = null;
  customerAutoMatch: Customer = null;

  roleUpdateMessageFlag = false;

  employerEventSubscription: Subscription = null;
  customerEventSubscription: Subscription = null;
  skipEventSubscription: Subscription = null;


  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupValidateService: OfficeAgentLookupValidateService,
              private officeAgentLookupCacheService: OfficeAgentLookupCacheService,
              private employerUpdateService: EmployerUpdateService,
              private customerUpdateService: CustomerUpdateService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  // called when loaded
  ngOnInit() {
    super.ngOnInit();

    this.roleUpdateMessageFlag = this.planOrderApplication.hasUpdate(PlanOrderApplicationUpdates.cooperatingAgentListingFlag);

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, this.lookupRelationshipType);

    // subscribe to employer updates
    this.employerEventSubscription = this.officeAgentLookupPersistenceService.employerEventEmitter.subscribe(
      (employer: Employer) => {

        this.selectedEmployer = employer;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to customer updates
    this.customerEventSubscription = this.officeAgentLookupPersistenceService.customerEventEmitter.subscribe(
      (customer: Customer) => {

        this.selectedCustomer = customer;

        this.validationStatus = ValidationStatus.none;
      });

    // subscribe to skip events
    this.skipEventSubscription = this.officeAgentLookupService.skipEventEmitter.subscribe(
      (skipFlag: boolean) => {

        if (skipFlag) {
          this.didClickSkip();
        }
      });
  }



  ngOnDestroy() {
    this.officeAgentLookupCacheService.clearCache();

    this.employerEventSubscription.unsubscribe();
    this.customerEventSubscription.unsubscribe();
    this.skipEventSubscription.unsubscribe();
  }


  shouldDisplayEmployerAutoMatch(): boolean {
    return !!this.employerAutoMatch;
  }


  shouldDisplayCustomerAutoMatch(): boolean {
    return !!this.customerAutoMatch;
  }


  // should the Next button be displayed
  shouldDisplayNextButton(): boolean {
    return (this.selectedCustomer !== null);
  }


  agentRoleUpdatedMessage(): string {
    let message: string = null;

    if (this.roleUpdateMessageFlag) {
      const roleText = (this.planOrderApplication.cooperatingAgentListingFlag ? 'Listing' : 'Selling');

      message = 'The Cooperating Agent role has been updated to ' + roleText + ' Agent';
    }

    return message;
  }



  // user clicked the Back button
  didClickBackButton(): void {

  }


  // user clicked the Next button
  didClickNextButton(): void {
    this.performValidation();
  }


  // performs validation
  performValidation(): void {

    const newEmployerFlag = !this.selectedEmployer.employerID;

    const lookupTypeCode = OfficeAgentLookupRelationshipType.typeCodeCooperatingAgent;

    this.officeAgentLookupValidateService.performValidation(this.selectedEmployer, this.selectedCustomer, lookupTypeCode)
      .subscribe((results: IOfficeAgentLookupValidateResult) => {

        this.customerValidateResult = results.customerValidateResult;
        this.employerValidateResult = results.employerValidateResult;

        this.customerAutoMatch = results.customerAutoMatch;
        this.employerAutoMatch = results.employerAutoMatch;


        // set the validation status
        let validationStatus = ValidationStatus.success;

        // if there was a customer auto-match
        if (this.customerAutoMatch) {
          validationStatus = ValidationStatus.customerAutoMatch;

          // if there was an employer auto-match
        } else if (this.employerAutoMatch) {
          validationStatus = ValidationStatus.employerAutoMatch;

          // if there are any validation errors
        } else if (this.validationError()) {
          validationStatus = ValidationStatus.validationFailure;

          // checks specific to new offices
        } else if (newEmployerFlag) {

          // if the physical address was updated with validation
          if (this.employerValidateResult.employerPhysicalAddressUpdated()) {
            validationStatus = ValidationStatus.addressValidationSuccess;

            // if the physical address wasn't validated
          } else if (!this.employerValidateResult.employerPhysicalAddressValidated()) {
            validationStatus = ValidationStatus.addressValidationFailure;
          }

        }

        this.validationStatus = validationStatus;


        // if the validation was successful
        if (validationStatus === ValidationStatus.success) {
          this.gotoNextStep();
        }


      });
  }





  employerPhysicalAddressForValidation(): Address[] {
    const addresses = [];

    if (this.employerValidateResult) {
      const address = this.employerValidateResult.employerPhysicalAddress();

      addresses.push(address);
    }

    return addresses;
  }


  shouldDisplayAddressValidationModal(): boolean {
    let flag = false;

    flag = flag || (this.validationStatus === ValidationStatus.addressValidationSuccess);
    flag = flag || (this.validationStatus === ValidationStatus.addressValidationFailure);

    return flag;
  }


  handleAddressValidationUserResponse(flag: boolean) {
    // if the user wants to continue
    if (flag) {
      const physicalAddress = this.selectedEmployer.physicalAddress;

      // if the address was validated
      if (this.employerValidateResult.employerPhysicalAddressValidated()) {
        const validatedAddress = this.employerValidateResult.employerValidatedPhysicalAddress();

        physicalAddress.address = validatedAddress.address;
        physicalAddress.city = validatedAddress.city;
        physicalAddress.stateCode = validatedAddress.stateCode;
        physicalAddress.zipCode = validatedAddress.zipCode;
      }

      this.gotoNextStep();

      // if the user wants to make changes
    } else {
      this.validationStatus = ValidationStatus.none;
    }
  }






  // should the validation error be displayed
  validationError(): boolean {
    let errorFlag = false;

    if (this.employerValidateResult) {
      errorFlag = errorFlag || this.employerValidateResult.errorFlag;
    }

    if (this.customerValidateResult) {
      errorFlag = errorFlag || this.customerValidateResult.errorFlag;
    }

    return errorFlag;
  }


  shouldDisplayValidationError(): boolean {
    let displayFlag = true;

    if (!this.validationError()) {
      displayFlag = false;
    }

    if (this.validationStatus !== ValidationStatus.validationFailure) {
      displayFlag = false;
    }

    return displayFlag;
  }



  // the user responded to the office auto-match
  didClickOfficeAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the office
    if (responseFlag) {
      this.selectedEmployer = this.employerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();


      // if the user does not want to accept the office
    } else {
      this.employerAutoMatch = null;
    }
  }


  // the user clicked the agent auto match response
  didClickAgentAutoMatchResponse(responseFlag: boolean) {
    // if the user wants to accept the agent
    if (responseFlag) {
      this.selectedCustomer = this.customerAutoMatch;

      this.selectedCustomer.employer = this.selectedEmployer;

      this.gotoNextStep();

      // if the user does not want to accept the agent
    } else {
      this.customerAutoMatch = null;
    }

  }



  // user clicked to Skip
  didClickSkip(): void {
    this.relationshipSkippedFlag = true;
    this.selectedEmployer = null;
    this.selectedCustomer = null;

    this.gotoNextStep();
  }


  // go to the next step
  gotoNextStep() {
    // are we adding a cooperating agent?
    const previousAgentFlag = !!this.planOrderApplication.cooperatingAgent;

    this.planOrderApplication.cooperatingAgentSkippedFlag = this.relationshipSkippedFlag;
    this.planOrderApplication.cooperatingAgent = this.selectedCustomer;

    // are we adding a cooperating agent?
    if (!previousAgentFlag && this.planOrderApplication.cooperatingAgent) {
      this.planOrderApplication.cooperatingAgentListingFlag = !this.planOrderApplication.initiatingAgentListingFlag;
    }

    this.officeAgentLookupPersistenceService.clearPersistedValues();

    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }


}



enum ValidationStatus {
  none = 10,
  validationFailure = 20,
  addressValidationFailure = 30,
  addressValidationSuccess = 40,
  employerAutoMatch = 50,
  customerAutoMatch = 60,
  success = 70
}


