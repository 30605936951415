

export class LegacyLoginResult {

  loginAttemptedFlag = false;
  loginSuccessFlag = false;

}


export interface ILegacyLoginResult {
  loginAttemptedFlag: string;
  loginSuccessFlag: string;
}
