
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TransactionRole} from '@orhp/phx-plan-ui-module';
import {PlanOrderLookupValueService} from '../_services/plan-order-lookup-value.service';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {Employer} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {TransactionRoleOrdererRelationship} from '@orhp/phx-plan-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';

@Component({
  selector: 'app-plan-order-role',
  templateUrl: './role.component.html',
  styleUrls: ['../plan-order.scss']
})

export class PlanOrderRoleComponent extends PlanOrderBaseComponent implements OnInit {

  selectedTransactionRole: TransactionRole;

  get transactionRoles(): TransactionRole[] {
    // pull out which transaction roles to display
    const transactionRoles: TransactionRole[] = [];

    this.planLookupValueService.transactionRoles.forEach((indexRole: TransactionRole) => {
      let includeRoleFlag = indexRole.displayForRoleSelectionFlag;

      // if the role is ORHP Acct Exec, only include it if they're logged in
      if (indexRole.acctExecFlag && !this.planOrderAuthService.isOrhpUser()) {
        includeRoleFlag = false;
      }

      // if the user selected Active Listing, test for if it should be included
      const transactionType = this.planOrderApplication.transactionType;

      if (transactionType) {
        // if the transaction type is active listing
        if (transactionType.activeListingFlag) {
          if (!indexRole.displayForActiveListingFlag) {
            includeRoleFlag = false;
          }
        }
      }

      if (includeRoleFlag) {
        transactionRoles.push(indexRole);
      }
    });

    return transactionRoles;
  }



  constructor(private router: Router,
              private route: ActivatedRoute,
              private planOrderAuthService: PlanOrderAuthService,
              private customerLookupValueService: CustomerLookupValueService,
              private planLookupValueService: PlanLookupValueService,
              private planOrderService: PlanOrderService,
              private planOrderLookupValueService: PlanOrderLookupValueService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  ngOnInit() {
    super.ngOnInit();

    // initially try defaulting to the application role
    if (!this.selectedTransactionRole) {
      this.selectedTransactionRole = this.planOrderApplication.role;
    }

    // set the current step
    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'role');
  }


  // is this the selected transaction role
  isSelectedTransactionRole(transactionRole: TransactionRole): boolean {
    return (transactionRole === this.selectedTransactionRole);
  }


  // should the Next button be displayed
  shouldDisplayNextButton(): boolean {
    return !!this.selectedTransactionRole;
  }


  // the user clicked one of the roles
  didClickTransactionRole(transactionRole: TransactionRole): void {
    this.selectedTransactionRole = transactionRole;

    this.gotoNextStep();
  }


  gotoNextStep() {
    const previousRole = this.planOrderApplication.role;

    // if the previous role was double-ending, and this is not double-ending
    if (previousRole &&
        previousRole.realtorListingSellingFlag &&
        !this.selectedTransactionRole.realtorListingSellingFlag) {
      this.planOrderApplication.cooperatingAgent = null;
    }

    this.planOrderApplication.role = this.selectedTransactionRole;

    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }

}
