﻿import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../login/service/authentication.service';
import {Customer, CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {HomeService} from "./home.service";

@Component({
  moduleId: module.id,
  templateUrl: 'home.component.html',
  styleUrls: [
    './home.component.scss'
  ]
})

export class HomeComponent implements OnInit {

    get homeUpperRightMessageHtml(): string {
      return this.homeService.homeUpperRightMessageHtml;
    }


    constructor(private authorizationService: AuthenticationService,
                private customerLookupValueService: CustomerLookupValueService,
                private homeService: HomeService) {
    }


    get userFirstName(): string {
      let name: string = null;

      const customer = <Customer>this.authorizationService.customer;

      if (customer) {
        name = customer.givenName;
      }

      return name;
    }


    ngOnInit() {

    }


    shouldDisplayBanners(): boolean {
      let flag = true;

      if (this.customerLookupValueService.lookupValuesLoaded) {
        const employer = this.authorizationService.employer;

        if (employer && employer.employerType.telemarketingFlag) {
          flag = false;
        }
      }



      return flag;
    }




}
