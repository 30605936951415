import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {AuthenticationService} from './login/service/authentication.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  appSettingsLoaded = false;


  private _currentYearText: string = null;

  get currentYearText(): string {
    if (!this._currentYearText) {
      const now = new Date();

      this._currentYearText = String(now.getFullYear());
    }

    return this._currentYearText;
  }



  get modalProgress(): ModalProgress {
    return this.modalProgressService.progress;
  }


  get customer(): Customer {
    return this.authenticationService.customer;
  }


  get globalErrorMessages(): string[] {
    return this.systemService.globalErrorMessages;
  }


  urlUpdateSubscription: Subscription = null;



  constructor(private router: Router,
              private route: ActivatedRoute,
              private http: PhxHttpService,
              private modalProgressService: PhxModalProgressService,
              private authenticationService: AuthenticationService,
              private systemService: PhxSystemService,
              private customerLookupValueService: CustomerLookupValueService) {
  }


  ngOnInit() {
    this.registerGoogleAnalyticsTracking();
  }


  ngOnDestroy() {
    if (this.urlUpdateSubscription) {
      this.urlUpdateSubscription.unsubscribe();
    }
  }


  // registers the Google Analytics tracking
  registerGoogleAnalyticsTracking() {
    // subscribe to the router events
    this.urlUpdateSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const navEvent = <NavigationEnd>event;

        this.systemService.sendGoogleAnalyticsTracking(navEvent.urlAfterRedirects);
      }
    });
  }


  shouldDisplayModalLogin(): boolean {
    return this.authenticationService.displayModalLoginFlag;
  }


  shouldDisplayGlobalErrorMessage(): boolean {
    return !!this.globalErrorMessages.length;
  }


  loggedIn(): boolean {
    return this.authenticationService.loggedIn();
  }


  didClickLogin() {
    this.router.navigate(['/login']);
  }


  didClickLogout() {
    this.authenticationService.logout();

    this.router.navigate(['/login']);
  }

}
