import {Component, OnInit} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {AuthenticationService} from '../login/service/authentication.service';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {LegacyLoginResult} from '../login/model/legacy-login-result';
import {OfficeAgentLookupService} from '../_modules/office-agent-lookup/_services/lookup.service';
import {CustomerLookupValueFetchService} from '@orhp/phx-customer-ui-module';
import {NavbarService} from './service/navbar.service';
import {Subscription} from 'rxjs/Rx';
import {ToolboxSettingsService} from '../_services/toolbox-settings.service';
import {ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';
import {LegacyToolboxService, RedirectCode} from '../_services/legacy-toolbox.service';

@Component({
    selector: 'phx-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  marketingToolsErrorFlag = false;
  acctExecToolboxErrorFlag = false;

  urlUpdateSubscription: Subscription = null;

  hamburgerMenuExpandedFlag = false;


  legacyRedirectInProgressFlag = false;
  legacyRedirectCode: string = null;


  get employer(): Employer {
    return this.authenticationService.employer;
  }


  get productOffice(): ProductOffice {
    return this.toolboxSettingsService.productOffice;
  }

  get productOfficeToolbox(): ProductOfficeToolbox {
    return (!!this.productOffice ? this.productOffice.toolbox : null);
  }



  constructor(private router: Router,
              private systemService: PhxSystemService,
              private modalProgressService: PhxModalProgressService,
              private navbarService: NavbarService,
              private customerLookupValueFetchService: CustomerLookupValueFetchService,
              private customerLookupValueService: CustomerLookupValueService,
              private authenticationService: AuthenticationService,
              private toolboxSettingsService: ToolboxSettingsService,
              private legacyToolboxService: LegacyToolboxService,
              private officeAgentLookupService: OfficeAgentLookupService) {
  }


  get customer(): Customer {
    let customer: Customer = null;

    if (this.customerLookupValueService.lookupValuesLoaded) {
      customer = this.authenticationService.customer;
    }

    return customer;
  }


  loggedIn(): boolean {
    return !!this.customer;
  }


  ngOnInit() {
    this.systemService.loadAppSettings().subscribe((loadedFlag: boolean) => {
      this.loadLookupValues();
    });
  }


  // loads lookup values
  loadLookupValues() {
    this.authenticationService.validateCurrentCustomer().subscribe((customerResultFlag: boolean) => {
      this.customerLookupValueFetchService.fetchLookupValuesIfNeeded()
        .subscribe((customerLookupResult: boolean) => {
          // do nothing on complete
        });
    });
  }


  // are lookup values loaded?
  lookupValuesLoadedFlag(): boolean {
    let loadedFlag = true;

    if (!this.customerLookupValueService.lookupValuesLoaded) {
        loadedFlag = false;
    }

    return loadedFlag;
  }


  isOrhpToolbox(): boolean {
    return !this.isThirdPartyToolbox();
  }


  isThirdPartyToolbox(): boolean {
    let flag = false;

    if (this.thirdPartyHeaderImageURL() !== '') {
      flag = true;
    }

    return flag;
  }


  thirdPartyHeaderImageURL(): string {
    let url = '';

    if (!!this.productOfficeToolbox && ((this.productOfficeToolbox.headerImageURL || '') !== '')) {
      url = 'https://www1.orhp.com/images/logo-thirdparty/' + this.productOfficeToolbox.headerImageURL;
    }

    return url;
  }







  /**
   * Returns the button text for Place Application
   */
  placeApplicationButtonText(): string {
    let text = 'Get a Quote / Place Application';

    const productOfficeGroup = this.authenticationService.productOffice;
    const productOfficeToolbox = (!!productOfficeGroup ? productOfficeGroup.toolbox : null);

    if (!!productOfficeToolbox && !productOfficeToolbox.allowPlanQuoteFlag) {
      text = 'Order Warranty / Start Over';
    }

    return text;
  }



  didClickHamburgerMenu() {
    this.hamburgerMenuExpandedFlag = !this.hamburgerMenuExpandedFlag;
  }


  didClickMenuItem() {
    this.hamburgerMenuExpandedFlag = !this.hamburgerMenuExpandedFlag;
  }


  /**
   * Returns whether the Home icon should be displayed
   */
  shouldDisplayHomeIcon(): boolean {
    let flag = true;

    if (!!this.productOfficeToolbox && this.productOfficeToolbox.hideNavBarFlag) {
      flag = false;
    }

    return flag;
  }


  /**
   * Returns whether the logged-in items should be displayed
   */
  shouldDisplayLoggedInItems(): boolean {
    let flag = true;

    // require customer lookup values
    if (!this.lookupValuesLoadedFlag()) {
        flag = false;
    }

    // require user to be logged in
    if (!this.authenticationService.loggedIn()) {
        flag = false;
    }

    if (!!this.productOfficeToolbox && this.productOfficeToolbox.hideNavBarFlag) {
      flag = false;
    }

    return flag;
  }


  shouldDisplayNotLoggedInItems(): boolean {
    let displayFlag = true;

    // require customer lookup values
    if (!this.lookupValuesLoadedFlag()) {
        displayFlag = false;
    }

    // require user to be logged out
    if (this.authenticationService.loggedIn()) {
        displayFlag = false;
    }

    return displayFlag;
  }



  shouldDisplayLoginMenuItem(): boolean {
    return this.navbarService.shouldDisplayLoginMenuItem();
  }



  legacyRedirectDescription(): string {
    let description = '';

    switch (this.legacyRedirectCode) {
      case RedirectCode.aeToolbox:
        description = 'Reports Center';
        break;

      case RedirectCode.marketingTools:
        description = 'Marketing Tools';
        break;
    }

    return description;
  }



  shouldDisplayMarketingTools(): boolean {
    let flag = true;

    // if we're not logged in
    if (!this.authenticationService.loggedIn()) {
      flag = false;
    }

    // do not display for telemarketing offices
    if (this.lookupValuesLoadedFlag() && !!this.employer) {
      const employerType = this.employer.employerType;

      if (employerType && employerType.telemarketingFlag) {
        flag = false;
      }
    }

    return flag;
  }



  // user clicked Marketing Tools
  didClickMarketingTools() {
    this.legacyRedirectCode = RedirectCode.marketingTools;
    this.legacyRedirectInProgressFlag = true;

    this.legacyToolboxService.redirectToLegacySite(this.legacyRedirectCode);
  }


  didClickCancelLegacyRedirect() {
    this.legacyRedirectCode = null;
    this.legacyRedirectInProgressFlag = false;
  }




  shouldDisplayAcctExecToolboxButton() {
    let displayFlag = false;

    if (this.authenticationService.orhpUserFlag) {
      displayFlag = true;
    }

    return displayFlag;
  }


  didClickAcctExecToolboxButton() {
    this.legacyRedirectCode = RedirectCode.aeToolbox;
    this.legacyRedirectInProgressFlag = true;

    this.legacyToolboxService.redirectToLegacySite(this.legacyRedirectCode);
  }


  didClickLogout() {
    this.router.navigate(['/logout']);
  }


}

