import {Injectable} from '@angular/core';
import {AddressValidateResult} from '@orhp/phx-addressvalidate-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {AddressValidateRequest} from '@orhp/phx-addressvalidate-ui-module';
import {EmployerLookupParams} from '@orhp/phx-customer-ui-module';
import {Observable} from 'rxjs/Observable';
import {Employer} from '@orhp/phx-customer-ui-module';
import {
  Customer, CustomerLookupParams, CustomerValidateResult,
  EmployerValidateResult
} from '@orhp/phx-customer-ui-module';
import {AddressValidateService} from '@orhp/phx-addressvalidate-ui-module';
import {
  CustomerLookupService, CustomerUpdateService, EmployerLookupService,
  EmployerUpdateService
} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';


@Injectable()

export class OfficeAgentLookupValidateService {


  constructor(private addressValidateService: AddressValidateService,
              private employerUpdateService: EmployerUpdateService,
              private employerLookupService: EmployerLookupService,
              private customerUpdateService: CustomerUpdateService,
              private customerLookupService: CustomerLookupService) {
  }




  // performs validation
  performValidation(employer: Employer,
                    customer: Customer,
                    lookupRelationshipTypeCode?: string
                    ): Observable<IOfficeAgentLookupValidateResult> {

    const observable = Observable.create((subscriber: Subscriber<IOfficeAgentLookupValidateResult>) => {

      let lookupRelationshipType: OfficeAgentLookupRelationshipType = null;

      if (lookupRelationshipTypeCode) {
        lookupRelationshipType = new OfficeAgentLookupRelationshipType(lookupRelationshipTypeCode);
      }

      const newEmployerFlag = !employer.employerID;
      const newCustomerFlag = !customer.customerID;

      let unknownEscrowFlag = false;

      if (lookupRelationshipType) {
        unknownEscrowFlag = (lookupRelationshipType.escrowOfficerFlag ? customer.unknownEscrowFlag : false);
      }

      const observables = [];

      // validate the customer
      const customerValidateIndex = observables.length;
      const customerObservable = this.customerUpdateService.performCustomerValidation(customer);
      observables.push(customerObservable);

      // if this is a new customer and existing office, look for an existing customer
      let customerDuplicateIndex: number = null;

      if (!newEmployerFlag && newCustomerFlag && !unknownEscrowFlag) {
        customerDuplicateIndex = observables.length;

        const customerDuplicateObservable = this.findExistingCustomer(employer, customer, lookupRelationshipType);
        observables.push(customerDuplicateObservable);
      }


      // if it's a new employer
      let employerValidateIndex: number = null;
      let employerDuplicateIndex: number = null;

      if (newEmployerFlag) {
        // validate the info
        employerValidateIndex = observables.length;
        observables.push(this.employerUpdateService.performEmployerValidation(employer));

        // look for an existing employer
        employerDuplicateIndex = observables.length;
        observables.push(this.findExistingEmployer(employer));
      }


      // are any errors returned
      let hasErrorFlag = false;


      Observable.forkJoin(observables).subscribe(results => {
        // customer validation
        const customerValidateResult = <CustomerValidateResult>results[customerValidateIndex];

        if (customerValidateResult.errorFlag) {
          hasErrorFlag = true;
        }

        // employer validation
        let employerValidateResult: EmployerValidateResult = null;

        if (employerValidateIndex) {
          employerValidateResult = <EmployerValidateResult>results[employerValidateIndex];

          if (employerValidateResult.errorFlag) {
            hasErrorFlag = true;
          }
        }


        // if we're looking for a duplicate customer
        let customerAutoMatch: Customer = null;

        if (!hasErrorFlag && customerDuplicateIndex) {
          const duplicateCustomers = <Customer[]>results[customerDuplicateIndex];

          if (duplicateCustomers.length) {
            customerAutoMatch = duplicateCustomers[0];
          }
        }


        // if we're looking for a duplicate employer
        let employerAutoMatch: Employer = null;

        if (!hasErrorFlag && employerDuplicateIndex) {
          const existingEmployers = <Employer[]>results[employerDuplicateIndex] || [];

          // if an existing employer was found
          if (existingEmployers.length) {
            employerAutoMatch = existingEmployers[0];
          }
        }


        const validateResults: IOfficeAgentLookupValidateResult = {
          employerValidateResult: employerValidateResult,
          customerValidateResult: customerValidateResult,
          employerAutoMatch: employerAutoMatch,
          customerAutoMatch: customerAutoMatch
        };

        subscriber.next(validateResults);
        subscriber.complete();
      });
    });

    return observable;
  }





  // looks for an existing employer
  findExistingEmployer(employer: Employer): Observable<Employer[]> {
    const observable: Observable<Employer[]> = Observable.create((subscriber: Subscriber<Employer[]>) => {

      const address = employer.physicalAddress;

      // validate the office address
      const addressRequest = new AddressValidateRequest();

      addressRequest.address = address.address;
      addressRequest.city = address.city;
      addressRequest.stateCode = address.stateCode;
      addressRequest.zipCode = address.zipCode;

      this.addressValidateService.validateAddress(addressRequest).subscribe((addressResult: AddressValidateResult) => {
        // if the address validated
        if (addressResult.validatedFlag) {
          // look for an existing employer
          const employerParams = new EmployerLookupParams();

          employerParams.employerTypeCode = employer.employerType.employerType;
          employerParams.address = addressResult.cleanAddress;
          employerParams.zipCode = addressResult.cleanZipCode;

          this.employerLookupService.fetchEmployers(employerParams).subscribe((employers: Employer[]) => {
            subscriber.next(employers);
            subscriber.complete();
          });

          // if the address didn't validate
        } else {
          subscriber.next(null);
          subscriber.complete();
        }
      });

    });

    return observable;
  }


  findExistingCustomer(employer: Employer,
                       customer: Customer,
                       lookupRelationshipType: OfficeAgentLookupRelationshipType
                        ): Observable<Customer[]> {
    const observable = Observable.create((subscriber: Subscriber<Customer[]>) => {

      // perform a customer lookup by name
      const params = new CustomerLookupParams();

      params.employer = employer;
      params.givenName = customer.givenName;
      params.surName = customer.surName;
      params.includeEmployerFlag = false;

      if (lookupRelationshipType) {
        if (lookupRelationshipType.initiatingAgentFlag) {
          params.toolboxRealtorFlag = true;

        } else if (lookupRelationshipType.cooperatingAgentFlag) {
          params.toolboxRealtorFlag = true;

        } else if (lookupRelationshipType.escrowOfficerFlag) {
          params.toolboxEscrowFlag = true;
        }
      }

      this.customerLookupService.fetchCustomers(params).subscribe((customers: Customer[]) => {
        subscriber.next(customers);
        subscriber.complete();
      });
    });

    return observable;
  }




}


export interface IOfficeAgentLookupValidateResult {
  customerValidateResult: CustomerValidateResult;
  employerValidateResult: EmployerValidateResult;
  customerAutoMatch: Customer;
  employerAutoMatch: Employer;
}

