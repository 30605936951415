
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {ProductCoverage, ProductOffice, ProductOfficeToolbox} from '@orhp/phx-product-ui-module';
import {ProductLookupService} from '@orhp/phx-product-ui-module';
import {ProductLookupValueService} from '@orhp/phx-product-ui-module';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {Product} from '@orhp/phx-product-ui-module';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {DateUtility} from '@orhp/phx-common-ui-module';
import {PlanQuoteService} from '../_services/plan-quote.service';
import {PlanQuoteRequest} from '../_src/plan-quote-request';
import {PlanQuoteResult} from '../_src/plan-quote-result';
import {Customer} from '@orhp/phx-customer-ui-module';
import {IMapItem, MapUtility} from '@orhp/phx-common-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {SystemMessageLookupParams} from '@orhp/phx-system-ui-module';
import {SystemMessage} from '@orhp/phx-system-ui-module';
import {Observable} from 'rxjs/Observable';
import {ProductDomainFactoryService} from '@orhp/phx-product-ui-module';
import {UsefulMap} from '@orhp/phx-common-ui-module';
import {PlanDomainFactoryService} from '@orhp/phx-plan-ui-module';
import {PlanOption} from '@orhp/phx-plan-ui-module';
import {ArrayUtility} from '@orhp/phx-common-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';
import {DurationEnum} from '../_src/price-duration-enum';

@Component({
  selector: 'app-plan-order-optional-coverage',
  templateUrl: './optional-coverage.component.html',
  styleUrls: [
    '../plan-order.scss',
    './optional-coverage.component.scss'
  ]
})

export class PlanOrderOptionalCoverageComponent extends PlanOrderBaseComponent implements OnInit {

  coveragesLoadedFlag = false;

  sellerCoverageFlags = [true, false];

  coverages: SelectableProductCoverage[] = null;

  optionUnits = [1, 2, 3, 4];

  coverageWithPrerequisitesAttempted: SelectableProductCoverage = null;

  optionalCoverageSystemMessages: SystemMessage[] = null;

  optionalCoverageReselectionMessage: SystemMessage = null;

  buyerCoverageHeaderMessage: SystemMessage = null;
  sellerCoverageHeaderMessage: SystemMessage = null;

  quoteStatus: QuoteStatus = QuoteStatus.none;
  emailQuoteRequest: PlanQuoteRequest = null;


  optionalCoverageReselectMessageType = 'plan-order-optional-coverage-reselect-message';

  optionalCoverageMessageType = 'plan-order-optional-coverage-message';

  buyerCoverageHeaderMessageType = 'plan-order-optional-coverage-buyer-header';
  sellerCoverageHeaderMessageType = 'plan-order-optional-coverage-seller-header';

  /* ORHP-3017 */
// shows the guest home option modal
  showGuestHomeOptionModal = false;

// shows the guest home warning modal
  showGuestHomeWarningModal = false;

// stores the answer to the guest home modal question
  guestHomeExceedsSizeFlag: boolean = null;


  guestHomeExceedsSizeQuestionType = 'guest-home-exceeds-size-question';
  guestHomeExceedsSizeWarningType = 'guest-home-exceeds-size-warning';
  guestHomeExceedsSizeQuestionMessage: SystemMessage = null;
  guestHomeExceedsSizeWarningMessage: SystemMessage = null;
  optionalCvgDuration = '';
  planCvgDuration = '';




  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }

  get productOfficeToolbox(): ProductOfficeToolbox {
    return this.planOrderAuthService.productOfficeToolbox;
  }




  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private systemService: PhxSystemService,
              private systemMessageLookupService: SystemMessageLookupService,
              private productLookupValueService: ProductLookupValueService,
              private productLookupService: ProductLookupService,
              private productDomainFactoryService: ProductDomainFactoryService,
              private planDomainFactoryService: PlanDomainFactoryService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService,
              private planOrderAuthService: PlanOrderAuthService,
              private planQuoteService: PlanQuoteService) {
    super(planOrderService);
  }


  get product(): Product {
    return this.planOrderApplication.product;
  }




  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'optional-coverage');

    // load system messages
    const messageLookupParams = new SystemMessageLookupParams();
    messageLookupParams.type = this.optionalCoverageMessageType + ',' +
      this.buyerCoverageHeaderMessageType + ',' +
      this.sellerCoverageHeaderMessageType + ',' +
      this.optionalCoverageReselectMessageType + ',' +
      this.guestHomeExceedsSizeQuestionType + ',' +
      this.guestHomeExceedsSizeWarningType;
    messageLookupParams.includeCriteriaFlag = true;


    const observables: Observable<any>[] = [];

    observables.push(this.systemMessageLookupService.systemMessageLookup(messageLookupParams));
    observables.push(this.productLookupService.fetchCoverages(this.product));

    Observable.forkJoin(observables).subscribe((results: any[]) => {
      // handle system messages
      const systemMessages = <SystemMessage[]>results[0];

      // update system messages
      systemMessages.forEach((indexSystemMessage: SystemMessage) => {
        let text = indexSystemMessage.message;

        text = text.replace('${appsPhoneNumber}', this.planOrderAuthService.appsPhoneNumber);

        indexSystemMessage.message = text;
      });

      this.optionalCoverageSystemMessages = ArrayUtility.arrayFindMultiple(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.optionalCoverageMessageType);
      });

      this.optionalCoverageReselectionMessage = ArrayUtility.arrayFind(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.optionalCoverageReselectMessageType);
        }
      );

      this.buyerCoverageHeaderMessage = ArrayUtility.arrayFind(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.buyerCoverageHeaderMessageType);
        }
      );

      this.sellerCoverageHeaderMessage = ArrayUtility.arrayFind(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.sellerCoverageHeaderMessageType);
        }
      );

      this.guestHomeExceedsSizeQuestionMessage = ArrayUtility.arrayFind(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.guestHomeExceedsSizeQuestionType);
        }
      );


      this.guestHomeExceedsSizeWarningMessage = ArrayUtility.arrayFind(
        systemMessages,
        (testMessage: SystemMessage): boolean => {
          return (testMessage.type === this.guestHomeExceedsSizeWarningType);
        }
      );


      // handle optional coverages
      const fetchedCoverages = <ProductCoverage[]>results[1];


      const planOptions = this.planOrderApplication.planOptions;

      const selectableCoverages = [];

      fetchedCoverages.forEach((indexCoverage: ProductCoverage) => {
        const indexOption = ArrayUtility.arrayFind(planOptions, (testOption: PlanOption): boolean => {
          return (testOption.coverageID === indexCoverage.coverageID);
        });

        // create a new selectable product coverage
        const indexSelectableCoverage = new SelectableProductCoverage(
          this.productDomainFactoryService,
          this.planDomainFactoryService,
          indexCoverage,
          indexOption
        );

        selectableCoverages.push(indexSelectableCoverage);

        // should this coverage be selected?
        let shouldCoverageBeSelected = false;

        // if the coverage is included
        if (this.isCoverageIncluded(indexSelectableCoverage)) {
          shouldCoverageBeSelected = true;
        }

        // if the CoverageID was selected
        if (indexOption) {
          shouldCoverageBeSelected = true;
        }

        // if we're selecting the coverage
        if (shouldCoverageBeSelected) {
          this.addCoverage(indexSelectableCoverage);
        }
      });

      this.coverages = selectableCoverages;

      this.coveragesLoadedFlag = true;
    });

  }




  sellersCoverageFlagsToDisplay(): boolean[] {
    const flags = [];

    this.sellerCoverageFlags.forEach((indexFlag: boolean) => {
      if (this.shouldDisplayCoverageSection(indexFlag)) {
        flags.push(indexFlag);
      }
    });

    return flags;
  }




  // retrieves the coverage by ID
  coverageByID(coverageID: number): SelectableProductCoverage {
    const coverage = ArrayUtility.arrayFind(this.coverages, (testCoverage: SelectableProductCoverage): boolean => {
      return (testCoverage.productCoverage.coverageID === coverageID);
    });

    return coverage;
  }



  // is the product selected
  productSelected(): boolean {
    return (this.product !== null);
  }


  // should display coverage section
  shouldDisplayCoverageSection(sellerCoverageFlag: boolean): boolean {
    return (this.coveragesWithSellersFlag(sellerCoverageFlag).length !== 0);
  }


  // should the no sellers coverage message be displayed?
  noSellersCoverageMessage(): string {
    let displayFlag = true;

    // look for sellers coverage; do not display if found
    const hasSellersCoverage = !!ArrayUtility.arrayFind(this.coverages,
      (testCoverage: SelectableProductCoverage): boolean => {

      return testCoverage.productCoverage.coverageType.sellerCoverageFlag;
    });

    if (hasSellersCoverage) {
      displayFlag = false;
    }

    // does the product/office not want messages displayed?
    if (!!this.productOfficeToolbox && this.productOfficeToolbox.hideBuyerSellerCoverageTitleFlag) {
      displayFlag = false;
    }

    // what is the sellers coverage message
    const propertyType = this.planOrderApplication.property.propertyType;
    const noSellersCoverageMessage = propertyType.noSellersCoverageMessage;

    if (!noSellersCoverageMessage) {
      displayFlag = false;
    }

    const message = (displayFlag ? noSellersCoverageMessage : null);

    return message;
  }


  optionReselectionMessage(): string {
    let displayFlag = false;

    // are there any selected coverages?
    let hasSelectedCoveragesFlag = false;

    if (this.planOrderApplication.productCoverages) {
      hasSelectedCoveragesFlag = !!this.planOrderApplication.productCoverages.length;
    }

    // were there previous display codes?
    let hasPreviousDisplayCodesFlag = false;

    if (this.planOrderApplication.selectedDisplayCodes) {
      const selectedDisplayCodes = this.planOrderApplication.selectedDisplayCodes;

      hasPreviousDisplayCodesFlag = (selectedDisplayCodes ? !!selectedDisplayCodes.length : false);
    }


    // if there are no selected coverages, but there are display codes
    if (!hasSelectedCoveragesFlag && hasPreviousDisplayCodesFlag) {
      displayFlag = true;
    }


    // pull the message if neede
    let message: string = null;

    if (displayFlag) {
      message = this.optionalCoverageReselectionMessage.message;
    }

    return message;
  }




  // coverages by sellers flag
  coveragesWithSellersFlag(sellerCoverageFlag: boolean): SelectableProductCoverage[] {
    const coverages = [];

    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexCoverage = indexSelectableCoverage.productCoverage;
      const indexCoverageType = indexCoverage.coverageType;

      let includeCoverageFlag = true;

      // if the sellers coverage category doesn't match
      if (indexCoverageType.sellerCoverageFlag !== sellerCoverageFlag) {
        includeCoverageFlag = false;
      }

      // if the coverage is standard
      if (!indexCoverageType.optionalCoverageFlag) {
        includeCoverageFlag = false;
      }

      if (includeCoverageFlag) {
        coverages.push(indexSelectableCoverage);
      }
    });

    return coverages;
  }


  // section title for sellers coverage flag
  sectionTitleText(sellerCoverageFlag: boolean): string {
    let text: string = null;

    // if we're allowing any title text
    let textFlag = true;

    if (!!this.productOfficeToolbox && this.productOfficeToolbox.hideBuyerSellerCoverageTitleFlag) {
      textFlag = false;
    }

    if (textFlag) {
      if (sellerCoverageFlag) {
        text = this.sellerCoverageHeaderMessage.message;

      } else {
        text = this.buyerCoverageHeaderMessage.message;
      }
    }

    return text;
  }


  // is the coverage selected?
  isCoverageSelected(selectableCoverage: SelectableProductCoverage): boolean {
    return selectableCoverage.selectedFlag;
  }


  // should the coverage be displayed?
  shouldCoverageBeDisplayed(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return coverage.coverageType.optionalCoverageFlag;
  }


  // is the coverage daily?
  isCoverageDaily(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return (coverage.premiumPer && coverage.premiumPer.toUpperCase() === 'D');
  }


  // is the coverage clickable?
  isCoverageClickable(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return (coverage.premium !== 0);
  }


  // is the coverage included?
  isCoverageIncluded(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return coverage.includedCoverageFlag;
  }


  // does the coverage have prerequisites?
  doesCoverageHavePrerequisites(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return (coverage.coveragePrerequisites.length > 0);
  }


  // should display coverage detail?
  shouldDisplayCoverageDetail(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    return (coverage.coverageDetail !== '');
  }


  // returns the coverages that have the specified coverage as a prerequisite
  coveragesWithPrerequisite(selectableCoverage: SelectableProductCoverage): SelectableProductCoverage[] {
    const selectableCoverages: SelectableProductCoverage[] = [];

    // loop over all displayed coverages
    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexCoverage = indexSelectableCoverage.productCoverage;

      // loop over all of the prerequisites for the specified coverage
      indexCoverage.coveragePrerequisites.forEach((indexPrerequisiteCoverageID: number) => {

        // if the displayed coverage matches the specified coverage
        if (indexPrerequisiteCoverageID === selectableCoverage.productCoverage.coverageID) {
          selectableCoverages.push(indexSelectableCoverage);
        }
      });
    });

    return selectableCoverages;
  }



  // returns the prerequisite coverages for the specified coverage
  prerequisitesForCoverage(selectableCoverage: SelectableProductCoverage): SelectableProductCoverage[] {
    const prerequisites: SelectableProductCoverage[] = [];

    // loop over all displayed coverages
    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexCoverage = indexSelectableCoverage.productCoverage;

      // loop over all of the prerequisites for the specified coverage
      selectableCoverage.productCoverage.coveragePrerequisites
        .forEach((indexPrerequisiteCoverageID: number) => {

        // if the displayed coverage matches the specified coverage
        if (indexCoverage.coverageID === indexPrerequisiteCoverageID) {
          prerequisites.push(indexSelectableCoverage);
        }
      });
    });

    return prerequisites;
  }



  allowPlanQuote(): boolean {
    let flag = true;

    if (!!this.productOfficeToolbox && !this.productOfficeToolbox.allowPlanQuoteFlag) {
      flag = false;
    }

    return flag;
  }




  addCoverage(coverage: SelectableProductCoverage) {
    coverage.selectedFlag = true;

    // if we need to create a new PlanOption
    if (!coverage.planOption) {
      const planOption = this.planDomainFactoryService.newPlanOption();

      planOption.coverageID = coverage.coverageID;
      planOption.optionUnits = 1;
      planOption.optionAmount = coverage.premium;

      coverage.planOption = planOption;
    }
  }





  removeCoverage(coverage: SelectableProductCoverage) {
    coverage.selectedFlag = false;

    coverage.planOption = null;
  }




  shouldEnableOptionUnitSelector(coverage: SelectableProductCoverage): boolean {
    let enable = true;

    if (!this.isCoverageSelected(coverage)) {
      enable = false;
    }

    return enable;
  }

  // the user clicked YES it's too big
  didClickGuestHomeOptionYes() {
    // hide the guest home option modal
    this.showGuestHomeOptionModal = false;

    // store that it's too big so they can't continue
    this.guestHomeExceedsSizeFlag = true;

    // show the warning modal
    this.showGuestHomeWarningModal = true;
  }

  didClickGuestHomeOptionNo() {
    // hide the guest home option modal
    this.showGuestHomeOptionModal = false;

    // store that it's not too big so they can continue
    this.guestHomeExceedsSizeFlag = false;

    // run the Next button function again, it'll work since we've set this.guestHomeExceedsSizeFlag to false
    this.didClickNextButton();
  }

  // the user clicked the warning modal button to dismiss
  didClickGuestHomeWarningOKButton() {
    // hide the guest home option modal
    this.showGuestHomeWarningModal = false;
  }


  // the user clicked a coverage
  didClickCoverage(selectableCoverage: SelectableProductCoverage): void {
    this.coverageWithPrerequisitesAttempted = null;


    // only make changes if the coverage is clickable
    if (this.isCoverageClickable(selectableCoverage)) {

      // if the coverage is selected, deselect it
      if (this.isCoverageSelected(selectableCoverage)) {

        this.removeCoverage(selectableCoverage);

        // loop over the child coverages and deselect them
        const childCoverages = this.coveragesWithPrerequisite(selectableCoverage);

        childCoverages.forEach((indexChildCoverage: SelectableProductCoverage) => {
          this.removeCoverage(indexChildCoverage);
        });

      // if the coverage is not selected, select it
      } else {

        // pull prerequisites for the coverage
        const prerequisites = this.prerequisitesForCoverage(selectableCoverage);

        // if there's 0 or 1 prerequisite, auto-select it
        if (prerequisites.length <= 1) {
          this.addCoverage(selectableCoverage);

          prerequisites.forEach((indexPrerequisiteCoverage: SelectableProductCoverage) => {
            this.addCoverage(indexPrerequisiteCoverage);
          });

        // for multiple prerequisites
        } else {
          // are any selected?
          let prerequisiteSelected = false;

          prerequisites.forEach((indexPrerequisiteCoverage: SelectableProductCoverage) => {
            if (indexPrerequisiteCoverage.selectedFlag) {
              prerequisiteSelected = true;
            }
          });

          // if at least one prerequisite was selected, allow the selection
          if (prerequisiteSelected) {
            this.addCoverage(selectableCoverage);

          // if no prerequisites, flash an alert for this coverage
          } else {
            // delay this call so that the browser refreshes appropriately
            setTimeout(() => {
              this.coverageWithPrerequisitesAttempted = selectableCoverage;

              setTimeout(() => {
                this.coverageWithPrerequisitesAttempted = null;
              }, 6000);
            }, 100);
          }
        }
      }
    }
  }


  shouldDisplayCoveragePrerequisiteAlert(selectableCoverage: SelectableProductCoverage): boolean {
    const coverages = this.coveragesWithPrerequisite(selectableCoverage);

    let displayFlag = false;

    if (this.coverageWithPrerequisitesAttempted) {
      coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
        if (indexSelectableCoverage.coverageID === this.coverageWithPrerequisitesAttempted.productCoverage.coverageID) {
          displayFlag = true;
        }
      });
    }

    return displayFlag;
  }



  // should the coverage total row be displayed
  shouldDisplayCoverageTotalRow(sellerCoverageFlag: boolean): boolean {
    const displayFlag = !sellerCoverageFlag;

    return displayFlag;
  }


  // returns the coverage total
  coverageTotalAmount(): number {
    let totalAmount = this.product.premium;

    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexCoverage = indexSelectableCoverage.productCoverage;
      const indexOption = indexSelectableCoverage.planOption;

      // if the coverage is selected, and it's not daily
      let includeAmountFlag = true;
      includeAmountFlag = includeAmountFlag && this.isCoverageSelected(indexSelectableCoverage);
      includeAmountFlag = includeAmountFlag && !indexCoverage.dailyFlag;
      includeAmountFlag = includeAmountFlag && !!indexOption;

      if (includeAmountFlag) {
        const optionUnits = indexOption.optionUnits || 1;

        totalAmount += (indexCoverage.premium * optionUnits);
      }
    });

    return totalAmount;
  }


  // coverage total amount text
  formattedCoverageTotalAmount(): string {
    const totalAmount = this.coverageTotalAmount();

    const totalAmountText = StringUtility.formatDollarNoDecimal(totalAmount);
    return totalAmountText;
  }


  // user clicked to select a different product
  didClickDifferentProduct(): void {
    const url = this.router.routerState.snapshot.url;
    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, 'optional-coverage');

    const urlSegment = new UrlSegment('product', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }




  // should the option unit selector be displayed
  shouldDisplayOptionUnitSelector(selectableCoverage: SelectableProductCoverage): boolean {
    const coverage = selectableCoverage.productCoverage;

    let flag = true;

    // if the option units aren't editable
    if (!coverage.optionUnitsEditableFlag) {
      flag = false;
    }

    // if the coverage is included
    if (coverage.includedCoverageFlag) {
      flag = false;
    }

    // test by property type
    const propertyType = this.planOrderApplication.property.propertyType;

    if (!propertyType.multipleOptionUnitFlag) {
      flag = false;
    }

    return flag;
  }



  // should the print error display
  shouldDisplayQuotePrintError(): boolean {
    return (this.quoteStatus === QuoteStatus.printError);
  }




  // user clicked the Print Quote button
  didClickPrintQuoteButton(): void {
    this.generatePrintQuote();
  }


  // user clicked the Email Quote button
  didClickEmailQuoteButton(): void {
    this.emailQuoteRequest = this.generateQuoteRequest();

    this.quoteStatus = QuoteStatus.emailPrompt;
  }


  // should the plan quote email box be shown
  shouldDisplayEmailQuoteBox(): boolean {
    return (this.quoteStatus === QuoteStatus.emailPrompt);
  }



  emailQuoteCompleteHandler(resultFlag: boolean) {
    this.quoteStatus = QuoteStatus.none;
  }




  optionUnitsByCoverageID(): any {
    let map = {};

    if (this.coverages) {
      map = ArrayUtility.arrayToMap(this.coverages, (item: SelectableProductCoverage): number => {
        return item.coverageID;
      });
    }

    return map;
  }





  // generates the quote to the specified recipient
  generateQuoteRequest(): PlanQuoteRequest {

    // creates the request
    const request = this.planQuoteService.newPlanQuoteRequest();

    // populate simple values
    request.product = this.planOrderApplication.product;
    request.propertyType = this.planOrderApplication.property.propertyType;

    // add coverages
    const selectedCoverages = [];

    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexCoverage = indexSelectableCoverage.productCoverage;

      let includeCoverageFlag = false;

      if (this.isCoverageSelected(indexSelectableCoverage)) {
        includeCoverageFlag = true;
      }

      if (indexCoverage.includedCoverageFlag) {
        includeCoverageFlag = false;
      }

      if (includeCoverageFlag) {
        selectedCoverages.push(indexCoverage);
      }
    });

    request.productCoverages = selectedCoverages;

    // include option units by CoverageID
    request.optionUnitsByCoverageID = this.optionUnitsByCoverageID();


    return request;
  }



  // generate a print quote
  generatePrintQuote() {
    // send Google Analytics tracking
    this.systemService.sendGoogleAnalyticsTracking('/plan-quote/print');

    // generate a plan quote request
    const request = this.generateQuoteRequest();

    // open a separate window
    const quoteWindow = window.open();

    // perform the quote fetch
    this.planQuoteService.fetchPlanQuote(request).subscribe((result: PlanQuoteResult) => {

      // if the result was successful, populate the window
      if (result && (result.quoteHtml !== '')) {
        quoteWindow.document.write(result.quoteHtml);

        quoteWindow.document.close();

      } else {
        quoteWindow.close();
      }
    });
  }


  messagesForSelectedCoverages(): string[] {
    const messages: string[] = [];

    const property = this.planOrderApplication.property;
    const propertyTypeCode = property.propertyType.propertyType;

    if (this.optionalCoverageSystemMessages) {
      // loop over system messages
      this.optionalCoverageSystemMessages.forEach((indexSystemMessage: SystemMessage) => {
        // loop over all coverages
        this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
          // if the coverage is selected
          if (this.isCoverageSelected(indexSelectableCoverage)) {
            let criteriaFlag = true;

            // test for state criteria
            if (indexSystemMessage.hasStateCodeCriteria()) {
              if (!indexSystemMessage.hasStateCodeCriteria(property.stateCode)) {
                criteriaFlag = false;
              }
            }

            // test for property type criteria
            if (indexSystemMessage.hasPropertyTypeCriteria()) {
              if (!indexSystemMessage.hasPropertyTypeCriteria(propertyTypeCode)) {
                criteriaFlag = false;
              }
            }

            // test for display code criteria
            if (indexSystemMessage.hasDisplayCodeCriteria()) {
              const displayCode = indexSelectableCoverage.productCoverage.displayCode;

              if (!indexSystemMessage.hasDisplayCodeCriteria(displayCode)) {
                criteriaFlag = false;
              }
            }

            if (criteriaFlag) {
              messages.push(indexSystemMessage.message);
            }
          }
        });
      });
    }

    return messages;
  }




  shouldDisplayNextButton(): boolean {
    let displayFlag = true;

    const role = this.planOrderApplication.role;

    if (role && role.onlyQuoteFlag) {
      displayFlag = false;
    }

    if (!this.coveragesLoadedFlag) {
      displayFlag = false;
    }

    return displayFlag;
  }



  // user clicked the Next button
  didClickNextButton(): void {
    this.quoteStatus = QuoteStatus.none;

    const selectedPlanOptions = [];
    const selectedCoverages = [];

    // is one of the guest home options selected? we're looking for guest home display codes
    let guestHomeSelectedFlag = false;
    const guestHomeDisplayCodes = ['$', '$$', '$$$'];


    this.coverages.forEach((indexSelectableCoverage: SelectableProductCoverage) => {
      const indexOption = indexSelectableCoverage.planOption;
      const indexCoverage = indexSelectableCoverage.productCoverage;

      let persistFlag = true;

      if (!indexSelectableCoverage.selectedFlag) {
        persistFlag = false;
      }

      if (!indexCoverage.coverageType.optionalCoverageFlag) {
        persistFlag = false;
      }

      if (persistFlag) {
        selectedCoverages.push(indexCoverage);
        selectedPlanOptions.push(indexOption);

        // if this is a guest home display code, set the flag to true
        if (guestHomeDisplayCodes.indexOf(indexCoverage.displayCode) > -1  ) {
          guestHomeSelectedFlag = true;
        }
      }
    });

    // if the guest home exceeds the size, show the warning modal and stop
    if (guestHomeSelectedFlag && this.guestHomeExceedsSizeFlag) {
      this.showGuestHomeWarningModal = true;
      return;
    }

    // if the guest home is selected and the guest home exceeds question isn't answered, show the option modal
    if (guestHomeSelectedFlag && (this.guestHomeExceedsSizeFlag === null)) {
      this.showGuestHomeOptionModal = true;
      return;
    }

    this.planOrderApplication.planOptions = selectedPlanOptions;
    this.planOrderApplication.productCoverages = selectedCoverages;


    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();
    const url = '../' + nextRoute;

    this.router.navigate([url], { relativeTo: this.route });
  }

  displayOptionalCvgDuration(selectableCoverage: SelectableProductCoverage): boolean {
    let displayDuration = false;

    if (!!selectableCoverage && this.isCoverageDaily(selectableCoverage)) {

      this.optionalCvgDuration = DurationEnum.D;
      displayDuration = true;

    } else if (!selectableCoverage && !!this.productOfficeToolbox && !this.productOfficeToolbox.allowPremiumPayLaterFlag &&
        !!DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode]) {

      this.planCvgDuration = DurationEnum[this.productOfficeToolbox.priceDisplayDurationCode].toString();
      displayDuration = true;

    }

    return displayDuration;
  }


  protected readonly Number = Number;
}




class SelectableProductCoverage {

  // selected?
  private _selectedFlag = false;

  get selectedFlag(): boolean {
    return this._selectedFlag;
  }

  set selectedFlag(selectedFlag: boolean) {
    if (selectedFlag) {

    } else {

    }

    this._selectedFlag = selectedFlag;
  }


  // misc vars
  productDomainFactoryService: ProductDomainFactoryService = null;
  planDomainFactoryService: PlanDomainFactoryService = null;
  productCoverage: ProductCoverage = null;
  planOption: PlanOption = null;


  get coverageID(): number {
    return this.productCoverage.coverageID;
  }

  get premium(): number {
    return this.productCoverage.premium;
  }

  get coverageDescription(): string {
    return this.productCoverage.coverageDescription;
  }

  get coverageDetail(): string {
    return this.productCoverage.coverageDetail;
  }

  get optionUnits(): number {
    return this.planOption.optionUnits;
  }

  set optionUnits(optionUnits: number) {
    this.planOption.optionUnits = optionUnits;
  }


  constructor(productDomainFactoryService: ProductDomainFactoryService,
              planDomainFactoryService: PlanDomainFactoryService,
              productCoverage: ProductCoverage,
              planOption: PlanOption) {
    this.productDomainFactoryService = productDomainFactoryService;
    this.planDomainFactoryService = planDomainFactoryService;
    this.productCoverage = productCoverage;
    this.planOption = planOption;
  }




}



enum QuoteStatus {
  none = 10,
  emailPrompt = 20,
  emailSuccess = 30,
  printInProgress = 40,
  printSuccess = 50,
  printError = 60
}



enum QuoteRecipient {
  email = 10,
  print = 20
}

