
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlanLookupService} from '@orhp/phx-plan-ui-module';
import {PlanLookupValueService} from '@orhp/phx-plan-ui-module';
import {Plan} from '@orhp/phx-plan-ui-module';
import {Property} from '@orhp/phx-property-ui-module';
import {PropertyDomainFactoryService} from '@orhp/phx-property-ui-module';
import {ActivatedRoute, NavigationEnd, Router, UrlSerializer} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {PlanCounts, PlanCountStatus} from '@orhp/phx-plan-ui-module';
import {AuthenticationService} from '../../login/service/authentication.service';
import {Customer} from '@orhp/phx-customer-ui-module';
import {PlanLookupParams} from '@orhp/phx-plan-ui-module';
import {PlanDomainFactoryService} from '@orhp/phx-plan-ui-module';
import {Subscriber} from 'rxjs/Subscriber';
import {Event} from '@angular/router';
import {SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {PhxLoginService} from '@orhp/phx-common-ui-module';
import {MyWarrantiesService} from './my-warranties.service';
import {PlanStatus} from '@orhp/phx-plan-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {Subscription} from 'rxjs/Subscription';
import {PlanOrderService} from '../../_modules/plan-order/_services';
import {PhxSystemService} from '@orhp/phx-common-ui-module';
import {UserService} from '../../login/service';
import {Response} from '@angular/http';
import {observable} from 'rxjs';
import {ProductOfficeToolbox} from '@orhp/phx-product-ui-module';
import {ToolboxSettingsService} from '../../_services/toolbox-settings.service';





@Component({
  selector: 'app-my-warranties',
  moduleId: module.id,
  templateUrl: 'my-warranties.component.html',
  styleUrls: [
    './my-warranties.component.scss'
  ]
})

export class MyWarrantiesComponent implements OnInit, OnDestroy {

  initialPlanLoad = false;
  pageStatus = PageStatus.loadInProgress;

  plans: Plan[] = null;

  filteredSearchWasRun = false;
  filteredSearchWasRunWithTerritory = false;

  territories: String[] = [];
  guestGUID: String = null;

  // filter by status
  planStatusQuickStats: PlanStatusQuickStats[] = null;
  selectedQuickStats: PlanStatusQuickStats = null;


  // search type
  private _searchType: SearchType = null;
  private _longTermFlag: boolean = null;
  private _territorySearchResultsFlag: boolean = null;

  get longTermFlag(): boolean {

    if (this._longTermFlag == null) {
      this._longTermFlag = this.myWarrantiesService.lastLongTermFlag;
    }

    return this._longTermFlag || false;
  }

  set longTermFlag(longTermFlag: boolean) {
    this._longTermFlag = longTermFlag;

    this.myWarrantiesService.lastLongTermFlag = longTermFlag;
  }

  get territorySearchResultsFlag(): boolean {

    if (this._territorySearchResultsFlag == null) {
      this._territorySearchResultsFlag = this.myWarrantiesService.lastTerritorySearchResultsFlag;
    }

    return this._territorySearchResultsFlag || false;
  }

  set territorySearchResultsFlag(territorySearchResultsFlag: boolean) {
    this._territorySearchResultsFlag = territorySearchResultsFlag;

    this.myWarrantiesService.lastTerritorySearchResultsFlag = territorySearchResultsFlag;
  }

  get searchType(): SearchType {
    if (!this._searchType) {
      this._searchType = this.myWarrantiesService.lastSearchType;
    }

    if (!this._searchType) {
      this._searchType = SearchType.propertyAddress;
    }

    return this._searchType;
  }

  set searchType(searchType: SearchType) {
    this._searchType = searchType;

    this.myWarrantiesService.lastSearchType = searchType;
  }

  get searchTextTrimmed(): string {
    return this.searchText.trim();
  }

  // dashboard plan counts
  private _planCounts: PlanCounts = null;

  get planCounts(): PlanCounts {
    return this._planCounts;
  }

  set planCounts(planCounts: PlanCounts) {
    this._planCounts = planCounts;

    const quickStats: PlanStatusQuickStats[] = [];

    quickStats.push({
      code: 'paid',
      description: 'Paid',
      formattedPlanCount: planCounts.formattedPaidPlanCount,
      statusFilter: planCounts.paidPlanStatus
    });

    quickStats.push({
      code: 'unpaid',
      description: 'Unpaid',
      formattedPlanCount: planCounts.formattedUnpaidPlanCount,
      statusFilter: planCounts.unpaidPlanStatus
    });

    quickStats.push({
      code: 'expired',
      description: 'Expired',
      formattedPlanCount: planCounts.formattedExpiredPlanCount,
      statusFilter: planCounts.expiredPlanStatus
    });

    quickStats.push({
      code: 'cancelled',
      description: 'Cancelled',
      formattedPlanCount: planCounts.formattedCancelledPlanCount,
      statusFilter: planCounts.cancelledPlanStatus
    });

    quickStats.push({
      code: 'total',
      description: 'Total',
      formattedPlanCount: planCounts.formattedTotalPlanCount,
      statusFilter: null
    });

    this.planStatusQuickStats = quickStats;

    if (!this.selectedQuickStats) {
      quickStats.forEach((indexQuickStats: PlanStatusQuickStats) => {
        if (indexQuickStats.code === 'total') {
          this.selectedQuickStats = indexQuickStats;
        }
      });
    }
  }


  // filtered plan count
  private _filteredPlanCount = 0;

  get filteredPlanCount(): number {
    return this._filteredPlanCount;
  }

  set filteredPlanCount(filteredPlanCount: number) {
    if (this._filteredPlanCount !== filteredPlanCount) {
      this._filteredPlanCount = filteredPlanCount;
    }
  }



  get productOfficeToolbox(): ProductOfficeToolbox {
    return (!!this.toolboxSettingsService.productOffice ? this.toolboxSettingsService.productOffice.toolbox : null);
  }





  propertiesByID: Map<number, Property> = null;

  plansPerPage = 15;

  longTermMonths: number = null;
  shortTermMonths: number = null;
  currentMonths: number = null;

  pageChangeSubscription: Subscription = null;


  /**
   * Returns the logged-in customer
   */
  get customer(): Customer {
    return this.authenticationService.customer;
  }


  // search text
  private _searchText: string = null;

  get searchText(): string {
    if (!this._searchText) {
      if (this.myWarrantiesService.lastSearchText) {
        this._searchText = String(this.myWarrantiesService.lastSearchText);
      }
    }

    if (!this._searchText) {
      this._searchText = '';
    }

    return this._searchText;
  }

  set searchText(searchText: string) {
    if (this._searchText !== searchText) {
      this._searchText = searchText;

      this.myWarrantiesService.lastSearchText = this._searchText;
    }
  }



  get urlPageNumber(): string {
    return this.route.snapshot.params['pageNumber'];
  }


  // what is the current page number
  get currentPageNumber(): number {
    const pageNumberText = <any>this.urlPageNumber;

    let pageNumber = (isNaN(pageNumberText) ? null : Number(pageNumberText));

    // if a page number wasn't specified
    if (pageNumber === null) {
      // if there was a last page number loaded
      if (this.myWarrantiesService.lastPageNumberLoaded) {
        pageNumber = this.myWarrantiesService.lastPageNumberLoaded;

      // otherwise default to page 1
      } else {
        pageNumber = 1;
      }
    }

    // validate the page number
    if (pageNumber < 1) {
      pageNumber = 1;

    } else if (pageNumber > this.totalPageCount) {
      pageNumber = this.totalPageCount;
    }

    return pageNumber;
  }


  // what's the total page count
  get totalPageCount(): number {
    // how many plans total
    const totalPlanCount = this.filteredPlanCount || 0;

    // how many pages
    const totalPageCount = Math.ceil(totalPlanCount / this.plansPerPage);

    return totalPageCount;
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private loginService: PhxLoginService,
              private systemService: PhxSystemService,
              private planOrderService: PlanOrderService,
              private planLookupService: PlanLookupService,
              private authenticationService: AuthenticationService,
              private planLookupValueService: PlanLookupValueService,
              private planDomainFactoryService: PlanDomainFactoryService,
              private userService: UserService,
              private propertyDomainFactoryService: PropertyDomainFactoryService,
              private urlSerializer: UrlSerializer,
              private myWarrantiesService: MyWarrantiesService,
              private toolboxSettingsService: ToolboxSettingsService
  ) {
  }



  ngOnInit() {
    // scroll to the top of the window
    window.scrollTo(0, 0);

    // clear the prior plan
    this.planOrderService.planOrderApplication = null;

    // set that we're loading plans
    this.pageStatus = PageStatus.loadInProgress;

    // subscribe to a page change event
    this.pageChangeSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.fetchPlans(true).subscribe((planFetchResultFlag: boolean) => {
          // do nothing
        });
      }
    });


    // fetch user territories and the current guest
    const observables = [];
    observables.push(this.fetchUserTerritories());
    observables.push(this.fetchCurrentGuest());

    Observable.forkJoin(observables).subscribe((results: any[]) => {

      // how are we retrieving plan counts?
      let filteredPlanCountObservable: Observable<PlanCounts> = null;

      // if there's search text, retrieve the filtered plan counts
      if (this.searchTextTrimmed !== '') {
        filteredPlanCountObservable = this.fetchPlanCounts(true);

        // if there's no search text, use the total plan counts
      } else {
        filteredPlanCountObservable = Observable.create((subscriber: Subscriber<PlanCounts>) => {
          subscriber.next(null);
          subscriber.complete();
        });
      }

      // if we're on page 1 and there's no search text
      const firstSearchFlag = (this.urlPageNumber === '1');

      // execute the first search
      if (firstSearchFlag) {
        const planObservables = [];

        // fetch all plan counts
        planObservables.push(this.fetchPlanCounts(false));
        planObservables.push(filteredPlanCountObservable);
        planObservables.push(this.fetchPlans(false));

        Observable.forkJoin(planObservables).subscribe((planResults: any[]) => {
          // pull all plan counts
          const planCounts = (planResults[0] as PlanCounts);
          this.planCounts = planCounts;

          // pull the filtered plan counts
          const filteredPlanCounts = (planResults[1] as PlanCounts) || planCounts;
          this.filteredPlanCount = filteredPlanCounts.totalPlanCount;

          // set that the plans have been loaded
          this.initialPlanLoad = true;
        });

      // execute the normal search
      } else {
        // fetch plan counts
        const planObservables = [];

        planObservables.push(this.fetchPlanCounts(false));
        planObservables.push(filteredPlanCountObservable);

        Observable.forkJoin(planObservables).subscribe((planResults: any[]) => {
          // pull the total plan counts
          const planCounts = (planResults[0] as PlanCounts);
          this.planCounts = planCounts;

          // pull the filtered plan counts
          const filteredPlanCounts = (planResults[1] as PlanCounts) || planCounts;
          this.filteredPlanCount = filteredPlanCounts.totalPlanCount;

          // if the current page is more than the page total
          const urlPageNumber = ((this.urlPageNumber || '') !== '' ? Number(this.urlPageNumber) : null);

          if (urlPageNumber && (urlPageNumber > this.totalPageCount)) {
            this.gotoPageNumber(this.totalPageCount);
          }

          // fetch the filtered plans
          this.fetchPlans(true).subscribe((planFetchResultFlag: boolean) => {
            this.initialPlanLoad = true;
          });
        });
      }
    });

    this.longTermMonths = this.planLookupValueService.monthsByLongTermStatus(true);
    this.shortTermMonths = this.planLookupValueService.monthsByLongTermStatus(false);

    if (this.longTermFlag) {
      this.currentMonths = this.longTermMonths;

    } else {
     this.currentMonths = this.shortTermMonths;
    }

  }


  ngOnDestroy() {
    this.pageChangeSubscription.unsubscribe();
  }

  fetchUserTerritories(): Observable<boolean> {

    const observable = Observable.create(( subscriber: Subscriber<boolean>) => {


      this.userService.loadCurrentUserTerritories().subscribe(
        response => {
          if (response) {

            const territoryObjects = response.json();
            this.territories = territoryObjects.map(indexTerritory => indexTerritory.territory);

            subscriber.next(true);
            subscriber.complete();

          } else {

            subscriber.next(false);
            subscriber.complete();

          }
        }

      );
    });

    return observable;

  }

  fetchCurrentGuest(): Observable<boolean> {

    const observable = Observable.create(( subscriber: Subscriber<boolean>) => {


      this.userService.loadCurrentGuest().subscribe( (response: Response)  => {
        if (response) {
          this.guestGUID = response.json().id;
          subscriber.next(true);
          subscriber.complete();

        } else {

          subscriber.next(false);
          subscriber.complete();

        }
      });


    });

    return observable;

  }

  isPlanSearchSelected(): boolean {
    return (this.searchType === SearchType.planNumber);
  }


  didClickPlanSearch() {
    this.searchType = SearchType.planNumber;

    this.searchText = '';
    this.territorySearchResultsFlag = false;
  }

  shouldDisplayTerritoryBox(): boolean {
    const employer = (this.customer ? this.customer.employer : undefined);
    const employerType = (employer ? employer.employerType : undefined);
    const orhpFlag = (employerType ? employerType.orhpFlag : false);

    if (orhpFlag && this.territories.length > 0) {
       return true;
    }
    return false;
  }
  didClickToggleTerritorySearchResults() {
    if (this.searchText.length > 0) {
      this.territorySearchResultsFlag = !this.territorySearchResultsFlag;
    } else {
      this.territorySearchResultsFlag = false;
    }
  }


  isPropertyAddressSearchSelected(): boolean {
    return (this.searchType === SearchType.propertyAddress);
  }


  didClickPropertyAddressSearch() {
    this.searchType = SearchType.propertyAddress;

    this.searchText = '';
    this.territorySearchResultsFlag = false;
  }


  isCustomerSearchSelected(): boolean {
    return (this.searchType === SearchType.customer);
  }


  didClickCustomerSearch() {
    this.searchType = SearchType.customer;

    this.searchText = '';
    this.territorySearchResultsFlag = false;
  }


  didSelectLongTerm() {
    this.pageStatus = PageStatus.loadInProgress;
    this.longTermFlag = true;
    this.currentMonths = this.longTermMonths;
    this.reloadResults();
  }


  didSelectShortTerm() {
    this.pageStatus = PageStatus.loadInProgress;
    this.longTermFlag = false;
    this.currentMonths = this.shortTermMonths;
    this.reloadResults();
  }


  // populate search params
  searchTextIntoParams(params: PlanLookupParams) {
    const searchText = (this.searchTextTrimmed || '');

    if (searchText !== '') {
      switch (this.searchType) {
        case SearchType.planNumber:
          params.planNumberLike = searchText;
          break;

        case SearchType.date:
          params.planDate = StringUtility.parseDate(searchText);
          break;

        case SearchType.propertyAddress:
          params.propertyAddressLike = searchText;
          break;

        case SearchType.customer:
          params.customerLike = searchText;
          break;
      }
    }
  }

  reloadResults() {
    const hasSearchTextFlag = (this.searchTextTrimmed || '') !== '';
    const quickStatFlag = (this.selectedQuickStats.code.toLocaleLowerCase() !== 'total');

    // Make sure to pass the search flag if there is search text, or a quickStat is selected
    const passSearchFlag = hasSearchTextFlag || quickStatFlag;

    if (passSearchFlag) {
      // Only reload total counts separately if there is a search term and switching period of months

      this.fetchPlanCounts(false).subscribe((planCounts: PlanCounts) => {
        this.planCounts = planCounts;
      });

    }

    // go to the first page
    this.gotoPageNumber(1);

    // First fetch plan counts for paging purposes
    this.fetchPlanCounts(passSearchFlag).subscribe((planCounts: PlanCounts) => {

      // if this is not a search update the total counts
      if (!passSearchFlag) {
        this.planCounts = planCounts;
      }

      // update the filtered counts for paging purposes
      this.filteredPlanCount = planCounts.totalPlanCount;

      // fetch the plans to display
      this.fetchPlans(passSearchFlag).subscribe((fetchResultFlag: boolean) => {

      });

    });

  }


  fetchPlanCounts(searchTextFlag?: boolean): Observable<PlanCounts> {
    const planFetchParams = this.planDomainFactoryService.newPlanLookupParams();

    if (searchTextFlag) {
      const hasSearchText = (this.searchTextTrimmed || '') !== '';
      this.searchTextIntoParams(planFetchParams);

      planFetchParams.totalCountsOnlyFlag = true;

      if (this.selectedQuickStats) {
        planFetchParams.status = this.selectedQuickStats.statusFilter;
      }


      if (hasSearchText && this.territorySearchResultsFlag) {
        planFetchParams.territoryResultsFlag = true;
      }
    }


    if (this.longTermFlag) {

      planFetchParams.longTermResultsFlag = true;

    }

    return this.planLookupService.fetchPlanCounts(planFetchParams);
  }





  // fetches plans
  fetchPlans(searchTextFlag?: boolean): Observable<boolean> {
    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {
      this.pageStatus = PageStatus.loadInProgress;

      // fetches plans
      const planFetchParams = this.planDomainFactoryService.newPlanLookupParams();
      planFetchParams.toolboxUserRelationshipFlag = true;
      planFetchParams.propertyFlag = true;
      planFetchParams.maxPlanCount = this.plansPerPage;
      planFetchParams.startAtOffset = (this.currentPageNumber - 1) * this.plansPerPage;

      if (planFetchParams.startAtOffset < 0) {
        planFetchParams.startAtOffset = 0;
      }

      if (this.longTermFlag) {
        planFetchParams.longTermResultsFlag = true;
      }

      // if there is search text
      if (searchTextFlag) {
        this.searchTextIntoParams(planFetchParams);

        const hasSearchText = (this.searchTextTrimmed || '') !== '';
        this.filteredSearchWasRun = hasSearchText;
        this.filteredSearchWasRunWithTerritory = false;

        if (hasSearchText && this.territorySearchResultsFlag) {
          planFetchParams.territoryResultsFlag = true;
          this.filteredSearchWasRunWithTerritory = true;
          planFetchParams.toolboxPlanOrdererRoleFlag = true;
        }

        if (this.selectedQuickStats) {
          planFetchParams.status = this.selectedQuickStats.statusFilter;
        }

      } else {
        this.filteredSearchWasRun = false;
        this.filteredSearchWasRunWithTerritory = false;
      }

      this.planLookupService.fetchPlans(planFetchParams).subscribe((plans: Plan[]) => {
        this.propertiesByID = new Map<number, Property>();

        this.plans = plans;

        // what page status should we use?
        this.pageStatus = (this.plans.length ? PageStatus.loadSuccess : PageStatus.loadEmpty);

        subscriber.next(true);
        subscriber.complete();
      });
    });

    return observable;
  }

  shouldDisplayTerritoryWarning(): boolean {
    if (this.searchTextTrimmed.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  shouldDisplayTerritoryEditWarning(): boolean {
    if (this.searchTextTrimmed.length > 0 && this.territorySearchResultsFlag) {
      return true;
    } else {
      return false;
    }
  }

  // should the clear search be displayed
  shouldDisplayClearSearchButton(): boolean {
    return this.filteredSearchWasRun;
  }


  // user clicked to clear the search
  didClickClearSearch() {
    this.searchText = '';
    this.territorySearchResultsFlag = false;
    this.filteredSearchWasRunWithTerritory = false;

    this.planStatusQuickStats.forEach((indexQuickStats: PlanStatusQuickStats) => {
      if (indexQuickStats.code.toLowerCase() === 'total') {
        this.selectedQuickStats = indexQuickStats;
      }
    });

    this.didClickSearch();
  }


  // is the quick stats filter selected?
  isQuickStatsFilterSelected(quickStat: PlanStatusQuickStats): boolean {
    let flag = false;

    if (this.selectedQuickStats) {
      flag = (this.selectedQuickStats.code === quickStat.code);
    }

    return flag;
  }


  shouldShowTerritoryResultFlagChecked(): boolean {
    if (this.territorySearchResultsFlag && this.searchTextTrimmed !== '') {
     return true;
    } else {
      return false;
    }
  }


  // the user clicked the status filter
  didClickStatusFilter(quickStat: PlanStatusQuickStats) {
    this.selectedQuickStats = quickStat;

    this.territorySearchResultsFlag = false;
    this.filteredSearchWasRunWithTerritory = false;

    this.didClickSearch();
  }




  shouldDisplayAddChangeClosingInfoText(): boolean {
    let flag = true;

    if (!!this.productOfficeToolbox && (this.productOfficeToolbox.productOfficeID === 414)) {
      flag = false;
    }

    return flag;
  }



  // the user changed the search text
  didClickSearch() {
    let searchCode: string = null;


    if (this.searchTextTrimmed === '') {
      searchCode = 'all';

    } else {
      switch (this.searchType) {
        case SearchType.customer:
          searchCode = 'customer';
          break;

        case SearchType.date:
          searchCode = 'date';
          break;

        case SearchType.planNumber:
          searchCode = 'plan-number';
          break;

        case SearchType.propertyAddress:
          searchCode = 'property-address';
          break;
      }

      this.filteredPlanCount = null;
    }

    if (searchCode) {
      this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/search-by-' + searchCode);
    }

    this.pageStatus = PageStatus.loadInProgress;

    // goto the first page
    this.gotoPageNumber(1);

    const observables = [];

    observables.push(this.fetchPlanCounts(true));
    observables.push(this.fetchPlans(true));

    Observable.forkJoin(observables).subscribe((results: any[]) => {
      const planCounts = (results[0] as PlanCounts);
      this.filteredPlanCount = planCounts.totalPlanCount;

      const planFetchResultFlag = (results[1] as boolean);
      this.pageStatus = (this.plans.length ? PageStatus.loadSuccess : PageStatus.loadEmpty);
    });

  }





  shouldDisplayPlanCounts(): boolean {
    return !!this.planCounts;
  }


  shouldDisplayPageSelector(): boolean {
    return !this.shouldDisplayLoadInProgress();
  }


  shouldDisplayLoadInProgress(): boolean {
    return (this.pageStatus === PageStatus.loadInProgress);
  }


  shouldDisplayNoPlansMessage(): boolean {
    return (this.pageStatus === PageStatus.loadEmpty);
  }


  shouldDisplayPlanList(): boolean {
    let flag = true;
    flag = flag && (this.pageStatus === PageStatus.loadSuccess);
    flag = flag && !!this.filteredPlanCount;

    return flag;
  }


  propertyForPlan(plan: Plan): Property {
    let property = this.propertiesByID[plan.propertyID];

    if (!property) {
      property = this.propertyDomainFactoryService.newProperty(plan.propertyJsonObject);

      this.propertiesByID[plan.propertyID] = property;
    }

    return property;
  }


  statusAppReceivedForPlan(plan: Plan): boolean {
    let flag = false;

    if (plan.status.status === 0) {
      flag = true;
    }

    return flag;
  }


  statusDescriptionForPlan(plan: Plan): string {
    const desc = (plan.status ? plan.status.description : 'Unavailable');

    return desc;
  }


  statusSubDateForPlan(plan: Plan): Date {
    let date: Date = null;

    // if the plan has a status
    if (plan.status) {
      // if the status is expired
      if (plan.status.expiredFlag) {
        date = plan.planCoverageEnds;
      }

      // if the status is cancelled
      if (plan.status.cancelledFlag) {
        date = plan.cancelDate;
      }

      // if the status is buyer cvg active
      if (plan.status.status === PlanStatus.buyersCoverageActive) {
        date = plan.planCoverageStarts;
      }

      // if the status is future
      if (plan.status.status === PlanStatus.futurePlan) {
        date = plan.planCoverageStarts;
      }

      // if the status is app received
      if (plan.status.status === PlanStatus.appReceived) {
        date = plan.planReceivedDate;
      }

      // if the status is sellers cvg active
      if (plan.status.status === PlanStatus.sellersCoverageActive) {
        date = plan.planReceivedDate;
      }
    }

    return date;
  }


  customPlanRoleDisplayText(): string {
    let text: string = null;

    // if there's a product office for the current user
    if (!!this.productOfficeToolbox) {
      const productOfficeText = (this.productOfficeToolbox.customPlanRoleDisplayText || '');

      if (productOfficeText !== '') {
        text = productOfficeText;
      }
    }

    return text;
  }



  relationshipTextForPlan(plan: Plan): string {
    let text = '';

    // if the plan was keyed in the Toolbox
    if (plan.guestToolboxPlan) {

      if (text === '') {
        // if the plan was keyed by the current user
        const currentGuestGUID = this.authenticationService.guestGUID || '';
        const planGuestGUID = plan.guestToolboxPlan.guestGUID || '';

        if (currentGuestGUID.toLowerCase() === planGuestGUID.toLowerCase()) {
          text = this.customPlanRoleDisplayText() || '';
        }
      }

      // if there's a role
      if (text === '') {
        if (plan.guestToolboxPlan.planRole) {
          text = plan.guestToolboxPlan.planRole.description;

        } else if (plan.guestToolboxPlan.relationship) {
          text = plan.guestToolboxPlan.relationship.description;
        }
      }
    }

    if (text === '') {
      text = 'Unknown';
    }

    return text;
  }

  isAEPlan(plan: Plan): string {
    let text = '';

    if (plan.planOrdererRole !== null) {

      if (plan.planOrdererRole.guestGUID.toLocaleLowerCase() !== this.guestGUID.toLocaleLowerCase()) {
        text = '*';
      }

    } else {
      text = '*';
    }
    return text;
  }


  shouldDisplayInstructions(): boolean {
    let planCount = 0;

    if (this.plans) {
      planCount = this.plans.length;
    }

    return !!planCount;
  }


  shouldDisplayPageNumbers(): boolean {
    let displayPageNumbers = this.initialPlanLoad;

    if (this.filteredPlanCount <= this.plansPerPage) {
      displayPageNumbers = false;
    }

    return displayPageNumbers;
  }


  didClickPageNumber(pageNumber: number) {
    this.gotoPageNumber(pageNumber);
  }


  gotoPageNumber(pageNumber: number) {
    const urlPageNumber = ((this.urlPageNumber || '') !== '' ? Number(this.urlPageNumber) : null);

    if (pageNumber !== urlPageNumber) {
      this.myWarrantiesService.lastPageNumberLoaded = pageNumber;

      let url = '';

      if (this.urlPageNumber) {
        url += '../';
      }

      url += String(pageNumber);

      this.router.navigate([url], { relativeTo: this.route });
    }
  }



  didClickPlan(plan: Plan) {
    const url = '/plan-order/' + String(plan.planID);

    this.router.navigate([url]);
  }


  didClickNewApplication() {
    const url = '/plan-order/clear-application';

    this.router.navigate([url]);
  }


  shouldDisplayQuickStat(planStatusQuickStats: PlanStatusQuickStats): boolean {
    return planStatusQuickStats.code.toLowerCase() === 'paid' ||
      !(this.authenticationService.productOffice && this.authenticationService.productOffice.homeBuyerEmailRequiredFlag);
  }

  shouldDisplaySearch(): boolean {
    if (this.productOfficeToolbox && this.productOfficeToolbox.autoLoginGuestGUID === '') {
      return false;
    }
    return true;
  }

}



enum PageStatus {
  none = 10,
  loadInProgress = 20,
  loadSuccess = 30,
  loadEmpty = 40
}


enum SearchType {
  planNumber = 10,
  date = 20,
  propertyAddress = 30,
  customer = 40
}


interface PlanStatusQuickStats {
  code: string;
  description: string;
  formattedPlanCount: string;
  statusFilter: string;
}

