import {Inject, Injectable} from '@angular/core';
import 'rxjs/add/operator/map'
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {Response} from '@angular/http';
import {DOCUMENT} from "@angular/common";
import {Customer} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {AuthenticationService} from '../../login';
import {ProductOffice} from '@orhp/phx-product-ui-module';


@Injectable()
export class ProfileService {

    private _baseURL = '';


    productOffice: ProductOffice = null;


    // do we need to pull a custom URL?
    get customUrl(): string {
      let customUrl: string = null;

      const productOffice = this.productOffice || this.authenticationService.productOffice;

      if (!!productOffice && productOffice.toolbox) {
        const productOfficeCustomUrlPath = productOffice.toolbox.customUrlPath || '';

        if (productOfficeCustomUrlPath !== '') {
          customUrl = productOfficeCustomUrlPath;
        }
      }

      return customUrl;
    }


    // pull the base URL
  get baseURL(): string {
      let baseURL = this._baseURL;

      if (!!this.customUrl) {
        baseURL += '/' + this.customUrl;
      }

      return baseURL;
  }




    constructor(
        @Inject(DOCUMENT) private document: Document,
        private phxHttp: PhxHttpService,
        private authenticationService: AuthenticationService
    ) {
        const currentURL = this.document.location.href;

        const tokens = currentURL.split('/', 3);

        this._baseURL = tokens[0] + '//' + tokens[2];
    }


    createProfile(
        guest: any,
        customer: Customer,
        employer: Employer,
        correspondencesPreferences: any
    ): Observable<ProfileUpdateResult> {
        const observable: Observable<ProfileUpdateResult> =
            Observable.create((subscriber: Subscriber<ProfileUpdateResult>) => {

                const returnResult = new ProfileUpdateResult();
                const url = 'profile/save';
                const formBody = JSON.stringify(
                    {
                        url: this.baseURL + '/account/validate',
                        guest: guest,
                        customer: customer.jsonValues(),
                        employer: employer.jsonValues(),
                        correspondencesPreferences: correspondencesPreferences
                    }
                );
                this.phxHttp.post(url, formBody)
                    .subscribe((response: Response) => {
                        const result = new ProfileUpdateResult();

                        result.successFlag = true;

                        subscriber.next(result);
                        subscriber.complete();
                    }, (error: Response) => {
                        subscriber.error(error);
                        subscriber.complete();
                    });
            });
        return observable;
    }


    saveProfile(
        guest: any,
        customer: Customer,
        employer: Employer,
        correspondencesPreferences: any,
        original?: any
    ): Observable<ProfileUpdateResult> {
        const observable: Observable<ProfileUpdateResult> =
            Observable.create(
                (subscriber: Subscriber<ProfileUpdateResult>) => {
                    const url = 'profile/save';
                    const formBody = JSON.stringify(
                        {
                            url: this.baseURL + '/account/validate',
                            guest: guest,
                            customer: customer.jsonValues(),
                            employer: employer.jsonValues(),
                            correspondencesPreferences: correspondencesPreferences,
                            original: original
                        }
                    );
                    this.phxHttp.post(url, formBody)
                        .subscribe((response: Response) => {
                            const jsonObject = response.json();
                            const result = new ProfileUpdateResult();

                            if (jsonObject.errors.length > 0 || !jsonObject.object.success) {
                                result.successFlag = false;
                            } else {
                                result.successFlag = true;
                                result.employer = employer;
                                result.customer = customer;
                                result.requireLogout = jsonObject.object.requireLogout;
                            }

                            subscriber.next(result);
                            subscriber.complete();
                        }, (error: Response) => {
                            subscriber.error(error);
                            subscriber.complete();
                        });
                });
        return observable;
    }


    loadCorrespondencePreferences(customer): Observable<any> {
        const observable: Observable<any> =
            Observable.create((subscriber: Subscriber<any>) => {
                const formBody = JSON.stringify(customer.jsonValues());

                this.phxHttp.post('profile/loadCorrespondencePreferences', formBody)
                    .subscribe((response: Response) => {
                        subscriber.next(response.json());
                        subscriber.complete();
                    }, (error: Response) => {
                        subscriber.error(error);
                        subscriber.complete();
                    });

            });
        return observable;
    }


    loadCorrespondences(): Observable<any> {
        const observable: Observable<any> =
            Observable.create((subscriber: Subscriber<any>) => {
                const formBody = JSON.stringify({customerGUID: ''});

                this.phxHttp.post('profile/loadCorrespondencePreferences', formBody)
                    .subscribe((response: Response) => {
                        subscriber.next(response.json());
                        subscriber.complete();
                    }, (error: Response) => {
                        subscriber.error(error);
                        subscriber.complete();
                    });

            });
        return observable;
    }


}

export class ProfileUpdateResult {
    successFlag = false;
    requireLogout = false;
    customer: Customer = null;
    employer: Employer = null;
    guest: any = null;
}
