import {Injectable} from "@angular/core";
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {LocalStorage, SessionStorage} from '@orhp/phx-localstorage-ui-module';
import {SystemMessage} from '@orhp/phx-system-ui-module';
import {Observable} from "rxjs/Observable";
import {Subscriber} from "rxjs/Subscriber";

@Injectable()

export class HomeService {

  @LocalStorage('phx.toolbox.ui.HomeUpperRightMessage')
  homeUpperRightMessageHtml: string;


  constructor(private systemMessageLookupService: SystemMessageLookupService) {
  }


  // fetches the home page upper-right message
  fetchHomeUpperRightMessage(): Observable<boolean> {
    const observable = Observable.create((subscriber: Subscriber<boolean>) => {

      const code = 'toolbox-welcome-message-upper-right';

      this.systemMessageLookupService.systemMessageLookupByType(code)
        .subscribe((systemMessage: SystemMessage) => {

          if (systemMessage) {
            this.homeUpperRightMessageHtml = systemMessage.message;

          } else {
            this.homeUpperRightMessageHtml = null;
          }

          subscriber.next(true);
          subscriber.complete();
        });

    });

    return observable;
  }

}
