

import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSerializer} from '@angular/router';
import {Injectable} from '@angular/core';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupService} from './lookup.service';

@Injectable()

export class OfficeAgentLookupRootResolver implements Resolve<boolean> {

  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const observable: Observable<boolean> = Observable.create((subscriber: Subscriber<boolean>) => {

      let progress: ModalProgress = null;

      // provide the user with some progress
      progress = new ModalProgress('Initializing Office/Agent Lookup', 'Please wait...', 100);
      this.modalProgressService.progress = progress;

      // call the module init
      this.officeAgentLookupService.initModuleIfNeeded().subscribe((moduleInitResultFlag: boolean) => {
        // clear the progress indicator
        this.modalProgressService.progress = null;

        subscriber.next(true);
        subscriber.complete();
      });

    });

    return observable;
  }


}
