


import {Subscriber} from 'rxjs/Subscriber';
import {Observable} from 'rxjs/Observable';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment, UrlSerializer} from '@angular/router';
import {OfficeAgentLookupService} from './lookup.service';
import {PhxModalProgressService} from '@orhp/phx-common-ui-module';
import {Injectable} from '@angular/core';
import {EmployerLookupService} from '@orhp/phx-customer-ui-module';
import {Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {ModalProgress} from '@orhp/phx-common-ui-module';
import {OfficeAgentLookupPersistenceService} from './persistence.service';
import {EmployerDomainFactoryService} from '@orhp/phx-customer-ui-module';
import {OfficeAgentLookupRelationshipType} from '../_src/lookup-relationship-type';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';

@Injectable()

export class OfficeAgentLookupOfficeNewResolver implements Resolve<Employer> {


  // lookup relationship type
  get lookupRelationshipType(): OfficeAgentLookupRelationshipType {
    const lookupTypeCode = this.officeAgentLookupPersistenceService.lookupTypeCode;
    const lookupType = new OfficeAgentLookupRelationshipType(lookupTypeCode);

    return lookupType;
  }


  constructor(private modalProgressService: PhxModalProgressService,
              private officeAgentLookupService: OfficeAgentLookupService,
              private officeAgentLookupPersistenceService: OfficeAgentLookupPersistenceService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private employerLookupService: EmployerLookupService,
              private customerLookupValueService: CustomerLookupValueService,
              private router: Router,
              private urlSerializer: UrlSerializer) {
  }


  resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Employer> {
    const observable: Observable<Employer> = Observable.create((subscriber: Subscriber<Employer>) => {

      // look at the saved employer; reload if it already exists
      const savedEmployer = this.officeAgentLookupPersistenceService.employer;

      let createNewEmployerFlag = false;

      if (!savedEmployer) {
        createNewEmployerFlag = true;

      } else if (savedEmployer.guid) {
        createNewEmployerFlag = true;
      }

      let employer: Employer;

      if (createNewEmployerFlag) {
        employer = this.employerDomainFactoryService.newEmployer();

        const employerTypeCode = this.lookupRelationshipType.newEmployerTypeCode;
        employer.employerType = this.customerLookupValueService.employerTypeByCode(employerTypeCode);
        employer.physicalAddress.requiredFlag = true;
        employer.mainPhone.requiredFlag = true;

        this.officeAgentLookupPersistenceService.employer = employer;

      } else {
        employer = savedEmployer;
      }

      subscriber.next(employer);
      subscriber.complete();
    });

    return observable;
  }

}
