import {Observable} from "rxjs/Observable";
import {CanDeactivate} from "@angular/router";
import {PlanOrderReviewComponent} from "./review.component";
import {Subscriber} from "rxjs/Subscriber";


export class PlanOrderReviewDeactivateGuard implements CanDeactivate<PlanOrderReviewComponent> {

  modalEventEmitterSubscription: Subscriber<boolean> = null;

  canDeactivate(component: PlanOrderReviewComponent): Observable<boolean> {

    const observable = Observable.create((subscriber: Subscriber<boolean>) => {

      // determine if we need to auto-send the confirmations on completion
      const autoSendFlag = component.shouldAutoSendConfirmation();

      // if we need to auto-send
      if (autoSendFlag) {
        // subscribe to confirmation email events
        this.modalEventEmitterSubscription = component.confirmationAutoSendEventEmitter
          .subscribe((resultFlag: boolean) => {

          // unsubscribe if we're moving onward
          if (resultFlag) {
            this.modalEventEmitterSubscription.unsubscribe();
          }

          subscriber.next(resultFlag);
          subscriber.complete();
        });

        // auto-send the confirmations
        component.autoSendConfirmation();

      // if we're not auto-sending
      } else {
        subscriber.next(true);
        subscriber.complete();
      }

    });

    return observable;
  }

}

