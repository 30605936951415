import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthenticationService} from './service';
import {PhxLoginService} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-login-passthru',
  templateUrl: './login-passthru.component.html',
  styleUrls: [
    './login-passthru.component.scss'
  ]
})

export class LoginPassthruComponent implements OnInit {


  get urlLoginTokenGUID(): string {
    return this.route.snapshot.params['loginTokenGUID'] || '';
  }


  get urlRedirectTo(): string {
    return this.route.snapshot.params['redirectTo'] || '';
  }


  get urlExternalRedirectTo(): string {
    return this.route.snapshot.params['externalRedirectTo'] || '';
  }



  loginStatus = LoginStatus.inProgress;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private loginService: PhxLoginService
  ) {}


  ngOnInit() {
    this.performLogin();
  }


  performLogin() {
    // logout first
    this.authenticationService.logout();

    // perform a login
    this.authenticationService.loginPassthru(this.urlLoginTokenGUID).then((resultFlag: boolean) => {
      if (resultFlag) {
        this.loginStatus = LoginStatus.success;

        if (this.urlRedirectTo !== '') {
          const redirectTo = '/' + this.urlRedirectTo;

          this.router.navigate([redirectTo], {});

        } else if (this.urlExternalRedirectTo !== '') {
          window.location.href = this.urlExternalRedirectTo;

        } else {
          this.router.navigate(['/'], {});
        }


      } else {
        this.loginStatus = LoginStatus.failure;
      }
    });
  }



  isLoginInProgress(): boolean {
    return (this.loginStatus === LoginStatus.inProgress);
  }


  isLoginSuccess(): boolean {
    return (this.loginStatus === LoginStatus.success);
  }


  isLoginFailure(): boolean {
    return (this.loginStatus === LoginStatus.failure);
  }



}


enum LoginStatus {
  inProgress = 10,
  success = 20,
  failure = 30
}

