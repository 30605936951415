import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map'
import {PhxHttpService} from '@orhp/phx-common-ui-module';
import {Http} from '@angular/http';

@Injectable()
export class AccountService {
    constructor(private http: Http, private phxHttp: PhxHttpService) {
    }

    requestPasswordReset(email, url) {
        const formParams = new Map<string, any>();
        formParams['email'] = email;
        formParams['type'] = 'R';
        formParams['url'] = url;
        const formBody = JSON.stringify(formParams);

        const fullURL = this.phxHttp.buildApiUrl('account/requestPasswordReset');
        return this.http.post(
            fullURL, formBody
        );
    }

    resetPassword(id: String, password: String, url: String) {
        const formParams = new Map<string, any>();
        formParams['id'] = id;
        formParams['password'] = password;
        formParams['url'] = url;
        const formBody = JSON.stringify(formParams);

        const fullURL = this.phxHttp.buildApiUrl('account/doPasswordReset');
        return this.http.post(
            fullURL, formBody
        );

    }

    validatePasswordReset(id: String) {
        const fullURL = this.phxHttp.buildApiUrl('account/validatePasswordReset?id=' + id);
        return this.http.get(fullURL);
    }

    resendValidationEmail(email: String, url: String) {
        const formParams = new Map<string, any>();
        formParams['email'] = email;
        formParams['type'] = 'R';
        formParams['url'] = url;
        const formBody = JSON.stringify(formParams);
        const fullURL = this.phxHttp.buildApiUrl('account/resendValidationEmail');

        return this.http.post(
            fullURL, formBody
        );

    }


}
