
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {AddressValidateService} from '@orhp/phx-addressvalidate-ui-module';
import {AddressValidateResult} from '@orhp/phx-addressvalidate-ui-module';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {Property} from '@orhp/phx-property-ui-module';
import {PropertyLookupService} from '@orhp/phx-property-ui-module';
import {PropertyLookupParams} from '@orhp/phx-property-ui-module';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {AddressValidateRequest} from '@orhp/phx-addressvalidate-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {PlanLookupService} from '@orhp/phx-plan-ui-module';
import {PlanLookupParams} from '@orhp/phx-plan-ui-module';
import {PlanDomainFactoryService} from '@orhp/phx-plan-ui-module';
import {PlanCounts} from '@orhp/phx-plan-ui-module';
import {Customer} from '@orhp/phx-customer-ui-module';
import {SystemMessageLookupService} from '@orhp/phx-system-ui-module';
import {SystemMessage} from '@orhp/phx-system-ui-module';
import {PropertyType} from '@orhp/phx-property-ui-module';
import {Address} from '@orhp/phx-common-ui-module';
import {ObservableUtility, RoutingUtility} from '@orhp/phx-common-ui-module';
import {RegionLookupService} from '@orhp/phx-geography-ui-module';
import {Region} from '@orhp/phx-geography-ui-module';
import {CityLookupService} from '@orhp/phx-geography-ui-module';
import {City} from '@orhp/phx-geography-ui-module';
import {observable} from 'rxjs/symbol/observable';
import {ZipCodeLookupService} from '@orhp/phx-geography-ui-module';
import {CityZip} from '@orhp/phx-geography-ui-module';
import {PhxSystemService} from '@orhp/phx-common-ui-module';

@Component({
  selector: 'app-plan-order-property-address',
  templateUrl: './property-address.component.html',
  styleUrls: [
    '../plan-order.scss',
    './property-address.component.scss'
  ]
})

export class PlanOrderPropertyAddressComponent extends PlanOrderBaseComponent implements OnInit {

  addressValidationState: AddressValidationState = AddressValidationState.pending;

  userAddress: Address = null;

  addressValidationAttempted = false;
  addressValidationErrorMessage = null;

  validatedAddress: Address = null;

  originalProperty: Property = null;
  existingProperty: Property = null;
  propertyType: PropertyType = null;

  existingPlanMessage: string = null;
  doNotRenewMessage: string = null;

  cityZipCorrectFlag = false;

  citiesByZipCode: City[] = null;
  zipCodesByCity: CityZip[] = null;



  get userFieldsDisabled(): boolean {
    let disabledFlag = true;

    disabledFlag = disabledFlag && (this.addressValidationState !== AddressValidationState.cityZipMismatch);

    return disabledFlag;
  }


  @ViewChild('addressInput')
  addressInput: ElementRef;


  // constructor
  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private systemService: PhxSystemService,
              private systemMessageLookupService: SystemMessageLookupService,
              private regionLookupService: RegionLookupService,
              private cityLookupService: CityLookupService,
              private zipCodeLookupService: ZipCodeLookupService,
              private addressValidateService: AddressValidateService,
              private propertyLookupService: PropertyLookupService,
              private planLookupService: PlanLookupService,
              private planDomainFactoryService: PlanDomainFactoryService,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService) {
    super(planOrderService);
  }



  // on init
  ngOnInit() {
    super.ngOnInit();

    this.planOrderStepService.setCurrentStepByCode(this.planOrderApplication, 'property-address');

    // duplicate the original property
    const property = this.planOrderApplication.property.duplicate();

    this.originalProperty = property;
    this.propertyType = property.propertyType;

    this.userAddress = new Address();

    this.userAddress.address = property.address;
    this.userAddress.city = property.city;
    this.userAddress.stateCode = property.stateCode;
    this.userAddress.zipCode = property.zipCode;

    this.addressInput.nativeElement.focus();
    this.addressInput.nativeElement.select();
  }


  // should the running message be displayed
  shouldDisplayRunningMessage(): boolean {
    return (this.addressValidationState === AddressValidationState.running);
  }


  // should the address validation error message be displayed
  shouldDisplayAddressValidationError(): boolean {
    return (this.addressValidationState === AddressValidationState.validationFailure);
  }


  // should the address validation success message be displayed
  shouldDisplayAddressValidationSuccess(): boolean {
    return (this.addressValidationState === AddressValidationState.validationSuccess);
  }


  // should the city zip confirm checkbox appear
  shouldDisplayCityZipConfirmCheckbox(): boolean {
    return (this.addressValidationState === AddressValidationState.cityZipMismatch);
  }


  // address validation attempted?
  addressValidationPrompt(): boolean {
    let flag = false;

    if (this.addressValidationState === AddressValidationState.validationFailure) {
      flag = true;
    }

    if (this.addressValidationState === AddressValidationState.validationSuccess) {
      flag = true;
    }

    return flag;
  }


  // was address validation successful?
  addressValidationSuccess(): boolean {
    return (this.addressValidationState === AddressValidationState.validationSuccess);
  }


  // validation user reponse handler
  validationResponseHandler(flag: boolean) {
    // if the user wants to continue
    if (flag) {
      // if the address is validated
      if (this.addressValidationState === AddressValidationState.validationSuccess) {
        this.continueWithValidatedAddress();

      // if the address is not validated
      } else if (this.addressValidationState === AddressValidationState.validationFailure) {
        this.continueWithUserAddress();
      }

    // if the user wants to cancel
    } else {
      this.didClickTryAgain();
    }
  }



  // validation failure handler
  continueWithUserAddress() {
    const newProperty = this.planOrderApplication.property.duplicate();

    newProperty.address = this.userAddress.address.toUpperCase();
    newProperty.city = this.userAddress.city.toUpperCase();
    newProperty.stateCode = this.userAddress.stateCode.toUpperCase();
    newProperty.zipCode = this.userAddress.zipCode.toUpperCase();
    newProperty.overrideValidationFlag = true;

    newProperty.validationAttemptedFlag = true;

    if (newProperty.hasUpdateFlag) {
      this.planOrderApplication.property = newProperty;
    }

    this.gotoNextStep();
  }



  // should the disallowed message be displayed
  shouldDisplayDoNotRenewMessage(): boolean {
    let displayFlag = true;

    if (this.addressValidationState !== AddressValidationState.doNotRenew) {
      displayFlag = false;
    }

    if (!this.doNotRenewMessage) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // should the existing plan message be displayed?
  shouldDisplayExistingPlanMessage(): boolean {
    let displayFlag = true;

    if (this.addressValidationState !== AddressValidationState.existingPlan) {
      displayFlag = false;
    }

    if (!this.existingPlanMessage) {
      displayFlag = false;
    }

    return displayFlag;
  }


  // should the zip code change prompt be displayed?
  shouldDisplayRegionChangeMessage(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.addressValidationState === AddressValidationState.regionChange);

    return displayFlag;
  }



  // should the city change prompt be displayed?
  shouldDisplayCityZipMismatch(): boolean {
    let displayFlag = false;

    displayFlag = displayFlag || (this.addressValidationState === AddressValidationState.cityZipMismatch);

    return displayFlag;
  }


  // validate the street address
  shouldDisplayStreetAddressError(): boolean {
    return (this.addressValidationAttempted && !this.validateStreetAddress());
  }


  shouldDisplayCityError(): boolean {
    return (this.addressValidationAttempted && !this.validateCity());
  }


  shouldDisplayStateCodeError(): boolean {
    return (this.addressValidationAttempted && !this.validateStateCode());
  }


  shouldDisplayZipCodeError(): boolean {
    return (this.addressValidationAttempted && !this.validateZipCode());
  }


  // validate the street address
  validateStreetAddress(): boolean {
    return (this.userAddress.address.trim() !== '');
  }


  // validate the city
  validateCity(): boolean {
    return (this.userAddress.city.trim() !== '');
  }


  // validate the state
  validateStateCode(): boolean {
    return (this.userAddress.stateCode.trim() !== '');
  }


  // validate the zip
  validateZipCode(): boolean {
    return (this.userAddress.zipCode.trim() !== '');
  }


  addressSameAsProperty(address: Address, property: Property): boolean {
    // was the address changed?
    let addressChangeFlag = false;
    addressChangeFlag = addressChangeFlag || (property.address !== address.address);
    addressChangeFlag = addressChangeFlag || (property.city !== address.city);
    addressChangeFlag = addressChangeFlag || (property.stateCode !== address.stateCode);
    addressChangeFlag = addressChangeFlag || (property.zipCode !== address.zipCode);

    return !addressChangeFlag;
  }



  // validates the address
  didClickValidateAddress(): void {
    this.addressValidationAttempted = true;

    // was the address changed?
    const addressChangeFlag = !this.addressSameAsProperty(this.userAddress, this.originalProperty);

    const autoNextStep = !addressChangeFlag && this.originalProperty.validationAttemptedFlag;

    // if the address wasn't changed, skip validation
    if (autoNextStep) {
      this.gotoNextStep();

    // if the address has changed, perform validation
    } else {
      this.performAddressValidation();
    }
  }


  didClickCityZipCorrectFlag() {
    this.cityZipCorrectFlag = !this.cityZipCorrectFlag;
  }


  // returns an address object with validation
  addressForValidationPrompt(): Address {
    const address = (this.validatedAddress ? this.validatedAddress : new Address());

    return address;
  }



  didClickChooseCity(city: City) {
    this.userAddress.city = city.city;
    this.userAddress.stateCode = city.stateCode;

    this.performAddressValidation();
  }


  didClickChooseZipCode(cityZip: CityZip) {
    this.userAddress.zipCode = cityZip.zipCode;

    this.performAddressValidation();
  }




  // performs address validation
  performAddressValidation() {
    const cityZipCorrectFlag = this.cityZipCorrectFlag;

    this.cityZipCorrectFlag = false;

    // reset the existing property if one was found
    this.existingProperty = null;

    // are the address entries valid
    let validFlag = true;
    validFlag = validFlag && this.validateStreetAddress();
    validFlag = validFlag && this.validateCity();
    validFlag = validFlag && this.validateStateCode();
    validFlag = validFlag && this.validateZipCode();

    // if an address was entered
    if (validFlag) {
      this.addressValidationState = AddressValidationState.running;

      // validate the user address with the zip code
      this.validateAddress(true).subscribe((address: Address) => {
        // if the address did not validate, make sure the zip code is only 5 digits
        if (!address.validatedFlag) {
          address.zipCode = address.zipCode5;
        }

        this.validatedAddress = address;

        // perform a city/zip mismatch if the address didn't validate
        const cityZipMismatchObservable =
          (address.validatedFlag ?
            ObservableUtility.emptyObservable(false) :
            this.matchCityAgainstZip());

        cityZipMismatchObservable.subscribe((cityZipMismatchFlag: boolean) => {

          // look for an existing property
          this.performAddressLookup(address).subscribe((existingProperty: Property) => {

            // if an existing property returned
            if (existingProperty) {
              this.existingProperty = existingProperty;

              this.handleExistingPropertyValidation();

            // if there was a city/zip mismatch
            } else if (cityZipMismatchFlag) {
              // if the user has selected that the city and zip are correct
              if (cityZipCorrectFlag) {
                // continue with the user address
                this.continueWithUserAddress();

              } else {
                this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/city-zip-mismatch');

                this.addressValidationState = AddressValidationState.cityZipMismatch;
              }

            // handle the address
            } else {
              this.handleAcceptedAddress();
            }
          });
        });

      });
    }
  }





  // performs an address validation with or without zip code
  validateAddress(zipCodeFlag: boolean): Observable<Address> {
    const observable = Observable.create((subscriber: Subscriber<Address>) => {
      const request = new AddressValidateRequest();

      request.address = this.userAddress.address;

      if (zipCodeFlag) {
        request.zipCode = this.userAddress.zipCode5;

      } else {
        request.city = this.userAddress.city;
        request.stateCode = this.userAddress.stateCode;
      }

      // execute the address validation
      this.addressValidateService.validateAddress(request).subscribe((result: AddressValidateResult) => {
        let address: Address = null;

        // if the address is validated
        if (result.validatedFlag) {
          address = new Address();
          address.validatedFlag = true;

          address.address = result.cleanAddress;
          address.city = result.cleanCity;
          address.stateCode = result.cleanStateCode;
          address.zipCode = result.cleanZipCode;

          subscriber.next(address);
          subscriber.complete();

        // if the address is not validated and we haven't searched by zip code yet
        } else if (zipCodeFlag) {
          this.validateAddress(false).subscribe((noZipAddress: Address) => {
            subscriber.next(noZipAddress);
            subscriber.complete();

          });

        // if we're using the dirty address
        } else {
          address = new Address();
          address.validatedFlag = false;

          address.address = this.userAddress.address.toUpperCase();
          address.city = this.userAddress.city.toUpperCase();
          address.stateCode = this.userAddress.stateCode.toUpperCase();
          address.zipCode = this.userAddress.zipCode;

          subscriber.next(address);
          subscriber.complete();
        }
      });
    });

    return observable;
  }



  // perform a city lookup against the zip code
  matchCityAgainstZip(): Observable<boolean> {
    const returnObservable = Observable.create((subscriber: Subscriber<boolean>) => {

      let observable: Observable<any> = null;
      const observables = [];

      // look for cities based on the zip
      observable = this.cityLookupService.fetchCities(this.userAddress.zipCode);
      observables.push(observable);

      // look for zips based on city
      observable = this.zipCodeLookupService.fetchZipCodes(this.userAddress.city, this.userAddress.stateCode);
      observables.push(observable);

      // perform the fetches
      Observable.forkJoin(observables).subscribe((results: any[]) => {
        const citiesByZipCode = <City[]>results[0];
        const zipCodesByCity = <CityZip[]>results[1];

        // lets attempt to validate that the city and zip match base
        this.citiesByZipCode = citiesByZipCode;
        this.zipCodesByCity = zipCodesByCity;

        // look for a matching city
        let cityMatchFoundFlag = false;

        citiesByZipCode.forEach((indexCity: City) => {
          cityMatchFoundFlag = cityMatchFoundFlag || (indexCity.city.toUpperCase() === this.userAddress.city.toUpperCase());
        });

        // look for a matching zip code
        let zipMatchFoundFlag = false;

        zipCodesByCity.forEach((indexZip: CityZip) => {
          zipMatchFoundFlag = zipMatchFoundFlag || (indexZip.zipCode === this.userAddress.zipCode5);
        });

        // if there was a city/zip mismatch found
        let cityZipMismatchFlag = true;

        cityZipMismatchFlag = cityZipMismatchFlag && !cityMatchFoundFlag;
        cityZipMismatchFlag = cityZipMismatchFlag && !zipMatchFoundFlag;

        cityZipMismatchFlag = cityZipMismatchFlag && (!!citiesByZipCode.length || !!zipCodesByCity.length);

        subscriber.next(cityZipMismatchFlag);
        subscriber.complete();
      });

    });

    return returnObservable;
  }



  // handles existing property validation
  handleExistingPropertyValidation() {
    const property = this.existingProperty;

    // if the property is DNR'd
    if (property.doNotRenewReasonID) {
      this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/do-not-renew');

      // retrieve the system message
      this.systemMessageLookupService.systemMessageLookupByType('plan-order-dnr-property-message')
        .subscribe((systemMessage: SystemMessage) => {
          this.doNotRenewMessage = systemMessage.message;

          this.addressValidationState = AddressValidationState.doNotRenew;
        });

    // are there any disqualifying plans on the property?
    } else {
      const planLookupParams = this.planDomainFactoryService.newPlanLookupParams();

      planLookupParams.propertyID = property.propertyID;
      planLookupParams.toolboxNewPlanIneligibleFlag = true;

      if (this.planOrderApplication.planID) {
        planLookupParams.excludePlanID = String(this.planOrderApplication.planID);
      }

      this.planLookupService.fetchPlanCounts(planLookupParams).subscribe((counts: PlanCounts) => {

        let messageCode: string = null;

        // if unpaid or paid plans were returned
        if (counts.unpaidPlanCount || counts.paidPlanCount) {
          messageCode = 'plan-order-existing-plan-message';

        // if cancelled or expired plans were returned
        } else if (counts.expiredPlanCount || counts.cancelledPlanCount) {
          messageCode = 'plan-order-dnr-property-message';
        }


        // if we're returning a message
        if (messageCode) {
          this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/' + messageCode);

          // retrieve the system message
          this.systemMessageLookupService.systemMessageLookupByType(messageCode)
            .subscribe((systemMessage: SystemMessage) => {

              this.existingPlanMessage = systemMessage.message;
              this.addressValidationState = AddressValidationState.existingPlan;
            });

        // if there were no plans
        } else {
          this.handleAcceptedAddress();
        }
      });
    }
  }



  // performs a property address lookup
  performAddressLookup(address: Address): Observable<Property> {
    const params = new PropertyLookupParams();

    params.address = address.address;
    params.city = address.city;
    params.stateCode = address.stateCode;
    params.zipCode = address.zipCode;


    const observable: Observable<Property> = Observable.create((subscriber: Subscriber<Property>) => {
      // execute the property fetch
      this.propertyLookupService.fetchProperties(params).subscribe((properties: Property[]) => {
        let property: Property = null;

        // if a property was returned
        if (properties.length > 0) {
          property = properties[0];
        }

        subscriber.next(property);
        subscriber.complete();
      });
    });


    return observable;
  }



  handleAcceptedAddress() {
    const sameAddressFlag = (this.validatedAddress.address.toLowerCase() === this.userAddress.address.toLowerCase());
    const sameCityFlag = (this.validatedAddress.city.toLowerCase() === this.userAddress.city.toLowerCase());
    const sameStateFlag = (this.validatedAddress.stateCode.toLowerCase() === this.userAddress.stateCode.toLowerCase());
    const sameZipFlag = (this.validatedAddress.zipCode5 === this.originalProperty.zipCode5);

    // test if we need to handle the zip code update
    const zipCodeUpdateObservable = (sameZipFlag ? ObservableUtility.emptyObservable(true) : this.handleZipCodeUpdate());

    zipCodeUpdateObservable.subscribe((zipOkayFlag: boolean) => {
      // if we can proceed
      if (zipOkayFlag) {
        // if the address is validated
        if (this.validatedAddress.validatedFlag) {
          // if the validated and user addresses are the same
          if (sameAddressFlag && sameCityFlag && sameStateFlag && sameZipFlag) {
            this.continueWithValidatedAddress();

          } else {
            this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/address-validated');

            this.addressValidationState = AddressValidationState.validationSuccess;
          }

        // if the address is not validated
        } else {
          const addressMatchFlag = this.addressSameAsProperty(this.validatedAddress, this.originalProperty);

          let continueFlag = true;
          continueFlag = continueFlag && addressMatchFlag;
          continueFlag = continueFlag && this.originalProperty.validationAttemptedFlag;

          // if validation was already attempted, skip it
          if (continueFlag) {
            this.continueWithValidatedAddress();

          // if the validation was not attempted, display a message
          } else {
            this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/address-not-validated');

            this.addressValidationState = AddressValidationState.validationFailure;
          }
        }
      }
    });

  }


  handleZipCodeUpdate(): Observable<boolean> {
    const returnObservable = Observable.create((subscriber: Subscriber<boolean>) => {

      // fetch the region for the old and new zip code
      let observable: Observable<any> = null;
      const observables = [];

      observable = this.regionLookupService.fetchRegion(this.originalProperty.zipCode5);
      observables.push(observable);

      observable = this.regionLookupService.fetchRegion(this.validatedAddress.zipCode5);
      observables.push(observable);

      Observable.forkJoin(observables).subscribe((results: any[]) => {
        const userRegion = <Region>results[0];
        const validatedRegion = <Region>results[1];

        // are the regions the same?
        let sameRegionFlag = false;

        if (userRegion && validatedRegion) {
          sameRegionFlag = userRegion.isEqual(validatedRegion);
        }

        // if the regions are the same, just continue
        if (sameRegionFlag) {
          subscriber.next(true);

          // if the regions aren't the same, prompt the user
        } else {
          this.systemService.sendGoogleAnalyticsTrackingFromCurrentUrl(this.router, '/region-change');

          this.addressValidationState = AddressValidationState.regionChange;

          subscriber.next(false);
        }

        subscriber.complete();
      });

    });

    return returnObservable;
  }



  continueWithValidatedAddress() {
    const property = this.planOrderApplication.property.duplicate();

    if (this.existingProperty) {
      property.propertyID = this.existingProperty.propertyID;
      property.address = this.existingProperty.address;
      property.city = this.existingProperty.city;
      property.stateCode = this.existingProperty.stateCode;
      property.zipCode = this.existingProperty.zipCode;
      property.guid = this.existingProperty.guid;

    } else {
      property.address = this.validatedAddress.address;
      property.city = this.validatedAddress.city;
      property.stateCode = this.validatedAddress.stateCode;
      property.zipCode = this.validatedAddress.zipCode;

      property.overrideValidationFlag = true;
    }

    property.validationAttemptedFlag = true;

    if (property.hasUpdateFlag) {
      this.planOrderApplication.property = property;
    }

    this.gotoNextStep();
  }



  // try again
  didClickTryAgain() {
    this.addressValidationState = AddressValidationState.pending;

    this.addressValidationAttempted = false;
  }


  // update zip code
  didClickUpdateZipCode() {
    let propertyTypeResultCode = '';

    if (this.addressValidationState === AddressValidationState.regionChange) {
      const property = this.planOrderApplication.property.duplicate();

      property.address = this.validatedAddress.address;
      property.city = this.validatedAddress.city;
      property.stateCode = this.validatedAddress.stateCode;
      property.zipCode = this.validatedAddress.zipCode;

      this.planOrderApplication.property = property;

      this.persistPlanOrderApplication();

      propertyTypeResultCode = 'region-change';
    }


    let urlSegment: UrlSegment = null;
    const urlTree = this.urlSerializer.parse(this.router.routerState.snapshot.url);

    urlSegment = new UrlSegment('property-type', {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    urlSegment = new UrlSegment(propertyTypeResultCode, {});
    RoutingUtility.appendUrlSegmentToTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }


  // persists and goto next step
  gotoNextStep() {
    // next step logic
    this.persistPlanOrderApplication();

    const nextRoute = this.planOrderStepService.getNextRoute();

    let urlSegment: UrlSegment = null;
    const urlTree = this.urlSerializer.parse(this.router.routerState.snapshot.url);

    urlSegment = new UrlSegment(nextRoute, {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, urlSegment);

    this.router.navigateByUrl(urlTree);
  }

}



enum AddressValidationState {
  pending = 10,
  running = 20,
  doNotRenew = 30,
  validationSuccess = 40,
  validationFailure = 50,
  existingPlan = 60,
  cityZipMismatch = 70,
  regionChange = 80
}
