
import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {PlanOrderService} from '../_services/plan-order.service';
import {PlanOrderApplication} from '../_models/plan-order-application';
import {PlanOrderProgressBarStep} from '../_models/progress-bar-step';
import {PlanOrderStepService} from '../_services/plan-order-step.service';
import {Plan} from '@orhp/phx-plan-ui-module';
import {PlanOrderBaseComponent} from '../plan-order-base-component';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer, UrlTree} from '@angular/router';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {StringUtility} from '@orhp/phx-common-ui-module';
import {PlanUtility} from '@orhp/phx-plan-ui-module';
import {ProgressBarImageService} from './progress-bar-image.service';
import {PlanOrderAuthService} from '../_services/plan-order-auth.service';

@Component({
  selector: 'app-plan-order-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: [
    '../plan-order.scss',
    './progress-bar.component.scss'
  ]
})

export class PlanOrderProgressBarComponent extends PlanOrderBaseComponent implements OnInit, AfterViewInit {

  @Input() titleText: string;
  @Input() displayPlanNumberFlag = true;

  planNumberLookup = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private planOrderService: PlanOrderService,
              private planOrderStepService: PlanOrderStepService,
              private progressBarImageService: ProgressBarImageService,
              private planOrderAuthService: PlanOrderAuthService
              ) {
    super(planOrderService);
  }



  get displayedTitleText(): string {
    let text = this.titleText;

    if (!text) {
      const productOfficeGroup = this.planOrderAuthService.productOffice;
      const productOfficeToolbox = (!!productOfficeGroup ? productOfficeGroup.toolbox : null);

      if (!!productOfficeToolbox && !productOfficeToolbox.allowPlanQuoteFlag) {
        text = 'Order Warranty';

      } else {
        text = 'Get a Quote / Place Application';
      }
    }

    return text;
  }


  get allSteps(): PlanOrderProgressBarStep[] {
    return this.planOrderStepService.allSteps;
  }


  get stepsToDisplay(): PlanOrderProgressBarStep[] {
    const allSteps = [];

    this.allSteps.forEach((indexStep: PlanOrderProgressBarStep) => {
      if (indexStep.progressBarFlag) {
        allSteps.push(indexStep);
      }
    });

    return allSteps;
  }


  get currentStep(): PlanOrderProgressBarStep {
    return this.planOrderStepService.currentStep;
  }


  get currentVisibleStep(): PlanOrderProgressBarStep {
    return (this.currentStep ? this.visibleStepForStep(this.currentStep) : null);
  }


  // on init
  ngOnInit(): void {
  }


  // scroll to the current step in the progress bar
  ngAfterViewInit() {
    const divActive = document.getElementById('step-current');

    if (divActive) {
      divActive.scrollIntoView();
    }
  }



  // if we're displaying the plan number
  planNumberDisplay(): string {
    let displayFlag = this.displayPlanNumberFlag;


    // if this is a new plan
    const application = this.planOrderService.planOrderApplication;

    if (!application.planID) {
      displayFlag = false;
    }

    let planNumberDisplay: string = null;

    if (displayFlag) {
      planNumberDisplay = String(application.planID);
    }

    return planNumberDisplay;
  }





  // the user clicked a step
  didClickStep(step: PlanOrderProgressBarStep) {
    const currentStep = this.planOrderStepService.currentStep;
    const currentRoute = currentStep.route;

    const url = this.router.routerState.snapshot.url;

    const urlTree = RoutingUtility.urlTreeToSegment(this.urlSerializer, url, currentRoute);

    const newUrlSegment = new UrlSegment(step.route, {});
    RoutingUtility.replaceLastUrlSegmentInTree(urlTree, newUrlSegment);

    this.router.navigateByUrl(urlTree);
  }


  // should demo mode be displayed?
  shouldDisplayDemoMode(): boolean {
    return this.planOrderApplication.demoModeFlag;
  }



  // should the progress bar be displayed?
  shouldDisplayProgressBar(): boolean {
    let displayFlag = true;

    const step = this.currentStep;

    // if the current step is not defined
    if (!step) {
      displayFlag = false;

    // if there is a current step
    } else {
      // if the current step is not displayed in the progress bar, and there is no alt step
      if (!step.progressBarFlag && !step.altStepCode) {
        displayFlag = false;
      }
    }

    return displayFlag;
  }


  // returns the index of the step
  indexOfStepByCode(stepCode: string): number {
    let stepIndex: number = null;

    let stepCounter = 0;

    this.allSteps.forEach((indexStep: PlanOrderProgressBarStep) => {
      if (indexStep.code === stepCode) {
        stepIndex = stepCounter;
      }

      stepCounter++;
    });

    return stepIndex;
  }


  indexOfStep(step: PlanOrderProgressBarStep): number {
    let index: number = null;

    if (step) {
      index = this.indexOfStepByCode(step.code);
    }

    return index;
  }


  idForStep(step: PlanOrderProgressBarStep): string {
    let id = step.code;

    if (this.isStepCurrent(step)) {
      id = 'step-current';
    }

    return id;
  }



  // returns the visible step for the given step
  visibleStepForStep(step: PlanOrderProgressBarStep): PlanOrderProgressBarStep {
    let visibleStep = step;

    if (step.altStepCode) {
      const altStepIndex = this.indexOfStepByCode(step.altStepCode);

      visibleStep = this.allSteps[altStepIndex];
    }

    return visibleStep;
  }


  // returns the previous visible step
  previousVisibleStep(step: PlanOrderProgressBarStep): PlanOrderProgressBarStep {
    const visibleStep = this.visibleStepForStep(step);
    const visibleStepIndex = this.indexOfStep(visibleStep);

    let previousVisibleStep: PlanOrderProgressBarStep = null;

    let stepIteratorIndex = visibleStepIndex - 1;

    while (!previousVisibleStep && (stepIteratorIndex >= 0)) {
      const indexStep = this.allSteps[stepIteratorIndex];

      if (indexStep.progressBarFlag) {
        previousVisibleStep = indexStep;
      }

      stepIteratorIndex--;
    }

    return previousVisibleStep;
  }


  // returns the next visible step
  nextVisibleStep(step: PlanOrderProgressBarStep): PlanOrderProgressBarStep {
    const visibleStep = this.visibleStepForStep(step);
    const visibleStepIndex = this.indexOfStep(visibleStep);

    let nextVisibleStep: PlanOrderProgressBarStep = null;

    let stepIteratorIndex = visibleStepIndex + 1;

    while (!nextVisibleStep && (stepIteratorIndex < this.allSteps.length)) {
      const indexStep = this.allSteps[stepIteratorIndex];

      if (indexStep.progressBarFlag) {
        nextVisibleStep = indexStep;
      }

      stepIteratorIndex++;
    }

    return nextVisibleStep;
  }



  // is there a single step?
  isSingleStep(): boolean {
    return (this.allSteps.length === 1);
  }


  // is this the first step
  isFirstStep(step: PlanOrderProgressBarStep): boolean {
    const previousStep = this.previousVisibleStep(step);

    return !previousStep;
  }


  // is this the last step
  isLastStep(step: PlanOrderProgressBarStep): boolean {
    const nextStep = this.nextVisibleStep(step);

    return !nextStep;
  }


  // is this a pending step
  isStepPending(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);
    const visibleStepIndex = this.indexOfStep(visibleStep);

    const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

    let isStepPending = false;

    // if the step is not enabled, it's pending
    if (!step.enabledFlag) {
      isStepPending = true;

    // if the current step is less than this step, it's pending
    } else if (currentVisibleStepIndex < visibleStepIndex) {
      isStepPending = true;
    }

    return isStepPending;
  }

  // is this the current step
  isStepCurrent(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);
    const visibleStepIndex = this.indexOfStep(visibleStep);

    const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

    return (visibleStepIndex === currentVisibleStepIndex);
  }


  // is the previous step complete?
  isStepComplete(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);
    const visibleStepIndex = this.indexOfStep(visibleStep);

    const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

    let isStepComplete = true;

    if (!step.enabledFlag) {
      isStepComplete = false;

    } else if (currentVisibleStepIndex <= visibleStepIndex) {
      isStepComplete = false;
    }

    return isStepComplete;
  }


  // is the previous step complete?
  isPreviousStepComplete(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);

    const previousVisibleStep = this.previousVisibleStep(visibleStep);

    let isPreviousStepComplete = false;

    if (previousVisibleStep) {
      const previousVisibleStepIndex = this.indexOfStep(previousVisibleStep);

      const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

      isPreviousStepComplete = (previousVisibleStepIndex < currentVisibleStepIndex);

      isPreviousStepComplete = isPreviousStepComplete && previousVisibleStep.enabledFlag;
    }


    return isPreviousStepComplete;
  }


  // is the next step complete?
  isNextStepComplete(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);

    const nextVisibleStep = this.nextVisibleStep(visibleStep);
    const nextVisibleStepIndex = this.indexOfStep(nextVisibleStep);

    const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

    let completeFlag = (nextVisibleStepIndex < currentVisibleStepIndex);

    if (nextVisibleStep && !nextVisibleStep.enabledFlag) {
      completeFlag = false;
    }

    return completeFlag;
  }


  // is the next step current?
  isNextStepCurrent(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);

    const nextVisibleStep = this.nextVisibleStep(visibleStep);
    const nextVisibleStepIndex = this.indexOfStep(nextVisibleStep);

    const currentVisibleStepIndex = this.indexOfStep(this.currentVisibleStep);

    return (nextVisibleStepIndex === currentVisibleStepIndex);
  }

  // is the next step enabled?
  isNextStepEnabled(step: PlanOrderProgressBarStep): boolean {
    const visibleStep = this.visibleStepForStep(step);

    const nextVisibleStep = this.nextVisibleStep(visibleStep);

    let enabled = false;

    if (nextVisibleStep) {
      enabled = nextVisibleStep.enabledFlag;
    }

    return enabled;
  }


  // returns the appropriate image for the step
  imageSrcForStep(step: PlanOrderProgressBarStep): string {
    const visibleStep = this.visibleStepForStep(step);

    const isFirstStep = this.isFirstStep(visibleStep);
    const isLastStep = this.isLastStep(visibleStep);
    const isStepCurrent = this.isStepCurrent(visibleStep);
    const isStepComplete = this.isStepComplete(visibleStep);
    const isStepEnabled = visibleStep.enabledFlag;
    const isNextStepEnabled = this.isNextStepEnabled(visibleStep);
    const isPreviousStepComplete = this.isPreviousStepComplete(visibleStep);

    // now build the image name
    let imageName = 'progress-';

    if (isFirstStep) {
      imageName += 'n';

    } else if (isPreviousStepComplete && isStepEnabled) {
      imageName += 'c';

    } else {
      imageName += 'p';
    }

    imageName += '-';

    if (isStepComplete) {
      imageName += 'complete';

    } else if (isStepCurrent) {
      imageName += 'current';

    } else {
      imageName += 'pending';
    }

    imageName += '-';

    if (isLastStep) {
      imageName += 'n';

    } else if (isStepComplete && isNextStepEnabled) {
      imageName += 'c';

    } else {
      imageName += 'p';
    }

    const imageSrc = this.progressBarImageService.base64ImageByName(imageName);

    return imageSrc;
  }


  // returns the image for the left of the step marker
  imageSrcForStepLineLeft(step: PlanOrderProgressBarStep): string {
    const visibleStep = this.visibleStepForStep(step);

    const isFirstStep = this.isFirstStep(visibleStep);
    const isPreviousStepComplete = this.isPreviousStepComplete(visibleStep);

    let imageName = '';

    if (isFirstStep) {
      imageName = 'clear-1x1';

    } else if (isPreviousStepComplete && step.enabledFlag) {
      imageName = 'progress-line-complete';

    } else {
      imageName = 'progress-line-pending';
    }

    const imageSrc = this.progressBarImageService.base64ImageByName(imageName);

    return imageSrc;
  }


  // returns the image for the right of the step marker
  imageSrcForStepLineRight(step: PlanOrderProgressBarStep): string {
    const visibleStep = this.visibleStepForStep(step);

    const isStepComplete = this.isStepComplete(visibleStep);
    const isLastStep = this.isLastStep(visibleStep);
    const isNextStepComplete = this.isNextStepComplete(visibleStep);
    const isNextStepCurrent = this.isNextStepCurrent(visibleStep);

    let imageName = '';

    if (isLastStep) {
      imageName = 'clear-1x1';

    } else if (isStepComplete && (isNextStepComplete || isNextStepCurrent)) {
      imageName = 'progress-line-complete';

    } else {
      imageName = 'progress-line-pending';
    }

    const imageSrc = this.progressBarImageService.base64ImageByName(imageName);

    return imageSrc;

  }


  // should the step link be enabled?
  shouldEnableStepLink(step: PlanOrderProgressBarStep): boolean {
    let enableLink = false;

    if (step.enabledFlag) {
      const stepIndex = this.indexOfStep(step);
      const currentStepIndex = this.indexOfStep(this.currentStep);

      if (stepIndex < currentStepIndex) {
        enableLink = true;
      }
    }

    return enableLink;
  }


}


