import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {routes} from './app.routes';

import { AppComponent } from './app.component';

import {PlanOrderModule} from './_modules/plan-order/plan-order.module';
import {OfficeAgentLookupModule} from './_modules/office-agent-lookup/office-agent-lookup.module';
import {PhxCommonModule, PhxErrorService} from '@orhp/phx-common-ui-module';
import {PhxCustomerModule} from '@orhp/phx-customer-ui-module';
import {PlanOrderComponent} from './plan-order/plan-order.component';
import {NavbarComponent} from "./navbar/navbar.component";
import {HomeModule} from "./home/home.module";
import {LoginModule} from "./login/login.module";
import {UserModule} from "./user/user.module";
import {DebugComponent} from "./debug/debug.component";
import {PhxSystemModule} from '@orhp/phx-system-ui-module';
import {NavbarService} from './navbar/service/navbar.service';
import {DebugGuard} from './debug/debug.guard';
import {LegacyToolboxComponent} from './legacy-toolbox/legacy-toolbox.component';
import {ToolboxSettingsService} from './_services/toolbox-settings.service';
import {ResetGuard} from './_services/reset.guard';
import {LegacyToolboxService} from './_services/legacy-toolbox.service';
import {AppSettingsGuard} from './_services/app-settings.guard';

import {APP_BASE_HREF} from '@angular/common';
import {CustomUrlParser} from './_src/custom-url-parser';
import {NoAutoLoginGuard} from './_services/no-auto-login-guard';


@NgModule({
  declarations: [
    AppComponent,
    PlanOrderComponent,
    NavbarComponent,
    DebugComponent,
    LegacyToolboxComponent
  ],
  imports: [
    BrowserModule,
    PhxCommonModule,
    PhxCustomerModule,
    PhxSystemModule,
    PlanOrderModule,
    OfficeAgentLookupModule,
    HomeModule,
    LoginModule,
    UserModule,
    FormsModule,
    routes
  ],
  providers: [
    AppSettingsGuard,
    NoAutoLoginGuard,
    ResetGuard,
    NavbarService,
    ToolboxSettingsService,
    LegacyToolboxService,
    DebugGuard,
    {
      provide: ErrorHandler,
      useClass: PhxErrorService
    },
    { provide: APP_BASE_HREF,
      useValue: CustomUrlParser.getBaseHref()
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
