import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map'
import {PhxHttpService, PhxErrorService} from '@orhp/phx-common-ui-module';
import {CustomerLookupValueService} from '@orhp/phx-customer-ui-module';

@Injectable()
export class UserService {
    constructor(
        private phxHttp: PhxHttpService,
        private errorService: PhxErrorService,
        private customerLookupValueService: CustomerLookupValueService
    ) { }

    /***
     * populate the {Customer} object for the currently logged in {Login}
     *
     * @returns {any}
     */
    loadCurrentCustomer(){
        return this.phxHttp.get('user/currentCustomer');
    }

    loadCurrentGuest() {
        return this.phxHttp.get('user/currentGuest');
    }

    loadCurrentUserTerritories() {
      return this.phxHttp.get('user/territories');
    }
}
