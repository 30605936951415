import {Routes} from '@angular/router';
import {ResetAccountComponent} from "./reset-account/reset-account.component";
import {SignUpComponent} from "./sign-up/sign-up.component";
import {OfficeAgentLookupRoutes} from "../_modules/office-agent-lookup/office-agent-lookup.routes";
import {ProfileComponent} from "./profile/profile.component";
import {ValidateEmailComponent} from "./validate-email/validate-email.component";
import {AuthGuard} from "../login/service/auth.guard";
import {ResendValidationComponent} from "./resend-validation/resend-validation.component";

export const UserRoutes: Routes = [
    {path: 'account',
        children: [
            {path: 'reset', component: ResetAccountComponent},
            {path: 'reset/:id', component: ResetAccountComponent},
            {path: 'validate/:id', component: ValidateEmailComponent},
            {path: 'resend/validation/:email', component: ResendValidationComponent}
        ]
    },
    {path: 'my-profile', component: ProfileComponent, children: OfficeAgentLookupRoutes, canActivate: [AuthGuard]},
    {path: 'signup', component: SignUpComponent, children: OfficeAgentLookupRoutes}
];
