import {EventEmitter, Injectable} from '@angular/core';
import {
  ActivityResult,
  ActivityResultError,
  LocalStorageService,
  PhxHttpClientService,
  PhxHttpService,
  PhxSerializer
} from '@orhp/phx-common-ui-module';
import {PlanOrderApplication, PlanOrderApplicationUpdates} from '../_models/plan-order-application';
import {PlanOrderProgress} from '../_models/plan-order-progress';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs/Subscriber';
import {
  Customer,
  CustomerDomainFactoryService,
  CustomerLookupValueService,
  CustomerUpdateService,
  Employer,
  EmployerDomainFactoryService,
  EmployerUpdateService
} from '@orhp/phx-customer-ui-module';
import {
  IProperty,
  Property,
  PropertyDomainFactoryService,
  PropertyLookupValueService,
  PropertyUpdateService
} from '@orhp/phx-property-ui-module';
import {Response} from '@angular/http';
import {PlanOrderService} from './plan-order.service';
import {PlanUpdateService} from '@orhp/phx-plan-ui-module';
import {PlanInvoicePaymentRequest, PlanInvoicePaymentResult, PlanInvoiceService} from '@orhp/phx-accounting-ui-module';
import {HttpErrorResponse} from '@angular/common/http';
import {PlanOrderConfirmationRequest} from '../_src/plan-order-confirmation-request';


@Injectable()


export class PlanOrderUpdateService {

  constructor(private http: PhxHttpService,
              private httpClient: PhxHttpClientService,
              private localStorageService: LocalStorageService,
              private planUpdateService: PlanUpdateService,
              private planOrderService: PlanOrderService,
              private propertyDomainFactoryService: PropertyDomainFactoryService,
              private employerDomainFactoryService: EmployerDomainFactoryService,
              private customerDomainFactoryService: CustomerDomainFactoryService,
              private employerUpdateService: EmployerUpdateService,
              private customerUpdateService: CustomerUpdateService,
              private customerLookupValueService: CustomerLookupValueService,
              private propertyLookupValueService: PropertyLookupValueService,
              private propertyUpdateService: PropertyUpdateService,
              private planInvoiceService: PlanInvoiceService
  ) { }



  applicationUpdateInProcess = false;

  updateProgressEventEmitter: EventEmitter<PlanOrderProgress> = new EventEmitter();


  // submits the application for update
  submitApplication(application: PlanOrderApplication): Observable<PlanOrderProgress> {
    const observable: Observable<PlanOrderProgress> = Observable.create((subscriber: Subscriber<PlanOrderProgress>) => {
      let observableContainer: PlanOrderObservableContainer = null;
      const observables: PlanOrderObservableContainer[] = [];

      // create new initiating agent office
      if (application.initiatingAgentOffice && application.initiatingAgentOffice.hasUpdateFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitEmployerUpdate(application, application.initiatingAgentOffice);
        observableContainer.statusMessage = 'Saving Initiating Agent Office';

        observables.push(observableContainer);
      }


      // create new cooperating agent office
      if (application.cooperatingAgentOffice && application.cooperatingAgentOffice.hasUpdateFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitEmployerUpdate(application, application.cooperatingAgentOffice);
        observableContainer.statusMessage = 'Saving Cooperating Agent Office';

        observables.push(observableContainer);
      }


      // create new closing office
      if (application.closingOffice && application.closingOffice.hasUpdateFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitEmployerUpdate(application, application.closingOffice);
        observableContainer.statusMessage = 'Saving Closing Office';

        observables.push(observableContainer);
      }


      // update initiating agent
      if (application.initiatingAgent && application.initiatingAgent.hasUpdateFlag) {
        application.initiatingAgent.employer = application.initiatingAgentOffice;

        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitCustomerUpdate(application, application.initiatingAgent);
        observableContainer.statusMessage = 'Saving Initiating Agent';

        observables.push(observableContainer);

      }


      // update cooperating agent
      if (application.cooperatingAgent && application.cooperatingAgent.hasUpdateFlag) {
        application.cooperatingAgent.employer = application.cooperatingAgentOffice;

        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitCustomerUpdate(application, application.cooperatingAgent);
        observableContainer.statusMessage = 'Saving Cooperating Agent';

        observables.push(observableContainer);
      }


      // update closing officer
      if (application.closingAgent && application.closingAgent.hasUpdateFlag) {
        application.closingAgent.employer = application.closingOffice;

        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitCustomerUpdate(application, application.closingAgent);
        observableContainer.statusMessage = 'Saving Closing Agent';

        observables.push(observableContainer);
      }


      // update home buyers
      const homeBuyers = (application.homeBuyers || []);

      for (let customerIndex = 0; customerIndex < homeBuyers.length; customerIndex++) {
        const indexCustomer = homeBuyers[customerIndex];

        if (indexCustomer.hasUpdateFlag) {
          observableContainer = new PlanOrderObservableContainer();
          observableContainer.observable = this.submitCustomerUpdate(application, indexCustomer);
          observableContainer.statusMessage = 'Saving Home Buyer #' + (customerIndex + 1);

          observables.push(observableContainer);
        }
      }

      // update home sellers
      const homeSellers = (application.homeSellers || []);

      for (let customerIndex = 0; customerIndex < homeSellers.length; customerIndex++) {
        const indexCustomer = homeSellers[customerIndex];

        if (indexCustomer.hasUpdateFlag) {
          observableContainer = new PlanOrderObservableContainer();
          observableContainer.observable = this.submitCustomerUpdate(application, indexCustomer);
          observableContainer.statusMessage = 'Saving Home Seller #' + (customerIndex + 1);

          observables.push(observableContainer);
        }
      }


      // update property
      if (application.property.hasUpdateFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitPropertyUpdate(application, application.property);
        observableContainer.statusMessage = 'Saving Property Address';

        observables.push(observableContainer);
      }


      // update mailing address
      if (application.hasUpdate(PlanOrderApplicationUpdates.mailingAddress)) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitMailingAddressUpdate(application);
        observableContainer.statusMessage = 'Saving Mailing Address';

        observables.push(observableContainer);
      }



      // should a credit card payment be made?
      let creditCardPaymentFlag = false;
      const paymentUpdateFlag = application.hasUpdate(PlanOrderApplicationUpdates.payment);

      // if we're paying immediately
      if (application.payNowFlag) {
        // if it's a new plan, submit the payment
        if (!application.planID) {
          creditCardPaymentFlag = true;
        }

        // if there's a payment update, submit the payment
        if (paymentUpdateFlag) {
          creditCardPaymentFlag = true;
        }
      }


      // if a credit card payment is being made, don't send confirmations immediately
      if (creditCardPaymentFlag) {
        application.sendConfirmationsOnSaveFlag = false;
      }



      // update application
      let updatePlanFlag = true;
      updatePlanFlag = updatePlanFlag && (application.updates.length > 0);

      const planStatus = application.planStatus;

      if (planStatus && !planStatus.editableFlag) {
        updatePlanFlag = false;
      }

      if (updatePlanFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitApplicationUpdate(application);
        observableContainer.statusMessage = 'Saving Application';

        observables.push(observableContainer);
      }




      // if we're performing a credit card payment
      if (creditCardPaymentFlag) {
        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.submitCreditCardPayment(application);
        observableContainer.statusMessage = 'Processing Payment';

        observables.push(observableContainer);
      }


      // if we need to send order confirmations
      if (!application.sendConfirmationsOnSaveFlag) {
        const newPlanFlag = !application.planID;

        observableContainer = new PlanOrderObservableContainer();
        observableContainer.observable = this.sendOrderConfirmations(application, newPlanFlag);
        observableContainer.statusMessage = 'Sending Confirmations/Invoices';

        observables.push(observableContainer);
      }


      // set that the application confirmation hasn't been sent
      application.confirmationSentFlag = false;

      this.planOrderService.persistPlanOrderApplication(application);



      setTimeout(() => {
        this.applicationUpdateInProcess = true;

        this.dequeueObservable(subscriber, application, observables, 0);
      });

    });

    return observable;
  }


  // dequeues another observable
  dequeueObservable(subscriber: Subscriber<PlanOrderProgress>,
                    application: PlanOrderApplication,
                    observableContainers: PlanOrderObservableContainer[],
                    observableIndex: number = 0,
                    previousProgress?: PlanOrderProgress) {

    try {

      // if there's another observable left
      if (observableIndex < observableContainers.length) {
        const observableContainer = observableContainers[observableIndex];

        console.log(observableContainer.statusMessage);

        const percentComplete = Math.floor(((observableIndex + 1) / (observableContainers.length + 1)) * 100);
        const progress = new PlanOrderProgress(percentComplete, observableContainer.statusMessage, true);

        subscriber.next(progress);

        this.updateProgressEventEmitter.emit(progress);

        observableContainer.observable.subscribe((result: PlanOrderUpdateResult) => {
          // if the result was successful
          if (result.successFlag) {
            // dequeue the next observable
            this.dequeueObservable(subscriber, application, observableContainers, (observableIndex + 1), progress);

            // if the result was not successful
          } else {
            application.saveErrors = result.errors;

            this.planOrderService.persistPlanOrderApplication(application);

            const failedProgress = new PlanOrderProgress(100, result.resultMessage, false);

            subscriber.next(failedProgress);
            subscriber.complete();
          }
        });

      // if there are no observables left
      } else {
        application.saveErrors = null;

        this.applicationUpdateInProcess = false;

        const completeProgress = new PlanOrderProgress(100, 'Complete', true);
        subscriber.next(completeProgress);

        this.updateProgressEventEmitter.emit(completeProgress);

        subscriber.complete();
      }

    } catch (error) {
      this.planOrderService.planOrderApplication.logError('phx.toolbox.ui.DequeueError', error);
      this.planOrderService.persistPlanOrderApplication();
    }
  }



  // submits an employer update
  submitEmployerUpdate(application: PlanOrderApplication, employer: Employer): Observable<PlanOrderUpdateResult> {

    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericEmployerUpdateError';
    genericError.message = 'There was an error while updating an Office';

    const observable: Observable<PlanOrderUpdateResult> =
      Observable.create((subscriber: Subscriber<PlanOrderUpdateResult>) => {

      const returnResult = new PlanOrderUpdateResult();

      this.employerUpdateService
        .performEmployerUpdate(employer)
        .subscribe((updateResult: ActivityResult) => {

        const updatedEmployer = updateResult.object;

        // if the employer was valid
        if (updatedEmployer && updatedEmployer.employerID) {
          this.employerDomainFactoryService.populateEmployer(employer, updatedEmployer.jsonValues());

          returnResult.successFlag = true;

        // if the employer wasn't valid
        } else {
          returnResult.successFlag = false;

          if (updateResult.errors.length) {
            returnResult.addErrorsFromResult(updateResult);

          } else {
            returnResult.errors.push(genericError);
          }
        }

        subscriber.next(returnResult);
        subscriber.complete();

      }, (error: any) => {
        returnResult.successFlag = false;
        returnResult.errors.push(genericError);

        subscriber.next(returnResult);
        subscriber.complete();
      });
    });

    return observable;
  }


  // submits a customer update
  submitCustomerUpdate(application: PlanOrderApplication, customer: Customer): Observable<PlanOrderUpdateResult> {

    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericCustomerUpdateError';
    genericError.message = 'There was an error while updating an Agent/Buyer/Seller';

    const observable: Observable<PlanOrderUpdateResult> =
      Observable.create((subscriber: Subscriber<PlanOrderUpdateResult>) => {

      const returnResult = new PlanOrderUpdateResult();

      this.customerUpdateService
        .performCustomerUpdate(customer)
        .subscribe((updateResult: ActivityResult) => {

        const returnedCustomer = <Customer>updateResult.object;

        // if a valid returned customer was found
        if (returnedCustomer && returnedCustomer.customerID) {
          const returnedCustomerJsonObject = returnedCustomer.jsonValues();

          this.customerDomainFactoryService.populateCustomer(customer, returnedCustomerJsonObject);

          returnResult.successFlag = true;

        // if no valid customer was returned
        } else {
          returnResult.successFlag = false;

          // if there were any errors
          if (updateResult.errors.length) {
            returnResult.addErrorsFromResult(updateResult);

          // include a generic error
          } else {
            returnResult.errors.push(genericError);
          }
        }

        subscriber.next(returnResult);
        subscriber.complete();

      }, (error: any) => {
        returnResult.successFlag = false;
        returnResult.errors.push(genericError);

        subscriber.next(returnResult);
        subscriber.complete();
      });
    });

    return observable;
  }


  // submits a property update
  submitPropertyUpdate(application: PlanOrderApplication, property: Property): Observable<PlanOrderUpdateResult> {

    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericPropertyUpdateError';
    genericError.message = 'There was an error while updating the Property Address';

    const observable: Observable<PlanOrderUpdateResult> =
      Observable.create((subscriber: Subscriber<PlanOrderUpdateResult>) => {

      const updateResult = new PlanOrderUpdateResult();

      this.propertyUpdateService
        .performPropertyUpdate(property)
        .subscribe((result: ActivityResult) => {

        const propertyJsonObject = <IProperty>result.object;

        if (propertyJsonObject && propertyJsonObject.propertyID) {
          this.propertyDomainFactoryService.populateProperty(property, propertyJsonObject);

          updateResult.successFlag = true;

        } else {
          updateResult.successFlag = false;

          if (result.errors.length) {
            updateResult.addErrorsFromResult(result);

          } else {
            updateResult.errors.push(genericError);
          }
        }

        subscriber.next(updateResult);
        subscriber.complete();

      }, (error: any) => {
        updateResult.successFlag = false;
        updateResult.errors.push(genericError);

        subscriber.next(updateResult);
        subscriber.complete();
      });
    });

    return observable;
  }




  // submits a mailing address update
  submitMailingAddressUpdate(application: PlanOrderApplication): Observable<PlanOrderUpdateResult> {

    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericMailingAddressUpdateError';
    genericError.message = 'There was an error while updating the Mailing Address';

    const observable: Observable<PlanOrderUpdateResult> =
      Observable.create((subscriber: Subscriber<PlanOrderUpdateResult>) => {

      const updateResult = new PlanOrderUpdateResult();

      this.planUpdateService.updateMailingAddress(application.planID, application.mailingAddress)
        .subscribe((resultFlag: boolean) => {

        updateResult.successFlag = resultFlag;

        if (!resultFlag) {
          updateResult.errors.push(genericError);
        }

        subscriber.next(updateResult);
        subscriber.complete();

      }, (error: any) => {
        updateResult.successFlag = false;
        updateResult.errors.push(genericError);

        subscriber.next(updateResult);
        subscriber.complete();
      });
    });

    return observable;
  }



  // submits an application update
  submitApplicationUpdate(application: PlanOrderApplication): Observable<PlanOrderUpdateResult> {

    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericPlanUpdateError';
    genericError.message = 'There was an error while updating your Application';

    const observable: Observable<PlanOrderUpdateResult> =
      Observable.create((subscriber: Subscriber<PlanOrderUpdateResult>) => {

      const url = 'planOrder/put';
      const formBody = JSON.stringify(application.jsonValues());

      const updateResult = new PlanOrderUpdateResult();

      this.http.post(url, formBody).subscribe((response: Response) => {
        const responseResult = new ActivityResult(response.json());

        // if the update was successful
        if (responseResult.object && responseResult.object.guid) {
          application.planID = PhxSerializer.toInteger(responseResult.object.planID);
          application.planGUID = (responseResult.object.guid as string);

          this.planOrderService.persistPlanOrderApplication();

          updateResult.successFlag = true;

        // if the update was not successful
        } else {
          updateResult.successFlag = false;

          if (responseResult.errors.length) {
            updateResult.addErrorsFromResult(responseResult);

          } else {
            updateResult.errors.push(genericError);

          }
        }

        subscriber.next(updateResult);
        subscriber.complete();

      }, (error: Response) => {
        updateResult.successFlag = false;
        updateResult.errors.push(genericError);

        subscriber.next(updateResult);
        subscriber.complete();
      });

    });

    return observable;
  }



  submitCreditCardPayment(application: PlanOrderApplication): Observable<PlanOrderUpdateResult> {
    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericCreditCardPaymentError';
    genericError.message = 'There was an error while processing your payment';

    const observable = new Observable((subscriber: Subscriber<PlanOrderUpdateResult>) => {
      const paymentRequest = new PlanInvoicePaymentRequest();
      paymentRequest.planID = application.planID;
      paymentRequest.paymentNumbers = 1;
      paymentRequest.creditCardFlag = true;
      paymentRequest.directProcessFlag = application.directProcessFlag;

      const updateResult = new PlanOrderUpdateResult();
      updateResult.successFlag = false;

      this.planInvoiceService.processPayment(paymentRequest)
        .subscribe((paymentResult: ActivityResult<PlanInvoicePaymentResult>) => {
          application.ccPaymentResult = paymentResult;

          this.planOrderService.persistPlanOrderApplication();

          // was the payment successful?
          const invoicePaymentResult = (paymentResult ? paymentResult.object : null);
          const creditCardSaleResult = (invoicePaymentResult ? invoicePaymentResult.creditCardSalesResult : null);

          if (creditCardSaleResult && creditCardSaleResult.successFlag) {
            updateResult.successFlag = true;
          }

          subscriber.next(updateResult);
          subscriber.complete();

        }, (error: HttpErrorResponse) => {
          const ccPaymentResult = new ActivityResult<PlanInvoicePaymentResult>();
          ccPaymentResult.errors.push(genericError);

          genericError.message = 'Error processing payment: ' + error.message;

          application.ccPaymentResult = ccPaymentResult;

          this.planOrderService.persistPlanOrderApplication();

          subscriber.next(updateResult);
          subscriber.complete();
        }
      );

    });

    return observable;
  }





  // sends order confirmations
  sendOrderConfirmations(application: PlanOrderApplication, newPlanFlag: boolean): Observable<PlanOrderUpdateResult> {
    const genericError = new ActivityResultError();
    genericError.identifier = 'phx.toolbox.ui.GenericOrderConfirmationError';
    genericError.message = 'There was an error while processing your payment';

    const observable = new Observable((subscriber: Subscriber<PlanOrderUpdateResult>) => {
      const request = new PlanOrderConfirmationRequest();
      request.newPlanFlag = true;
      request.planID = application.planID;
      request.additionalEmailAddresses = application.additionalEmailAddresses;

      if (application.property) {
        request.propertyID = application.property.propertyID;
      }

      if (application.product) {
        request.productID = application.product.productID;
      }

      if (application.initiatingAgent) {
        request.initiatingAgentCustomerID = application.initiatingAgent.customerID;
      }

      const updateResult = new PlanOrderUpdateResult();

      // return success
      updateResult.successFlag = true;

      application.confirmationSentFlag = true;
      this.planOrderService.persistPlanOrderApplication();

      // send order confirmations
      this.planOrderService.sendPlanOrderConfirmation(request).subscribe((result: ActivityResult) => {
        this.planOrderService.persistPlanOrderApplication();

        subscriber.next(updateResult);
        subscriber.complete();

      }, (error: HttpErrorResponse) => {
        const confSendResult = new ActivityResult<boolean>();
        confSendResult.errors.push(genericError);

        genericError.message = 'Error sending confirmation: ' + error.message;

        // application.ccPaymentResult = ccPaymentResult;

        this.planOrderService.persistPlanOrderApplication();

        subscriber.next(updateResult);
        subscriber.complete();
      });
    });

    return observable;
  }





}



class PlanOrderObservableContainer {
  observable: Observable<PlanOrderUpdateResult> = null;
  statusMessage = '';
}


class PlanOrderUpdateResult {
  successFlag = false;
  resultMessage = '';
  errors: ActivityResultError[] = [];


  // adds errors from a result
  addErrorsFromResult(result: ActivityResult) {
    if (result && result.errors) {
      result.errors.forEach((indexError: ActivityResultError) => {
        this.errors.push(indexError);
      });
    }
  }


  // adds a single error
  addError(identifier: string, message: string) {
    const error = new ActivityResultError();
    error.identifier = identifier;
    error.message = message;

    this.errors.push(error);
  }

}

