import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PlanOrderApplication} from '../../_models';
import {ArrayUtility, RoutingUtility} from '@orhp/phx-common-ui-module';
import {ActivatedRoute, Router, UrlSegment, UrlSerializer} from '@angular/router';
import {ConfirmationAutoSendMode} from '../review.component';
import {CorrespondenceLookupValuesService, InvoiceCommunicationType} from '@orhp/phx-correspondence-ui-module';
import {ProductOffice} from '@orhp/phx-product-ui-module';
import {PlanOrderAuthService} from '../../_services/plan-order-auth.service';
import {PlanOrderPaymentComponent} from '../../payment/payment.component';


@Component({
  selector: 'app-plan-order-review-payment',
  templateUrl: './review-payment.component.html',
  styleUrls: [
    '../../plan-order.scss',
    '../review.component.scss',
    './review-payment-component.scss'
  ]
})


export class PlanOrderReviewPaymentComponent {

  @Input()
  canEditApplication: boolean;

  @Input()
  planOrderApplication: PlanOrderApplication;

  @Output()
  onEditEventEmitter = new EventEmitter<boolean>();



  get productOfficeGroup(): ProductOffice {
    return this.planOrderAuthService.productOffice;
  }



  constructor(private router: Router,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private planOrderAuthService: PlanOrderAuthService,
              private correspondenceLookupValueService: CorrespondenceLookupValuesService
              ) {

  }



  didClickEditPayment() {
    this.onEditEventEmitter.emit(true);
  }



  shouldDisplayCreditCardDetail(): boolean {
    return ((this.planOrderApplication.creditCardGUID || '') !== '');
  }


  noCreditCardDetail(): boolean {
    return ((this.planOrderApplication.creditCardGUID || '') === '');
  }




  // what's the description we're using for the invoice
  invoiceDescription(): string {
    return PlanOrderPaymentComponent.invoiceDescriptionWithInfo(
      this.correspondenceLookupValueService,
      this.productOfficeGroup
    );
  }




}
