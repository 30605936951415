import {CustomerInvoiceRecipient} from '@orhp/phx-accounting-ui-module';

export class PlanOrderPaymentEditingEmail {

  constructor(customerInvoiceRecipient?: CustomerInvoiceRecipient) {
    if (customerInvoiceRecipient) {
      this.customerInvoiceRecipient = customerInvoiceRecipient;
      this.emailAddress = customerInvoiceRecipient.emailAddress;
    }
  }

  emailAddress: string = null;
  customerInvoiceRecipient: CustomerInvoiceRecipient = null;
  deletedFlag = false;

}
