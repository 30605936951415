import {Injectable} from "@angular/core";
import {Http, Response} from "@angular/http";
import {Observable} from "rxjs/Observable";
import {Subscriber} from "rxjs/Subscriber";
import {PhxHttpService} from '@orhp/phx-common-ui-module';

@Injectable()
export class CorrespondenceService {
    constructor(private http: Http, private phxHttp: PhxHttpService) {
    }


    canResendAnniversaryCard(planID: any): Observable<boolean> {
        const observable: Observable<boolean> =
            Observable.create((subscriber: Subscriber<boolean>) => {
                this.phxHttp.get('correspondence/canResendAnniversaryCard?planID=' + planID).subscribe(
                    (r) => {
                        const result = r.json();

                        subscriber.next(result.allow);
                        subscriber.complete();
                    }
                );
            });

        return observable;
    }

    resendAnniversaryCard(planID: any): Observable<boolean> {
        const observable: Observable<boolean> =
            Observable.create((subscriber: Subscriber<boolean>) => {
                const formBody = JSON.stringify({planID:planID});

                this.phxHttp.post('correspondence/resendAnniversaryCard', formBody).subscribe(
                    (r: Response) => {
                        const result = r.json();

                        subscriber.next(result.success);
                        subscriber.complete();
                    }
                );
            });

        return observable;
    }


    canResendCongratulationsCard(planID: any): Observable<boolean> {
        const observable: Observable<boolean> =
            Observable.create((subscriber: Subscriber<boolean>) => {
                this.phxHttp.get('correspondence/canResendCongratulationsCard?planID=' + planID).subscribe(
                    (r) => {
                        const result = r.json();

                        subscriber.next(result.allow);
                        subscriber.complete();
                    }
                );
            });

        return observable;
    }


    resendCongratulationsCard(planID: any): Observable<boolean> {
        const observable: Observable<boolean> =
            Observable.create((subscriber: Subscriber<boolean>) => {
                const formBody = JSON.stringify({planID:planID});

                this.phxHttp.post('correspondence/resendCongratulationsCard', formBody).subscribe(
                    (r: Response) => {
                        const result = r.json();

                        subscriber.next(result.success);
                        subscriber.complete();
                    }
                );
            });

        return observable;
    }

}
