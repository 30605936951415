

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OfficeAgentLookupService} from '../_services/lookup.service';
import {Customer, Employer} from '@orhp/phx-customer-ui-module';
import {RoutingUtility} from '@orhp/phx-common-ui-module';
import {CustomerPhone} from '@orhp/phx-customer-ui-module';
import {IAgentResolverInterface} from '../_src/agent-resolver-interface';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['../office-agent-lookup.scss']
})


export class AgentComponent implements OnInit {


  constructor(private router: Router,
              private route: ActivatedRoute,
              private officeAgentLookupService: OfficeAgentLookupService) {
  }


  customer: Customer = null;


  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      const customerData = <IAgentResolverInterface>data;

      this.customer = customerData.customer;
    });
  }



  shouldDisplayContactInfo(): boolean {
    let displayFlag = true;

    const lookupRelationshipType = this.officeAgentLookupService.lookupRelationshipType;

    if (lookupRelationshipType && lookupRelationshipType.escrowOfficerFlag) {
      if (this.customer.unknownEscrowFlag) {
        displayFlag = false;
      }
    }

    return displayFlag;
  }


}
